import React from 'react'
import { Alert, AlertIcon, AlertTitle, AlertDescription, Flex, Text, List, ListItem } from '@chakra-ui/react'
import { FreightPendenciesResult } from '../../../../services/endpoints/freights'
import { useAppSelector } from '../../../../store'

interface Props {
  pendencies?: FreightPendenciesResult
}

const FreightPendenciesAlert: React.FC<Props> = ({ pendencies }) => {
  const { routePointsHasPendencies } = useAppSelector(state => state.freightPendenciesSlice)
  return (
    <Alert variant="subtle" status="warning" borderRadius="md">
      <Flex direction="column" align="start" gridGap={4}>
        <Flex>
          <AlertIcon />
          <AlertTitle mr={2} fontSize="x-large">
            Ainda existem pendências a serem preenchidas!
          </AlertTitle>
        </Flex>
        <AlertDescription>
          {pendencies?.cnh && pendencies?.cnh.length > 0 && (
            <Flex direction={pendencies?.cnh.length <= 8 ? 'row' : 'column'} align="start" gridGap={2} ml={8}>
              <Text fontWeight="bold" fontSize="lg">
                CNH:
              </Text>
              <List spacing={1} styleType="none">
                <Flex direction={pendencies?.cnh.length >= 8 ? 'row' : 'column'} align="start" gridGap={2}>
                  {pendencies.cnh.map((item, index) => (
                    <ListItem>
                      <Text>
                        {item}
                        {index < pendencies.cnh.length - 1 && ', '}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {pendencies?.motorist && pendencies?.motorist.length > 0 && (
            <Flex
              direction={pendencies?.motorist.length <= 8 ? 'row' : 'column'}
              align="start"
              gridGap={2}
              ml={8}
            >
              <Text fontWeight="bold" fontSize="lg">
                Motorista:
              </Text>
              <List spacing={1} styleType="none">
                <Flex
                  direction={pendencies?.motorist.length <= 8 ? 'row' : 'column'}
                  align="start"
                  gridGap={2}
                >
                  {pendencies.motorist.map((item, index) => (
                    <ListItem>
                      <Text>
                        {item}
                        {index < pendencies.motorist.length - 1 && ','}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {pendencies?.vehicle && pendencies?.vehicle.length > 0 && (
            <Flex
              direction={pendencies?.vehicle.length <= 8 ? 'row' : 'column'}
              align="start"
              gridGap={2}
              ml={8}
            >
              <Text fontWeight="bold" fontSize="lg">
                Veículo:
              </Text>
              <List spacing={1} styleType="none">
                <Flex
                  direction={pendencies?.vehicle.length <= 8 ? 'row' : 'column'}
                  align="start"
                  gridGap={2}
                >
                  {pendencies.vehicle.map((item, index) => (
                    <ListItem>
                      <Text>
                        {item}
                        {pendencies.vehicle && index < pendencies.vehicle.length - 1 && ','}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {pendencies?.vehicleOwner && pendencies?.vehicleOwner.length > 0 && (
            <Flex
              direction={pendencies?.vehicleOwner.length <= 8 ? 'row' : 'column'}
              align="start"
              gridGap={2}
              ml={8}
            >
              <Text fontWeight="bold" fontSize="lg">
                Proprietário do Veículo:
              </Text>

              <List spacing={1} styleType="none">
                <Flex
                  direction={pendencies?.vehicleOwner.length <= 8 ? 'row' : 'column'}
                  align="start"
                  gridGap={2}
                >
                  {pendencies.vehicleOwner.map((item, index) => (
                    <ListItem>
                      <Text>
                        {item}
                        {pendencies.vehicleOwner && index < pendencies.vehicleOwner.length - 1 && ','}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {pendencies?.trailer && pendencies?.trailer.length > 0 && (
            <Flex
              direction={pendencies?.trailer.length <= 8 ? 'row' : 'column'}
              align="start"
              gridGap={2}
              ml={8}
            >
              <Text fontWeight="bold" fontSize="lg">
                Reboque:
              </Text>
              <List spacing={1} styleType="none">
                <Flex
                  direction={pendencies?.trailer.length <= 8 ? 'row' : 'column'}
                  align="start"
                  gridGap={2}
                >
                  {pendencies.trailer.map((item, index) => (
                    <ListItem>
                      <Text>
                        {item}
                        {index < pendencies.trailer.length - 1 && ','}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {pendencies?.trailerOwner && pendencies?.trailerOwner.length > 0 && (
            <Flex
              direction={pendencies?.trailerOwner.length <= 8 ? 'row' : 'column'}
              align="start"
              gridGap={2}
              ml={8}
            >
              <Text fontWeight="bold" fontSize="lg">
                Proprietário do Reboque:
              </Text>
              <List spacing={1} styleType="none" ml={8}>
                <Flex
                  direction={pendencies?.trailerOwner.length <= 8 ? 'row' : 'column'}
                  align="start"
                  gridGap={2}
                >
                  {pendencies.trailerOwner.map((item, index) => (
                    <ListItem ml={4}>
                      <Text>
                        {item}
                        {pendencies?.trailerOwner && index < pendencies?.trailerOwner.length - 1 && ','}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {pendencies?.anttOwner && pendencies?.anttOwner.length > 0 && (
            <Flex
              direction={pendencies?.anttOwner.length <= 8 ? 'row' : 'column'}
              align="start"
              gridGap={2}
              ml={8}
            >
              <Text fontWeight="bold" fontSize="lg">
                Proprietário ANTT:
              </Text>
              <List spacing={1} styleType="none">
                <Flex
                  direction={pendencies?.anttOwner.length <= 8 ? 'row' : 'column'}
                  align="start"
                  gridGap={2}
                >
                  {pendencies.anttOwner.map((item, index) => (
                    <ListItem>
                      <Text>
                        {item}
                        {index < pendencies.anttOwner.length - 1 && ','}
                      </Text>
                    </ListItem>
                  ))}
                </Flex>
              </List>
            </Flex>
          )}
          {routePointsHasPendencies && (
            <Flex ml={8} direction="row" align="start" gridGap={2}>
              <Text fontWeight="bold" fontSize="lg">
                Pontos de Rota:
              </Text>
              <Text>Não foram informados</Text>
            </Flex>
          )}
        </AlertDescription>
      </Flex>
    </Alert>
  )
}

export default FreightPendenciesAlert
