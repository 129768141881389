/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaRegTrashAlt, FaSearch } from 'react-icons/fa'
import { RiAddLine, RiEditLine, RiSearchLine } from 'react-icons/ri'
import { Input } from '../../../../components/form'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { useDeleteClientBusinessGroup } from '../../../../services/endpoints/clients/deleteClientBusinessGroup'
import { useGetClientBusinessGroup } from '../../../../services/endpoints/clients/getClientBusinessGroup'
import { ClientBusinessGroup } from '../../../../services/types'
import { CreateClientBusinessGroupModal } from '../modals/CreateClientBusinessGroupModal'
import { UpdateClientBusinessGroupModal } from '../modals/UpdateClientBusinessGroupModal'

type ClientBusinessGroupFilter = {
  name?: string
  page?: string
}

export function ListClientBusinessGroup(): JSX.Element {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<ClientBusinessGroupFilter>({})
  const [clientBusinessGroup, setClientBusinessGroup] = useState<ClientBusinessGroup | undefined>()
  const { updateQueryParams, getParams } = useQueryParamUpdater<ClientBusinessGroupFilter>()

  const toast = useToast()

  const {
    isOpen: isOpenCreateClientBusinessGroupModal,
    onClose: onCloseCreateClientBusinessGroupModal,
    onOpen: onOpenCreateClientBusinessGroupModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateClientBusinessGroupModal,
    onClose: onCloseUpdateClientBusinessGroupModal,
    onOpen: onOpenUpdateClientBusinessGroupModal,
  } = useDisclosure()

  const { data: clientBusinessGroupData, isLoading } = useGetClientBusinessGroup({
    ...filters,
    page,
    per_page: 10,
  })

  const {
    mutateAsync: mutateAsyncDelete,
    error: errorDelete,
    isError: isErrorDelete,
  } = useDeleteClientBusinessGroup({
    onSuccess: () => {
      queryClient.invalidateQueries('clientBusinessGroup')
    },
  })

  const { setValue, handleSubmit, formState } = useForm({})

  const { isSubmitting } = formState

  const handleFilter: SubmitHandler<ClientBusinessGroupFilter> = async data => {
    updateQueryParams({
      ...data,
      page: String(page),
    })
    setFilters(data)
  }

  useEffect(() => {
    updateQueryParams({
      ...filters,
      page: page > 1 ? page.toString() : undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters])

  useEffect(() => {
    const name = getParams('name')
    const pageParam = getParams('page')

    if (pageParam) {
      setPage(Number(pageParam))
    }

    if (name) {
      handleFilter({ name })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isErrorDelete) {
    toastError({ toast, error: errorDelete })
  }

  async function handleDeleteClientBusinessGroup(id: string): Promise<void> {
    await mutateAsyncDelete({ id })

    toast({
      title: 'Grupo deletado com sucesso!',
      status: 'success',
      duration: 5000,
      position: 'top-right',
      isClosable: true,
    })
  }

  return (
    <Layout hasBackground>
      <Flex direction="column" gridGap="4" mb={4}>
        <Heading size="lg" fontWeight="normal" display="flex" justifyContent="space-between">
          <Text>Grupos empresariais</Text>
          <Button
            size="sm"
            colorScheme="yellow"
            leftIcon={<Icon as={RiAddLine} />}
            onClick={onOpenCreateClientBusinessGroupModal}
          >
            Novo
          </Button>
        </Heading>

        <Flex>
          <form onSubmit={handleSubmit(handleFilter)} noValidate>
            <Grid templateColumns="repeat(12, 1fr)" gap="4">
              <GridItem colSpan={[12, 4]}>
                <Input name="name" setValue={setValue} placeholder="Nome" initialValue={filters.name} />
              </GridItem>

              <GridItem colSpan={[12, 4]}>
                <Button
                  type="submit"
                  size="md"
                  colorScheme="blue"
                  leftIcon={<Icon as={RiSearchLine} />}
                  isLoading={isSubmitting}
                  mt={1}
                >
                  Filtrar
                </Button>
              </GridItem>
            </Grid>
          </form>
        </Flex>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex gridGap="4" direction="column">
          <Table overflowX="scroll">
            <Thead>
              <Tr>
                <Th w="15%" />
                <Th>Nome</Th>
                <Th>Segmento</Th>
              </Tr>
            </Thead>
            <Tbody>
              {clientBusinessGroupData?.data &&
                clientBusinessGroupData?.data.length > 0 &&
                clientBusinessGroupData.data.map(group => (
                  <Tr key={group.id}>
                    <Td>
                      <Flex gridGap="2">
                        <Tooltip label="Editar">
                          <Button
                            size="sm"
                            colorScheme="yellow"
                            onClick={() => {
                              setClientBusinessGroup(group)
                              onOpenUpdateClientBusinessGroupModal()
                            }}
                            variant="outline"
                          >
                            <Icon as={RiEditLine} fontSize={16} />
                          </Button>
                        </Tooltip>

                        <Tooltip label="Deletar">
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => {
                              handleDeleteClientBusinessGroup(group.id!)
                            }}
                            variant="outline"
                          >
                            <Icon as={FaRegTrashAlt} fontSize={16} />
                          </Button>
                        </Tooltip>
                        <Tooltip label="Ver Clientes">
                          <Button
                            size="sm"
                            colorScheme="blue"
                            onClick={() => {
                              window.open(`/clients?clientBusinessGroupId=${group.id}`, '_blank')
                            }}
                          >
                            <Icon as={FaSearch} fontSize={18} />
                          </Button>
                        </Tooltip>
                      </Flex>
                    </Td>
                    <Td>{group.name}</Td>
                    <Td>{group.segment?.name}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>

          {clientBusinessGroupData?.data.length === 0 && (
            <Text textAlign="center" mt={2}>
              Nenhum dado encontrado
            </Text>
          )}

          <Pagination
            currentPage={page}
            totalCountOfRegisters={Number(clientBusinessGroupData?.total)}
            registersPerPage={10}
            onPageChange={setPage}
            mt="8"
          />
        </Flex>
      )}

      {isOpenCreateClientBusinessGroupModal && (
        <CreateClientBusinessGroupModal
          isOpen={isOpenCreateClientBusinessGroupModal}
          onClose={onCloseCreateClientBusinessGroupModal}
        />
      )}

      {isOpenUpdateClientBusinessGroupModal && (
        <UpdateClientBusinessGroupModal
          isOpen={isOpenUpdateClientBusinessGroupModal}
          onClose={onCloseUpdateClientBusinessGroupModal}
          clientBusinessGroup={clientBusinessGroup}
        />
      )}
    </Layout>
  )
}
