import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { MdAdd } from 'react-icons/md'
import { RiEditLine } from 'react-icons/ri'
import { FormattedOneFreight } from '../../services/endpoints/freights/getOneFreight'
import { IMonitoringOccurences } from '../../services/types/MonitoringOccurenceType'
import { CreateMonitoringOccurences } from './CreateMonitoringOccurences'
import { ShowMonitoringOccurences } from './ShowMonitoringOccurences'
import { UpdateMonitoringOccurences } from './UpdateMonitoringOccurences'

interface IMonitoringOccurrencesProps {
  freight: FormattedOneFreight
  isLoading?: boolean
  bg: string
}

export function MonitoringOccurrences({
  freight,
  isLoading = false,
  bg,
}: IMonitoringOccurrencesProps): JSX.Element {
  const [monitoringOccurrenceId, setMonitoringOccurrenceId] = useState<string>()
  const [updateMonitoringOccurrence, setUpdateMonitoringOccurrence] = useState<IMonitoringOccurences>()

  const {
    isOpen: isOpenShowMonitoringOccurrence,
    onOpen: onOpenShowMonitoringOccurrence,
    onClose: onCloseShowMonitoringOccurrence,
  } = useDisclosure()

  const {
    isOpen: isOpenCreateMonitoringOccurrence,
    onOpen: onOpenCreateMonitoringOccurrence,
    onClose: onCloseCreateMonitoringOccurrence,
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateMonitoringOccurrence,
    onOpen: onOpenUpdateMonitoringOccurrence,
    onClose: onCloseUpdateMonitoringOccurrence,
  } = useDisclosure()

  return (
    <Box bg={bg} p="4" borderRadius="8" mt="4">
      {isLoading ? (
        <Spinner />
      ) : (
        freight.monitoring_occurrences && (
          <>
            <Flex justify="space-between" align="center" direction={['column', 'column', 'row']} gridGap={4}>
              <Heading fontSize="md" fontWeight="medium">
                Ocorrências de monitoramento
              </Heading>
              <Button
                size="sm"
                colorScheme="orange"
                onClick={() => {
                  onOpenCreateMonitoringOccurrence()
                }}
                variant="outline"
                leftIcon={<Icon as={MdAdd} />}
              >
                Adicionar ocorrência
              </Button>
            </Flex>

            <Divider my="6" />

            <>
              {freight.monitoring_occurrences && freight.monitoring_occurrences.length > 0 ? (
                <TableContainer maxW="1100px">
                  {!isLoading ? (
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Data de criação</Th>
                          <Th>Tipo de monit.</Th>
                          <Th>Motivo</Th>
                          <Th w="200px">Observação</Th>
                          <Th>Usuário criador</Th>
                          <Th />
                        </Tr>
                      </Thead>
                      <Tbody flex={1}>
                        {freight.monitoring_occurrences.map(monitoringOccurrence => (
                          <Tr p={2} key={monitoringOccurrence.id}>
                            <Td maxW="100px">
                              {format(new Date(monitoringOccurrence.created_at), 'dd/MM/yyyy')}
                            </Td>
                            <Td textTransform="uppercase">{monitoringOccurrence.reason.monitoring_type}</Td>
                            <Td maxW="160px">
                              {monitoringOccurrence.reason.reason_name.length > 25
                                ? `${monitoringOccurrence.reason.reason_name.substring(0, 25)}...`
                                : monitoringOccurrence.reason.reason_name}
                            </Td>
                            <Td maxW="200px">
                              {monitoringOccurrence.observation.length > 20
                                ? `${monitoringOccurrence.observation.substring(0, 22)}...`
                                : monitoringOccurrence.observation}
                            </Td>
                            <Td>
                              {monitoringOccurrence.user.name.length > 25
                                ? `${monitoringOccurrence.user.name.substring(0, 25)}...`
                                : monitoringOccurrence.user.name}
                            </Td>

                            <Td p={2}>
                              <Flex justifyContent="space-between">
                                <Tooltip label="Visualizar registro" hasArrow placement="bottom">
                                  <Button
                                    size="sm"
                                    colorScheme="blue"
                                    marginLeft={2}
                                    onClick={() => {
                                      onOpenShowMonitoringOccurrence()
                                      setMonitoringOccurrenceId(monitoringOccurrence.id)
                                    }}
                                  >
                                    <Icon as={AiFillEye} />
                                  </Button>
                                </Tooltip>

                                {monitoringOccurrence.user.can_edit && (
                                  <Tooltip label="Editar registro" hasArrow placement="bottom">
                                    <Button
                                      size="sm"
                                      colorScheme="yellow"
                                      marginRight={2}
                                      onClick={() => {
                                        onOpenUpdateMonitoringOccurrence()
                                        setUpdateMonitoringOccurrence(monitoringOccurrence)
                                      }}
                                    >
                                      <Icon as={RiEditLine} />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Flex>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <>
                      <Flex justifyContent="center">
                        <Spinner />
                      </Flex>
                    </>
                  )}
                </TableContainer>
              ) : (
                <Flex justifyContent="center">
                  <Text>Nenhuma ocorrência registrada</Text>
                </Flex>
              )}
            </>

            {isOpenShowMonitoringOccurrence && monitoringOccurrenceId && (
              <ShowMonitoringOccurences
                isOpen={isOpenShowMonitoringOccurrence}
                onClose={onCloseShowMonitoringOccurrence}
                freight_id={freight.id}
                monitoringOccurrenceId={monitoringOccurrenceId}
              />
            )}

            {isOpenCreateMonitoringOccurrence && (
              <CreateMonitoringOccurences
                isOpen={isOpenCreateMonitoringOccurrence}
                onClose={onCloseCreateMonitoringOccurrence}
                freight_id={freight.id}
              />
            )}

            {isOpenUpdateMonitoringOccurrence && updateMonitoringOccurrence && (
              <UpdateMonitoringOccurences
                isOpen={isOpenUpdateMonitoringOccurrence}
                onClose={onCloseUpdateMonitoringOccurrence}
                monitoringOccurence={updateMonitoringOccurrence}
              />
            )}
          </>
        )
      )}
    </Box>
  )
}
