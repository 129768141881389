import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  StatHelpText,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine, RiStarFill } from 'react-icons/ri'
import React, { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { AxiosError } from 'axios'
import { InputMask } from '../../../../../../../components/form/InputMask'
import { SelectOption } from '../../../../../../../components/form/types/SelectOption'
import { toastError } from '../../../../../../../config/error/toastError'
import { useGetFreights } from '../../../../../../../services/endpoints/freights/getFreights'
import { buonnyItemTypesOptions } from '../../../../../../../services/utils/getBuonnyItemTypes'
import { validateCpf } from '../../../../../../../services/utils/validateCpf'
import { useAppDispatch } from '../../../../../../../store'
import { Select } from '../../../../../../../components/form/Select'
import { RadioGroup } from '../../../../../../../components/form/RadioGroup'
import { findMotoristByCpf } from '../../../../../../../services/endpoints/motorists/findMotoristByCpf'
import { FormattedMotorist } from '../../../../../../../services/endpoints/motorists/getMotorist'
import { queryClient } from '../../../../../../../config/react-query'
import { VehicleType } from '../../../../../../../services/types'
import { setFreightSuccess } from '../../../../../../../store/slices/freightSlice'
import { FormattedOneFreight } from '../../../../../../../services/endpoints/freights'
import { handleCpfIsBlackListed } from '../../../../../../black-list/check-document-exists/handleCpfIsBlackListed'
import BlackListType from '../../../../../../../services/types/BlackListType'
import { useCheckRiskByBuonny } from '../../../../../../../services/endpoints/risk-management/check-risk-by-buonny'
import { BuonnyResponseStatus } from '../../../../../../risk-analysis/modals/check-motorist-buonny-modal/CheckBuonny'
import { CheckRiskStatus } from '../../../../../../risk-analysis/show/cards/check-risk-ststus/CheckRiskStatus'
import { getTollTagsTransporters } from '../../../../../../../services/endpoints/truckpad/getTollTagsTransporters'

interface CheckBuonnyFormData {
  document: string
  carter: string
  license_plate: string
  body_license_plate: string
  buonny_cargo_type: SelectOption
  buonny_cargo_price: number
}

interface ModalProps {
  freight: FormattedOneFreight
  motorist?: FormattedMotorist
  vehicle?: VehicleType
}

const checkBuonnyFormSchema = yup.object().shape({
  document: yup
    .string()
    .test('validate-cpf', 'CPF inválido', value => {
      return validateCpf(value)
    })
    .min(11, 'CPF incompleto')
    .required('Campo obrigatório'),
  carter: yup.string().required('Campo obrigatório'),
  license_plate: yup.string().min(7, 'Placa inválida').required('Campo obrigatório'),
  body_license_plate: yup.string(),
  buonny_cargo_type: yup.string().required('Campo obrigatório'),
  buonny_cargo_price: yup.number().required('Campo obrigatório'),
})

export function CheckRiskMotorist({ freight, motorist, vehicle }: ModalProps): JSX.Element {
  const toast = useToast()
  const [isLoadingTollTags, setIsLoadingTollTags] = useState(true)
  const checkRiskByBuonny = useCheckRiskByBuonny({
    onSuccess: () => {
      queryClient.invalidateQueries(['freight', freight.id])
      queryClient.invalidateQueries('one-risk-analysis')
      queryClient.invalidateQueries('cost-risk-analysis')
      queryClient.invalidateQueries('check-cost-risk-analysis')
      toast({
        title: 'Consulta na Buonny realizada com sucesso',
        status: 'success',
        duration: 1000 * 5, // 5 seconds
        isClosable: true,
        position: 'top-right',
      })
    },
  })
  const dispatch = useAppDispatch()
  const [motoristFound, setMotoristFound] = useState(motorist)
  const bg = useColorModeValue('white', 'gray.800')
  const [blackList, setBlackList] = useState<BlackListType>()
  const [buonnyStatus, setBuonnyStatus] = useState<BuonnyResponseStatus>()
  const { data: motoristFreights } = useGetFreights({
    motorist_id: motoristFound?.id,
    status: 'finished',
    per_page: 5,
  })

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(checkBuonnyFormSchema),
  })

  const { errors } = formState

  const [tollTags, setTollTags] = useState<string[]>([])
  const handleCheckBuonny: SubmitHandler<CheckBuonnyFormData> = async data => {
    try {
      const plates = [data.license_plate]
      if (data.body_license_plate) {
        plates.push(data.body_license_plate)
      }

      const { data: response } = await checkRiskByBuonny.mutateAsync({
        product: 2,
        document: data.document,
        carter: data.carter,
        plates,
        buonny_cargo_type: String(data.buonny_cargo_type),
        buonny_cargo_price: String(data.buonny_cargo_price),
        freight_id: freight?.id,
        motorist_id: motoristFound?.id,
        vehicle_id: vehicle?.id,
      })

      setBuonnyStatus({
        motorist: response.data.motorist.name,
        document: response.data.motorist.cpf,
        vehicle: response.data.motorist.vehicle,
        trailer: response.data.motorist.trailer,
        vehicle_license_plate: response.data.motorist.vehicle_license_plate,
        trailer_license_plate: response.data.motorist.trailer_license_plate,
        status: response.data.buonny.status,
        message: response.data.buonny.mensagem,
        consult_number: response.data.buonny.consulta,
      })
    } catch (error) {
      const err = error as AxiosError
      if (
        err?.response?.data?.message &&
        String(err.response.data.message).includes('O CPF do profissional não consta no banco de dados')
      ) {
        toastError({
          toast,
          error: 'O CPF do profissional não consta no banco de dados da Buonny. Enviar ficha para cadastro.',
        })
      } else toastError({ toast, error })
    }
  }

  const handlePlateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const plateValue = e.target.value.toUpperCase()
    setValue('license_plate', plateValue)
    if (plateValue.length === 7) {
      try {
        const response = await getTollTagsTransporters({ plate: plateValue })
        if (response && response.length > 0) {
          const tags = response.map(tag => tag.provider)
          setTollTags(tags)
        } else {
          setTollTags([])
        }
      } catch (error) {
        // pass
      }
    }
  }

  useEffect(() => {
    setIsLoadingTollTags(false)
    if (vehicle?.license_plate && vehicle?.license_plate.length === 7) {
      setIsLoadingTollTags(true)
      getTollTagsTransporters({ plate: vehicle.license_plate }).then(response => {
        if (response && response.length > 0) {
          const tags = response.map(tag => tag.provider)
          setTollTags(tags)
        } else {
          setTollTags([])
        }
        setIsLoadingTollTags(false)
      })
    }
  }, [vehicle?.license_plate])

  return buonnyStatus ? (
    <Box>
      <CheckRiskStatus buonnyStatus={buonnyStatus} />
      <Flex justify="flex-end" mb={2}>
        <Button
          size="sm"
          colorScheme="blue"
          rightIcon={<FaArrowRight />}
          onClick={() => {
            dispatch(setFreightSuccess(null))
            if (motoristFound && vehicle) {
              window.location.href = `/freights/show/${freight?.id}/pendencies?motorist_id=${motoristFound.id}&vehicle_id=${vehicle.id}&integration=buonny`
            } else if (motoristFound && !vehicle) {
              window.location.href = `/freights/show/${freight?.id}/pendencies?motorist_id=${motoristFound.id}&integration=buonny`
            } else if (freight && !motoristFound && !vehicle)
              window.location.href = `/freights/show/${freight?.id}/pendencies?integration=buonny`
          }}
        >
          Continuar cadastros
        </Button>
      </Flex>
    </Box>
  ) : (
    <form onSubmit={handleSubmit(handleCheckBuonny)}>
      {motoristFound ? (
        <Heading fontWeight="normal" size="md" mb="6">
          Verificar junto à Buonny se o motorista <strong>{motoristFound.name}</strong> está apto a realizar o
          frete.
        </Heading>
      ) : (
        <Heading fontWeight="normal" size="md" mb="6">
          Verificar junto à Buonny se um motorista NÃO cadastrado está apto a realizar o frete.
        </Heading>
      )}

      {blackList && (
        <Alert
          mb={5}
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle fontSize="lg">CPF em restrição</AlertTitle>
          <AlertDescription mt="6">
            <Box>Esse CPF consta na lista de restrição, portando não pode ser consultado na Buonny.</Box>
          </AlertDescription>
        </Alert>
      )}

      <Stack spacing="4">
        <SimpleGrid minChildWidth="150px" spacing="4">
          <InputMask
            isRequired
            mask="999.999.999-99"
            autoFocus
            maskPlaceholder=""
            registerOnlyNumbers
            name="document"
            label="CPF motorista"
            setValue={setValue}
            error={errors.document}
            initialValue={motoristFound?.cpf}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 14) {
                const { value } = e.target
                const motoristInBlackList = await handleCpfIsBlackListed(value)
                if (motoristInBlackList) {
                  setBlackList(motoristInBlackList)
                } else {
                  setBlackList(undefined)
                }
                const findMotoristCpf = await findMotoristByCpf(value)
                if (findMotoristCpf) {
                  setMotoristFound(findMotoristCpf as FormattedMotorist)
                } else setMotoristFound(undefined)
              }
            }}
          />
          <RadioGroup
            name="carter"
            label="Carreteiro?"
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="S"
            setValue={setValue}
            error={errors.carter}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="150px" spacing="4">
          <div>
            <InputMask
              isRequired
              mask="aaa9*99"
              maskPlaceholder=""
              initialValue={vehicle?.license_plate}
              name="license_plate"
              label="Placa (cavalo)"
              error={errors.license_plate}
              onChange={handlePlateChange}
              isLoading={isLoadingTollTags}
              setValue={setValue}
              uppercaseAll
            />

            {tollTags.length > 0 ? (
              <Text color="blue.500" mt="2">
                Tags:{tollTags.join(', ')}
              </Text>
            ) : (
              <>
                {!isLoadingTollTags && (
                  <Text color="blue.500" mt="2">
                    Tags: Não Identificadas
                  </Text>
                )}
              </>
            )}
          </div>

          <div>
            <InputMask
              isRequired={vehicle?.vehicle_category.has_trailer}
              mask="aaa9*99"
              maskPlaceholder=""
              name="body_license_plate"
              label="Placa (carroceria)"
              setValue={setValue}
              error={errors.body_license_plate}
              initialValue={
                vehicle && vehicle.trailers.length > 0 ? vehicle.trailers[0]?.license_plate : undefined
              }
              uppercaseAll
            />
            {errors.body_license_plate && (
              <Text color="red.500" mt="2">
                {errors.body_license_plate.message}
              </Text>
            )}
          </div>
        </SimpleGrid>
        <SimpleGrid minChildWidth="150px" spacing="4">
          <Select
            isRequired
            name="buonny_cargo_type"
            label="Tipo de carga"
            setValue={setValue}
            options={[{ label: '', value: '' }, ...buonnyItemTypesOptions]}
            error={errors.buonny_cargo_type}
            initialValue={freight?.buonny_cargo_type}
          />
          <Select
            isRequired
            name="buonny_cargo_price"
            label={`Valor da carga (${freight.cargo_value})`}
            setValue={setValue}
            error={errors.buonny_cargo_price}
            options={[
              { label: '', value: '' },
              { label: 'De R$ 0,01 a R$ 100.000,00', value: '50000' },
              { label: 'De R$ 100.000,01 a R$ 200.000,00', value: '150000' },
              { label: 'De R$ 200.000,01 a R$ 300.000,00', value: '250000' },
              { label: 'De R$ 300.000,01 a R$ 400.000,00', value: '350000' },
              { label: 'De R$ 400.000,01 a R$ 500.000,00', value: '450000' },
              { label: 'De R$ 500.000,01 a R$ 800.000,00', value: '550000' },
              { label: 'De R$ 800.000,01 a R$ 1.000.000,00', value: '900000' },
              {
                label: 'De R$ 1.000.000,01 a R$ 3.000.000,00',
                value: '2000000',
              },
              {
                label: 'De R$ 3.000.000,01 a R$ 9.999.999,00',
                value: '5000000',
              },
            ]}
            initialValue={freight?.buonny_cargo_price}
          />
        </SimpleGrid>
      </Stack>

      {motoristFound && motoristFreights && motoristFreights.total > 0 && (
        <Box my="8" bgColor={bg} p="4" borderRadius="8" maxH="240px" overflowY="auto">
          <Heading fontWeight="normal" size="md" display="flex" flexDirection="row">
            Últimas avaliações do profissional
            {motoristFound.rate_score && (
              <Text ml={1}>
                - {motoristFound.rate_score}
                <Icon as={RiStarFill} fontSize={22} color="orange" mb="1" ml="1" />
              </Text>
            )}
            {motoristFreights.total && <Text ml={1}>- {motoristFreights.total} Fretes</Text>}
          </Heading>

          <VStack spacing="2" mt="4">
            {motoristFreights.data.map(motoristFreight => (
              <Box bg={bg} p="3" w="100%" border="1px" borderRadius="4" borderColor="gray.300">
                <Flex d="flex" alignItems="flex-end" justifyContent="space-between">
                  <Box>
                    <Text as="span" fontWeight="bold" mx="1" color="orange" fontSize="lg">
                      {motoristFreight.rate ? (
                        <>
                          <Icon as={RiStarFill} fontSize={22} color="orange" mb={2} mr={1} />
                          {motoristFreight.rate?.behavior_score}
                        </>
                      ) : (
                        'Frete não avaliado'
                      )}
                    </Text>
                    <Text as="span" fontWeight="bold" fontSize="md">
                      - {motoristFreight.origin.name}/{motoristFreight.origin.state.uf}
                    </Text>
                    -
                    <Text as="span" fontWeight="bold" fontSize="md">
                      {motoristFreight.destination.name}/{motoristFreight.destination.state.uf}
                    </Text>
                  </Box>
                  <Button
                    colorScheme="orange"
                    as={Link}
                    size="sm"
                    ml="2"
                    href={`/freights/show/${motoristFreight.id}`}
                    isExternal
                  >
                    Ver
                  </Button>
                </Flex>
                <StatHelpText>{motoristFreight.rate?.description}</StatHelpText>
              </Box>
            ))}
          </VStack>
        </Box>
      )}

      <Stack spacing="2" direction={['column', 'row']} mt="4" py="2" justify="flex-end">
        <Button
          size="sm"
          type="submit"
          colorScheme="green"
          isDisabled={!!blackList}
          leftIcon={<Icon as={RiCheckLine} />}
          isLoading={formState.isSubmitting || checkRiskByBuonny.isLoading}
        >
          Verificar
        </Button>
      </Stack>
    </form>
  )
}
