import { LoadingRequestStatusEnum } from '../types/EnumTypes'

export function getLoadingRequestColorByStatus(status: LoadingRequestStatusEnum): string {
  const loadingOrderStatusWithColor = {
    awaiting_analysis: 'gray.600',
    rejected: 'red.400',
    awaiting_cf: 'blue.500',
    awaiting_payment: 'blue.500',
    paid: 'yellow.500',
    finished: 'green.500',
  }

  return loadingOrderStatusWithColor[status]
}
