import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { RiCheckLine, RiDirectionLine } from 'react-icons/ri'
import { SelectOption } from '../../../../../components/form'
import { Select } from '../../../../../components/form/Select'
import { toastError } from '../../../../../config/error/toastError'
import { useAbility } from '../../../../../hooks/useAbility'
import { useCan } from '../../../../../hooks/useCan'
import { useForceFreightStatus } from '../../../../../services/endpoints/freights/forceFreightStatus'
import { freightStatusInProgressOptions } from '../../../../../services/endpoints/freights/getFreights'
import { FreightStatusOptions } from '../../../../../services/types/FreightType'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { setNextStatus } from '../../../../../store/slices/freightSlice'

export function SelectNextStatusModal(): JSX.Element | null {
  const { mutateAsync: forceFreightStatus, isLoading } = useForceFreightStatus()
  const isUsersCanForceStatus = useAbility('operation', 'force-freight-status')
  const isUserSuper = useCan({ roles: ['super'] })
  const {
    isOpen: isSetNextStatusOpen,
    onClose: onSetNextStatusClose,
    onOpen: onSetNextStatusOpen,
  } = useDisclosure()
  const dispatch = useAppDispatch()
  const { freight, nextStatus } = useAppSelector(state => state.freightSlice)
  const toast = useToast()
  const { handleSubmit, formState, setValue } = useForm({})

  const handleStatusChange = (): SelectOption[] => {
    const statusCurrent = freight?.status
    if (!statusCurrent) return []
    const index = freightStatusInProgressOptions.findIndex(option => option.value === statusCurrent)
    if (index === -1) return []
    return freightStatusInProgressOptions.slice(0, index)
  }

  const handleSetNextStatus = async () => {
    try {
      if (freight && nextStatus) {
        await forceFreightStatus({
          freight_id: freight.id,
          status: nextStatus.value as FreightStatusOptions,
        })

        onSetNextStatusClose()
        toast({
          title: 'Status alterado com sucesso!',
          status: 'success',
          position: 'top-right',
        })
      }
    } catch (error) {
      toastError({ toast, error })
    } finally {
      dispatch(setNextStatus(null))
    }
  }

  return freight ? (
    <Flex justify="space-between" gridGap="2" direction={['column', 'column', 'row']}>
      <>
        {isUsersCanForceStatus && freight.motorist_id && !isUserSuper ? (
          <Select
            fontSize="sm"
            borderRadius="full"
            name="status"
            setValue={setValue}
            h="30px"
            w="160px"
            placeholder="Forçar Status"
            onSelectOption={status => {
              dispatch(setNextStatus(status))
              onSetNextStatusOpen()
            }}
            loadOptionsSync={handleStatusChange}
          />
        ) : (
          isUserSuper && (
            <Select
              fontSize="sm"
              borderRadius="full"
              name="status"
              setValue={setValue}
              h="30px"
              w="160px"
              placeholder="Forçar Status"
              onSelectOption={status => {
                dispatch(setNextStatus(status))
                onSetNextStatusOpen()
              }}
              options={[{ label: 'Pendente', value: 'pending' }, ...freightStatusInProgressOptions]}
            />
          )
        )}
      </>
      <Modal
        isOpen={isSetNextStatusOpen}
        onClose={onSetNextStatusClose}
        isCentered
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleSetNextStatus)}>
            <ModalHeader>Alterar status do frete</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex align="center">
                O status do frete será alterado para
                <Tag size="lg" borderRadius="full" colorScheme="green" ml="4">
                  <TagLeftIcon as={RiDirectionLine} />
                  {nextStatus && <TagLabel>{nextStatus.label}</TagLabel>}
                </Tag>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onSetNextStatusClose}>
                Cancelar
              </Button>
              <Button
                onClick={handleSetNextStatus}
                colorScheme="orange"
                leftIcon={<Icon as={RiCheckLine} />}
                isLoading={isLoading || formState.isSubmitting}
              >
                Alterar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  ) : null
}
