import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

export type RoutePoint = {
  latitude: number
  longitude: number
}
export type ParamsFreightVehicleTags = {
  freight_id: string
  vehicle_license_plate: string
  tag_provider: string
  tag_number?: number
}

async function postFreightVehicleTags(data: ParamsFreightVehicleTags): Promise<AxiosResponse> {
  return apiServer.post('/create-freight-vehicle-tags/', data)
}

export function usePostFreightVehicleTags(
  options?: UseMutationOptions<AxiosResponse, unknown, ParamsFreightVehicleTags, unknown>,
): UseMutationResult<AxiosResponse, unknown, ParamsFreightVehicleTags, unknown> {
  return useMutation(async (data: ParamsFreightVehicleTags) => postFreightVehicleTags(data), options)
}
