import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import BlackListType from '../../types/BlackListType'

export type BlackListParams = {
  cpf?: string
  cnpj?: string
  license_plate?: string
}

export async function fetchOneBlackList(params: BlackListParams): Promise<BlackListType> {
  const { data: response } = await apiServer.get('/blacklist', {
    params,
  })
  return response.data
}

export function useGetOneBlackList(params: BlackListParams): UseQueryResult<BlackListType> {
  return useQuery(['blacklist', params], () => fetchOneBlackList(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes
    retry: false,
  })
}
