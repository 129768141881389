import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  GridItem,
  Heading,
  Icon,
  Image as ChakraImage,
  Input as ChakraInput,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiCheckLine, RiFileCopyLine, RiUploadCloudLine } from 'react-icons/ri'
import driverLicensePlaceholderImg from '../../../../../assets/driver-license-placeholder.png'
import { AutocompleteAsync } from '../../../../../components/form/AutocompleteAsync'
import { Input } from '../../../../../components/form/Input'
import { InputMask } from '../../../../../components/form/InputMask'
import { Select } from '../../../../../components/form/Select'
import { AutocompleteOption } from '../../../../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../../../../components/form/types/SelectOption'
import { toastError } from '../../../../../config/error/toastError'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import { usePostDriversLicenseInformations } from '../../../../../services/endpoints/documents/ocr/get-drivers-license-informations'
import { usePostConvertPdfToImage } from '../../../../../services/endpoints/documents/pdf/post-convert-pdf-to-image'
import { findMotoristAndOwnerByCpf } from '../../../../../services/endpoints/motorists/findMotoristAndOwnerByCpf'
import { findMotoristByCpf } from '../../../../../services/endpoints/motorists/findMotoristByCpf'
import { findOwnerByCnpj } from '../../../../../services/endpoints/owners/findOwnerByCnpj'
import { findOwnerByCpf } from '../../../../../services/endpoints/owners/findOwnerByCpf'
import { ResponseOcrCnh } from '../../../../../services/types'
import BlackListType from '../../../../../services/types/BlackListType'
import OwnerType from '../../../../../services/types/OwnerType'
import { copyToClipboard } from '../../../../../services/utils/copyToClipboard'
import { formatDateForInitialValue } from '../../../../../services/utils/dates/formatDateForInitialValue'
import { formatDateToEUA } from '../../../../../services/utils/dates/formatDateToCountry'
import { parseBase64ToImage } from '../../../../../services/utils/parseImageToBase64'
import { CEP, searchAddressByCep } from '../../../../../services/utils/searchAddressByCep'
import { CnpjData, searchPersonByCnpj } from '../../../../../services/utils/searchPersonByCnpj'
import { ufOptions } from '../../../../../services/utils/ufOptions'
import { isValidateWidthImage } from '../../../../../services/utils/validateWidthImage'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import {
  setDriverLicenseFile,
  setDriverLicenseOwnerImg,
  setIsNewImage,
} from '../../../../../store/slices/ocrCnhSlice'
import { setMotoristAndOwner, setOwner } from '../../../../../store/slices/ownerSlice'
import { handleCnpjIsBlackListed } from '../../../../black-list/check-document-exists/handleCnpjIsBlackListed'
import { handleCpfIsBlackListed } from '../../../../black-list/check-document-exists/handleCpfIsBlackListed'

interface FormProps {
  hasTrailer: boolean
  initialData?: OwnerType
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  CpfRef?: React.RefObject<HTMLInputElement>
  CnpjRef?: React.RefObject<HTMLInputElement>
  ownerType?: 'pf' | 'pj'
  setOwnerType: (data: 'pf' | 'pj' | undefined) => void
  setCpfInBlackList?: (data: BlackListType | undefined) => void
  setCnpjInBlackList?: (data: BlackListType | undefined) => void
}

interface Owner {
  city_id: SelectOption
  type: 'pf' | 'pj'
  pis: string
  birth: string
  rg_dispatcher: string
  rg_dispatch_date: string
  rg_uf: string
  rg_ie: string
  father_name: string
  mother_name: string
  district: string
  address_number: number
  document: string
  address: string
  zipcode: string
  rntrc: string
  rntrc_type?: 'E' | 'C' | 'EQ' | 'T'
  phone: string
  account: string
  agency: string
  account_type: string
  bank: AutocompleteOption
  name: string
  email: string
}

export interface OwnerForm {
  motorist_is_owner: 'N' | 'S'
  antt_owner: 'motorist' | 'vehicle_owner' | 'trailer_owner' | 'other_people'
  owner_vehicle_is_the_same_owner_trailer: 'N' | 'S'
  pis?: string
  rntrc?: string
  rntrc_type?: 'E' | 'C' | 'EQ' | 'T'
  account?: string
  agency?: string
  account_type?: string
  bank?: AutocompleteOption
  owner: Owner
}

export function Form({
  hasTrailer,
  initialData,
  CnpjRef,
  CpfRef,
  formState,
  ownerType,
  setValue,
  setOwnerType,
  setCpfInBlackList,
  setCnpjInBlackList,
}: FormProps): JSX.Element {
  const toast = useToast()
  const [resultOCR, setResultOCR] = useState<ResponseOcrCnh>()
  const { owner } = useAppSelector(state => state.ownerSlice)
  const { driverLicenseOwnerImg } = useAppSelector(state => state.ocrCnhSlice)
  const dispatch = useAppDispatch()
  const { errors } = formState
  const [addressByCNPJ, setAddressByCNPJ] = useState<CnpjData>()
  const [addressByCep, setAddressByCep] = useState<CEP>()
  const convertPdf = usePostConvertPdfToImage()

  const imgEncoded = useMemo(() => {
    const splits = driverLicenseOwnerImg.split(',')
    return splits[1]
  }, [driverLicenseOwnerImg])

  const {
    mutate: postDriverLicenseInformations,
    data: driverLicenseInformations,
    isLoading: isDriverLicenseInformationsLoading,
  } = usePostDriversLicenseInformations({
    onSuccess: data => {
      setResultOCR(data)

      const MessageNonValidFields: JSX.Element = (
        <>
          {data?.nonValidFields?.map(field => (
            <li>{field}</li>
          ))}
        </>
      )
      toast({
        title: data?.nonValidFields
          ? 'Campos que podem não estar preenchidos corretamente:'
          : 'Documento processado com sucesso!',
        description: data?.nonValidFields ? MessageNonValidFields : 'Documento processado com sucesso!',
        status: 'warning',
        duration: 15000,
        isClosable: true,
        position: 'top',
      })
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const handleSubmit = async () => {
    try {
      postDriverLicenseInformations(driverLicenseOwnerImg)
    } catch (errorSubmit) {
      toast({
        title: 'Erro ao enviar informações',
        description: errorSubmit.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    if (owner && owner.zipcode) {
      const loadAddress = async () => {
        const cep = await searchAddressByCep(String(owner.zipcode))
        if (cep) setAddressByCep(cep)
      }
      loadAddress()
    }
    if (owner?.type === 'pf' || resultOCR?.cpf) {
      setOwnerType('pf')
    } else if (owner?.type === 'pj') {
      setOwnerType('pj')
    } else if (!ownerType) setOwnerType(initialData?.type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData?.type, owner, resultOCR?.cpf])

  useEffect(() => {
    dispatch(setDriverLicenseOwnerImg(driverLicensePlaceholderImg))
  }, [dispatch])

  return (
    <>
      <GridItem colSpan={12}>
        <Divider my={4} />
      </GridItem>

      {hasTrailer && (
        <>
          <GridItem colSpan={12}>
            <Alert status="warning">
              <AlertIcon />
              <AlertTitle mr={2}>
                Preencha o campo RNTRC correspondente quando donos de ANTT forem diferentes
              </AlertTitle>
            </Alert>
          </GridItem>
          <GridItem colSpan={3}>
            <InputMask
              mask="99999999"
              maskPlaceholder=""
              required
              registerOnlyNumbers
              name="owner.rntrc"
              label="RNTRC"
              setValue={setValue}
              initialValue={owner?.rntrc || initialData?.rntrc}
              error={errors.rntrc}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <Divider my={4} />
          </GridItem>
        </>
      )}

      {ownerType !== 'pj' && (
        <GridItem colSpan={[12, 6, 5, 3]} rowSpan={[0, 5, 6, 4]} mt={6} w="full" justifyContent="center">
          <Flex direction="row" gap={6} w="full" wrap="wrap" height="auto" justify="center">
            <FormLabel htmlFor="cnh-owner">
              <Flex justifyContent="center" alignItems="center" direction="column">
                <Heading size="sm">CNH do proprietário</Heading>
                <ChakraImage src={driverLicenseOwnerImg} w="100%" mb={4} />
              </Flex>
              <Flex
                justifyContent="center"
                cursor="pointer"
                bg="orange.500"
                w="100%"
                py="2"
                px="4"
                borderRadius="8"
                color="white"
              >
                <Icon as={RiUploadCloudLine} fontSize={24} mr="2" />
                Carregar arquivo
              </Flex>

              <ChakraInput
                id="cnh-owner"
                name="cnh-owner"
                type="file"
                d="none"
                onChange={async e => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0]

                    let isValidImage
                    let imageConvertedToFile
                    if (file.name.includes('.pdf')) {
                      const resultConvertPdf = await convertPdf.mutateAsync({ file, slicePdf: true })

                      if (resultConvertPdf) {
                        imageConvertedToFile = await parseBase64ToImage(resultConvertPdf.imageConverted)
                        dispatch(setDriverLicenseOwnerImg(resultConvertPdf.imageConverted))
                        dispatch(setDriverLicenseFile(imageConvertedToFile))

                        if (!driverLicenseOwnerImg.includes('placeholder')) {
                          dispatch(setIsNewImage(true))
                        }

                        toast({
                          title: 'Arquivo carregado com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }
                    } else {
                      isValidImage = await isValidateWidthImage(file)
                      if (!isValidImage) {
                        toast({
                          title: 'Imagem inválida',
                          description:
                            'Imagem pequena, tente carregar uma imagem com pelo menos 800px de largura',
                          status: 'error',
                          duration: 8000,
                          isClosable: true,
                          position: 'top',
                        })
                        return
                      }

                      dispatch(setDriverLicenseFile(file))
                      const fileReader = new FileReader()
                      if (
                        e.target.files &&
                        e.target.files.length > 0 &&
                        (e.target.files[0].name.includes('.png') ||
                          e.target.files[0].name.includes('.jpg') ||
                          e.target.files[0].name.includes('.jpeg') ||
                          e.target.files[0].name.includes('.pdf'))
                      ) {
                        fileReader.onload = async ev => {
                          if (ev.target && ev.target.result) {
                            const imgData = ev.target.result.toString()
                            if (!driverLicenseOwnerImg.includes('placeholder')) {
                              dispatch(setIsNewImage(true))
                            }
                            dispatch(setDriverLicenseOwnerImg(imgData))
                          }
                        }
                        fileReader.readAsDataURL(file)

                        toast({
                          title: 'Arquivo carregado com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      } else {
                        toast({
                          title: 'Formato inválido! Envie um arquivo em PNG ou JPEG!',
                          status: 'error',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }
                    }
                  }
                }}
              />

              <VStack mt={2} w="100%" justifyContent="center">
                <Button
                  w="100%"
                  rightIcon={<Icon as={RiCheckLine} />}
                  colorScheme="green"
                  isLoading={isDriverLicenseInformationsLoading}
                  isDisabled={driverLicenseOwnerImg === driverLicensePlaceholderImg}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  {driverLicenseInformations ? 'Enviar novo arquivo' : 'Enviar arquivo'}
                </Button>
                {convertPdf.isLoading && (
                  <Alert status="info" w="100%" my="4" borderRadius="4">
                    <AlertIcon />
                    <Box>
                      <AlertTitle mr={2}>
                        <Flex direction={['column', 'row']} gridGap="2">
                          Convertendo PDF.
                          <Spinner size="md" />
                        </Flex>
                      </AlertTitle>
                    </Box>
                  </Alert>
                )}
              </VStack>
            </FormLabel>
            <ChakraInput name="cnh-owner" defaultValue={imgEncoded} d="none" />
          </Flex>
        </GridItem>
      )}

      {(owner || initialData) && (
        <GridItem colSpan={[12, 4, 3]}>
          <Text
            cursor="pointer"
            color="blue.200"
            onClick={() => {
              if (owner) {
                if (owner.cpf && owner?.type === 'pf') copyToClipboard(owner.cpf)
                if (owner.cnpj && owner?.type === 'pj') copyToClipboard(owner.cnpj)
              }
              if (initialData) {
                if (initialData.cpf && initialData?.type === 'pf') copyToClipboard(initialData.cpf)
                if (initialData.cnpj && initialData?.type === 'pj') copyToClipboard(initialData.cnpj)
              }
              toast({
                title: 'Documento copiado com sucesso!',
                status: 'success',
                position: 'top-right',
                duration: 1000,
              })
            }}
            fontWeight="medium"
            ml="1"
            mt="8"
          >
            Copiar documento <Icon as={RiFileCopyLine} fontSize={16} />
          </Text>
        </GridItem>
      )}

      <GridItem colSpan={[12, 6, 3]}>
        <Select
          name="owner.type"
          label="Tipo"
          error={errors.owner?.type}
          setValue={setValue}
          initialValue={resultOCR?.cpf ? 'pf' : owner?.type || initialData?.type}
          options={[
            { label: '', value: '' },
            { label: 'Pessoa física', value: 'pf' },
            { label: 'Pessoa Jurídica', value: 'pj' },
          ]}
          onSelectOption={op => {
            setOwnerType(op.value as 'pf' | 'pj')
          }}
          isRequired
        />
      </GridItem>

      {ownerType === 'pf' ? (
        <GridItem colSpan={[12, 6, 3]}>
          <InputMask
            name="owner.document"
            label="CPF"
            mask="999.999.999-99"
            maskPlaceholder=""
            setValue={setValue}
            ref={CpfRef}
            onBlur={async e => {
              const { value } = e.target
              const data = await handleCpfIsBlackListed(value)
              const findOwnerCpf = await findOwnerByCpf(value)
              if (findOwnerCpf) {
                dispatch(setOwner(findOwnerCpf))
              } else {
                const findMotoristCpf = await findMotoristByCpf(value)
                if (findMotoristCpf) {
                  const findMotoristAndOwner = await findMotoristAndOwnerByCpf(String(findMotoristCpf.cpf))
                  dispatch(setMotoristAndOwner(findMotoristAndOwner))
                  dispatch(setOwner(findMotoristCpf as OwnerType))
                }
              }
              if (data && setCpfInBlackList) {
                setCpfInBlackList(data)
              } else if (setCpfInBlackList) {
                setCpfInBlackList(undefined)
              }
            }}
            onChange={async e => {
              if (e.target.value.length === 14) {
                const { value } = e.target

                const data = await handleCpfIsBlackListed(value)
                const findOwnerCpf = await findOwnerByCpf(value)
                if (findOwnerCpf) {
                  dispatch(setOwner(findOwnerCpf))
                } else {
                  const findMotoristCpf = await findMotoristByCpf(value)
                  if (findMotoristCpf) dispatch(setOwner(findMotoristCpf as OwnerType))
                }
                if (data && setCpfInBlackList) {
                  setCpfInBlackList(data)
                } else if (setCpfInBlackList) {
                  setCpfInBlackList(undefined)
                }
              }
            }}
            initialValue={resultOCR?.cpf || owner?.cpf || initialData?.cpf}
            error={errors.owner?.document}
            isRequired
            registerOnlyNumbers
            onFocus={() => {
              dispatch(setOwner(undefined))
            }}
            clearState={() => {
              dispatch(setOwner(undefined))
            }}
          />
        </GridItem>
      ) : (
        <GridItem colSpan={[12, 6, 3]}>
          <InputMask
            name="owner.document"
            label="CNPJ"
            mask="99.999.999/9999-99"
            maskPlaceholder=""
            setValue={setValue}
            ref={CnpjRef}
            onChange={async e => {
              if (e.target.value.length >= 17) {
                const { value } = e.target
                const data = await handleCnpjIsBlackListed(value)
                const findOwnerCnpj = await findOwnerByCnpj(value)
                const cnpj = await searchPersonByCnpj(String(e.target.value))
                dispatch(setOwner(findOwnerCnpj))
                if (cnpj) {
                  setAddressByCNPJ(cnpj || findOwnerCnpj)
                }
                if (data && setCnpjInBlackList) {
                  setCnpjInBlackList(data)
                } else if (setCnpjInBlackList) {
                  setCnpjInBlackList(undefined)
                }
              }
            }}
            error={errors.owner?.document}
            isRequired
            initialValue={owner?.cnpj || addressByCNPJ?.cnpj || initialData?.cnpj}
            registerOnlyNumbers
            onFocus={() => {
              dispatch(setOwner(undefined))
            }}
            clearState={() => {
              dispatch(setOwner(undefined))
            }}
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 6, 6]}>
        <Input
          name="owner.name"
          label={ownerType === 'pf' ? 'Nome' : 'Razão social'}
          setValue={setValue}
          initialValue={resultOCR?.name || owner?.name || addressByCNPJ?.name || initialData?.name}
          error={errors.owner?.name}
          uppercaseFirst={!(addressByCNPJ?.name || initialData?.name)}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <InputMask
          isRequired
          name="owner.phone"
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          label="Telefone"
          error={errors.owner?.phone}
          setValue={setValue}
          initialValue={owner?.phone || addressByCNPJ?.phone || initialData?.phone}
        />
      </GridItem>

      <GridItem colSpan={[12, 4, 3]}>
        <Input
          name="owner.rg_ie"
          label={ownerType === 'pf' ? 'RG' : 'Inscrição Estadual'}
          setValue={setValue}
          initialValue={resultOCR?.identity || owner?.rg || owner?.ie || initialData?.rg || initialData?.ie}
          error={errors.owner?.rg_ie}
          isRequired={ownerType === 'pf'}
        />
      </GridItem>

      {ownerType === 'pf' && (
        <>
          <GridItem colSpan={[12, 8, 6]}>
            <Input
              name="owner.mother_name"
              label="Nome da Mãe"
              setValue={setValue}
              initialValue={resultOCR?.motherName || owner?.mother_name || initialData?.mother_name}
              error={errors.owner?.mother_name}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 10, 7]}>
            <Input
              isRequired
              name="owner.father_name"
              label="Nome do pai"
              error={errors.owner?.father_name}
              setValue={setValue}
              initialValue={resultOCR?.fatherName || owner?.father_name || initialData?.father_name}
              uppercaseFirst
            />
          </GridItem>

          <GridItem colSpan={[12, 2, 2]}>
            <Select
              name="owner.rg_uf"
              label="RG UF"
              setValue={setValue}
              options={ufOptions}
              initialValue={resultOCR?.dispatcherUf || owner?.rg_uf || initialData?.rg_uf}
              error={errors.owner?.rg_uf}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 4, 3]}>
            <Input
              name="owner.rg_dispatcher"
              label="Órgão expeditor"
              error={errors.owner?.rg_dispatcher}
              setValue={setValue}
              initialValue={resultOCR?.dispatcher || owner?.rg_dispatcher || initialData?.rg_dispatcher}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 4, 3]}>
            <Input
              name="owner.rg_dispatch_date"
              type="date"
              label="Data de emissão"
              setValue={setValue}
              initialValue={
                formatDateToEUA(resultOCR?.emissionDate) ||
                owner?.rg_dispatch_date ||
                initialData?.rg_dispatch_date
              }
              error={errors.owner?.rg_dispatch_date}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 4, 3]}>
            <Input
              name="owner.birth"
              type="date"
              label="Data de Nascimento"
              setValue={setValue}
              error={errors.owner?.birth}
              initialValue={
                formatDateToEUA(resultOCR?.birthDate) ||
                owner?.birth ||
                formatDateForInitialValue(initialData?.birth)
              }
              isRequired
            />
          </GridItem>
        </>
      )}

      <GridItem colSpan={12}>
        <Divider my={4} />
      </GridItem>

      <GridItem colSpan={12}>
        <Heading size="md">Endereço</Heading>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <InputMask
          name="owner.zipcode"
          label="CEP"
          mask="99.999-999"
          maskPlaceholder=""
          registerOnlyNumbers
          setValue={setValue}
          onChange={async e => {
            if (e.target.value.length === 10) {
              const cep = await searchAddressByCep(String(e.target.value))
              if (cep) {
                setAddressByCep(cep)
              }
            }
          }}
          onBlur={async e => {
            const cep = await searchAddressByCep(String(e.target.value))
            if (cep) {
              setAddressByCep(cep)
            }
          }}
          initialValue={owner?.zipcode || initialData?.zipcode || addressByCNPJ?.zipcode}
          error={errors.owner?.zipcode}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 7]}>
        <Input
          name="owner.address"
          label="Endereço"
          setValue={setValue}
          initialValue={
            owner?.address ||
            addressByCep?.address ||
            addressByCNPJ?.address ||
            initialData?.address ||
            undefined
          }
          error={errors.owner?.address}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 2]}>
        <InputMask
          mask="99999999999999999999"
          maskPlaceholder=""
          name="owner.address_number"
          label="Número"
          setValue={setValue}
          initialValue={
            (owner?.address_number && String(owner?.address_number)) ||
            (initialData?.address_number && String(initialData?.address_number)) ||
            (addressByCNPJ?.address_number ? String(addressByCNPJ?.address_number) : undefined)
          }
          error={errors.owner?.address_number}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 4]}>
        <Input
          name="owner.district"
          label="Bairro"
          setValue={setValue}
          initialValue={
            owner?.district ||
            addressByCep?.district ||
            addressByCNPJ?.district ||
            initialData?.district ||
            undefined
          }
          error={errors.owner?.district}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 4]}>
        <AutocompleteAsync
          name="owner.city_id"
          label="Cidade"
          setValue={setValue}
          loadOptions={searchCitiesByName}
          initialValue={
            (owner?.city && {
              label: owner?.city?.name,
              value: owner?.city?.ibge_id,
            }) ||
            addressByCep?.city ||
            addressByCNPJ?.city ||
            (initialData?.city_id && {
              label: String(initialData?.city?.name),
              value: String(initialData?.city?.ibge_id),
            }) ||
            undefined
          }
          error={errors.owner?.city_id}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 4]}>
        <Input
          name="owner.email"
          label="E-mail"
          error={errors.owner?.email}
          setValue={setValue}
          initialValue={owner?.email || initialData?.email}
        />
      </GridItem>
    </>
  )
}
