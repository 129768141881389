import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import {
  DeepMap,
  FieldError,
  FieldValues,
  FormState,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form'
import { RiUserAddLine } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'
import { Ability } from '../../components/ability'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { RadioGroup } from '../../components/form/RadioGroup'
import { apiServer } from '../../services/api'
import { findMotoristByCpf } from '../../services/endpoints/motorists/findMotoristByCpf'
import { findOwnerByCpf } from '../../services/endpoints/owners/findOwnerByCpf'
import { MotoristType, ResponseApiType } from '../../services/types'
import OwnerType from '../../services/types/OwnerType'
import { MotoristQuickFormData } from './create/CreateMotorist'
import { CreateMotoristCompleteFormModal } from './create/CreateMotoristCompleteFormModal'

interface MotoristQuickFormProps {
  initialData?: Partial<MotoristQuickFormData>
  formState: FormState<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  handleSubmit: UseFormHandleSubmit<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
  handleCreateMotorist: SubmitHandler<MotoristQuickFormData>
}

export function MotoristQuickForm({
  initialData,
  formState,
  setValue,
  handleSubmit,
  handleCreateMotorist,
  errors,
}: MotoristQuickFormProps): JSX.Element {
  const history = useHistory()
  const {
    isOpen: isOpenCreateMotoristModal,
    onOpen: onOpenCreateMotoristModal,
    onClose: onCloseCreateMotoristModal,
  } = useDisclosure()

  const [listMotorists, setListMotorists] = useState<MotoristType[]>()
  const motoristPhoneRef = useRef<HTMLInputElement>(null)
  const motoristCpfRef = useRef<HTMLInputElement>(null)
  const motoristNameRef = useRef<HTMLInputElement>(null)
  const [owner, setOwner] = useState<OwnerType>()

  async function handleCheckMotoristExists() {
    if (motoristNameRef.current) {
      const { value } = motoristNameRef.current

      try {
        const { data: res } = await apiServer.get<ResponseApiType<MotoristType[]>>(`/motorist/search`, {
          params: { name: value },
        })

        const { data } = res
        if (data.length > 0) {
          setListMotorists(data)
        } else {
          setListMotorists(undefined)
        }
      } catch {
        setListMotorists(undefined)
      }
    }
  }

  useEffect(() => {
    if (owner) onOpenCreateMotoristModal()
  }, [onOpenCreateMotoristModal, owner])

  return (
    <>
      <form onSubmit={handleSubmit(handleCreateMotorist)} noValidate>
        <Box>
          {listMotorists && (
            <Alert status="warning" mb="4" borderRadius="4">
              <AlertIcon />
              <Box>
                {listMotorists.length > 1 ? 'Existem ' : 'Existe '}
                <strong>{listMotorists.length}</strong>{' '}
                {listMotorists.length > 1 ? 'motoristas ' : 'motorista '} com o mesmo nome já está{' '}
                {listMotorists.length > 1 ? 'cadastrados' : 'cadastrado'}.
                <Text color="orange.500">
                  <Link to={`/motorists?motorist_name=${motoristNameRef.current?.value}`}>Veja na lista</Link>
                </Text>
              </Box>
            </Alert>
          )}
        </Box>

        <Box>
          <Grid templateColumns="repeat(12, 1fr)" gap="4">
            <GridItem colSpan={[12, 6, 3]}>
              <InputMask
                autoFocus
                name="cpf"
                label="CPF"
                mask="999.999.999-99"
                maskPlaceholder=""
                ref={motoristCpfRef}
                setValue={setValue}
                initialValue={owner?.cpf || initialData?.cpf}
                error={errors.cpf}
                onChange={async e => {
                  if (e.target.value.length === 14) {
                    const { value } = e.target
                    const motorist = await findMotoristByCpf(value)
                    if (motorist?.id) {
                      history.push(`/motorists/show/${motorist.id}`)
                    }
                    const ownerCpf = await findOwnerByCpf(value)
                    if (ownerCpf) {
                      setOwner(ownerCpf)
                    }
                  }
                }}
                registerOnlyNumbers
                isRequired
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <InputMask
                isRequired
                ref={motoristPhoneRef}
                name="phone"
                mask="(99) 9 9999-9999"
                registerOnlyNumbers
                maskPlaceholder=""
                label="Telefone Principal"
                error={errors.phone}
                setValue={setValue}
                initialValue={initialData?.phone}
              />
            </GridItem>

            <GridItem colSpan={[12, 6]}>
              <Input
                isRequired
                ref={motoristNameRef}
                name="name"
                label="Nome completo"
                error={errors.name}
                setValue={setValue}
                initialValue={initialData?.name}
                onChange={e => {
                  if (e.target.value.length > 15) handleCheckMotoristExists()
                }}
                uppercaseFirst
              />
            </GridItem>

            <GridItem colSpan={[12, 5]}>
              <RadioGroup
                name="prospected_by"
                label="Prospectado"
                error={errors.prospected_by}
                setValue={setValue}
                initialValue={initialData?.prospected_by ? Number(initialData?.prospected_by) : undefined}
                options={[
                  { label: 'Fretebras', value: 0 },
                  { label: 'Agenciador', value: 1 },
                  { label: 'Base', value: 2 },
                ]}
              />
            </GridItem>
          </Grid>
          <Divider my="4" />
        </Box>

        <Flex mt="4" justify="flex-end">
          <HStack spacing="4">
            <Link to="/motorists">
              <Button variant="ghost" colorScheme="red">
                Cancelar
              </Button>
            </Link>

            <Ability module="people" action="create-motorist">
              <Button
                type="submit"
                colorScheme="orange"
                isLoading={formState?.isSubmitting}
                leftIcon={<Icon as={RiUserAddLine} />}
              >
                Cadastrar motorista
              </Button>
            </Ability>
          </HStack>
        </Flex>
      </form>

      <CreateMotoristCompleteFormModal
        isOpen={isOpenCreateMotoristModal}
        onClose={() => {
          onCloseCreateMotoristModal()
        }}
        ownerExistsData={owner}
      />
    </>
  )
}
