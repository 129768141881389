import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { BlockedEntitiesType, ResponseApiType } from '../../types'

export type BlockedEntitiesParams = {
  risk_analysis_id: string
  freight_number: number
  freight_id?: string
  motorist_id?: string
  vehicle_id?: string
  trailer_id?: string
  driver_license_id?: string
  vehicle_owner_id?: string | null
  trailer_owner_id?: string | null
}

export async function fetchOneBlockedEntities(
  params: Partial<BlockedEntitiesParams>,
): Promise<BlockedEntitiesType | undefined> {
  if (Object.values(params).every(value => value === null)) {
    return undefined
  }
  if (Object.keys(params).length === 0) {
    return undefined
  }
  const { data: response } = await apiServer.get<ResponseApiType<BlockedEntitiesType>>(`/blocked-entities`, {
    params,
  })
  const data = response.data as BlockedEntitiesType
  return data
}

export function useGetOneBlockedEntities(
  params: Partial<BlockedEntitiesParams>,
): UseQueryResult<BlockedEntitiesType> {
  return useQuery(['find-blocked-entities', params], () => fetchOneBlockedEntities(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
  })
}
