import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormLabel,
  Select,
  Switch,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import MapComponent from '../../../../../components/GoogleMapComponents/MapComponent'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import {
  changeStatusMonitoringWhatsAppByZApiCron,
  changeWhatsAppSendingIntervalByZApi,
  sendingRequestMonitoringWhatsAppByZApi,
  setDisabledBtnSendingMsgWhatsAppByZApi,
  setInitialBtnWhatsAppByZApi,
  setStateTogglePermissionWhatsAppByZApi,
} from '../../../../../store/slices/freightSlice'
import { setDriverLicenseImg, setIsNewImage } from '../../../../../store/slices/ocrCnhSlice'

interface FreighMonitoringWhatsappProps {
  freight: FormattedOneFreight
}

const FreighMonitoringWhatsapp = ({ freight }: FreighMonitoringWhatsappProps): JSX.Element => {
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const dispatch = useAppDispatch()
  const {
    isLoading,
    waypointsZApi,
    initialBtnWppMonitoringDisabled,
    initialResStatusWppMonitoring,
    stateTogglePermission,
    btnSendingMsgToMotoristDisabled,
  } = useAppSelector(state => state.freightSlice)

  useEffect(() => {
    dispatch(setDriverLicenseImg(''))
    dispatch(setIsNewImage(false))
    if (freight?.id && freight?.motorist_id) {
      dispatch(setStateTogglePermissionWhatsAppByZApi(Boolean(freight?.can_monitoring_whatsapp)))
    }
  }, [dispatch, freight?.id, freight?.motorist_id, freight?.can_monitoring_whatsapp])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setInitialBtnWhatsAppByZApi(false))
    }, 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    waypointsZApi,
    initialBtnWppMonitoringDisabled,
    initialResStatusWppMonitoring,
    dispatch,
    freight?.status,
  ])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setDisabledBtnSendingMsgWhatsAppByZApi(false))
    }, 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, btnSendingMsgToMotoristDisabled])

  return (
    <>
      {freight.has_monitoring_whatsapp && (
        <Accordion borderRadius="md" bg={bg} allowToggle w="100%" defaultIndex={[0]}>
          <AccordionItem border="none" w="100%">
            <AccordionButton
              border="0px"
              _active={{ outline: 'none' }}
              _focus={{ outline: 'none' }}
              _hover={{ outline: 'none', bg: 'none' }}
              py="3"
            >
              <Box as="span" flex="1" textAlign="left" fontSize="md" fontWeight="medium">
                Monitoramento WhatsApp
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Flex
                bg={bg}
                p="2"
                mb="4"
                alignItems="center"
                justifyContent="center"
                justify="space-between"
                align="center"
                direction={['column', 'column', 'column']}
                gridGap={2}
              >
                <Flex alignItems="center" flexDir={['column', 'row']} justifyContent="center" mb="4">
                  <Flex alignItems="center" justifyContent="center" direction="row">
                    <FormLabel htmlFor="isToggle">Envio automático?</FormLabel>
                    <Switch
                      id="isToggle"
                      isChecked={stateTogglePermission}
                      defaultChecked={stateTogglePermission}
                      checked={stateTogglePermission}
                      onChange={() => {
                        dispatch(changeStatusMonitoringWhatsAppByZApiCron(freight.id, stateTogglePermission))
                        toast({
                          title: 'Alteração feita com sucesso!',
                          description:
                            !stateTogglePermission === true
                              ? 'A partir de agora esse motorista receberá mensagens automáticas.'
                              : 'O motorista NÃO receberá mais mensagens automáticas!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                          duration: 10000,
                        })
                      }}
                    />
                  </Flex>
                  <Center height="20px" ml={10} mr={10}>
                    <Divider orientation="vertical" />
                  </Center>
                  <Flex alignItems="center" justifyContent="center" direction="column">
                    <FormLabel htmlFor="isSelectedInterval">Intervalo para o envio de mensagem:</FormLabel>
                    <Select
                      id="isSelectedInterval"
                      placeholder="Selecione um intervalo"
                      defaultValue={freight.whatsapp_notification_interval ?? 2}
                      onChange={e => {
                        if (e.target.value !== '') {
                          dispatch(changeWhatsAppSendingIntervalByZApi(freight.id, Number(e.target.value)))
                          toast({
                            title: 'Alteração feita com sucesso!',
                            description: `A partir de agora esse motorista receberá mensagens automáticas no intervalo de ${e.target.value} Horas.`,
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                            duration: 5000,
                          })
                        }
                      }}
                    >
                      <option value={2}>2 Horas</option>
                      <option value={4}>4 Horas</option>
                      <option value={6}>6 Horas</option>
                      <option value={8}>8 Horas</option>
                    </Select>
                  </Flex>
                  <Center height="20px" ml={10} mr={10}>
                    <Divider orientation="vertical" />
                  </Center>
                  <Flex alignItems="center" justifyContent="center" direction="column">
                    <FormLabel htmlFor="isSelectedInterval">Solicite o envio manualmente:</FormLabel>
                    <Tooltip label="Envie uma mensagem manualmente para o motorista que está fazendo um frete com monitoramento por WhatsApp.">
                      <Button
                        disabled={btnSendingMsgToMotoristDisabled}
                        isLoading={isLoading}
                        colorScheme="green"
                        ml="4"
                        size="sm"
                        width="200px"
                        onClick={() => {
                          dispatch(sendingRequestMonitoringWhatsAppByZApi(freight.id))
                          dispatch(setDisabledBtnSendingMsgWhatsAppByZApi(true))

                          toast({
                            title: 'Sucesso!',
                            description: 'Mensagem enviada com sucesso para o WhatsApp do motorista.',
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                          })
                        }}
                      >
                        {btnSendingMsgToMotoristDisabled ? 'Por favor, espere...' : 'Solicitar localização'}
                      </Button>
                    </Tooltip>
                  </Flex>
                </Flex>

                <MapComponent freight={freight} type="whatsapp" />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  )
}

export default FreighMonitoringWhatsapp
