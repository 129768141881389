import { Box, Center, SkeletonText, Spinner, Text, Tooltip } from '@chakra-ui/react'
import { DirectionsRenderer, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import { FormattedOneFreight } from '../../services/endpoints/freights'
import {
  SelectedMarker,
  useGetDirectionService,
  useGetWaypoints,
} from '../../services/endpoints/motorists/getWaypoints'
import { containerStyle } from './GoogleMaps'
import { InfoWindowComponent } from './InfoWindow'
import { Markers } from './Markers'

export interface IMapComponentProps {
  freight: FormattedOneFreight
  status?: string
  type: 'app' | 'whatsapp' | 'buonny'
  initial_date?: Date
  final_date?: Date
  showDistance?: boolean
  height?: string
}

export default function MapComponent({
  type,
  freight,
  initial_date,
  final_date,
  showDistance = true,
  height,
}: IMapComponentProps): JSX.Element {
  const { data: outputWayPoints } = useGetWaypoints({
    type,
    freight,
    initial_date,
    final_date,
  })
  const waypoints = outputWayPoints?.allWaypoints
  const firstWayPoint = outputWayPoints?.firstWayPoint
  const lastWayPoint = outputWayPoints?.lastWayPoint
  const { data: resultDirections, isLoading: isLoadingDirections } = useGetDirectionService({
    firstWayPoint,
    lastWayPoint,
  })
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker | undefined>()
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    language: 'pt_BR',
    libraries: ['places'],
  })

  useEffect(() => {
    const loadMarker = async () => {
      if (lastWayPoint) {
        setSelectedMarker({ ...lastWayPoint, is_lastPoint: !!waypoints?.length })
      }
      if (!waypoints?.length) setSelectedMarker(undefined)
    }
    loadMarker()
  }, [lastWayPoint, waypoints?.length])

  if (!isLoaded) {
    return <SkeletonText />
  }

  return (
    <>
      {isLoadingDirections ? (
        <Center color="blue.500" width="100%" gridGap={4} my={4}>
          Carregando mapa... <Spinner size="xl" />
        </Center>
      ) : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            width: '100%' || '650px',
            height: '100%',
            backgroundColor: 'white',
          }}
          {...(height
            ? {
                __css: {
                  '& > div': {
                    minHeight: `${height} !important`,
                  },
                },
              }
            : {})}
          aria-label="Mapa de frete"
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={1}
            center={selectedMarker && { lat: selectedMarker.lat, lng: selectedMarker.lng }}
            mapTypeId="satellite"
            options={{
              gestureHandling: 'greedy',
              streetViewControl: false,
              keyboardShortcuts: false,
            }}
          >
            <Box style={{ width: '100%', height: '100px' }}>
              <Markers
                freight={freight}
                setSelectedMarker={setSelectedMarker}
                firstWayPoint={firstWayPoint}
                lastWayPoint={lastWayPoint}
                waypoints={waypoints}
              />

              {resultDirections && (
                <DirectionsRenderer
                  directions={resultDirections.directions}
                  options={{
                    markerOptions: {
                      visible: false,
                    },
                  }}
                />
              )}

              <InfoWindowComponent
                selectedMarker={selectedMarker}
                onCloseClick={() => setSelectedMarker(undefined)}
              />

              {resultDirections && resultDirections.distance && (
                <Box
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    color: 'gray',
                    width: 200,
                    borderRadius: '0 5px 0 0',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: 0,
                    padding: 5,
                    display: !showDistance ? 'none' : 'flex',
                  }}
                >
                  <Tooltip label="Distância total entre o ponto que o motorista iniciou a rota e o atual dele baseado nos dados do Google Maps.">
                    <Text fontWeight="bold" color="gray.700">
                      Distância percorrida: {resultDirections.distance}
                    </Text>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </GoogleMap>
        </Box>
      )}
    </>
  )
}
