import { Button, Divider, Flex, Heading, Stack, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiCaravanLine } from 'react-icons/ri'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { TrailerInfos } from '../../../components/card-infos/TrailerInfos'
import { VehicleInfos } from '../../../components/card-infos/VehicleInfos'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useIsBlockEntity } from '../../../services/endpoints/blocked-entities/isBlockEntity'
import { detachTrailerFromVehicle } from '../../../services/endpoints/vehicles/detachTrailerFromVehicle'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { VehicleType } from '../../../services/types'
import { TrailerOwnerInfos } from '../cards/TrailerOwnerInfos'
import { VehicleOwnerInfos } from '../cards/VehicleOwnerInfos'
import { AttachTrailerToVehicleModal } from '../modals/AttachTrailerToVehicleModal'
import { EditTrailerModal } from '../modals/EditTrailerModal'
import { EditVehicleModal } from '../modals/EditVehicleModal'

interface InfoVehicleExistsProps {
  vehicle_id: string
  motorist_id?: string
}

export function InfoVehicleExists({ vehicle_id, motorist_id }: InfoVehicleExistsProps): JSX.Element {
  const toast = useToast()
  const [vehicle, setVehicle] = useState<VehicleType>()
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()
  const {
    onClose: onCloseEditTrailerModal,
    isOpen: isOpenEditTrailerModal,
    onOpen: onOpenEditTrailerModal,
  } = useDisclosure()
  const [trailerId, setTrailerId] = useState<string>()
  const { data, refetch } = useGetVehicle(vehicle_id)
  const { data: isBlockedEntity } = useIsBlockEntity({ vehicle_id })
  useEffect(() => {
    if (data) setVehicle(data)
  }, [data, refetch])

  useEffect(() => {
    if (vehicle) {
      if (vehicle?.trailers && vehicle?.trailers.length > 0) {
        vehicle.trailers.map(trailer => setTrailerId(String(trailer.id)))
      }
    }
  }, [vehicle])

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gridGap={4}
        direction={['column', 'column', 'column', 'row']}
      >
        <Heading size="lg" fontWeight="normal" mb="8" minW={300}>
          Dados do Veículo
        </Heading>
        <Stack direction={['column', 'row']} align="center" mb={4}>
          {vehicle && vehicle.vehicle_category.has_trailer && (
            <AttachTrailerToVehicleModal buttonTitle="Vincular reboque" size="sm" vehicle_id={vehicle.id} />
          )}
          <Button
            colorScheme="orange"
            size="sm"
            isDisabled={isBlockedEntity}
            onClick={() => {
              onOpen()
            }}
          >
            <Tooltip
              hasArrow
              placement="top"
              label={isBlockedEntity && 'Motorista em analise! Edição bloqueada!'}
            >
              Editar
            </Tooltip>
          </Button>
        </Stack>
      </Flex>
      {vehicle && (
        <>
          <VehicleInfos vehicle={vehicle} />
          <VehicleOwnerInfos vehicle_id={vehicle.id} motorist_id={motorist_id} />
        </>
      )}
      {vehicle &&
        vehicle.trailers.length > 0 &&
        vehicle.trailers.map((trailer, idx) => (
          <>
            {trailer.id && (
              <>
                <Divider my="4" key={trailer.id} />
                <Flex
                  justifyContent="space-between"
                  direction={['column', 'row']}
                  gridGap={3}
                  key={trailer.id}
                >
                  <Heading size="lg" mb="4" fontWeight="normal">
                    Dados do reboque {idx + 1}
                  </Heading>
                  <Stack direction={['column', 'row']}>
                    <Button
                      size="sm"
                      leftIcon={<RiCaravanLine />}
                      colorScheme="red"
                      onClick={() => {
                        onOpenConfirm()
                      }}
                    >
                      Desvincular reboque
                    </Button>

                    <Button
                      size="sm"
                      colorScheme="yellow"
                      onClick={() => {
                        onOpenEditTrailerModal()
                        setTrailerId(trailer.id)
                      }}
                    >
                      Editar
                    </Button>
                  </Stack>
                </Flex>
                <TrailerInfos trailer={trailer} />
                {trailer.id && <TrailerOwnerInfos trailer_id={trailer.id} motorist_id={motorist_id} />}
              </>
            )}
          </>
        ))}

      {vehicle && (
        <>
          <EditTrailerModal
            isOpen={isOpenEditTrailerModal}
            onClose={() => {
              onCloseEditTrailerModal()
              refetch()
            }}
            motorist_id={motorist_id}
            trailer_id={String(trailerId)}
            vehicle_id={vehicle.id}
          />

          <EditVehicleModal
            isOpen={isOpen}
            onClose={() => {
              onCloseEditTrailerModal()
              onClose()
              refetch()
            }}
            motorist_id={motorist_id}
            vehicle_id={vehicle.id}
          />
        </>
      )}

      {trailerId && vehicle && (
        <AlertDialogConfirm
          isOpen={isOpenConfirm}
          title="Desvincular reboque da tração"
          description="Tem certeza que deseja desvincular o reboque da tração?"
          onClose={onCloseConfirm}
          onConfirm={async () => {
            try {
              await detachTrailerFromVehicle(vehicle.id, trailerId)
              setTrailerId(undefined)
              toast({
                status: 'success',
                title: 'Reboque desvinculado da tração com sucesso!',
                position: 'top-right',
                duration: 8000,
                isClosable: true,
              })
              refetch()
              queryClient.invalidateQueries('motorist')
              queryClient.invalidateQueries('owner')
            } catch (error) {
              toastError({ toast, error })
            }
          }}
        />
      )}
    </>
  )
}
