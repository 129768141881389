/* eslint-disable no-console */
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine, RiSearchLine, RiUser2Fill } from 'react-icons/ri'
import * as yup from 'yup'
import { AlertModal } from '../../../../../components/AlertModal'
import { RadioGroup } from '../../../../../components/form'
import { Autocomplete } from '../../../../../components/form/Autocomplete'
import { Input } from '../../../../../components/form/Input'
import { InputMask } from '../../../../../components/form/InputMask'
import { Select } from '../../../../../components/form/Select'
import { toastError } from '../../../../../config/error/toastError'
import { queryClient } from '../../../../../config/react-query'
import { useAuth } from '../../../../../contexts/AuthContext'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { apiServer } from '../../../../../services/api'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { useGetMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { usePutTrailer } from '../../../../../services/endpoints/trailers/putTrailer'
import { usePutVehicle } from '../../../../../services/endpoints/vehicles/putVehicle'
import { BlockedEntitiesType, ResponseApiType, VehicleType } from '../../../../../services/types'
import OwnerType from '../../../../../services/types/OwnerType'
import { TrailerType } from '../../../../../services/types/TrailerType'
import { bankList } from '../../../../../services/utils/bankList'
import { getLabelBank } from '../../../../../services/utils/getLabelBank'
import getOnlyNumbers from '../../../../../services/utils/getOnlyNumbers'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { setOwner } from '../../../../../store/slices/ownerSlice'
import { verifyIfDataByMotorist, verifyIfIsNewOwner, verifyUnlockPermission } from '../../validations'
import { Form, OwnerForm } from './Form'
import { PisConsultationModal } from './modals/PisConsultationModal'
import { RntrcConsultationModal } from './modals/RntrcConsultationModal'

type RegisterOwnersProps = {
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  tabIndex: number
  refetch: () => void
  formValidation: yup.ObjectSchema<any>
  vehicle?: VehicleType
  trailer?: TrailerType
  initialData?: OwnerType
  blockedEntity?: BlockedEntitiesType
  typeOwner: 'ownerVehicle' | 'ownerTrailer' | 'ownerAntt'
  freight: FormattedOneFreight
}

export function RegisterOwners({
  vehicle,
  trailer,
  blockedEntity,
  setTabIndex,
  tabIndex,
  refetch,
  formValidation,
  initialData,
  typeOwner,
  freight,
}: RegisterOwnersProps): JSX.Element {
  const { routePointsHasPendencies } = useAppSelector(state => state.freightPendenciesSlice)
  const motorist_id = useQueryParams('motorist_id')
  const [ownerType, setOwnerType] = useState<'pf' | 'pj'>()
  const [isLoadingState, setIsLoadingState] = useState(false)
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const {
    isOpen: isOpenPisConsultation,
    onOpen: onOpenPisConsultation,
    onClose: onClosePisConsultation,
  } = useDisclosure()
  const {
    isOpen: isOpenRntrcConsultation,
    onOpen: onOpenRntrcConsultation,
    onClose: onCloseRntrcConsultation,
  } = useDisclosure()

  const isNotExistsRoutePoints = routePointsHasPendencies && typeOwner === 'ownerAntt'

  useEffect(() => {
    setIsLoadingState(true)
    dispatch(setOwner(undefined))
    setTimeout(() => {
      setIsLoadingState(false)
    }, 100)
  }, [dispatch, tabIndex])
  const { data: motorist } = useGetMotorist(motorist_id)
  const { owner: ownerFound } = useAppSelector(state => state.ownerSlice)
  const updateVehicle = usePutVehicle({
    onSuccess: () => {
      queryClient.invalidateQueries('vehicle')
      queryClient.invalidateQueries('owner')
      queryClient.invalidateQueries('find-blocked-entities')
    },
  })
  const updateTrailer = usePutTrailer({
    onSuccess: () => {
      queryClient.invalidateQueries('trailer')
      queryClient.invalidateQueries('owner')
      queryClient.invalidateQueries('find-blocked-entities')
    },
  })
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(formValidation),
  })

  const encodeLocation = (location: any) => {
    const locationParams = {
      lat: location.lat,
      lng: location.lng,
      uf: location.state.uf,
      label: location.name,
      country: 'BRA',
      cityName: location.name,
    }
    return encodeURIComponent(JSON.stringify(locationParams))
  }

  const buildRoutePointsUrl = () => {
    if (!freight) return ''
    const originParams = encodeLocation(freight.origin)
    const destinationParams = encodeLocation(freight.destination)

    return (
      `/pricing/route-planner?freight_id=${freight?.id}` +
      `&freight_number=${freight?.freight_number}` +
      `&origin=${originParams}` +
      `&destination=${destinationParams}` +
      `&freight_status=${freight?.status}` +
      `&vehicle_plate=${vehicle?.license_plate || ''}` +
      `&tabIndex=${tabIndex}`
    )
  }

  async function LinkOwnerToVehicle(owner_id: string): Promise<void> {
    if (vehicle && typeOwner === 'ownerAntt') {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        antt_owner_id: owner_id,
        disable_entities_block: true,
      })
    }
    if (vehicle && typeOwner === 'ownerVehicle') {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        owner_id,
        disable_entities_block: true,
      })
    }
    if (trailer && typeOwner === 'ownerTrailer') {
      await updateTrailer.mutateAsync({
        id: trailer.id,
        owner_id,
        disable_entities_block: true,
      })
    }
  }

  async function UnlinkOwnerFromVehicle(): Promise<void> {
    if (vehicle && initialData && typeOwner === 'ownerAntt') {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        antt_owner_id: null as any,
        disable_entities_block: true,
      })
    }
    if (vehicle && initialData && typeOwner === 'ownerVehicle') {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        owner_id: null as any,
        disable_entities_block: true,
      })
    }
    if (trailer && initialData && typeOwner === 'ownerTrailer') {
      await updateTrailer.mutateAsync({
        id: trailer.id,
        owner_id: null as any,
        disable_entities_block: true,
      })
    }
    window.location.reload()
  }

  function NotifyCreateOwner(): void {
    toast({
      title: 'Proprietário cadastrado com sucesso',
      isClosable: true,
      status: 'success',
      position: 'top-right',
    })
    if (!isNotExistsRoutePoints) setTabIndex(state => state + 1)
    refetch()
  }

  function NotifyOnlyLinkOwnerToVehicle(): void {
    toast({
      title:
        'Proprietário está em análise e portanto não foi editado, apenas vinculado. Para alterações solicite correção da análise de risco.',
      isClosable: true,
      status: 'warning',
      position: 'top',
      duration: 1000 * 8,
    })
    if (!isNotExistsRoutePoints) setTabIndex(state => state + 1)
    refetch()
  }

  function NotifyUpdateOwner(): void {
    toast({
      title: 'Proprietário atualizado com sucesso',
      isClosable: true,
      status: 'success',
      position: 'top-right',
    })
    if (!isNotExistsRoutePoints) setTabIndex(state => state + 1)
    refetch()
  }

  const { errors } = formState

  const handleIncludeAntt: SubmitHandler<OwnerForm> = async data => {
    const address_number = Number(getOnlyNumbers(data.owner.address_number.toString()))
    const isBlocked = verifyUnlockPermission({ blockedEntity, role: user.role })
    const isDataMotorist = verifyIfDataByMotorist(ownerFound)
    try {
      if (isBlocked && ownerFound) {
        await LinkOwnerToVehicle(ownerFound.id)
        NotifyOnlyLinkOwnerToVehicle()
        return
      }
      const { city_id, document, rg_ie, ...ownerFormData } = data.owner
      if (initialData && initialData.id) {
        const isNewOwnerToReplace = verifyIfIsNewOwner({
          type: data.owner.type,
          documentProvided: data.owner.document,
          cpfCurrent: initialData.cpf,
          cnpjCurrent: initialData.cnpj,
          ownerFound,
        })
        if (isBlocked && initialData.id && !ownerFound && !isNewOwnerToReplace) {
          await LinkOwnerToVehicle(initialData.id)
          NotifyOnlyLinkOwnerToVehicle()
        } else if (isNewOwnerToReplace) {
          const { data: response } = await apiServer.post<ResponseApiType<OwnerType>>(`/create-owner`, {
            ...ownerFormData,
            address_number,
            city_id: city_id?.value,
            cpf: data.owner.type === 'pf' ? document : undefined,
            cnpj: data.owner.type === 'pj' ? document : undefined,
            rg: data.owner.type === 'pf' ? rg_ie : undefined,
            ie: data.owner.type === 'pj' ? rg_ie : undefined,
            pis: data.pis,
            rntrc: data.rntrc,
            rntrc_type: data.rntrc_type,
            account: data.account,
            account_type: data.account_type,
            agency: data.agency,
            bank: data.bank?.value,
          })
          await LinkOwnerToVehicle(response.data.id)
          NotifyCreateOwner()
        } else if (!isNewOwnerToReplace) {
          if (isDataMotorist) {
            const { data: response } = await apiServer.post('/create-owner-by-motorist', {
              motorist_id: ownerFound?.id,
              pis: data.pis,
              rntrc: data.rntrc,
              rntrc_type: data.rntrc_type,
              account: data.account,
              account_type: data.account_type,
              agency: data.agency,
              bank: data.bank?.value,
            })
            await LinkOwnerToVehicle(response.data.id)
            NotifyCreateOwner()
          } else if (!isDataMotorist && !isNewOwnerToReplace) {
            const { data: response } = await apiServer.put<ResponseApiType<OwnerType>>(
              `/update-owner/${ownerFound?.id || initialData.id}`,
              {
                ...ownerFormData,
                address_number,
                city_id: city_id?.value,
                cpf: data.owner.type === 'pf' ? document : undefined,
                cnpj: data.owner.type === 'pj' ? document : undefined,
                rg: data.owner.type === 'pf' ? rg_ie : undefined,
                ie: data.owner.type === 'pj' ? rg_ie : undefined,
                pis: data.pis,
                rntrc: data.rntrc,
                rntrc_type: data.rntrc_type,
                account: data.account,
                account_type: data.account_type,
                agency: data.agency,
                bank: data.bank?.value,
              },
            )
            await LinkOwnerToVehicle(response.data.id)
            NotifyUpdateOwner()
          }
        }
      } else if (isDataMotorist) {
        const { data: response } = await apiServer.post('/create-owner-by-motorist', {
          motorist_id: ownerFound?.id,
          pis: data.pis,
          rntrc: data.rntrc,
          rntrc_type: data.rntrc_type,
          account: data.account,
          account_type: data.account_type,
          agency: data.agency,
          bank: data.bank?.value,
        })
        await LinkOwnerToVehicle(response.data.id)
        NotifyCreateOwner()
      } else if (ownerFound && !isDataMotorist) {
        const { data: response } = await apiServer.put<ResponseApiType<OwnerType>>(
          `/update-owner/${ownerFound?.id}`,
          {
            ...ownerFormData,
            address_number,
            city_id: city_id?.value,
            cpf: data.owner.type === 'pf' ? document : undefined,
            cnpj: data.owner.type === 'pj' ? document : undefined,
            rg: data.owner.type === 'pf' ? rg_ie : undefined,
            ie: data.owner.type === 'pj' ? rg_ie : undefined,
            pis: data.pis,
            rntrc: data.rntrc,
            rntrc_type: data.rntrc_type,
            account: data.account,
            account_type: data.account_type,
            agency: data.agency,
            bank: data.bank?.value,
          },
        )
        await LinkOwnerToVehicle(response.data.id)
        NotifyUpdateOwner()
      } else {
        const { data: response } = await apiServer.post<ResponseApiType<OwnerType>>(`/create-owner`, {
          ...ownerFormData,
          address_number,
          city_id: city_id?.value,
          cpf: data.owner.type === 'pf' ? document : undefined,
          cnpj: data.owner.type === 'pj' ? document : undefined,
          rg: data.owner.type === 'pf' ? rg_ie : undefined,
          ie: data.owner.type === 'pj' ? rg_ie : undefined,
          pis: data.pis,
          rntrc: data.rntrc,
          rntrc_type: data.rntrc_type,
          account: data.account,
          account_type: data.account_type,
          agency: data.agency,
          bank: data.bank?.value,
        })
        await LinkOwnerToVehicle(response.data.id)
        NotifyCreateOwner()
      }
      // verificar se é a pagina de cadastro de proprietário de veículo, antt ou reboque, só redirecionar se for antt
      if (isNotExistsRoutePoints) {
        // significa que não selecionou uma rota no roteirizador
        queryClient.invalidateQueries('find-route-points')
        window.location.href = buildRoutePointsUrl()
        localStorage.setItem('currentUrl', window.location.href)
      }
    } catch (error) {
      toastError({
        toast,
        error,
      })
    }
  }

  return (
    <Box>
      {isLoadingState ? (
        <Center mt={20}>
          <Spinner size="lg" />
        </Center>
      ) : (
        <>
          <form onSubmit={handleSubmit(handleIncludeAntt)} noValidate>
            <Flex align="center" justify="start" gridGap={6} direction={['column', 'column', 'row']}>
              {vehicle && vehicle.antt_owner && typeOwner === 'ownerAntt' && (
                <AlertModal
                  size="sm"
                  titleButton="Desvincular proprietário"
                  title="Desvincular Proprietário"
                  description={`Tem certeza que deseja desvincular ${vehicle.antt_owner.name}?`}
                  colorScheme="red"
                  isLoading={updateVehicle.isLoading}
                  leftIcon={<RiUser2Fill />}
                  onConfirm={UnlinkOwnerFromVehicle}
                />
              )}
              {vehicle && vehicle.owner && typeOwner === 'ownerVehicle' && (
                <AlertModal
                  size="sm"
                  titleButton="Desvincular proprietário"
                  title="Desvincular Proprietário"
                  description={`Tem certeza que deseja desvincular ${vehicle.owner.name}?`}
                  colorScheme="red"
                  isLoading={updateVehicle.isLoading}
                  leftIcon={<RiUser2Fill />}
                  onConfirm={UnlinkOwnerFromVehicle}
                />
              )}
              {trailer && trailer.owner && typeOwner === 'ownerTrailer' && (
                <AlertModal
                  size="sm"
                  titleButton="Desvincular proprietário"
                  title="Desvincular Proprietário"
                  description={`Tem certeza que deseja desvincular ${trailer.owner.name}?`}
                  colorScheme="red"
                  isLoading={updateVehicle.isLoading}
                  leftIcon={<RiUser2Fill />}
                  onConfirm={UnlinkOwnerFromVehicle}
                />
              )}

              {(!vehicle?.owner_id || !trailer?.owner_id || !vehicle?.antt_owner_id) && (
                <RadioGroup
                  name="who_owner"
                  label="Quem é proprietário? (Opcional)"
                  setValue={setValue}
                  options={
                    typeOwner === 'ownerVehicle'
                      ? [
                          { label: 'Motorista', value: 'motorist' },
                          { label: 'nenhum', value: 'none' },
                        ]
                      : [
                          { label: 'Motorista', value: 'motorist' },
                          { label: 'Proprietário Veículo', value: 'vehicleOwner' },
                          { label: 'nenhum', value: 'none' },
                        ]
                  }
                  onSelectOption={op => {
                    if (op.value === 'motorist' && motorist) {
                      dispatch(
                        setOwner({
                          ...motorist,
                          type: String(motorist.type) === '1' ? 'pf' : 'pj',
                        } as OwnerType),
                      )
                    }
                    if (op.value === 'vehicleOwner') {
                      dispatch(setOwner({ ...vehicle?.owner } as OwnerType))
                    }
                    if (op.value === 'trailerOwner') {
                      dispatch(setOwner({ ...trailer?.owner } as OwnerType))
                    }
                    if (op.value === 'none') {
                      setIsLoadingState(true)
                      dispatch(setOwner(undefined))
                      setTimeout(() => {
                        setIsLoadingState(false)
                      }, 100)
                    }
                  }}
                />
              )}
            </Flex>

            <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center" mt={3}>
              <Form
                setOwnerType={setOwnerType}
                ownerType={ownerType}
                hasTrailer={!!trailer}
                initialData={initialData}
                setValue={setValue}
                formState={formState}
              />
            </Grid>

            {typeOwner === 'ownerAntt' && (
              <>
                <Divider mt={3} />
                <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center" mt={3}>
                  <GridItem colSpan={12}>
                    <Flex align="center" gridGap={4}>
                      <Heading size="md">Dados da antt</Heading>
                      <Button
                        onClick={() => {
                          onOpenRntrcConsultation()
                        }}
                        colorScheme="blue"
                        rightIcon={<Icon as={RiSearchLine} />}
                      >
                        RNTRC
                      </Button>
                      <Button
                        onClick={() => {
                          onOpenPisConsultation()
                        }}
                        colorScheme="green"
                        rightIcon={<Icon as={RiSearchLine} />}
                      >
                        PIS
                      </Button>
                    </Flex>
                  </GridItem>

                  {ownerType === 'pf' && (
                    <GridItem colSpan={[12, 3]}>
                      <InputMask
                        mask="999999999999999999999999999999"
                        maskPlaceholder=""
                        name="pis"
                        label="PIS"
                        setValue={setValue}
                        initialValue={initialData?.pis || ownerFound?.pis}
                        error={errors.pis}
                        isRequired
                      />
                    </GridItem>
                  )}

                  <GridItem colSpan={[12, 4]}>
                    <InputMask
                      mask="99999999"
                      maskPlaceholder=""
                      registerOnlyNumbers
                      name="rntrc"
                      label="RNTRC"
                      setValue={setValue}
                      initialValue={initialData?.rntrc || ownerFound?.rntrc}
                      error={errors.rntrc}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 3]}>
                    <Select
                      name="rntrc_type"
                      label="Tipo RNTRC"
                      options={[
                        { label: '', value: '' },
                        { label: 'Equiparado', value: 'EQ' },
                        { label: 'ETC', value: 'E' },
                        { label: 'CTC', value: 'C' },
                        { label: 'TAC', value: 'T' },
                      ]}
                      setValue={setValue}
                      error={errors.rntrc_type}
                      initialValue={initialData?.rntrc_type || ownerFound?.rntrc_type}
                      isRequired
                    />
                  </GridItem>
                </Grid>

                <Divider mt={3} />

                <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center" mt={3}>
                  <GridItem colSpan={12}>
                    <Heading size="md">Dados Bancários</Heading>
                  </GridItem>
                  <GridItem colSpan={[12, 3]}>
                    <Autocomplete
                      name="bank"
                      label="Banco"
                      error={errors.bank}
                      setValue={setValue}
                      initialValue={
                        initialData?.bank
                          ? {
                              label: getLabelBank(String(initialData?.bank)),
                              value: String(initialData?.bank),
                            }
                          : undefined || ownerFound?.bank
                          ? {
                              label: getLabelBank(String(ownerFound?.bank)),
                              value: String(ownerFound?.bank),
                            }
                          : undefined
                      }
                      options={bankList}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 3]}>
                    <Select
                      name="account_type"
                      label="Tipo de conta"
                      error={errors.account_type}
                      setValue={setValue}
                      initialValue={initialData?.account_type || ownerFound?.account_type}
                      options={[
                        { label: '', value: '' },
                        { label: 'Corrente', value: 'current' },
                        { label: 'Poupança', value: 'savings' },
                        { label: 'Outros', value: 'others' },
                      ]}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 3]}>
                    <Input
                      name="agency"
                      label="Agencia"
                      error={errors.agency}
                      setValue={setValue}
                      initialValue={initialData?.agency || ownerFound?.agency}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={[12, 3]}>
                    <Input
                      name="account"
                      label="Conta"
                      error={errors.account}
                      setValue={setValue}
                      initialValue={initialData?.account || ownerFound?.account}
                      isRequired
                    />
                  </GridItem>
                </Grid>
              </>
            )}

            <HStack
              spacing="4"
              justifyContent="center"
              mt="6"
              pt="6"
              borderTopWidth="1px"
              borderTopColor="gray.700"
            >
              <Button
                type="submit"
                rightIcon={<Icon as={RiCheckLine} />}
                isLoading={formState.isSubmitting || updateVehicle.isLoading}
                colorScheme="green"
                onClick={() => {
                  console.log(formState.errors)
                }}
              >
                {isNotExistsRoutePoints ? 'Salvar e Ir para Seleção de Rotas' : 'Salvar e Avançar'}
              </Button>
            </HStack>
          </form>
          <PisConsultationModal
            isOpen={isOpenPisConsultation}
            onClose={onClosePisConsultation}
            motherName={ownerFound?.mother_name}
            cpf={ownerFound?.cpf}
            birthDate={ownerFound?.birth}
            name={ownerFound?.name}
          />
          <RntrcConsultationModal
            isOpen={isOpenRntrcConsultation}
            onClose={onCloseRntrcConsultation}
            cpf={ownerFound?.cpf}
            cnpj={ownerFound?.cnpj}
            rntrc={ownerFound?.rntrc}
            licensePlate={vehicle?.license_plate}
          />
        </>
      )}
    </Box>
  )
}
