import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { TextArea } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { usePutLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PutLoadingRequest'
import { LoadingRequestStatusEnum } from '../../../../../services/types/EnumTypes'

type ApproveOrRejectLoadingRequestModalProps = {
  isOpen: boolean
  onClose: () => void
  analysisStatus: string
}

type RequestParams = {
  id: string
}

const UpdateLoadingRequestFormSchema = yup.object().shape({
  status: yup.string(),
  reject_reason: yup.string().when('status', {
    is: LoadingRequestStatusEnum.REJECTED,
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
})

export function ApproveOrRejectLoadingRequestModal({
  isOpen,
  onClose,
  analysisStatus,
}: ApproveOrRejectLoadingRequestModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateLoadingRequestFormSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateLoadingRequestStatus, isLoading } = usePutLoadingRequest({
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUpdateLoadingRequest: SubmitHandler<any> = async data => {
    const formData = new FormData()
    let status: LoadingRequestStatusEnum = LoadingRequestStatusEnum.REJECTED

    if (data.status === 'approved') {
      status = LoadingRequestStatusEnum.AWAITING_CF
    }

    formData.append('status', status)

    formData.append('reject_reason', data.status === 'approved' ? '' : data.reject_reason)

    await updateLoadingRequestStatus({
      id,
      data: formData,
    })

    if (data.status === 'approved') {
      toast({
        title: 'Solicitação de carregamento aprovada!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    } else if (data.status === 'rejected') {
      toast({
        title: 'Solicitação de carregamento reprovada!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    }

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleUpdateLoadingRequest)} noValidate>
            <Text fontWeight="bold" fontSize="20">
              {analysisStatus === 'approved'
                ? 'Deseja realmente aprovar a solicitação de carregamento?'
                : analysisStatus === 'rejected' || analysisStatus === 'request_review'
                ? 'Deseja realmente reprovar a solicitação de carregamento?'
                : ''}
            </Text>
            <Text color="gray.400" mt={2} mb={2}>
              {analysisStatus === 'approved'
                ? 'Se você aprovar, um outro operador responsável irá dar seguemento e gerar o CF no Datamex.'
                : analysisStatus === 'rejected' || analysisStatus === 'request_review'
                ? 'Se você reprovar, o operador responsável pela solicitação de carregamento irá reavaliar os dados.'
                : ''}
            </Text>

            {analysisStatus !== 'approved' && (
              <TextArea
                label="Motivo da reavalição"
                name="reject_reason"
                error={errors.reject_reason}
                height="120px"
                setValue={setValue}
                isRequired
              />
            )}

            <Flex justifyContent="flex-end" mt={2}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme={analysisStatus === 'approved' ? 'green' : 'red'}
                onClick={() => {
                  setValue('status', analysisStatus)
                }}
                isLoading={isLoading}
              >
                {analysisStatus === 'approved' ? (
                  <>
                    <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                  </>
                ) : (
                  <>
                    <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                  </>
                )}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
