import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Divider,
  Heading,
  useToast,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useGetClient } from '../../../services/endpoints/clients/getClient'
import { UpdateClientFormData, usePutClient } from '../../../services/endpoints/clients/putClient.ts'
import { ClientForm, ClientFormSchema } from '../ClientForm'

interface UpdateClientModalProps {
  isOpen: boolean
  onClose: () => void
  client_id: string
}

export function UpdateClientModal({ isOpen, onClose, client_id }: UpdateClientModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(ClientFormSchema),
  })
  const { isSubmitting } = formState
  const updateClient = usePutClient({
    onSuccess: () => {
      queryClient.invalidateQueries('client')
    },
  })

  const { data: clientData } = useGetClient(client_id)

  const handleUpdateClient: SubmitHandler<UpdateClientFormData> = async data => {
    const { clientBusinessGroup, ...clientDataForm } = data
    const name = clientBusinessGroup?.name

    if (name && name.label && !name.value) {
      Object.assign(clientDataForm, {
        clientBusinessGroup: {
          name: name.label,
        },
      })
    }

    if (name && name.label && name.value) {
      Object.assign(clientDataForm, {
        client_business_group_id: name.value,
      })
    }

    try {
      await updateClient.mutateAsync({
        ...clientDataForm,
        id: client_id,
      })
      onClose()
      toast({
        title: 'Cliente editado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent p="5">
        <Heading size="lg" fontWeight="normal">
          Editar Cliente
        </Heading>

        <Divider my="6" />

        <Box as="form" onSubmit={handleSubmit(handleUpdateClient)} noValidate>
          {clientData && <ClientForm formState={formState} setValue={setValue} clientData={clientData} />}
          <Flex mt="8" justify="flex-end">
            <HStack spacing="4">
              <Button onClick={onClose}>Cancelar</Button>

              <Button type="submit" colorScheme="orange" isLoading={isSubmitting}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
