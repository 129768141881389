/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Table,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  WrapItem,
} from '@chakra-ui/react'
import { addMinutes } from 'date-fns'
import React from 'react'
import { RiMapPinLine, RiRoadMapLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import '../../../../../assets/styles/scroll-custom.css'
import DistanceCalculator from '../../../../../components/DistanceCalculator'
import { FreightStatus } from '../../../../../components/FreightStatus'
import MapComponent from '../../../../../components/GoogleMapComponents/MapComponent'
import { PopoverWaypoints } from '../../../../../components/PopoverWaypoints'
import { ToggleColorMode } from '../../../../../components/ToggleColorMode'
import { Logo } from '../../../../../layout/Header/Logo'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { useGetFreightHistory } from '../../../../../services/endpoints/freights/getHistoryFreight'
import { useGetMapDirections } from '../../../../../services/endpoints/freights/getMapDirections'
import { useGetWaypoints } from '../../../../../services/endpoints/motorists/getWaypoints'
import { calculateTripDuration } from '../../../../../services/utils/calculateTripDuration'
import { RequestParams } from '../../../ClientPage'

type Props = {
  freight: FormattedOneFreight | undefined
}
function ClientCardInfos({ freight }: Props): JSX.Element {
  const { freight_id } = useParams<RequestParams>()

  const { data: listFreightStatusHistory } = useGetFreightHistory(freight_id)
  const { data: outputWayPoints } = useGetWaypoints({
    type: freight?.has_monitoring_buonny
      ? 'buonny'
      : !freight?.has_monitoring_buonny && freight?.has_monitoring_whatsapp
      ? 'whatsapp'
      : !freight?.has_monitoring_buonny && !freight?.has_monitoring_whatsapp
      ? 'app'
      : 'app',
    freight: freight!,
  })
  const lastWayPoint = outputWayPoints?.lastWayPoint

  const getMapDirections = useGetMapDirections(freight?.origin, freight?.destination)
  const { data } = useGetMapDirections(
    { lat: lastWayPoint?.lat, lng: lastWayPoint?.lng } as { lat: string | number; lng: string | number },
    freight?.destination,
  )

  let deliveryDate
  let tripDuration
  if (freight?.distance) {
    const date = new Date()
    date.setHours(12)
    tripDuration = calculateTripDuration(freight?.distance, 60, date)
    if (freight?.collect_cargo_at) {
      deliveryDate = new Date(freight?.collect_cargo_at)
      tripDuration = calculateTripDuration(freight?.distance, 60, deliveryDate)
      deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
    }
  }

  let deliveryDateLast
  let tripDurationLast
  const totalDistance = data?.metrics.distance.replace(' km', '').replace(' Km', '')

  if (totalDistance) {
    const date = new Date()
    date.setHours(12)
    tripDurationLast = calculateTripDuration(Number(totalDistance), 60, date)
    if (freight?.collect_cargo_at) {
      deliveryDateLast = new Date(freight?.collect_cargo_at)
      tripDurationLast = calculateTripDuration(Number(totalDistance), 60, deliveryDateLast)
      deliveryDateLast = addMinutes(deliveryDateLast, tripDurationLast.totalTripDurationInMinutes)
    }
  }

  return (
    <Flex
      h={[null, null, '100vh']}
      overflow="hidden"
      overflowY="hidden"
      direction={['column', 'column', 'row']}
    >
      <Box w={['100%', '100%', '500px']} p={0} h="100%">
        <Flex alignItems="center" justifyContent="center">
          <Heading p={4} size="lg">
            <Logo />
          </Heading>
          <ToggleColorMode />
        </Flex>
        <Spacer marginY={4} />
        <Box
          className="scrollable-content"
          h={['100%', '100%', 'calc(100vh - 15vh)']}
          overflowY={['hidden', 'hidden', 'scroll']}
          p={2}
        >
          {freight && (
            <Flex justifyContent="center">
              <PopoverWaypoints
                freight_distance_total={
                  Number(freight.distance) || Number(getMapDirections.data?.metrics.distance)
                }
                freight={freight}
              />
            </Flex>
          )}

          <Text fontSize={['lg', 'x-large']} fontWeight="bold" my="2" mr="10">
            Estimativas:
          </Text>
          <VStack spacing={4} align="stretch">
            <Box>
              {freight &&
                lastWayPoint &&
                !['delivered', 'finished', 'in_destination'].includes(freight.status) && (
                  <DistanceCalculator
                    originLastWaypoint={{
                      lat: lastWayPoint.lat,
                      lng: lastWayPoint.lng,
                    }}
                    destinationClient={{
                      lat: freight.destination.lat,
                      lng: freight.destination.lng,
                    }}
                  />
                )}

              {freight &&
                !['delivered', 'finished', 'in_destination'].includes(freight.status) &&
                tripDurationLast && (
                  <WrapItem alignItems="center" mb={2} mt={2}>
                    <Tag
                      size="lg"
                      borderRadius="full"
                      backgroundColor="orange.200"
                      color="black"
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                    >
                      <TagLabel mr="3" p={2} fontSize={['xx-small', 'sm']} whiteSpace="normal">
                        {tripDurationLast
                          ? `Estimativa de entrega em: ${tripDurationLast?.relativeTripDuration}.`
                          : 'Carregando...'}
                      </TagLabel>
                    </Tag>
                  </WrapItem>
                )}
              {freight &&
                !['delivered', 'finished', 'in_destination'].includes(freight.status) &&
                tripDuration && (
                  <WrapItem alignItems="center" mb={2} mt={2}>
                    <Tag
                      size="lg"
                      borderRadius="full"
                      backgroundColor="orange.200"
                      color="black"
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                    >
                      <TagLabel mr="3" p={2} fontSize={['xx-small', 'sm']} whiteSpace="normal">
                        {tripDuration
                          ? `Duração total do frete: ${tripDuration.relativeTripDuration} no destino.`
                          : 'Carregando...'}
                      </TagLabel>
                    </Tag>
                  </WrapItem>
                )}
              {freight && (
                <WrapItem alignItems="center" w="100%" mb={2} mt={2}>
                  <Tag
                    size="lg"
                    p={2}
                    borderRadius="full"
                    backgroundColor="orange.200"
                    color="black"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                  >
                    <TagLeftIcon fontSize={['xx-small', 'sm']} as={RiMapPinLine} />
                    <TagLabel mr="3" fontSize={['xx-small', 'sm']}>
                      {freight?.origin.name} - {freight?.origin.state.uf}
                    </TagLabel>
                    {'>'}
                    <TagLeftIcon as={RiRoadMapLine} ml="2" />
                    <TagLabel fontSize={['xx-small', 'sm']}>
                      {freight?.destination.name} - {freight?.destination.state.uf}
                    </TagLabel>
                  </Tag>
                </WrapItem>
              )}

              <Box mb={8} mt={10}>
                {freight && (
                  <FreightStatus freight={freight} listFreightStatusHistory={listFreightStatusHistory} />
                )}
              </Box>
              <Box>
                {lastWayPoint && freight && lastWayPoint.public_place && lastWayPoint.city && (
                  <Box>
                    <Text fontSize={['lg']} fontWeight="bold" mb="2" mr="10">
                      Posição atual do veículo
                    </Text>
                    <Table variant="simple" colorScheme="gray.100" size="sm">
                      <Tbody>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Cidade
                          </Td>
                          <Td fontSize={['xs']}>
                            {lastWayPoint.city?.name} - {lastWayPoint.city?.uf}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Logradouro
                          </Td>
                          <Td fontSize={['xs']}>{lastWayPoint.public_place}</Td>
                        </Tr>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Enviado em
                          </Td>
                          <Td fontSize={['xs']}>{lastWayPoint.waypoint_date_formatted}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </Box>

              <Box mt={10}>
                {freight && (
                  <Box>
                    <Text fontSize={['lg']} fontWeight="bold" mb="2" mr="10">
                      Detalhes sobre o frete
                    </Text>
                    <Table variant="simple" colorScheme="gray.100" size="sm">
                      <Tbody>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Cliente
                          </Td>
                          <Td fontSize={['xs']}>{freight?.client.nickname}</Td>
                        </Tr>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Motorista
                          </Td>
                          <Td fontSize={['xs']}>{freight?.motorist?.name}</Td>
                        </Tr>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Modelo e placa do veículo
                          </Td>
                          <Td fontSize={['xs']}>
                            {freight?.vehicle?.vehicle_category?.name}{' '}
                            {freight?.vehicle?.license_plate ? ` - ${freight?.vehicle?.license_plate}` : ''}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            Carroceria e placa
                          </Td>
                          <Td fontSize={['xs']}>
                            {freight?.vehicle?.vehicle_body?.name}{' '}
                            {freight?.vehicle?.body_license_plate
                              ? ` - ${freight?.vehicle?.body_license_plate}`
                              : ''}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontWeight="bold" fontSize={['xs']}>
                            KM total
                          </Td>
                          <Td fontSize={['xs']}>{getMapDirections.data?.metrics.distance}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </Box>
            </Box>
          </VStack>
        </Box>
      </Box>

      <Box width="100%" height="100%">
        {freight && (
          <MapComponent
            freight={freight}
            type={
              freight?.has_monitoring_buonny
                ? 'buonny'
                : !freight?.has_monitoring_buonny && freight?.has_monitoring_whatsapp
                ? 'whatsapp'
                : !freight?.has_monitoring_buonny && !freight?.has_monitoring_whatsapp
                ? 'app'
                : 'app'
            }
            showDistance={false}
          />
        )}
      </Box>
    </Flex>
  )
}

export default ClientCardInfos
