/* eslint-disable no-param-reassign */
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine, RiFileCopyLine } from 'react-icons/ri'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'
import { CargoType, CityType, VehicleBodyType, VehicleCategoryType } from '../../../../services/types'
import { ClientContactType } from '../../../../services/types/ClientType'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setFreightForm, setIsQuotation } from '../../../../store/slices/freightSlice'
import { FreightAndQuotationForm, FreightFormData } from '../../../operations/forms/FreightAndQuotationForm'
import { freightAndQuotationValidateSchema } from '../../../operations/forms/FreightAndQuotationValidation'

export interface DateCompare {
  date?: Date | string
  name: string
}
export interface QuotationProp {
  id?: string
  status?: string
  type: string | undefined
  cte?: string
  transporter?: string
  is_round_trip?: boolean
  suggested_price?: number
  suggested_price_type?: string
  agreed_price?: number
  description?: string
  service_price?: number
  has_toll?: boolean
  formatted_received_at?: string
  formatted_link_motorist_at?: string
  input_collect_cargo_at?: string
  formatted_delivered_cargo_at?: string
  is_fretebras_sync?: boolean
  origin_complement?: string
  destination_complement?: string
  client_contact_id?: string
  client_id?: string
  client: {
    id: string
    name: string
    nickname?: string
    client_business_group_id?: string
  }
  creator?: {
    id: string
    name: string
  }
  client_contact?: ClientContactType
  seller?: {
    id: string
    name: string
  }
  origin: CityType
  destination: CityType
  vehicle_categories?: Omit<VehicleCategoryType, 'fretebras_vehicle_type_id'>[]
  vehicle_bodies?: Omit<VehicleBodyType, 'fretebras_vehicle_body_id'>[]
  cargo?: Partial<CargoType>
  is_quotation?: boolean
  required_items?: string
  calculation_ref?: string
  quotation_id?: string
  sector?: string
  client_ref_type?: string
  client_ref_number?: string
}

interface EditOrDuplicateQuotationModalProps {
  type: 'edit' | 'duplicate' | undefined
  quotation: QuotationProp
  isOpen: boolean
  onClose: () => void
}

export function EditOrDuplicateQuotationModal({
  type,
  quotation,
  isOpen,
  onClose,
}: EditOrDuplicateQuotationModalProps): JSX.Element {
  const dispatch = useAppDispatch()
  const { freightForm, client_business_group_id } = useAppSelector(state => state.freightSlice)
  const toast = useToast()
  const { handleSubmit, setValue, formState, setError } = useForm({
    resolver: yupResolver(freightAndQuotationValidateSchema),
  })

  const dateNow = format(new Date(), "yyyy-MM-dd'T'HH:mm")

  function descriptionNoNumber(description: string): string | undefined {
    const text = String(description).split(' ')
    text.pop()

    return text.join(' ')
  }
  function freightNumber(description: string): string | undefined {
    const text = String(description).split(' ').pop()

    return text
  }

  useEffect(() => {
    dispatch(
      setFreightForm({
        id: quotation.id,
        type: quotation.type,
        transporter: quotation.transporter,
        status: quotation.status,
        service_price: quotation.service_price,
        vehicle_categories: quotation.vehicle_categories?.map(category => ({
          label: category.name,
          value: category.id,
        })),
        vehicle_bodies: quotation.vehicle_bodies?.map(body => ({
          label: body.name,
          value: body.id,
        })),
        client_id: {
          label: quotation.client?.nickname ? quotation.client?.nickname : quotation.client?.name,
          value: quotation.client?.id,
          client_business_group_id: quotation.client?.client_business_group_id,
        },
        client_contact_id: {
          label: String(quotation.client_contact?.name),
          value: String(quotation.client_contact?.id),
        },
        creator_id: {
          label: String(quotation.creator?.name),
          value: String(quotation.creator?.id),
        },
        seller_id: {
          label: String(quotation.seller?.name),
          value: String(quotation.seller?.id),
        },
        received_at: type === 'edit' ? quotation.formatted_received_at : dateNow,
        link_motorist_at: quotation.formatted_link_motorist_at,
        collect_cargo_at: quotation.input_collect_cargo_at,
        delivered_cargo_at: type === 'edit' ? quotation.formatted_delivered_cargo_at : undefined,
        origin_id: {
          label: quotation.origin.name,
          value: quotation.origin.ibge_id,
        },
        destination_id: {
          label: quotation.destination.name,
          value: quotation.destination.ibge_id,
        },
        suggested_price: quotation.suggested_price,
        agreed_price: quotation.agreed_price,
        suggested_price_type: quotation.suggested_price_type,
        has_toll: quotation.has_toll,
        description: descriptionNoNumber(String(quotation.description)),
        publish_on_fretebras: type === 'edit' ? quotation.is_fretebras_sync : false,
        origin_complement: quotation?.origin_complement,
        destination_complement: quotation.destination_complement,
        required_items: quotation.required_items,
        sector: quotation.sector,
        client_ref_type: quotation.client_ref_type,
        client_ref_number: quotation.client_ref_number,
        client_contact: quotation.client_contact && {
          client_id: quotation.client_contact.client_id,
          name: quotation.client_contact.name,
          email: quotation.client_contact.email,
          id: quotation.client_contact.id,
          phone: quotation.client_contact.phone,
          role: quotation.client_contact.role,
          client_business_group_id: quotation?.client_contact?.client_business_group_id,
        },
        cargo: quotation.cargo && {
          id: quotation.cargo.id,
          cargo_category_fretebras_id: quotation.cargo.cargo_category_fretebras_id,
          has_complement: quotation.cargo.has_complement,
          name: quotation.cargo.name,
          require_tracker: quotation.cargo.require_tracker,
          depth: quotation.cargo.depth ? Number(quotation.cargo.depth) : undefined,
          value: quotation.cargo.value ? Number(quotation.cargo.value) : undefined,
          height: quotation.cargo.height ? Number(quotation.cargo.height) : undefined,
          weight: quotation.cargo.weight ? Number(quotation.cargo.weight) : undefined,
          width: quotation.cargo.width ? Number(quotation.cargo.width) : undefined,
        },
      }),
    )
  }, [quotation, type, isOpen, dateNow, dispatch])

  const handleEditOrDuplicateQuotation = useCallback<SubmitHandler<FreightFormData>>(
    async data => {
      if (data.received_at && data.collect_cargo_at) {
        if (data.received_at >= data.collect_cargo_at) {
          setError('collect_cargo_at', {
            message: 'A previsão de coleta não pode ser menor ou igual a data de solicitação!',
          })
          return
        }
        if (data.delivered_cargo_at && data.collect_cargo_at >= data.delivered_cargo_at) {
          setError('delivered_cargo_at', {
            message: 'A previsão de entrega não pode ser menor ou igual a previsão de coleta!',
          })
          return
        }
      }

      const quotationFormData = {
        type: data.type,
        transporter: data.transporter,
        client_id: data.client_id?.value,
        client_contact_id:
          !data.client_contact?.switch_add_contact && data.client_contact?.client_contact_id?.value,
        received_at: data.received_at,
        delivered_cargo_at: data.delivered_cargo_at,
        collect_cargo_at: data.collect_cargo_at,
        creator_id: data.creator_id?.value,
        seller_id: data.seller_id?.value,
        origin_id: data.origin_id?.value,
        destination_id: data.destination_id?.value,
        suggested_price: data.suggested_price,
        suggested_price_type: data.suggested_price_type,
        agreed_price: data.agreed_price,
        service_price: data.service_price,
        has_toll: Boolean(data.has_toll),
        description: data.description,
        origin_zipcode: data.address_origin?.zipcode,
        origin_address: data.address_origin?.address,
        origin_number: data.address_origin?.address_number,
        origin_district: data.address_origin?.district,
        origin_complement: data.address_origin?.label,
        destination_zipcode: data.address_destination?.zipcode,
        destination_address: data.address_destination?.address,
        destination_number: data.address_destination?.address_number,
        destination_district: data.address_destination?.district,
        destination_complement: data.address_destination?.label,
        origin_coords: data.address_origin?.coords,
        destination_coords: data.address_destination?.coords,
        required_items: data.required_items,
        vehicle_categories_id: data.vehicle_categories?.map(i => i.value),
        vehicle_bodies_id: data.vehicle_bodies?.map(i => i.value),
        calculation_ref: quotation.calculation_ref || undefined,
        sector: data.sector,
        client_ref_type: data.client_ref_type,
        client_ref_number: data.client_ref_number,
        publish_on_fretebras: data.publish_on_fretebras,
        client_contact: data.client_contact?.switch_add_contact && {
          id: data.client_contact?.switch_add_contact ? undefined : data.client_contact_id?.value,
          client_contact_id: data.client_contact?.switch_add_contact
            ? undefined
            : data?.client_contact?.client_contact_id?.value,
          client_id: data.client_id?.value,
          name: data.client_contact?.name,
          email: data.client_contact?.email,
          phone: data.client_contact?.phone,
          phone2: data.client_contact?.phone2,
          role: data.client_contact?.role,
          client_business_group_id:
            quotation?.client_contact?.client_business_group_id || client_business_group_id,
        },
        cargo: data.cargo && {
          id: type === 'edit' ? quotation.cargo?.id : undefined,
          name: data.cargo.name,
          cargo_category_fretebras_id: data.cargo.cargo_category_fretebras_id,
          width: data.cargo.width,
          height: data.cargo.height,
          depth: data.cargo.depth,
          weight: data.cargo.weight,
          value: data.cargo.value,
          require_tracker: Boolean(data.cargo.require_tracker),
          has_complement: Boolean(data.cargo.has_complement),
        },
      } as FreightFormData

      if (type === 'edit') {
        if (quotation.id) {
          try {
            await apiServer.put(`quotations/${quotation.id}`, {
              ...quotationFormData,
              creator_id: data.creator_id?.value,
              seller_id: data.seller_id?.value,
              description: `${String(data.description)} ${freightNumber(String(quotation.description))}`,
            })
            toast({
              title: 'Cotação editada com sucesso!',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            })
            window.location.reload()
            queryClient.invalidateQueries('quotation')
            onClose()
          } catch (error) {
            toastError({ toast, error })
          }
        } else {
          toastError({ toast, error: 'Erro ao editar cotação' })
        }
      } else {
        try {
          let link_quotation = quotation.quotation_id || undefined

          link_quotation = `/quotations/show/${quotation.id}`

          quotationFormData.quotation_id = link_quotation

          const { data: res } = await apiServer.post(`/quotations`, quotationFormData)

          const newQuotation = res.data

          toast({
            title: 'Cotação criada com sucesso!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
          window.location.href = `/quotations/show/${newQuotation.id}`
          onClose()
        } catch (error) {
          toastError({ toast, error })
        }
      }
    },
    [
      quotation.calculation_ref,
      quotation?.client_contact,
      quotation.cargo?.id,
      quotation.id,
      quotation.description,
      quotation.quotation_id,
      client_business_group_id,
      type,
      setError,
      onClose,
      toast,
    ],
  )

  useEffect(() => {
    dispatch(setIsQuotation(true))
    if (isOpen) queryClient.invalidateQueries('quotation')
  }, [dispatch, isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        type = undefined
        onClose()
      }}
      closeOnOverlayClick={false}
      size="5xl"
      scrollBehavior="outside"
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleEditOrDuplicateQuotation)} noValidate>
          <ModalHeader fontSize="3xl">{type === 'edit' ? 'Editar cotação' : 'Cadastrar cotação'}</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FreightAndQuotationForm
              setValue={setValue}
              formState={formState}
              initialData={freightForm}
              isEdit={type === 'edit'}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme={type === 'edit' ? 'orange' : 'blue'}
              leftIcon={<Icon as={type === 'edit' ? RiEditLine : RiFileCopyLine} />}
              isLoading={formState.isSubmitting}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log(formState.errors)
              }}
            >
              {type === 'edit' ? 'Editar' : 'Cadastrar'}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
