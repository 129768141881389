export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString)
  return !Number.isNaN(date)
}

export const validateDate = (value?: Date | string): boolean => {
  if (!value || value !== 'undefined') return true
  const year = new Date(value).getFullYear()
  return year > 1900 && year < 2100
}
