import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { toastError } from '../../../../config/error/toastError'
import { useChangeCteRelatedFreight } from '../../../../services/endpoints/freights/changeCteRelatedFreight'

interface ChangeCteRelatedFreightModalProps {
  isOpen: boolean
  onClose: () => void
  cte_id: string
}

export function ChangeCteRelatedFreightModal({
  isOpen,
  onClose,
  cte_id,
}: ChangeCteRelatedFreightModalProps): JSX.Element {
  const { mutateAsync: handleChangeCteRelatedFreight, isLoading: isLoadingChangeCteRelated } =
    useChangeCteRelatedFreight()

  const [freightNumber, setFreightNumber] = useState<string>()

  const toast = useToast()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setFreightNumber(undefined)
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mudar o frete vinculado ao CTE</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            type="number"
            placeholder="Digite o número do frete"
            onChange={e => setFreightNumber(e.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            isLoading={isLoadingChangeCteRelated}
            onClick={async () => {
              if (freightNumber) {
                try {
                  await handleChangeCteRelatedFreight({
                    cte_id,
                    freight_number: freightNumber,
                  })
                  toast({
                    title: 'Frete vinculado com sucesso',
                    status: 'success',
                    position: 'top-right',
                  })
                  onClose()
                } catch (error) {
                  toastError({ toast, error })
                }
              }
            }}
            isDisabled={!freightNumber}
          >
            Vincular
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
