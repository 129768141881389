/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { queryClient } from '../../../../../config/react-query'
import { useIsBlockEntity } from '../../../../../services/endpoints/blocked-entities/isBlockEntity'
import { usePostFreightVehicleTags } from '../../../../../services/endpoints/truckpad/usePostFreightVehicleTags'
import { usePostRoutePoints } from '../../../../../services/endpoints/truckpad/usePostRoutePoints'
import { PlaceType } from '../RoutePlannerMap'
import RoutePlannerMapComponent from '../RoutePlannerMapComponent'
import RouteDiretion from './Diretion'
import { RouteCard } from './Route'

interface TollPlace {
  name: string
  value: number
  point: [number, number] // [Longitude, Latitude]
  address: string
}

export interface Route {
  points: { point: [number, number]; provider: string }[]
  tolls_place: TollPlace[]
  route_points: [number, number][]
  distance: number
  distance_unit: string
  duration: number
  duration_unit: string
  tolls_amount: number
  tolls_gross_amount: number
  tolls_count: number
  route_provider: string
  travel_type: string
  cached: null | boolean
}

interface RoutePlannerModalProps {
  isOpen: boolean
  onClose: () => void
  routes: Route[][]
  origin: string
  destination: string
  setTollValue?: (value: number) => void
  setDistance?: (value: number) => void
  setRouteResponsePoints?: (value: PlaceType[]) => void

  freight_id?: string
  vehicle_license_plate?: string
  tag_provider?: string
  isPageFreight?: boolean
  freightTollValue?: number
  freight_distance?: string
}

export function RoutePlannerModal({
  isOpen,
  onClose,
  routes,
  origin,
  destination,
  setTollValue,
  setDistance,
  setRouteResponsePoints,
  freight_id,
  vehicle_license_plate,
  tag_provider,
  isPageFreight = false,
  freightTollValue = undefined,
  freight_distance,
}: RoutePlannerModalProps): JSX.Element {
  const [clickedRoute, setClickedRoute] = useState<string>('')
  const [selectedRoute, setSelectedRoute] = useState<Route | null>(null)
  const { data: isBlockedEntity } = useIsBlockEntity({
    freight_id: freight_id || undefined,
  })
  const toast = useToast()
  const { mutateAsync: mutateAsyncPost } = usePostRoutePoints({
    onSuccess: () => {
      queryClient.invalidateQueries('route-planner')
    },
  })

  const { mutateAsync: mutateAsyncPostFreightVehicleTags } = usePostFreightVehicleTags({
    onSuccess: () => {
      queryClient.invalidateQueries('route-planner')
    },
  })

  function handleRouteSelection(route: any) {
    if (freight_id && !isBlockedEntity) {
      mutateAsyncPost({
        points: route.route_points.map((point: any[]) => ({
          latitude: point[1],
          longitude: point[0],
        })),
        freight_id,
        toll_value: route.tolls_gross_amount,
        provider: tag_provider,
        freight_distance: route.distance,
      })

      // Essa condicional significa que o frete está bloqueado, então não é possível selecionar a rota
      // se a placa e a tag estiverem preenchidas, então é possível selecionar a rota
      // Tudo isso, somente caso não seja chamado o componente pela página de frete
      if (vehicle_license_plate && tag_provider && !isBlockedEntity && !isPageFreight) {
        mutateAsyncPostFreightVehicleTags({
          freight_id,
          vehicle_license_plate,
          tag_provider,
        })
        toast({
          title: 'Rota Selecionada',
          description: `Rota selecionada com sucesso!`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        })

        if (setTollValue && setDistance) {
          setTollValue(route.tolls_gross_amount)
          setDistance(route.distance / 1000)
        }
        setSelectedRoute(route)
      } else if (isPageFreight) {
        toast({
          title: 'Consulta de Rota',
          description: `Rota não selecionada, não é possivel selecionar a rota pela tela de frete.`,
          status: 'warning',
          duration: 8000,
          isClosable: true,
          position: 'top',
        })
        setSelectedRoute(route)
      } else {
        toast({
          title: 'Consulta de Rota',
          description: `Rota não selecionada, verifique se o frete está bloqueado ou se a placa e a tag estão preenchidas.`,
          status: 'warning',
          duration: 8000,
          isClosable: true,
          position: 'top',
        })
        setSelectedRoute(route)
      }
    } else {
      // somente para exibir no mapa os valores, não serão armazenados quando não tiver vinculado a um frete
      if (setTollValue && setDistance) {
        setTollValue(route.tolls_gross_amount)
        setDistance(route.distance / 1000)
      }
      setSelectedRoute(route)
    }
  }

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          closeOnOverlayClick={false}
          size="full"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent maxW="90vw" bg={useColorModeValue('gray.100', 'gray.800')}>
            <ModalHeader display="flex" alignItems="center" gridGap="2" p={4}>
              <Button size="md" variant="solid" p="0" onClick={onClose}>
                <RiArrowLeftLine />
              </Button>
              <Heading as="h2" size="md">
                Rotas
              </Heading>
            </ModalHeader>
            <ModalBody overflowY="auto" p={0}>
              <Flex direction={{ base: 'column', lg: 'row' }}>
                <Box flex="0 1 500px" overflowY="auto" p={4}>
                  <RouteDiretion origin={origin} destination={destination} />
                  <Text my={4} fontWeight="bold" color="gray.500">
                    Clique na rota desejada:
                  </Text>
                  <Divider my={4} />
                  <Flex flexDir="column" gridGap={4}>
                    {!isPageFreight &&
                      routes.map((route, i) => (
                        <Box key={route[0].distance} borderBottom="1px" borderColor="gray.700">
                          <RouteCard
                            key={route[0].distance}
                            isSelected={clickedRoute === `route ${i + 1}`}
                            id={i + 1}
                            route={route}
                            handleRouteSelection={() => {
                              handleRouteSelection(route[0])
                              setClickedRoute(`route ${i + 1}`)
                            }}
                            onMouseEnter={() => {
                              setSelectedRoute(route[0])
                              if (setRouteResponsePoints)
                                setRouteResponsePoints(
                                  route[0].route_points.map(point => ({
                                    position: { lat: point[1], lng: point[0] },
                                  })),
                                )
                            }}
                          />
                        </Box>
                      ))}
                    {isPageFreight && routes && routes.length > 0 && (
                      <Box borderBottom="1px" borderColor="gray.700">
                        <RouteCard
                          isSelected
                          id={1}
                          route={routes[0]}
                          handleRouteSelection={() => {
                            handleRouteSelection(routes[0][0])
                            setClickedRoute(`route 1`)
                          }}
                          onMouseEnter={() => {
                            setSelectedRoute(routes[0][0])
                            if (setRouteResponsePoints)
                              setRouteResponsePoints(
                                routes[0][0].route_points.map(point => ({
                                  position: { lat: point[1], lng: point[0] },
                                })),
                              )
                          }}
                          freightTollValue={freightTollValue}
                          freight_distance={freight_distance || undefined}
                          isPageFreight
                        />
                      </Box>
                    )}
                  </Flex>
                </Box>
                <Box flex="2" h="calc(100vh - 72px)" overflowY="auto" position="sticky" top="0">
                  {selectedRoute && !isPageFreight && (
                    <Box width="100%" height="100%">
                      <RoutePlannerMapComponent
                        showIcons
                        zoom={8}
                        origin={{
                          lat: selectedRoute.route_points[0][1],
                          lng: selectedRoute.route_points[0][0],
                        }}
                        destination={{
                          lat: selectedRoute.route_points[selectedRoute.route_points.length - 1][1],
                          lng: selectedRoute.route_points[selectedRoute.route_points.length - 1][0],
                        }}
                        showDistance
                        places={selectedRoute.route_points.map(point => ({
                          position: { lat: point[1], lng: point[0] },
                        }))}
                      />
                    </Box>
                  )}
                  {isPageFreight && routes && routes.length > 0 && routes[0][0].route_points.length > 0 && (
                    <Box width="100%" height="100%">
                      <RoutePlannerMapComponent
                        showIcons
                        zoom={8}
                        origin={{
                          lng: routes[0][0].route_points[0][1],
                          lat: routes[0][0].route_points[0][0],
                        }}
                        destination={{
                          lng: routes[routes.length - 1][0].route_points[
                            routes[routes.length - 1][0].route_points.length - 1
                          ][1],
                          lat: routes[routes.length - 1][0].route_points[
                            routes[routes.length - 1][0].route_points.length - 1
                          ][0],
                        }}
                        places={routes
                          .filter(route => route[0].route_points && route[0].route_points.length > 0)
                          .flatMap(route =>
                            route[0].route_points.map(point => ({
                              position: {
                                lng: point[1],
                                lat: point[0],
                              },
                            })),
                          )}
                        showDistance
                        freightTollValue={freightTollValue}
                      />
                    </Box>
                  )}
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
