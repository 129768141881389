import {
  Divider,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import { useGetMonitoringOccurence } from '../../services/endpoints/freights/getMonitoringOccurence'

interface IShowMonitoringOccurencesProps {
  isOpen: boolean
  onClose: () => void
  freight_id: string
  monitoringOccurrenceId: string
}

export function ShowMonitoringOccurences({
  isOpen,
  onClose,
  freight_id,
  monitoringOccurrenceId,
}: IShowMonitoringOccurencesProps): JSX.Element {
  const { data: monitoringOccurence, isLoading } = useGetMonitoringOccurence({
    freight_id,
    monitoring_occurrence_id: monitoringOccurrenceId,
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dados da ocorrência de monitoramento</ModalHeader>
        <ModalCloseButton />
        {!isLoading && (
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap="4">
              <GridItem colSpan={[12, 4]}>
                <Flex>
                  <Text color="gray.400">Tipo de monitoramento: </Text>
                  <Text fontWeight="medium" ml="1">
                    {monitoringOccurence?.reason.monitoring_type.toUpperCase()}
                  </Text>
                </Flex>
              </GridItem>

              <GridItem colSpan={[12, 4]}>
                <Flex justifyContent="center">
                  <Text color="gray.400">Motivo: </Text>
                  <Text fontWeight="medium" ml="1">
                    {monitoringOccurence?.reason.reason_name}
                  </Text>
                </Flex>
              </GridItem>

              <GridItem colSpan={[12, 4]}>
                <Flex justifyContent="flex-end">
                  <Text color="gray.400">Data de criação: </Text>
                  <Text fontWeight="medium" ml="1">
                    {format(
                      monitoringOccurence?.created_at
                        ? new Date(monitoringOccurence?.created_at)
                        : new Date(),
                      'dd/MM/yyyy',
                    )}
                  </Text>
                </Flex>
              </GridItem>

              <GridItem colSpan={[12, 12]}>
                <Flex>
                  <Text color="gray.400">Usuário criador:</Text>
                  <Text fontWeight="medium" ml="1">
                    {monitoringOccurence?.user.name}
                  </Text>
                </Flex>
              </GridItem>

              <GridItem colSpan={[12, 12]} mb="2">
                <Divider w="100%" />
                <Text color="gray.400" mt="4" mb="1">
                  Observação:
                </Text>
                <Text fontWeight="medium">{monitoringOccurence?.observation}</Text>
              </GridItem>
            </Grid>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}
