/* eslint-disable react-hooks/rules-of-hooks */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input as ChakraInput,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { CalcAverage } from '../../components/CalcAverage'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { InputNumberMask } from '../../components/form/InputNumberMask'
import { Select } from '../../components/form/Select'
import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../components/form/types/SelectOption'
import { useAuth } from '../../contexts/AuthContext'
import { useGlobalVariables } from '../../contexts/GlobalVariables'
import { Layout } from '../../layout'
import { apiServer } from '../../services/api'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { searchClientsByName } from '../../services/endpoints/clients/searchClients'
import { getVehicleCategories } from '../../services/endpoints/vehicles/getVehicleCategories'
import { CityType, FreightType } from '../../services/types'
import QuotationType from '../../services/types/QuotationType'
import { calculateTripDuration, TripDurationResult } from '../../services/utils/calculateTripDuration'
import parseCashBRLToNumber from '../../services/utils/parseCashBRLToNumber'
import { CreateFreightModal } from '../freights/edit/CreateFreightModal'
import { CreateQuotationResponse } from './CreateCalcQuotation'
import { EditOrDuplicateQuotationModal } from './modals/edit/EditOrDuplicateQuotationModal'
import { ProposalInfoModal } from './modals/ProposalInfoModal'

interface RouteParams {
  slug: string
}

interface CalcProposal {
  taxa_imposto_fixo: number
  background_check: string
  taxa_imposto_sobre_folha: number
  taxa_contador_motorista: string
  ICMS_taxa: number
  ICMS: string
  ICMS_valor: number
  ICMS_valor__BRL: string
  ISS: string
  ISS_valor__BRL: string
  taxa_seguro: string
  aliquota_seguro: number
  valor_seguro: string
  valor_final: string
  custo_motorista: string
  valor_imposto_sobre_folha: string
  receita_liquida: string
  imposto_valor: number
  margem_contribuicao_valor: string
  valor_a_ser_cobrado: number
  valor_a_ser_cobrado__BRL: string
  valor_a_ser_cobrado_sem_icms: number
  valor_a_ser_cobrado_sem_icms__BRL: string
  valor_mercadoria: string
  margem_contribuicao: string
  valor_pedagio: string
}

export interface ProposalResponse {
  status: 'success' | 'error'
  data: {
    timestamp: string
    empresa_nome: SelectOption
    custo_nf: string
    mercadoria_outros_tipos: string
    carga_proibida: string
    insurance_ask: '1'
    slug: string
    info_frete: {
      locations: {
        origem: CityType
        destino: CityType
      }
      parametros_identifica_seguro: {
        tipo_mercadoria: string
        custo_nf: string
        origem_uf: string
        destino_uf: string
      }
      seguro: {
        mercadoria: {
          name: string
          grupo: string
          slug: string
        }
        frete: {
          regiao: string
          descricao: string
          estados: string
          cuidados: string[]
        }
        regras: {
          faixa: string
          regras: Array<string[]>
        }
      }
      rota?: {
        params: {
          waypoints: string[]
          locations: {
            origem: {
              name: string
              ibge_id: number
            }
            destino: {
              name: string
              ibge_id: number
            }
          }
          typeOfLoad: string
          vehicleType: string
          vehicle?: SelectOption
          axis: string
          model: string
        }
        result: {
          totalTollPrice: string
          totalMinimumFreight: string
          distance: string
          duration: string
          success: boolean
          full_price: string
          locations: {
            origem: {
              name: string
              ibge_id: number
            }
            destino: {
              name: string
              ibge_id: number
            }
          }
          waypoints: string[]
        }
      }
      calculo?: {
        params: {
          origem: string
          destino: string
          custo_nf: string
          valor_base: string
          tipo_valor_base: string
          proposta: string
          margem_contribuicao: string
          valor_pedagio: string | undefined
        }
        result: {
          PF: CalcProposal
          PJ: CalcProposal
        }
      }
    }
  }
}

interface SelectedVehicle {
  vehicleType: string
  axis: number
  vehycle: SelectOption
}

interface WikiRotaResponse {
  status: 'success' | 'error'
  message?: string
  content: {
    totalMinimumFreight: string
    distance: string
    duration: string
    success: boolean
    full_price: string
    locations: {
      origem: {
        name: string
        ibge_id: number
      }
      destino: {
        name: string
        ibge_id: number
      }
    }
    waypoints: string[]
    totalTollPrice: string
  }
}

export interface CalcProposalResponse {
  status: 'error' | 'success'
  message?: string
  content: {
    PF: CalcProposal
    PJ: CalcProposal
  }
}

type FreightCalculationHistoryType = {
  freight?: FreightType
  quotation?: QuotationType
}

const freightCalculationFormSchema = yup.object().shape({
  destination: yup.object().required('Campo obrigatório'),
})

export function FreightCalculation(): JSX.Element {
  const { user } = useAuth()
  const { setPageTitle } = useGlobalVariables()
  const params = useParams<RouteParams>()

  const { setValue, formState } = useForm({
    resolver: yupResolver(freightCalculationFormSchema),
  })
  const bg = useColorModeValue('white', 'gray.800')
  const [proposal, setProposal] = useState<ProposalResponse>()
  const [calcProposal, setCalcProposal] = useState<CalcProposalResponse>()
  const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicle>()
  const [selectVehicleCategories, setSelectVehicleCategories] = useState<SelectOption[]>([])
  const [isLoadingCalcWikirota, setIsLoadingCalcWikirota] = useState<boolean>()
  const [isLoadingNewRota, setIsLoadingNewRota] = useState<boolean>()
  const [roleProhibited, setRoleProhibited] = useState<boolean>(false)
  const [selectedTypeOfLoad, setSelectedTypeOfLoad] = useState('geral')
  const [baseType, setBaseType] = useState('custo_motorista')
  const [freightCalculationHistory, setFreightCalculationHistory] = useState<FreightCalculationHistoryType>({
    freight: undefined,
    quotation: undefined,
  })

  const [baseValue, setBaseValue] = useState('')
  const [calcToll, setCalcToll] = useState<string | undefined>()
  const [marginValue, setMarginValue] = useState('20')
  const [totalWithIcms, setTotalWithIcms] = useState('')
  const [distance, setDistance] = useState('')
  const [totalMinimumFreight, setTotalMinimumFreight] = useState('')
  const [tollPrice, setTollPrice] = useState('')
  const [totalTollPrice, setTotalTollPrice] = useState('')
  const [origin, setOrigin] = useState<AutocompleteOption>()
  const [isNewRota, setIsNewRota] = useState(false)
  const [destination, setDestination] = useState<AutocompleteOption>()
  const [isLoadingCalcProposal, setIsLoadingCalcProposal] = useState(false)
  const [servicePrice, setServicePrice] = useState<number | undefined>()

  const { errors } = formState
  const toast = useToast()
  const history = useHistory()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenQuotationFormModal,
    onClose: onCloseQuotationFormModal,
    onOpen: onOpenQuotationFormModal,
  } = useDisclosure()

  const {
    isOpen: isOpenFreightFormModal,
    onOpen: onOpenFreightFormModal,
    onClose: onCloseFreightFormModal,
  } = useDisclosure()

  useEffect(() => {
    async function loadProposal() {
      const result = await getVehicleCategories()
      const { data } = await apiServer.get<ProposalResponse>(
        `https://sofix.com.br/api/spotx_calculo/?api=proposta&id=${params.slug}`,
      )
      if (data.status === 'error') {
        history.push(`/freights/quotation`)
      }

      if (
        data?.data?.info_frete?.seguro?.regras?.regras
          ?.map(i => {
            return {
              role: i[0],
              aplication: i[1],
            }
          })
          .find(i => i.role.includes('Motorista Carreteiro / Autônomo') && i.aplication.includes('PROIBÍDO'))
      ) {
        setRoleProhibited(true)
      }

      setProposal(data)
      setSelectVehicleCategories(result as SelectOption[])

      if (data.status !== 'error' && data.data.info_frete.rota?.result) {
        setSelectedTypeOfLoad(String(data?.data.info_frete.rota?.params.typeOfLoad))
        setSelectedVehicle({
          axis: Number(data?.data.info_frete.rota?.params.axis),
          vehicleType: String(data?.data.info_frete.rota?.params.vehicleType),
          vehycle: data?.data.info_frete.rota?.params.vehicle as SelectOption,
        })
        setDistance(String(data?.data.info_frete.rota.result.distance))

        setTotalMinimumFreight(data.data.info_frete.rota.result.totalMinimumFreight)

        setBaseValue(data.data.info_frete.rota.result.totalMinimumFreight)

        setTollPrice(data.data.info_frete.rota.result.totalTollPrice)

        setCalcToll(data.data.info_frete.calculo?.params.valor_pedagio)

        setTotalTollPrice(String(data?.data.info_frete.rota.result.full_price))
      }

      if (data.status !== 'error' && data.data.info_frete.calculo?.result) {
        setBaseType(String(data?.data.info_frete.calculo?.params.tipo_valor_base))
        setBaseValue(
          String(
            new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(data?.data.info_frete.calculo?.params.valor_base)),
          )
            .replace('R$', '')
            .trim(),
        )

        setMarginValue(String(data?.data.info_frete.calculo?.params.margem_contribuicao))
      }

      if (data.status !== 'error' && data.data.info_frete.calculo?.result) {
        setCalcProposal({
          status: 'success',
          content: {
            ...data.data.info_frete.calculo?.result,
          },
        })
        setTotalWithIcms(String(data?.data.info_frete.calculo?.result.PF.valor_a_ser_cobrado__BRL))
      }
    }

    loadProposal()
  }, [params.slug, isOpenQuotationFormModal, history])

  setPageTitle(`
  ${proposal?.data.empresa_nome.label} -
  ${proposal?.data.info_frete?.locations.origem.name} /
  ${proposal?.data.info_frete?.locations.destino.name} | SpotX Admin`)

  if (baseType === undefined) {
    setBaseType('custo_motorista')
  }
  if (marginValue === undefined) {
    setMarginValue('20')
  }
  if (totalWithIcms === undefined) {
    setTotalWithIcms('')
  }
  if (baseValue === undefined) {
    setBaseValue('')
  }

  async function handleFreightCalculationHistory(): Promise<void> {
    if (selectedVehicle?.vehicleType === undefined) {
      toast({
        title: 'Selecione um veículo',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
      return
    }

    const { data } = await apiServer.get(`/freight-calculation-history`, {
      params: {
        origin_id: origin?.value ?? proposal?.data.info_frete.locations.origem.ibge_id,
        destination_id: destination?.value ?? proposal?.data.info_frete.locations.destino.ibge_id,
        vehicle_category: selectedVehicle.vehycle.label,
      },
    })

    setFreightCalculationHistory(data.data)
  }

  async function handleCalcWikirota() {
    if (selectedVehicle?.vehicleType === undefined) {
      toast({
        title: 'Selecione um veículo',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
      return
    }

    setIsLoadingCalcWikirota(true)

    const postRequestCalcWiki = {
      rota: {
        typeOfLoad: selectedTypeOfLoad,
        vehicleType: selectedVehicle?.vehicleType,
        axis: selectedVehicle?.axis,
        vehycle: selectedVehicle?.vehycle,
      },
      calcula: {
        proposta: proposal?.data.timestamp,
      },
    }

    await handleFreightCalculationHistory()

    const { data: wikiRotaResponse } = await apiServer.post<WikiRotaResponse>(
      'https://sofix.com.br/api/spotx_calculo/?api=rota',
      postRequestCalcWiki,
    )

    if (wikiRotaResponse.status === 'success') {
      setDistance(wikiRotaResponse.content.distance)
      setTotalMinimumFreight(wikiRotaResponse.content.totalMinimumFreight)
      setBaseValue(wikiRotaResponse.content.totalMinimumFreight)

      setTollPrice(wikiRotaResponse.content.totalTollPrice)

      setCalcToll(wikiRotaResponse.content.totalTollPrice.replace('.', ''))
      setTotalTollPrice(wikiRotaResponse.content.full_price)
      toast({
        title: 'Distância e Motorista c/pedagio calculado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        duration: 1500,
      })
    }
    if (wikiRotaResponse.status === 'error') {
      toast({
        title: wikiRotaResponse.message,
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
    }
    if (wikiRotaResponse.status === 'success') {
      setIsLoadingCalcWikirota(false)

      setIsLoadingCalcProposal(true)

      const postRequestCalcProposal = {
        calcula: {
          proposta: proposal?.data.timestamp,
          tipo_valor_base: baseType,
          valor_base: wikiRotaResponse.content.totalMinimumFreight,
          margem_contribuicao: marginValue,
          valor_pedagio: tollPrice,
        },
        id_operador: user.email,
      }

      const { data: calcProposalResponse } = await apiServer.post<CalcProposalResponse>(
        'https://sofix.com.br/api/spotx_calculo/?api=calculo',
        postRequestCalcProposal,
      )
      setCalcProposal(calcProposalResponse)
      if (calcProposalResponse.status === 'success') {
        setTotalWithIcms(String(calcProposalResponse.content.PF.valor_a_ser_cobrado__BRL))
        toast({
          title: 'Cálculo de valor base realizado com sucesso!',
          isClosable: true,
          position: 'top-right',
          status: 'success',
          duration: 1500,
        })
        setIsLoadingNewRota(false)
      }
      if (calcProposalResponse.status === 'error') {
        toast({
          title: calcProposalResponse.message,
          isClosable: true,
          position: 'top-right',
          status: 'error',
        })
      }
      if (calcProposalResponse.status === 'success') {
        setIsLoadingCalcProposal(false)
      }
      setIsLoadingCalcProposal(false)
    }
  }

  async function handleCalcProposal() {
    setIsLoadingCalcProposal(true)
    const postRequest = {
      calcula: {
        proposta: proposal?.data.timestamp,
        tipo_valor_base: baseType,
        valor_base: baseValue,
        valor_pedagio: calcToll?.replace('.', ','),
        margem_contribuicao: marginValue,
      },
      id_operador: user.email,
    }

    const { data: calcProposalResponse } = await apiServer.post<CalcProposalResponse>(
      'https://sofix.com.br/api/spotx_calculo/?api=calculo',
      postRequest,
    )
    setCalcProposal(calcProposalResponse)
    if (calcProposalResponse.status === 'success') {
      setTotalWithIcms(String(calcProposalResponse.content.PF.valor_a_ser_cobrado__BRL))
      toast({
        title: 'Cálculo de valor base realizado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        duration: 1500,
      })
    }
    if (calcProposalResponse.status === 'error') {
      toast({
        title: calcProposalResponse.message,
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
    }
    if (calcProposalResponse.status === 'success') {
      setIsLoadingCalcProposal(false)
    }
    setIsLoadingCalcProposal(false)
  }

  async function handleNewRota() {
    if (selectedVehicle?.vehicleType === undefined) {
      toast({
        title: 'Selecione um veículo',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
      return
    }
    setIsLoadingNewRota(true)

    const postRequest = {
      frete: {
        carga_proibida: proposal?.data.carga_proibida,
        timestamp: params.slug.split('-').pop(),
        empresa_nome: {
          label: proposal?.data.empresa_nome.label,
          value: proposal?.data.empresa_nome.value,
        },
        origem: origin === undefined ? proposal?.data.info_frete.locations.origem.ibge_id : origin.value,
        destino:
          destination === undefined ? proposal?.data.info_frete.locations.destino.ibge_id : destination.value,
        custo_nf: proposal?.data.custo_nf,
        mercadoria_outros_tipos: proposal?.data.mercadoria_outros_tipos,
        insurance_ask: 1,
      },
      id_operador: user.email,
    }

    const { data: responseSlug } = await apiServer.post<CreateQuotationResponse>(
      'https://sofix.com.br/api/spotx_calculo/?page=gera-proposta&mode=api',
      postRequest,
    )
    if (
      responseSlug.status === 'success' &&
      responseSlug?.data.info_frete.seguro.regras.regras
        .map(i => {
          return {
            role: i[0],
            aplication: i[1],
          }
        })
        .find(i => i.role.includes('Motorista Carreteiro / Autônomo') && i.aplication.includes('PROIBÍDO'))
    ) {
      toast({
        title: 'Restrição na apólice: ',
        description: `Não pode ser cotado na rota selecionada devido a impedimento da apólice. Motivo: MOTORISTA AUTÔNOMO É PROIBÍDO.
          Contate seu coordenador!`,
        isClosable: true,
        position: 'top',
        status: 'error',
        duration: 7000,
      })

      setIsLoadingNewRota(false)
      setRoleProhibited(true)
      return
    }

    if (responseSlug.status === 'success') {
      const { slug } = responseSlug.data
      history.push(`/freights/calculation/${slug}`)
    }
    if (responseSlug.status === 'success') {
      toast({
        title: 'Rota alterada com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        duration: 1500,
      })
      handleCalcWikirota()
      setRoleProhibited(false)
      setCalcProposal(undefined)
    }
  }

  useEffect(() => {
    if (
      (origin?.value && destination?.value) ||
      (proposal?.data.info_frete.locations.origem &&
        proposal?.data.info_frete.locations.destino &&
        selectedVehicle &&
        selectedVehicle.vehycle.label)
    ) {
      handleFreightCalculationHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    origin?.value,
    proposal?.data.info_frete.locations.origem,
    destination?.value,
    proposal?.data.info_frete.locations.destino,
    selectedVehicle,
    selectedVehicle?.vehycle.label,
  ])

  useEffect(() => {
    setIsNewRota(true)
    setCalcProposal(undefined)
    setDistance('')
    setTotalMinimumFreight('')
    setTollPrice('')
    setCalcToll('')
    setTotalTollPrice('')
    setBaseValue('')
  }, [origin, origin?.value, destination, destination?.value])

  useEffect(() => {
    if (!isLoadingNewRota) {
      setIsNewRota(false)
    }
  }, [isLoadingNewRota])

  const trackingMonitoring = proposal?.data.info_frete.seguro.regras.regras
    .map(i => {
      return {
        role: i[0],
        aplication: i[1],
      }
    })
    .find(i => i.role.includes('Rastreamento e Monitoramento') && i.aplication.includes('OBRIGATÓRIO'))
  const autonomousMotorist = proposal?.data.info_frete.seguro.regras.regras
    .map(i => {
      return {
        role: i[0],
        aplication: i[1],
      }
    })
    .find(i => i.role.includes('Motorista Carreteiro / Autônomo') && i.aplication !== 'OPCIONAL')

  const [tripDuration, setTripDuration] = useState<TripDurationResult>()

  useEffect(() => {
    if (distance) {
      let formattedDistance = distance.split(',')[0]
      formattedDistance = formattedDistance.replace('.', '')
      const date = new Date()
      date.setHours(12)
      date.setMinutes(0)
      date.setSeconds(0)
      const newTripDuration = calculateTripDuration(Number(formattedDistance), 60, date)
      setTripDuration(newTripDuration)
    }
  }, [distance])

  return (
    <Layout>
      <Box p="6" bg={bg} borderRadius="8">
        <Heading fontWeight="normal" size="lg">
          Cálculo de frete
        </Heading>
        {calcProposal && !isNewRota && autonomousMotorist && (
          <Alert
            status="warning"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Flex>
              <AlertIcon mx={1} />
              <AlertTitle fontSize="md">ATENÇÃO AO REQUISITO:</AlertTitle>
            </Flex>
            <AlertDescription fontSize="sm" maxWidth="sm">
              <Text color="red">
                {autonomousMotorist.role} {autonomousMotorist.aplication}
              </Text>
            </AlertDescription>
          </Alert>
        )}
        <Divider my="4" />

        {proposal && proposal.status === 'success' ? (
          <Grid templateColumns="repeat(12, 1fr)" gap="3" mt="6">
            <GridItem colSpan={12}>
              <Flex
                justifyContent="space-between"
                direction={['column', 'row', 'row']}
                gridGap="4"
                alignItems="center"
              >
                <Heading fontWeight="normal" size="md">
                  Informações básicas do frete
                </Heading>

                <Flex gridGap="2" direction={['column', 'column', 'row']}>
                  {(origin?.value || destination?.value) && isNewRota && (
                    <Button
                      colorScheme="green"
                      isLoading={isLoadingNewRota}
                      onClick={() => {
                        handleNewRota()
                      }}
                      minW="220px"
                    >
                      Confirmar alteração de rota
                    </Button>
                  )}
                  {(origin?.value || destination?.value) && isNewRota && !isLoadingNewRota && (
                    <Button
                      colorScheme="red"
                      as={Link}
                      href={`/freights/calculation/${params.slug}`}
                      isLoading={isLoadingNewRota}
                    >
                      Cancelar
                    </Button>
                  )}
                </Flex>
              </Flex>
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <FormControl isDisabled>
                <FormLabel>Duração da Rota</FormLabel>
                <ChakraInput label="Duração" value={tripDuration?.relativeTripDuration} />
              </FormControl>
            </GridItem>

            <GridItem colSpan={[12, 6, 6]}>
              <AutocompleteAsync
                isDisabled
                name="client"
                label="Cliente"
                error={errors.client}
                setValue={setValue}
                loadOptions={searchClientsByName}
                initialValue={{
                  label: proposal.data.empresa_nome.label,
                  value: proposal.data.empresa_nome.value,
                }}
              />
            </GridItem>
            <GridItem colSpan={[12, 6, 3]}>
              <InputNumberMask
                isCashBRL
                name="invoice"
                label="Valor (NF)"
                onChange={e => {
                  const parsedValue = e.target.value.replace(/[^0-9]/g, '')
                  setServicePrice(Number(parsedValue))
                }}
                onBlur={e => {
                  const parsedValue = e.target.value.replace(/[^0-9]/g, '')
                  setServicePrice(Number(parsedValue))
                }}
                error={errors.invoice}
                setValue={setValue}
                initialValue={Number(proposal.data.custo_nf)}
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <AutocompleteAsync
                isRequired
                name="origin"
                label="Origem"
                error={errors.origin}
                setValue={setValue}
                onSelectOption={origins => {
                  setOrigin({
                    label: origins.label,
                    value: Number(origins.value),
                  })
                }}
                loadOptions={searchCitiesByName}
                defaultValue={proposal.data.info_frete.locations.origem.name}
                initialValue={
                  proposal.data.info_frete.locations.origem.name
                    ? {
                        label: `${proposal.data.info_frete.locations.origem.name} - ${proposal.data.info_frete.locations?.origem?.state?.uf}`,
                        value: proposal.data.info_frete.locations.origem.ibge_id,
                      }
                    : undefined
                }
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <AutocompleteAsync
                isRequired
                name="destination"
                error={errors.destination}
                label="Destino"
                setValue={setValue}
                onSelectOption={destinations => {
                  setDestination({
                    label: destinations.label,
                    value: Number(destinations.value),
                  })
                }}
                loadOptions={searchCitiesByName}
                defaultValue={proposal.data.info_frete.locations.destino.name}
                initialValue={
                  proposal.data.info_frete.locations.destino.name
                    ? {
                        label: `${proposal.data.info_frete.locations.destino.name} - ${proposal.data.info_frete.locations?.destino?.state?.uf}`,
                        value: proposal.data.info_frete.locations.destino.ibge_id,
                      }
                    : undefined
                }
              />
            </GridItem>

            <GridItem colSpan={12}>
              <Divider my="4" />
            </GridItem>

            <GridItem colSpan={12}>
              <Heading fontWeight="normal" size="md">
                Pesq. Valor motorista
              </Heading>
            </GridItem>

            <GridItem colSpan={[12, 6, 6]}>
              <Select
                isDisabled={isLoadingCalcWikirota}
                name="table"
                label="Tabela"
                setValue={setValue}
                initialValue={selectedTypeOfLoad}
                error={errors.table}
                onSelectOption={option => {
                  setSelectedTypeOfLoad(String(option.value))
                }}
                options={[
                  {
                    label: 'geral',
                    value: 'geral',
                  },
                  {
                    label: 'conteinerizada',
                    value: 'conteinerizada',
                  },
                  {
                    label: 'frigorificada',
                    value: 'frigorificada',
                  },
                  {
                    label: 'granel liquido',
                    value: 'graneliquido',
                  },
                  {
                    label: 'granel pressurizada',
                    value: 'granelpressurizada',
                  },
                  {
                    label: 'granel solido',
                    value: 'granelsolido',
                  },
                  {
                    label: 'neogranel',
                    value: 'neogranel',
                  },
                  {
                    label: 'perigosa (conteinerizada)',
                    value: 'perigosaconteinerizada',
                  },
                  {
                    label: 'perigosa (frigorificada)',
                    value: 'perigosafrigorificada',
                  },
                  {
                    label: 'perigosa (geral)',
                    value: 'perigosageral',
                  },
                  {
                    label: 'perigosa (granel liquido)',
                    value: 'perigosagranelliquido',
                  },
                  {
                    label: 'perigosa (granel solido)',
                    value: 'perigosagranelsolido',
                  },
                ]}
              />
            </GridItem>
            <GridItem colSpan={[12, 6, 6]}>
              <Flex align="flex-end">
                <Select
                  isRequired
                  isDisabled={isLoadingCalcWikirota}
                  name="vehicle"
                  error={errors.vehicle}
                  label="Veículo"
                  setValue={setValue}
                  options={[{ label: '', value: '' }, ...selectVehicleCategories]}
                  onSelectOption={option => {
                    setSelectedVehicle({
                      axis: option.axes,
                      vehicleType: option.model,
                      vehycle: option,
                    })
                  }}
                  initialValue={selectedVehicle?.vehycle?.value}
                />

                <Button
                  minWidth="80px"
                  colorScheme="orange"
                  mb="1"
                  ml="4"
                  isLoading={isLoadingCalcWikirota}
                  onClick={handleCalcWikirota}
                  isDisabled={!!isNewRota || roleProhibited}
                >
                  Calcular
                </Button>
              </Flex>
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Input
                isDisabled
                name="distance"
                error={errors.distance}
                label="Distância"
                setValue={setValue}
                value={distance}
                initialValue={distance}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Input
                isDisabled
                name="total_minimum_freight"
                error={errors.total_minimum_freight}
                label="Frete Min."
                setValue={setValue}
                value={totalMinimumFreight}
                initialValue={totalMinimumFreight}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Input
                isDisabled
                name="toll_price"
                error={errors.toll_price}
                label="Prev. Pedágio"
                setValue={setValue}
                value={tollPrice}
                initialValue={tollPrice}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Input
                isDisabled
                name="toll_driver"
                error={errors.toll_driver}
                label="Frete Min. + Pedágio"
                setValue={setValue}
                value={totalTollPrice}
                initialValue={totalTollPrice}
              />
            </GridItem>

            <GridItem colSpan={12}>
              <Divider my="4" />
            </GridItem>

            <GridItem colSpan={12}>
              <Heading fontWeight="normal" size="md" justifyContent="center">
                Histórico
              </Heading>
            </GridItem>

            {(freightCalculationHistory.freight || freightCalculationHistory.quotation) &&
            !isLoadingCalcWikirota ? (
              <>
                <GridItem colSpan={12}>
                  <Table mt={4} size="md">
                    <Thead>
                      <Tr>
                        <Th>Tipo</Th>
                        <Th>Data</Th>
                        <Th>Motorista</Th>
                        <Th>Peso</Th>
                        <Th>Link</Th>
                        <Th>Outros</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {freightCalculationHistory && freightCalculationHistory.freight ? (
                        <Tr>
                          <Td>Frete</Td>
                          <Td>
                            {format(new Date(freightCalculationHistory?.freight.created_at), 'dd/MM/yyyy')}
                          </Td>
                          <Td>
                            {freightCalculationHistory?.freight.agreed_price &&
                              new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(freightCalculationHistory?.freight.agreed_price)}
                          </Td>
                          <Td>{freightCalculationHistory?.freight.cargo?.weight}</Td>
                          <Td>
                            <Tooltip label="Visualizar frete" hasArrow placement="auto">
                              <Box>
                                <Link
                                  href={`/freights/show/${freightCalculationHistory?.freight.id}`}
                                  target="_blank"
                                >
                                  #{freightCalculationHistory?.freight.freight_number}
                                </Link>
                              </Box>
                            </Tooltip>
                          </Td>
                          <Td>
                            <Link
                              href={`/pricing/route-analysis?origin_id=${
                                origin?.value ?? proposal?.data.info_frete.locations.origem.ibge_id
                              }&origin_label=${
                                origin?.label ?? proposal?.data.info_frete.locations.origem.name
                              }&destination_id=${
                                destination?.value ?? proposal?.data.info_frete.locations.destino.ibge_id
                              }&destination_label=${
                                destination?.label ?? proposal?.data.info_frete.locations.destino.name
                              }`}
                              target="_blank"
                            >
                              Ver análise
                            </Link>
                          </Td>
                        </Tr>
                      ) : null}
                      {freightCalculationHistory && freightCalculationHistory.quotation ? (
                        <Tr>
                          <Td>Cotação</Td>
                          <Td>
                            {format(new Date(freightCalculationHistory?.quotation.created_at), 'dd/MM/yyyy')}
                          </Td>
                          <Td>
                            {freightCalculationHistory.quotation.route_prices &&
                            freightCalculationHistory.quotation.route_prices.length > 0 ? (
                              <CalcAverage
                                values={freightCalculationHistory.quotation.route_prices?.map(
                                  price => price.value,
                                )}
                                total={freightCalculationHistory.quotation.route_prices.length}
                              />
                            ) : (
                              'R$ 0'
                            )}
                          </Td>
                          <Td>{freightCalculationHistory?.quotation.cargo?.weight}</Td>
                          <Td>
                            <Tooltip label="Visualizar cotação" hasArrow placement="auto">
                              <Box>
                                <Link
                                  href={`/quotations/show/${freightCalculationHistory?.quotation.id}`}
                                  target="_blank"
                                >
                                  #{freightCalculationHistory?.quotation.quotation_number}
                                </Link>
                              </Box>
                            </Tooltip>
                          </Td>
                          <Td />
                        </Tr>
                      ) : null}
                    </Tbody>
                  </Table>
                </GridItem>
              </>
            ) : (
              <GridItem colSpan={12}>
                {isLoadingCalcWikirota ? (
                  <Text display="flex" alignItems="center">
                    Carregando dados
                    <Spinner ml="2" />
                  </Text>
                ) : (
                  <Text display="flex" alignItems="center">
                    Nenhum resultado encontrado
                  </Text>
                )}
              </GridItem>
            )}

            <GridItem colSpan={12}>
              <Divider my="4" />
            </GridItem>

            <GridItem colSpan={12}>
              <Heading fontWeight="normal" size="md">
                Cálculo
              </Heading>
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Select
                name="calculation_basis"
                label="Base de cálculo"
                error={errors.calculation_basis}
                setValue={setValue}
                isDisabled={isLoadingCalcWikirota}
                initialValue={baseType}
                onSelectOption={option => {
                  setBaseType(String(option.value))
                }}
                options={[
                  {
                    label: 'Motorista',
                    value: 'custo_motorista',
                  },
                  {
                    label: 'Valor final',
                    value: 'valor_final',
                  },
                ]}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Input
                isDisabled={!!isNewRota}
                name="base_value"
                error={errors.base_value}
                label="Valor base"
                setValue={setValue}
                value={baseValue.replace('.', '').trim()}
                initialValue={baseValue}
                onChange={e => {
                  setBaseValue(e.target.value)
                }}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Input
                name="calc_toll"
                error={errors.calc_toll}
                label="Pedágio"
                setValue={setValue}
                value={calcToll ? calcToll.replace('.', ',').trim() : 0}
                initialValue={calcToll ? calcToll.replace('.', ',').trim() : 0}
                onChange={e => {
                  setCalcToll(e.target.value)
                }}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <Flex alignItems="flex-end">
                <Input
                  isDisabled={isLoadingCalcWikirota}
                  name="margin"
                  label="Margem"
                  setValue={setValue}
                  initialValue={marginValue}
                  value={marginValue.replace(',', '.')}
                  onChange={e => {
                    setMarginValue(e.target.value)
                  }}
                />
                <Button
                  minWidth="80px"
                  isLoading={isLoadingCalcProposal}
                  colorScheme="orange"
                  mb="1"
                  ml="4"
                  onClick={handleCalcProposal}
                  isDisabled={!!isNewRota || roleProhibited}
                >
                  Simular
                </Button>
              </Flex>
            </GridItem>
            {calcProposal && !isNewRota && (
              <>
                <GridItem colSpan={12}>
                  <Flex direction="row" color="red">
                    {trackingMonitoring?.role} {trackingMonitoring?.aplication}
                  </Flex>
                </GridItem>
                <GridItem colSpan={12}>
                  <Divider my="2" />
                </GridItem>

                <GridItem colSpan={12}>
                  <Heading fontWeight="normal" size="md">
                    Resultado
                  </Heading>
                </GridItem>

                <GridItem colSpan={12} overflowX="auto">
                  <Table variant="striped" size="md" d={['block', 'table', 'table']}>
                    <Thead>
                      <Tr>
                        <Th fontSize="md">TÍTULO</Th>
                        <Th fontSize="md">Autônomo (PF)</Th>
                        <Th fontSize="md">Empresa (PJ)</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td fontSize="md">TOTAL COM ICMS</Td>
                        <Td fontSize="lg">R$ {calcProposal.content.PF.valor_a_ser_cobrado__BRL}</Td>
                        <Td fontSize="lg">R$ {calcProposal.content.PJ.valor_a_ser_cobrado__BRL}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize="md">MOTORISTA S/PED.</Td>
                        <Td fontSize="lg">R$ {calcProposal?.content.PF.custo_motorista}</Td>
                        <Td fontSize="lg">R$ {calcProposal?.content.PJ.custo_motorista}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Button minWidth="115px" colorScheme="facebook" onClick={onOpen}>
                    Ver resultado completo
                  </Button>
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Button
                    minWidth="115px"
                    colorScheme="blue"
                    mb="1"
                    ml="4"
                    onClick={() => {
                      onOpenFreightFormModal()
                    }}
                  >
                    Criar novo frete
                  </Button>
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Button
                    minWidth="115px"
                    colorScheme="orange"
                    mb="1"
                    ml="4"
                    onClick={() => {
                      onOpenQuotationFormModal()
                    }}
                  >
                    Criar nova cotação
                  </Button>
                </GridItem>

                <GridItem colSpan={12}>
                  <Divider my="4" />
                </GridItem>

                <GridItem colSpan={12}>
                  <Heading fontWeight="normal" size="lg" mb="10">
                    Informações sobre o seguro da carga
                  </Heading>

                  <GridItem colSpan={12}>
                    <Divider my="4" />
                    <Text>
                      <strong>Região:</strong> Brasil{'  '}
                      {proposal.data.info_frete.seguro.frete.estados}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={12}>
                    <Divider my="4" />
                    <strong>Mercadoria:</strong>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: proposal.data.info_frete.seguro.mercadoria.name,
                      }}
                    />
                  </GridItem>
                  <GridItem colSpan={12}>
                    <Divider my="4" />
                    <Text>
                      <strong>Grupo:</strong> {proposal.data.info_frete.seguro.mercadoria.grupo}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={12} mb="12">
                    <Divider my="4" />
                    <strong>Cuidado:</strong>{' '}
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: String(proposal.data.info_frete.seguro.frete.cuidados[0]),
                      }}
                    />
                  </GridItem>

                  <Table variant="striped" colorScheme="gray" size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="md">REGRA</Th>
                        <Th fontSize="md">APLICAÇÃO</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {proposal.data.info_frete.seguro.regras.regras.map(item => (
                        <Tr key={String(item)}>
                          <Td> {item[0]} </Td>
                          <Td color={item[1] !== 'OPCIONAL' ? 'red' : undefined}>{item[1]}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </GridItem>
              </>
            )}
          </Grid>
        ) : proposal && proposal.status === 'error' ? (
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Proposta não encontrada!
            </AlertTitle>
            <AlertDescription maxWidth="sm">Não foi possível carregar sua proposta.</AlertDescription>
          </Alert>
        ) : (
          <Flex justify="center">
            <Spinner size="xl" />
          </Flex>
        )}
      </Box>

      <ProposalInfoModal
        isOpen={isOpen}
        onClose={onClose}
        proposalInfo={calcProposal}
        proposalResponse={proposal}
      />
      {proposal && (
        <>
          <EditOrDuplicateQuotationModal
            type="duplicate"
            isOpen={isOpenQuotationFormModal}
            onClose={onCloseQuotationFormModal}
            quotation={{
              calculation_ref: `/freights/calculation/${params.slug}`,
              client: {
                id: proposal.data.empresa_nome.value,
                name: proposal.data.empresa_nome.label,
              },
              origin: proposal.data.info_frete.locations.origem,
              destination: proposal.data.info_frete.locations.destino,

              type: 'terrestrial',
              suggested_price_type: 'full',
              suggested_price: parseCashBRLToNumber(baseValue),
              cargo: {
                id: proposal.data.info_frete.seguro.mercadoria.slug,
                // name: proposal.data.info_frete.seguro.mercadoria.name,
                value: Number(proposal.data.custo_nf),
              },
              vehicle_categories: [
                {
                  id: String(selectedVehicle?.vehycle.value),
                  type: String(selectedVehicle?.vehicleType),
                  has_trailer: Boolean(selectedVehicle?.vehycle.has_trailer),
                  name: String(selectedVehicle?.vehycle.label),
                  axes: Number(selectedVehicle?.axis),
                  model: String(selectedVehicle?.vehycle.model),
                },
              ],
              creator: {
                id: '',
                name: '',
              },
            }}
          />

          <CreateFreightModal
            isOpen={isOpenFreightFormModal}
            onClose={onCloseFreightFormModal}
            freight={{
              calculation_ref: `/freights/calculation/${params.slug}`,
              client: {
                id: proposal.data.empresa_nome.value,
                name: proposal.data.empresa_nome.label,
              },
              origin: proposal.data.info_frete.locations.origem,
              destination: proposal.data.info_frete.locations.destino,
              type: 'terrestrial',
              suggested_price_type: 'full',
              suggested_price: parseCashBRLToNumber(baseValue),
              cargo: {
                id: proposal.data.info_frete.seguro.mercadoria.slug,
                // name: proposal.data.info_frete.seguro.mercadoria.name,
                value: servicePrice || Number(proposal.data.custo_nf),
              },
              vehicle_categories: [
                {
                  id: String(selectedVehicle?.vehycle.value),
                  type: String(selectedVehicle?.vehicleType),
                  has_trailer: Boolean(selectedVehicle?.vehycle.has_trailer),
                  name: String(selectedVehicle?.vehycle.label),
                  axes: Number(selectedVehicle?.axis),
                  model: String(selectedVehicle?.vehycle.model),
                },
              ],
              creator: {
                id: '',
                name: '',
              },
            }}
          />
        </>
      )}
    </Layout>
  )
}
