import { useGetOneBlockedEntities } from '../../../../../services/endpoints/blocked-entities/getOneBlockedEntities'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { TrailerType } from '../../../../../services/types/TrailerType'
import VehicleType from '../../../../../services/types/VehicleType'
import { useAppSelector } from '../../../../../store'
import { trailerOwnerFormValidation } from '../../validations'
import { RegisterOwners } from './RegisterOwners'

type TrailerOwnerFormProps = {
  trailer?: TrailerType
  vehicle: VehicleType
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  refetch: () => void
  tabIndex: number
  freight: FormattedOneFreight
}

export function TrailerOwnerForm({
  trailer,
  vehicle,
  setTabIndex,
  refetch,
  tabIndex,
  freight,
}: TrailerOwnerFormProps): JSX.Element {
  const { owner: ownerFound } = useAppSelector(state => state.ownerSlice)
  const { data: blockedEntity } = useGetOneBlockedEntities({
    vehicle_owner_id: ownerFound?.id || trailer?.owner_id,
    trailer_owner_id: ownerFound?.id || trailer?.owner_id,
  })

  return (
    <RegisterOwners
      typeOwner="ownerTrailer"
      formValidation={trailerOwnerFormValidation}
      blockedEntity={blockedEntity}
      initialData={trailer?.owner}
      trailer={trailer}
      vehicle={vehicle}
      setTabIndex={setTabIndex}
      tabIndex={tabIndex}
      refetch={refetch}
      freight={freight}
    />
  )
}
