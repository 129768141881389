/* eslint-disable react/no-array-index-key */
import { SkeletonText } from '@chakra-ui/react'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'

export const containerStyle = {
  width: '100%',
  minHeight: '400px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

export const infoWindowTextStyle = {
  color: '#333',
}

const google_maps_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

export interface GoogleMapsProps {
  children: React.ReactNode
  center?: google.maps.LatLngLiteral
  width?: string
  places?: Array<{ position: google.maps.LatLngLiteral }>
}

export function GoogleMaps({ children, center, width, places }: GoogleMapsProps): JSX.Element {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: google_maps_api_key,
    language: 'pt_BR',
    libraries: ['places'],
  })

  if (!isLoaded) {
    return <SkeletonText />
  }

  const path = places?.map(place => place.position)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: width || '100%',
        backgroundColor: 'white',
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={8}
        center={center}
        mapTypeId="satellite"
        options={{
          gestureHandling: 'greedy',
          streetViewControl: false,
          keyboardShortcuts: false,
        }}
      >
        {places && places.map((place, index) => <Marker key={index} position={place.position} />)}

        <Polyline
          path={path}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            zIndex: 1,
          }}
        />
        {children}
      </GoogleMap>
    </div>
  )
}
