import { useGetOneBlockedEntities } from '../../../../../services/endpoints/blocked-entities/getOneBlockedEntities'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { VehicleType } from '../../../../../services/types'
import { useAppSelector } from '../../../../../store'
import { vehicleOwnerFormValidation } from '../../validations'
import { RegisterOwners } from './RegisterOwners'

type VehicleOwnerFormProps = {
  vehicle?: VehicleType
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  refetch: () => void
  tabIndex: number
  freight: FormattedOneFreight
}

export function VehicleOwnerForm({
  vehicle,
  setTabIndex,
  refetch,
  tabIndex,
  freight,
}: VehicleOwnerFormProps): JSX.Element {
  const { owner: ownerFound } = useAppSelector(state => state.ownerSlice)
  const { data: blockedEntity } = useGetOneBlockedEntities({
    vehicle_owner_id: ownerFound?.id || vehicle?.owner_id,
    trailer_owner_id: ownerFound?.id || vehicle?.owner_id,
  })

  return (
    <RegisterOwners
      typeOwner="ownerVehicle"
      formValidation={vehicleOwnerFormValidation}
      blockedEntity={blockedEntity}
      initialData={vehicle?.owner}
      vehicle={vehicle}
      setTabIndex={setTabIndex}
      tabIndex={tabIndex}
      refetch={refetch}
      freight={freight}
    />
  )
}
