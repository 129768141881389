import { Box, Button, Flex, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { RadioGroup } from '../../components/form/RadioGroup'
import { TextArea } from '../../components/form/TextArea'
import { toastError } from '../../config/error/toastError'
import { useQueryParams } from '../../hooks/useQueryParams'
import { SimpleHeader } from '../../layout/Header/SimpleHeader'
import { useGetClientContact } from '../../services/endpoints/clients/client-contacts/getContactFromClient'
import { publicApiServer } from '../../services/publicApi'
import { dateIsThisMonthCurrent } from '../../services/utils/dates/dateIsThisMonthCurrent'

interface CreateFeedbackData {
  client_id: string
  client_contact_id: string
  recommendation_score: string
  reason?: string
}

const FeedbackFormSchema = yup.object().shape({
  recommendation_score: yup.number().required('Campo obrigatório'),
})

export function ClientMonthlyNps(): JSX.Element {
  const client_id = useQueryParams('client_id')
  const client_contact_id = useQueryParams('client_contact_id')
  const { data: clientContact, isLoading, refetch } = useGetClientContact(client_contact_id)
  const isAlreadyAnsweredThisMonth = dateIsThisMonthCurrent(clientContact?.last_monthly_nps_at)
  const [selectedScore, setSelectedScore] = useState<number>()

  const card = useColorModeValue('gray.300', 'gray.700')

  const toast = useToast()
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(FeedbackFormSchema),
  })
  const { isSubmitting, errors } = formState

  async function handleCreateResearch(data: CreateFeedbackData) {
    const researchData = {
      client_id,
      client_contact_id,
      recommendation_score: data.recommendation_score,
      reason: data.reason,
    }
    try {
      await publicApiServer.post('/create-client-monthly-nps', researchData)
      refetch()
      toast({
        title: 'Pesquisa concluída com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Flex p="4" direction="column" overflowY="auto">
      <SimpleHeader />
      <Flex
        as="form"
        justifyContent="center"
        alignItems="center"
        gridGap={3}
        mt="4"
        direction={['column']}
        onSubmit={handleSubmit(handleCreateResearch)}
        noValidate
      >
        <Flex fontSize={['lg', 'x-large']} alignItems="center" gridGap={4} flexDirection={['column', 'row']}>
          <Text fontSize={['lg', 'xx-large']} fontWeight="bold">
            Pesquisa de satisfação
          </Text>
        </Flex>
        {isLoading ? (
          <Flex>
            <Spinner />
          </Flex>
        ) : (
          <>
            {isAlreadyAnsweredThisMonth && (
              <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                <Text fontSize={['lg', 'xx-large']} fontWeight="bold">
                  Agradecemos por responder a pesquisa.
                </Text>
              </Box>
            )}

            {!isAlreadyAnsweredThisMonth && (
              <>
                <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                  <Text fontSize="lg" fontWeight="bold">
                    Em uma escala de 0 a 10 qual é a chance de você recomendar a SpotX para uma outra
                    indústria? *
                  </Text>
                  <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                  <RadioGroup
                    mt="6"
                    direction={['row']}
                    name="recommendation_score"
                    error={errors.recommendation_score}
                    setValue={setValue}
                    onChange={e => setSelectedScore(Number(e))}
                    options={[
                      { label: '0', value: 0 },
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                      { label: '6', value: 6 },
                      { label: '7', value: 7 },
                      { label: '8', value: 8 },
                      { label: '9', value: 9 },
                      { label: '10', value: 10 },
                    ]}
                  />
                </Box>

                {selectedScore !== undefined && selectedScore <= 6 && (
                  <Box bg={card} maxW="620px" w="100%" p={8} borderRadius={8} shadow="lg">
                    <Text fontSize="md" fontWeight="bold" my="2">
                      Por quê você deu essa nota? (Fique à vontade para falar o que quiser)
                    </Text>
                    <TextArea name="reason" size="md" setValue={setValue} />
                  </Box>
                )}
                <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
                  Enviar resposta
                </Button>
              </>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
