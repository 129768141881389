import { Button, Divider, Flex, Heading, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { useGetTrailer } from '../../../services/endpoints/trailers/getTrailer'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { MotoristType } from '../../../services/types'
import { OwnerFormSchema } from '../OwnerForm'
import { OwnerQuickForm } from '../OwnerQuickForm'

export interface OwnerQuickFormData {
  type?: 'pf' | 'pj'
  cnpj?: string
  cpf?: string
  name?: string
  rntrc?: string
  rntrc_type?: 'E' | 'C' | 'EQ' | 'T'
  ie?: string
  phone?: string
  father_name?: string
  mother_name?: string
  rg?: string
  rg_uf?: string
  rg_dispatcher?: string
  birth?: Date
  rg_dispatch_date?: Date
  pis?: string
}

interface CreateQuickOwnerProps {
  vehicleOrTrailerId?: string
  motorist_id?: string
  refetchVehicles?: () => void
  onClose?: () => void
}

export function CreateQuickOwner({
  vehicleOrTrailerId,
  motorist_id,
  refetchVehicles,
  onClose,
}: CreateQuickOwnerProps): JSX.Element {
  const [motoristExists, setMotoristExists] = useState<MotoristType>()
  const motoristPhoneRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const history = useHistory()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(OwnerFormSchema),
  })
  const { data: vehicle } = useGetVehicle(String(vehicleOrTrailerId))
  const { data: trailer } = useGetTrailer(String(vehicleOrTrailerId))
  const handleCreateQuickOwner = useCallback<SubmitHandler<OwnerQuickFormData>>(
    async data => {
      const ownerQuickFormData = data
      try {
        const { data: res } = await apiServer.post(`/create-owner`, ownerQuickFormData)
        const newOwner = res.data
        if (vehicleOrTrailerId) {
          if (vehicle) {
            await apiServer.put(`/update-vehicle/${vehicle.id}`, {
              owner_id: newOwner.id,
            })
            if (!vehicle.vehicle_category.has_trailer) {
              if (motorist_id && refetchVehicles) {
                history.push(`/motorists/show/${motorist_id}`)
                refetchVehicles()
              }
            }
          }

          if (trailer) {
            await apiServer.put(`/update-trailer/${trailer.id}`, {
              owner_id: newOwner.id,
            })
            if (motorist_id && refetchVehicles) {
              history.push(`/motorists/show/${motorist_id}`)
              refetchVehicles()
            }
          }
          if (onClose && refetchVehicles) {
            refetchVehicles()
            onClose()
          }
          toast({
            title: 'Proprietário cadastrado e vinculado com sucesso!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
        } else {
          toast({
            title: 'Proprietário cadastrado com sucesso!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })

          history.push(`/owners/show/${newOwner.id}`)
        }
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [toast, vehicleOrTrailerId, motorist_id, refetchVehicles, history, onClose, vehicle, trailer],
  )

  async function handleCheckMotoristExists() {
    if (motoristPhoneRef.current) {
      const { value } = motoristPhoneRef.current

      try {
        const { data: res } = await apiServer.get<{ data: MotoristType }>(`/find-motorist-by-phone/${value}`)
        const { data } = res
        setMotoristExists(data)
      } catch {
        setMotoristExists(undefined)
      }
    }
  }

  return (
    <>
      <Heading fontSize="xx-large">Cadastro de proprietário</Heading>
      <Divider my="6" />
      <form onSubmit={handleSubmit(handleCreateQuickOwner)} noValidate autoComplete="off">
        <OwnerQuickForm
          setValue={setValue}
          formState={formState}
          handleCheckMotoristExists={handleCheckMotoristExists}
          motoristPhoneRef={motoristPhoneRef}
          motoristExists={motoristExists}
        />
        <Flex justifyContent="flex-end" gridGap="2">
          <Ability module="people" action="create-owner">
            <Button
              type="submit"
              colorScheme={vehicleOrTrailerId ? 'green' : 'orange'}
              isLoading={formState.isSubmitting}
            >
              {vehicleOrTrailerId ? 'Cadastrar e vincular' : 'Cadastrar'}
            </Button>
          </Ability>
        </Flex>
      </form>
    </>
  )
}
