import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Icon,
  SimpleGrid,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  WrapItem,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { format, utcToZonedTime } from 'date-fns-tz'
import ptLocale from 'date-fns/locale/pt-BR'

import { AiOutlineDownload } from 'react-icons/ai'
import { Layout } from '../../../layout'

import parseCurrency from '../../../services/utils/parseCurrency'

import { useShowCteDatamex } from '../../../services/endpoints/datamex/showCteDatamex'

interface RequestParams {
  cte_id: string
}

function handleFormatDate(value: Date | string) {
  const dataFormatada = value
    ? format(utcToZonedTime(value, 'America/Sao_Paulo'), 'dd/MM/yyyy', {
        timeZone: 'America/Sao_Paulo',
        locale: ptLocale,
      })
    : ''
  return dataFormatada
}

export function ShowCte(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { cte_id } = useParams<RequestParams>()

  const { data, isLoading } = useShowCteDatamex(cte_id)

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            <Box mb="6">
              <Flex alignItems="center" justifyContent="space-between" mb="6">
                <Heading size="lg" fontWeight="normal" mr="4">
                  Informações gerais
                </Heading>
              </Flex>
            </Box>

            <SimpleGrid minChildWidth="45%" spacing="4">
              <WrapItem>
                <Text color="gray.400">Chave:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.key}
                </Text>
              </WrapItem>
              <WrapItem>
                <Text color="gray.400">NCT:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.nct}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Série:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.serie}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Ínicio:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.origin}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Fim:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.destination}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Emitente:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.emitter}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Tomador:</Text>
                <Text fontWeight="medium" ml="1">
                  {data?.taker}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Valor Prest.:</Text>
                <Text fontWeight="medium" ml="1">
                  {parseCurrency(data?.installment_value)}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Valor Rec.:</Text>
                <Text fontWeight="medium" ml="1">
                  {parseCurrency(data?.receive_value)}
                </Text>
              </WrapItem>

              <WrapItem>
                <Text color="gray.400">Data Recebimento:</Text>
                <Text fontWeight="medium" ml="1">
                  {`${handleFormatDate(String(data?.emit_date))} - ${data?.status}`}
                </Text>
              </WrapItem>
            </SimpleGrid>
          </>
        )}
      </Box>

      <Box bg={bg} p="6" marginBottom={6} borderRadius="8" shadow="md">
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            <Box mb="6">
              <Flex mb="6" justify="space-between" align="center" gridGap="2">
                <Heading size="lg" fontWeight="normal">
                  Eventos da CTe
                </Heading>
              </Flex>
            </Box>

            {data?.cte_events.length === 0 ? (
              <Alert colorScheme="orange">Nenhum evento lançado para esta CTE</Alert>
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th>Tipo de Evento</Th>
                    <Th>Data de Emissão</Th>
                    <Th>Baixar XML</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.cte_events.map(eachRow => (
                    <Tr key={eachRow.id}>
                      <Td whiteSpace="nowrap">{eachRow.type}</Td>
                      <Td whiteSpace="nowrap">{handleFormatDate(eachRow.emit_date)}</Td>

                      <Td>
                        <Stack direction="row" spacing={['2', '2']}>
                          <Tooltip label="Baixar Xml">
                            <Button
                              size="xs"
                              colorScheme="orange"
                              as={Link}
                              href={eachRow.xml_file_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon as={AiOutlineDownload} fontSize="18" />
                            </Button>
                          </Tooltip>
                        </Stack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </>
        )}
      </Box>
    </Layout>
  )
}
