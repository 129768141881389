import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CiSearch } from 'react-icons/ci'
import { RiAddLine, RiDeleteBinLine, RiEditLine, RiFileCopyLine } from 'react-icons/ri'
import { Ability } from '../../../../components/ability'
import { AlertDialogConfirm } from '../../../../components/AlertDialogConfirm'
import { AutocompleteAsync, AutocompleteOption, Input } from '../../../../components/form'
import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { useDeleteContactByClient } from '../../../../services/endpoints/clients/client-contacts/deleteContactFromClient'
import { useListClientContacts } from '../../../../services/endpoints/clients/client-contacts/getAllContactsFromClient'
import { handlePrefetchContactFromClient } from '../../../../services/endpoints/clients/client-contacts/prefetchContact'
import { searchClientGroup } from '../../../../services/endpoints/clients/getClients'
import { copyToClipboard } from '../../../../services/utils/copyToClipboard'
import { dateIsThisMonthCurrent } from '../../../../services/utils/dates/dateIsThisMonthCurrent'
import { displayAdaptable } from '../../../../services/utils/tableAdaptable'
import { CreateContactByClientModal } from '../../create/CreateContactByClientModal'
import { UpdateContactByClientModal } from '../../edit/UpdateContactByClientModal'

type ClientContactsFiltersFormatted = {
  name?: string
  email?: string
  phone?: string
  phone2?: string
  role?: string
  page?: number
  client_business_group?: AutocompleteOption
}

export function ListClientContacts(): JSX.Element {
  const { updateQueryParams, getParams } = useQueryParamUpdater<ClientContactsFiltersFormatted>()
  const bg = useColorModeValue('white', 'gray.800')
  const { REACT_APP_BASE_WEB } = process.env
  const toast = useToast()
  const [contactIdToDelete, setContactIdToDelete] = useState<string>()
  const [contactIdToUpdate, setContactIdToUpdate] = useState<string>()
  const [filters, setFilters] = useState<ClientContactsFiltersFormatted>(() => {
    return {
      name: getParams('name'),
      email: getParams('email'),
      phone: getParams('phone'),
      phone2: getParams('phone2'),
      role: getParams('role'),
      client_business_group: getParams('client_business_group')
        ? {
            value: JSON.parse(getParams('client_business_group')).value,
            label: JSON.parse(getParams('client_business_group')).label,
          }
        : undefined,
    }
  })
  const [page, setPage] = useState(getParams('page') || 1)

  const { setValue, handleSubmit } = useForm({})
  const { data, refetch, isLoading } = useListClientContacts({
    ...filters,
    client_business_group_id: filters?.client_business_group?.value,
    page: page || filters?.page,
    per_page: 15,
  })

  const deleteContact = useDeleteContactByClient({
    onSuccess: () => {
      refetch()
      toast({
        title: 'Solicitante deletado com sucesso',
        position: 'top-right',
        status: 'success',
        isClosable: true,
        duration: 8000,
      })
    },
  })

  async function handleDeleteContact(contactByClient_id: string) {
    await deleteContact.mutateAsync(contactByClient_id)
  }
  const {
    onClose: onCloseUpdateContact,
    isOpen: isOpenUpdateContact,
    onOpen: onOpenUpdateContact,
  } = useDisclosure()
  const {
    onClose: onCloseCreateContact,
    isOpen: isOpenCreateContact,
    onOpen: onOpenCreateContact,
  } = useDisclosure()
  const {
    onClose: onCloseAlertDialogConfirm,
    isOpen: isOpenAlertDialogConfirm,
    onOpen: onOpenAlertDialogConfirm,
  } = useDisclosure()

  const handleFilter: SubmitHandler<ClientContactsFiltersFormatted> = dataFilter => {
    setFilters({
      ...dataFilter,
      client_business_group: dataFilter?.client_business_group,
    })
    setPage(1)
  }

  useEffect(() => {
    updateQueryParams({
      ...filters,
      client_business_group: JSON.stringify(filters?.client_business_group),
      page: page?.toString(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page])

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
        <Flex mb={['6', '8']} justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Lista de solicitantes
          </Heading>
          <Button
            size="sm"
            minW="80px"
            onClick={() => {
              onOpenCreateContact()
            }}
            colorScheme="orange"
            leftIcon={<Icon as={RiAddLine} fontSize="18" />}
          >
            Novo
          </Button>
        </Flex>

        <Flex mb={['6', '8']} direction="column" gridGap={3}>
          <form onSubmit={handleSubmit(handleFilter)}>
            <Grid templateColumns="repeat(12, 1fr)" gap="4">
              <GridItem colSpan={[12, 12, 6, 5]}>
                <Input
                  placeholder="Filtrar por nome"
                  name="name"
                  setValue={setValue}
                  initialValue={filters?.name}
                  clearState={() => {
                    updateQueryParams({ name: undefined })
                    setFilters(state => ({ ...state, name: undefined }))
                  }}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6, 5]}>
                <AutocompleteAsync
                  placeholder="Filtrar por grupo empresarial"
                  name="client_business_group"
                  setValue={setValue}
                  loadOptions={searchClientGroup}
                  initialValue={
                    filters?.client_business_group
                      ? JSON.parse(JSON.stringify(filters.client_business_group))
                      : undefined
                  }
                  clearState={() => {
                    updateQueryParams({ client_business_group: undefined })
                    setFilters(state => ({ ...state, client_business_group: undefined }))
                  }}
                />
              </GridItem>
              <GridItem colSpan={[12, 2]}>
                <Button type="submit" leftIcon={<CiSearch />} colorScheme="blue" mt={1}>
                  Buscar
                </Button>
              </GridItem>
            </Grid>
          </form>
        </Flex>

        {isLoading ? (
          <Center justifyContent="center">
            <Spinner size="lg" />
          </Center>
        ) : (
          <Table d={displayAdaptable} overflowX="auto" size="md">
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Telefone 1</Th>
                <Th>Telefone 2</Th>
                <Th>Email</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data?.map(ctt => (
                <Tr key={ctt.id}>
                  <Td>{ctt.name}</Td>
                  <Td>{ctt.phone}</Td>
                  <Td>{ctt.phone2}</Td>
                  <Td>{ctt.email}</Td>
                  <Td>
                    <HStack>
                      {!dateIsThisMonthCurrent(ctt.last_monthly_nps_at) && (
                        <Tooltip label="Copiar link de feedback mensal">
                          <Button
                            size="xs"
                            colorScheme="green"
                            onClick={() => {
                              copyToClipboard(
                                `${REACT_APP_BASE_WEB}/client-monthly-nps?client_id=${ctt.client_id}&client_contact_id=${ctt.id}`,
                              )
                              toast({
                                title: 'Link copiado com sucesso!',
                                status: 'success',
                                position: 'top-right',
                                duration: 5000,
                                isClosable: true,
                              })
                            }}
                          >
                            <Icon as={RiFileCopyLine} fontSize={16} />
                          </Button>
                        </Tooltip>
                      )}

                      <Ability module="people" action="update-client-contacts">
                        <Button
                          size="xs"
                          colorScheme="yellow"
                          onMouseEnter={() => handlePrefetchContactFromClient(ctt.id)}
                          onClick={() => {
                            setContactIdToUpdate(ctt.id)
                            onOpenUpdateContact()
                            refetch()
                          }}
                        >
                          <Icon as={RiEditLine} fontSize={16} />
                        </Button>
                      </Ability>

                      <Ability module="people" action="delete-client-contacts-by-id">
                        <Button
                          size="xs"
                          colorScheme="red"
                          onClick={() => {
                            setContactIdToDelete(ctt.id)
                            onOpenAlertDialogConfirm()
                          }}
                        >
                          <Icon as={RiDeleteBinLine} fontSize={16} />
                        </Button>
                      </Ability>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {contactIdToUpdate && (
          <UpdateContactByClientModal
            onClose={() => {
              refetch()
              onCloseUpdateContact()
            }}
            isOpen={isOpenUpdateContact}
            contactByClient_id={contactIdToUpdate}
          />
        )}
        <CreateContactByClientModal
          onClose={() => {
            onCloseCreateContact()
          }}
          isOpen={isOpenCreateContact}
        />
        {contactIdToDelete && (
          <AlertDialogConfirm
            isLoading={deleteContact.isLoading}
            isOpen={isOpenAlertDialogConfirm}
            title="Apagar usuário"
            description="Tem certeza que deseja excluir este contato?"
            onClose={onCloseAlertDialogConfirm}
            onConfirm={() => {
              handleDeleteContact(contactIdToDelete)
            }}
          />
        )}

        {data && (
          <Pagination
            currentPage={Number(page)}
            totalCountOfRegisters={Number(data?.total)}
            registersPerPage={15}
            onPageChange={setPage}
            mt="8"
          />
        )}
      </Box>
    </Layout>
  )
}
