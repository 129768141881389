import { Box, Flex, Heading, Text, Divider, Table, Tag, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { ReloadButton } from '../../../../../components/ReloadButton'
import { useGetAllRiskAnalysisHistory } from '../../../../../services/endpoints/risk-analysis/history/getAllRiskAnalysisHistory'

interface RiskAnalysisHistoryProps {
  risk_analysis_id: string
  bg: string
}

export function ListRiskAnalysisHistory({ bg, risk_analysis_id }: RiskAnalysisHistoryProps): JSX.Element {
  const {
    data: riskAnalysisHistory,
    isFetching,
    refetch,
  } = useGetAllRiskAnalysisHistory({
    risk_analysis_id,
  })

  return (
    <Box bg={bg} maxW={1100} p="6" borderRadius="8" shadow="md" mb="8">
      <Flex gridGap={4} align="center">
        <Heading size="lg" fontWeight="normal">
          Histórico de status
        </Heading>
        <ReloadButton isFetching={isFetching} refetch={refetch} />
      </Flex>

      <Divider my={4} />

      <Table
        size="sm"
        d={['block', 'block', 'block', 'block', 'block', 'block', 'block', 'block', 'block', 'table']}
        overflowX="auto"
        maxW={1100}
      >
        <Thead>
          <Tr>
            <Th>Usuário</Th>
            <Th>Data</Th>
            <Th>Status</Th>
            <Th>Código buonny</Th>
            <Th>Motivo</Th>
          </Tr>
        </Thead>
        <Tbody>
          {riskAnalysisHistory &&
            riskAnalysisHistory.map(item => {
              return (
                <Tr key={item.id}>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="xs">{item?.user?.name.split(' ')[0]}</Text>
                    <Text fontSize="xs">{item?.user?.name.split(' ')[1]}</Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="xs">{item?.created_at}</Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="xs">
                      <Tag colorScheme={item.color_status} p="2" borderRadius="full" gridGap={2}>
                        <Text fontSize="xs" fontWeight="bold">
                          {item?.status}
                        </Text>
                      </Tag>
                    </Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    {item?.consultation_code && (
                      <Text fontSize="xs">{item?.consultation_code.substring(0, 35)}</Text>
                    )}
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="xs">{item?.reason}</Text>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </Box>
  )
}
