import {
  Badge,
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { FaClipboardUser } from 'react-icons/fa6'
import { RiCheckLine, RiCloseCircleLine, RiEditLine, RiFileCopyLine, RiLockLine } from 'react-icons/ri'
import { TbRoute2 } from 'react-icons/tb'
import { Ability } from '../../../components/ability'
import { useIsBlockEntity } from '../../../services/endpoints/blocked-entities/isBlockEntity'
import { FormattedOneFreight } from '../../../services/endpoints/freights'
import {
  GetRoutePointsReturn,
  useGetRoutePoints,
} from '../../../services/endpoints/truckpad/find-route-points'
import { copyToClipboard } from '../../../services/utils/copyToClipboard'
import formatCPFAndCNPJ from '../../../services/utils/formatCPFAndCNPJ'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setActionFreightType } from '../../../store/slices/freightSlice'
import { Route, RoutePlannerModal } from '../../pricing/route-planner/show/modals/RoutePlannerModal'
import { DisableFreightModal } from '../edit/DisableFreightModal'
import { EditOrDuplicateFreightModal } from '../edit/EditOrDuplicateFreightModal'
import { EnableFreightModal } from '../edit/EnableFreightModal'
import { DatamexModal } from '../pendencies/forms/send-EmiteAi-Datamex/DatamexModal'
import { buildRoutePlannerUrl } from '../show/cards/freight-info'
import { BuonnyOutOfRangeAlert } from '../show/cards/freight-info/BuonnyOutOfRangeAlert'
import { EvaluateMotoristModal } from '../show/cards/selected-motorist/EvaluateMotoristModal'
import { SelectNextStatusModal } from '../show/cards/selected-motorist/SelectNextStatusModal'
import FreightAlerts from './FreightAlerts'

interface HeaderProps extends BoxProps {
  freight: FormattedOneFreight
}

const Header = ({ freight }: HeaderProps): JSX.Element => {
  const { REACT_APP_BASE_WEB } = process.env
  const toast = useToast()
  const dispatch = useAppDispatch()

  const {
    actionFreightType,
    //  isLoading,
    //  stateTogglePermission,
  } = useAppSelector(state => state.freightSlice)

  const { data: isBlockedEntity } = useIsBlockEntity({
    freight_id: freight.id,
  })
  const { data: routePointsData } = useGetRoutePoints({ freight_id: freight?.id })

  function generatePasswordClientPage(): string {
    if (freight.freight_number && freight.created_at_default) {
      const freightCreatedAt = new Date(freight.created_at_default)
      const calc = freight.freight_number * (freightCreatedAt.getDay() + 1)
      return String(calc)
    }
    return '12345'
  }

  function copyMotoristData(): void {
    if (freight.motorist && freight.motorist.cpf && freight.vehicle) {
      copyToClipboard(`${freight.motorist.name.toLocaleUpperCase()}
CPF: ${formatCPFAndCNPJ(freight.motorist.cpf)}
${freight.vehicle.vehicle_category.name.toLocaleUpperCase()} ${freight.vehicle.vehicle_body.name.toLocaleUpperCase()}
CAVALO: ${freight.vehicle.license_plate}
${freight.trailer ? `CARRETA: ${freight.trailer.license_plate}` : ''}`)
    }
  }

  function normalizeRoutePoints(data: GetRoutePointsReturn[]): Route[][] {
    return data.map(point => [
      {
        points: [],
        tolls_place: [],
        route_points: [[point.latitude, point.longitude]],
        distance: 0,
        distance_unit: 'km',
        duration: 0,
        duration_unit: 'minutes',
        tolls_amount: 0,
        tolls_gross_amount: 0,
        tolls_count: 0,
        route_provider: 'Unknown',
        travel_type: 'Unknown',
        cached: null,
      },
    ])
  }

  // modals

  const {
    isOpen: isDisableFreightModalOpen,
    onOpen: onDisableFreightModalOpen,
    onClose: onDisableFreightModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenEvaluateMotorist,
    onClose: onCloseEvaluateMotorist,
    onOpen: onOpenEvaluateMotorist,
  } = useDisclosure()

  const {
    isOpen: isEnableFreightModalOpen,
    onOpen: onEnableFreightModalOpen,
    onClose: onEnableFreightModalClose,
  } = useDisclosure()

  const {
    isOpen: isFreightFormModalOpen,
    onOpen: onFreightFormModalOpen,
    onClose: onFreightFormModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenRoutePlanner,
    onOpen: onOpenRoutePlanner,
    onClose: onCloseRoutePlanner,
  } = useDisclosure()
  const {
    isOpen: isOpenDatamexModal,
    onOpen: onOpenDatamexModal,
    onClose: onCloseDatamexModal,
  } = useDisclosure()

  return (
    <Box>
      {freight.buonny_value_out_of_range && <BuonnyOutOfRangeAlert />}
      <Flex justifyContent="right" alignItems="baseline">
        <Stack direction={['column', 'row']} mb="2">
          {freight?.status === 'finished' && (
            <Flex gridGap="2" alignItems="center" fontSize={['sm', 'md', 'lg']}>
              <Tooltip label="Copiar link">
                <Flex>
                  <Icon
                    cursor="pointer"
                    as={RiFileCopyLine}
                    onClick={() => {
                      copyToClipboard(
                        `${REACT_APP_BASE_WEB}/client-feedback/${freight.id}?short-feedback=true`,
                      )
                      toast({
                        title: 'Copiado com sucesso!',
                        status: 'success',
                        position: 'top-right',
                        isClosable: true,
                        duration: 1000,
                      })
                    }}
                  />
                </Flex>
              </Tooltip>
              <Link href={`/client-feedback/${freight.id}?short-feedback=true`}>Feedback do cliente</Link>

              {/* <Tooltip label="Copiar link">
                    <Flex>
                      <Icon
                        cursor="pointer"
                        as={RiFileCopyLine}
                        onClick={() => {
                          copyToClipboard(
                            `${REACT_APP_BASE_WEB}/client-feedback/${freight.id}`,
                          );
                          toast({
                            title: 'Copiado com sucesso!',
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                            duration: 1000,
                          });
                        }}
                      />
                    </Flex>
                  </Tooltip>
                  <Link href={`/client-feedback/${freight.id}`}>
                    Feedback do cliente
                  </Link> */}
            </Flex>
          )}

          {freight && (
            <Flex gridGap="2" alignItems="center" fontSize={['sm', 'md', 'lg']}>
              {freight.quotation_id && (
                <>
                  <Tooltip label="Copiar link">
                    <Flex>
                      <Icon
                        cursor="pointer"
                        as={RiFileCopyLine}
                        onClick={() => {
                          copyToClipboard(`${REACT_APP_BASE_WEB}/${freight.quotation_id}`)
                          toast({
                            title: 'Copiado com sucesso!',
                            status: 'success',
                            position: 'top-right',
                            isClosable: true,
                            duration: 1000,
                          })
                        }}
                      />
                    </Flex>
                  </Tooltip>
                  <Link href={`${freight.quotation_id}`}>Link da cotação</Link>
                </>
              )}

              {freight.motorist && freight.vehicle && (
                <Tooltip label="Copiar dados básicos do motorista">
                  <Flex>
                    <Icon
                      cursor="pointer"
                      as={FaClipboardUser}
                      onClick={() => {
                        copyMotoristData()
                        toast({
                          title: 'Copiado com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                          duration: 1000,
                        })
                      }}
                    />
                  </Flex>
                </Tooltip>
              )}

              {freight.has_monitoring_buonny && (
                <Tooltip label="Copiar senha">
                  <Flex>
                    <Icon
                      cursor="pointer"
                      as={RiLockLine}
                      onClick={() => {
                        copyToClipboard(generatePasswordClientPage())
                        toast({
                          title: 'Copiado com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                          duration: 1000,
                        })
                      }}
                    />
                  </Flex>
                </Tooltip>
              )}
              <Tooltip label="Copiar link">
                <Flex>
                  <Icon
                    cursor="pointer"
                    as={RiFileCopyLine}
                    onClick={() => {
                      copyToClipboard(`${REACT_APP_BASE_WEB}/tracking/${freight.id}`)
                      toast({
                        title: 'Copiado com sucesso!',
                        status: 'success',
                        position: 'top-right',
                        isClosable: true,
                        duration: 1000,
                      })
                    }}
                  />
                </Flex>
              </Tooltip>
              <Link href={`/tracking/${freight.id}`}>Link do cliente</Link>
            </Flex>
          )}
        </Stack>
      </Flex>
      <Flex
        justify="space-between"
        direction={['column', 'column', 'row']}
        gridGap="4"
        alignItems="center"
        aria-label="actions"
      >
        <Flex gridGap="2" direction="column">
          <Heading size="md" fontWeight="medium">
            Frete #{freight?.freight_number}
          </Heading>
          <Badge colorScheme="blue">{freight?.client?.nickname}</Badge>
        </Flex>
        <SelectNextStatusModal />

        <Stack direction={['column', 'row', 'row']} spacing="2" alignItems="center">
          {freight?.calculation_ref && (
            <Tooltip label="Ir para a calculadora">
              <Text as={Link} href={freight.calculation_ref}>
                <Icon as={AiOutlineCalculator} color="orange" fontSize="3xl" mr="1" />
              </Text>
            </Tooltip>
          )}

          <>
            {/* botao de desativar frete ok */}
            {!['finished', 'disabled'].includes(String(freight?.status)) && (
              <Button
                size="sm"
                colorScheme="red"
                leftIcon={<Icon as={RiCloseCircleLine} />}
                onClick={onDisableFreightModalOpen}
              >
                Desativar
              </Button>
            )}

            {freight?.status === 'disabled' && (
              <Button
                size="sm"
                colorScheme="green"
                leftIcon={<Icon as={RiCheckLine} />}
                onClick={onEnableFreightModalOpen}
              >
                Reativar
              </Button>
            )}
          </>

          <Ability module="operation" action="create-freight">
            <Button
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={RiFileCopyLine} />}
              onClick={() => {
                onFreightFormModalOpen()
                dispatch(setActionFreightType('duplicate'))
              }}
            >
              Duplicar
            </Button>
          </Ability>

          <Ability module="operation" action="update-freight">
            <Tooltip
              hasArrow
              placement="top"
              label={isBlockedEntity && 'Frete em analise! Edição bloqueada!'}
            >
              <Button
                size="sm"
                colorScheme="yellow"
                isDisabled={isBlockedEntity}
                leftIcon={<Icon as={RiEditLine} />}
                onClick={() => {
                  onFreightFormModalOpen()
                  dispatch(setActionFreightType('edit'))
                }}
              >
                Editar
              </Button>
            </Tooltip>
          </Ability>

          <Tooltip
            hasArrow
            placement="top"
            label={!isBlockedEntity ? 'Frete em analise! Edição bloqueada!' : ''}
          >
            <Button
              size="sm"
              colorScheme="green"
              isDisabled={isBlockedEntity}
              leftIcon={<Icon as={TbRoute2} />}
              onClick={() => {
                if (routePointsData && routePointsData.length > 0) {
                  onOpenRoutePlanner()
                } else {
                  window.location.href = buildRoutePlannerUrl(freight, freight.vehicle)
                }
              }}
            >
              Rotas
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            placement="top"
            label={isBlockedEntity ? 'Frete em analise! Edição bloqueada!' : ''}
          >
            <Button
              size="sm"
              colorScheme="green"
              isDisabled={isBlockedEntity}
              leftIcon={<Icon as={TbRoute2} />}
              onClick={onOpenDatamexModal}
            >
              Sync Datamex
            </Button>
          </Tooltip>
        </Stack>
      </Flex>
      <FreightAlerts freight={freight} />
      <DisableFreightModal
        freight_ids={[freight.id]}
        isOpen={isDisableFreightModalOpen}
        onClose={() => {
          onDisableFreightModalClose()
          if (freight.motorist_id && !freight.rate) {
            onOpenEvaluateMotorist()
          }
        }}
      />
      <EnableFreightModal
        freight_id={freight.id}
        isOpen={isEnableFreightModalOpen}
        onClose={() => {
          onEnableFreightModalClose()
        }}
      />
      <EditOrDuplicateFreightModal
        type={actionFreightType}
        freight_id={freight.id}
        isOpen={isFreightFormModalOpen}
        onClose={() => {
          onFreightFormModalClose()
        }}
      />
      <RoutePlannerModal
        isOpen={isOpenRoutePlanner}
        onClose={onCloseRoutePlanner}
        routes={normalizeRoutePoints(routePointsData || []) || []}
        freight_id={freight.id}
        vehicle_license_plate={freight.vehicle?.license_plate}
        origin={freight.origin.name}
        destination={freight.destination.name}
        freightTollValue={routePointsData && routePointsData[0] ? routePointsData[0].toll_value : undefined}
        isPageFreight
      />
      <EvaluateMotoristModal isOpen={isOpenEvaluateMotorist} onClose={onCloseEvaluateMotorist} />
      <DatamexModal freight={freight} isOpen={isOpenDatamexModal} onClose={onCloseDatamexModal} />
    </Box>
  )
}

export default Header
