/* eslint-disable react-hooks/rules-of-hooks */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import parseCurrency from '../../../../../services/utils/parseCurrency'
import { formatDistance, formatDuration } from './formats'

interface RouteTitleType {
  id: number
  distance: string
  duration: string
  tolls_count: number
  total: number
  provider: string
  freight_distance?: string
}

export const RouteTitle = ({
  distance,
  freight_distance,
  duration,
  provider,
  total,
  tolls_count,
  id,
}: RouteTitleType): JSX.Element => {
  return (
    <Box>
      <Heading as="h3" size="sm">
        Rota {id}
        {distance && parseFloat(distance) > 0 && `, ${distance}`}
        {freight_distance && parseFloat(freight_distance) > 0 && `, ${freight_distance} km`}
        {duration && parseFloat(duration) > 0 && `, ${duration}`}
        {tolls_count > 0 && ` - ${tolls_count} Pedágio${tolls_count > 1 ? 's' : ''}`}
      </Heading>

      <Text color="green.500" fontWeight="bold" pt={1}>
        Valor: {total ? parseCurrency(total) : undefined}
      </Text>
      <Text color="gray.500" fontWeight="bold" fontSize="sm">
        {provider !== 'Unknown' && provider}
      </Text>
    </Box>
  )
}

interface TollPlace {
  name: string
  value: number
  point: [number, number] // [Longitude, Latitude]
  address: string
}

interface Route {
  points: { point: [number, number]; provider: string }[]
  tolls_place: TollPlace[]
  route_points: [number, number][]
  distance: number
  distance_unit: string
  duration: number
  duration_unit: string
  tolls_amount: number
  tolls_gross_amount: number
  tolls_count: number
  route_provider: string
  travel_type: string
  cached: null | boolean
}

interface RouteCardProps {
  id: number
  onMouseEnter: () => void
  route: Route[]
  handleRouteSelection: () => void
  isSelected?: boolean
  isPageFreight?: boolean
  freightTollValue?: number
  freight_distance?: string
}

export const RouteCard = ({
  id,
  onMouseEnter,
  route,
  handleRouteSelection,
  isSelected,
  isPageFreight = false,
  freightTollValue = undefined,
  freight_distance,
}: RouteCardProps): JSX.Element => {
  const hasTolls = route[0].tolls_place.length > 0

  return (
    <Box
      key={id}
      bg={isSelected ? useColorModeValue('gray.100', 'gray.700') : 'transparent'}
      rounded="md"
      onMouseEnter={onMouseEnter}
      border={isSelected ? '1px' : '0px'}
      borderColor={isSelected ? 'green.500' : 'transparent'}
      _hover={{
        cursor: 'pointer',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      onClick={() => {
        handleRouteSelection()
      }}
    >
      <Box mb={2} borderRadius="md" p={2} rounded="md">
        <Flex justifyContent="space-between">
          <RouteTitle
            provider={route[0].route_provider}
            id={id}
            tolls_count={route[0].tolls_count}
            distance={route[0].distance > 0 ? formatDistance(route[0].distance) : ''}
            duration={route[0].duration > 0 ? formatDuration(route[0].duration) : ''}
            total={freightTollValue || route[0].tolls_gross_amount}
            freight_distance={freight_distance}
          />
        </Flex>
        {!isPageFreight && (
          <Accordion
            allowToggle
            pt={2}
            px={0}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <AccordionItem border="0px" p={0}>
              <AccordionButton px={0}>
                <Box flex="1" textAlign="left" fontWeight="bold" color="gray.400">
                  {!hasTolls
                    ? 'Sem Pedágios'
                    : route[0].tolls_place.length === 1
                    ? 'Ver Pedágio'
                    : 'Ver Praças de Pedágios'}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              {hasTolls && (
                <AccordionPanel pb={4} px={0}>
                  <Accordion allowToggle>
                    {route[0].tolls_place.map(toll => (
                      <AccordionItem border="0px" key={`${toll.name}-${toll.point[0]}-${toll.point[1]}`}>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            Pedágio: {toll.name}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          <p>Valor: R$ {toll.value.toFixed(2)}</p>
                          <p>Endereço: {toll.address}</p>
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        )}
      </Box>
    </Box>
  )
}
