/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { LoadingRequestTypeFormatted } from '../../../../../services/endpoints/freights/loading-request/GetLoadingRequest'
import { usePutLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PutLoadingRequest'
import { LoadingRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { FormLoadingRequest } from '../Form'
import { FreightCustomType } from './CreateLoadingRequestModal'

type UpdateLoadingRequestModalProps = {
  isOpen: boolean
  onClose: () => void
  loadingOrder?: LoadingRequestTypeFormatted
}

const UpdateLoadingRequestSchema = yup.object().shape({
  cte: yup.string(),
  motorist_id: yup.object().required('Campo obrigatório'),
  discount_buonny: yup.string().required('Campo Obrigatório'),
  reason: yup.string().required('Campo Obrigatório'),
  type: yup.string().required('Campo Obrigatório'),
  total_value: yup.string().required('Campo Obrigatório'),
  total_gross: yup.string().required('Campo Obrigatório'),
  beneficiary_name: yup.string().required('Campo Obrigatório'),
  beneficiary_document: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
  origin: yup.string(),
  destination: yup.string(),
  e_frete: yup.string().required('Campo Obrigatório'),
  payment_type: yup.string(),
  pix: yup.string(),
  bank: yup
    .object()
    .shape({})
    .when('pix', {
      is: undefined,
      then: yup.object().shape({}).required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
      otherwise: yup.object().shape({}),
    }),
  account_type: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  agency: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  account: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  account_digit: yup.string(),
})

type UpdateLoadingRequestType = {
  cte?: string
  discount_buonny: string
  reason: string
  type: string
  total_value: string
  total_gross: string
  beneficiary_name: string
  beneficiary_document: string
  observation?: string
  origin?: string
  destination?: string
  e_frete: string
  payment_type: string
  pix?: string | null
  bank?: AutocompleteOption | null
  account_type?: string | null
  agency?: string | null
  account?: string | null
  account_digit?: string | null
  motorist_id?: AutocompleteOption | null
}

type RequestParams = {
  id: string
}

export function UpdateLoadingRequestModal({
  isOpen,
  onClose,
  loadingOrder,
}: UpdateLoadingRequestModalProps): JSX.Element {
  const toast = useToast()
  const [customFreight, setCustomFreight] = useState<FreightCustomType>()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateLoadingRequestSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateLoadingRequest, isLoading: isLoadingUpdateLoadingRequest } =
    usePutLoadingRequest({
      onSuccess: () => {
        toast({
          title: 'Solicitação de carregamento atualizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })
        onClose()
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  useEffect(() => {
    if (
      loadingOrder &&
      loadingOrder?.freight &&
      loadingOrder?.freight?.client &&
      loadingOrder.motorist &&
      loadingOrder?.freight?.vehicle &&
      loadingOrder?.freight?.ctes
    ) {
      const ctesFormated = loadingOrder.freight.ctes.map(cte => ({ label: String(cte.nct), value: cte.id }))

      setCustomFreight({
        freight_id: loadingOrder.freight_id,
        client: `${loadingOrder.freight.client.name} - ${loadingOrder.freight.client.cnpj}`,
        motorist: {
          label: loadingOrder.motorist?.name,
          value: loadingOrder.motorist?.id,
        },
        vehicle_liscense_plate: `${loadingOrder.freight.vehicle?.license_plate}`,
        ctes: ctesFormated,
      })
    }
  }, [loadingOrder])

  const handleUpdateLoadingRequest: SubmitHandler<UpdateLoadingRequestType> = async data => {
    const formData = new FormData()
    let status: LoadingRequestStatusEnum = LoadingRequestStatusEnum.AWAITING_ANALYSIS

    if (loadingOrder?.status === LoadingRequestStatusEnum.REJECTED) {
      formData.append('reject_reason', '')
    }

    if (loadingOrder) {
      if (loadingOrder.cf_file && loadingOrder.cf_number) {
        status = LoadingRequestStatusEnum.AWAITING_PAYMENT
      }
      formData.append('discount_buonny', data.discount_buonny)
      formData.append('status', status)
      formData.append('pix', data.payment_type === 'pix' ? data.pix! : '')
      formData.append('account', data.payment_type === 'account' ? data.account! : '')
      formData.append('bank', data.payment_type === 'account' ? data.bank!.label! : '')
      formData.append('account_type', data.payment_type === 'account' ? data.account_type! : '')
      formData.append('agency', data.payment_type === 'account' ? data.agency! : '')
      formData.append('account_digit', data.payment_type === 'account' ? data.account_digit! : '')
      formData.append('motorist_id', data.motorist_id ? String(data.motorist_id.value) : '')
      formData.append('observation', data.observation ? data.observation : '')
      formData.append('reason', data.reason)
      formData.append('type', data.type)
      formData.append('total_value', data.total_value)
      formData.append('total_gross', data.total_gross)
      formData.append('beneficiary_name', data.beneficiary_name)
      formData.append('beneficiary_document', data.beneficiary_document)
      formData.append('origin', data.origin || '')
      formData.append('destination', data.destination || '')
      formData.append('e_frete', data.e_frete)
      formData.append('cte', data.cte ? String(data.cte) : '')
    }
    await updateLoadingRequest({
      id,
      data: formData,
    })
  }

  return (
    <Modal
      size="4xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Atualizar solicitação de carregamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUpdateLoadingRequest)} noValidate>
          <ModalBody>
            <FormLoadingRequest
              setValue={setValue}
              errors={errors}
              customFreight={customFreight}
              initialValue={loadingOrder}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="orange" isLoading={isLoadingUpdateLoadingRequest}>
              Atualizar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
