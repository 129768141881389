import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'

import { RiWhatsappLine } from 'react-icons/ri'
import { apiServer } from '../../../services/api'
import { FetchMotoristsParams } from '../../../services/endpoints/motorists/getMotorists'

interface SendWhatsAppMessageProps {
  allChecked: boolean
  selectedMotorists: string[]
  isOpen: boolean
  onClose: () => void
  filters?: FetchMotoristsParams
}

export function SendWhatsAppMessageModal({
  filters,
  allChecked,
  selectedMotorists,
  isOpen,
  onClose,
}: SendWhatsAppMessageProps): JSX.Element {
  const toast = useToast()
  const [message, setMessage] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)

  async function handleSendWhatsApp() {
    if (!message) setIsInvalid(true)
    else {
      const { data } = await apiServer.post('/motorists/whatsapp/create-json-file', {
        message,
        filters,
        all_checked: allChecked,
        selected_motorists: selectedMotorists,
      })

      onClose()
      setIsInvalid(false)
      setMessage('')

      toast({
        title: 'Você será redirecionado para o whatsapp web',
        status: 'info',
        position: 'top-right',
        duration: 2000,
      })

      await new Promise(resolve => setTimeout(resolve, 500))

      const newTab = window.open(`https://web.whatsapp.com/#lista_${data.file_id}.json`, '_blank')
      newTab?.focus()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enviar aos contatos selecionados</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Digite a mensagem aqui..."
            rows={5}
            isInvalid={isInvalid}
          />
          {isInvalid && (
            <Text fontSize="sm" fontWeight="medium" color="red.500">
              Campo obrigatório
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="whatsapp"
            leftIcon={<Icon as={RiWhatsappLine} fontSize={18} />}
            onClick={handleSendWhatsApp}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
