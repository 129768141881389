import { useGetOneBlockedEntities } from '../../../../../services/endpoints/blocked-entities/getOneBlockedEntities'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { VehicleType } from '../../../../../services/types'
import { useAppSelector } from '../../../../../store'
import { anttFormValidation } from '../../validations'
import { RegisterOwners } from '../Owners/RegisterOwners'

type AnttOwnerFormProps = {
  vehicle: VehicleType
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  refetch: () => void
  tabIndex: number
  freight: FormattedOneFreight
}

export function AnttOwnerForm({
  vehicle,
  setTabIndex,
  refetch,
  tabIndex,
  freight,
}: AnttOwnerFormProps): JSX.Element {
  const { owner: ownerFound } = useAppSelector(state => state.ownerSlice)
  const { data: blockedEntity } = useGetOneBlockedEntities({
    vehicle_owner_id: ownerFound?.id || vehicle?.antt_owner_id,
    trailer_owner_id: ownerFound?.id || vehicle?.antt_owner_id,
  })

  return (
    <RegisterOwners
      typeOwner="ownerAntt"
      formValidation={anttFormValidation}
      blockedEntity={blockedEntity}
      initialData={vehicle.antt_owner}
      vehicle={vehicle}
      setTabIndex={setTabIndex}
      tabIndex={tabIndex}
      refetch={refetch}
      freight={freight}
    />
  )
}
