import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, UserType } from '../../types'

export const userRolesOptions = [
  { label: 'Super', value: 'super' },
  { label: 'Admin', value: 'admin' },
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'Analista de risco', value: 'risk_analyst' },
  { label: 'Analista de logística', value: 'logistics_analyst' },
  { label: 'Analista comercial', value: 'commercial_analyst' },
  { label: 'Executivo de vendas', value: 'seller' },
  { label: 'Analista financeiro - contas a receber', value: 'financial_accounts_receivable' },
  { label: 'Analista financeiro - contas a pagar', value: 'financial_accounts_payable' },
  { label: 'Assistente de logística', value: 'logistical_assistant' },
]

export async function fetchUser(user_id: string): Promise<UserType | undefined> {
  if (user_id && user_id !== 'undefined') {
    const { data } = await apiServer.get<ResponseApiType<UserType>>('/users/find', {
      params: {
        id: user_id,
      },
    })

    return data.data
  }
  return undefined
}

export function useGetUser(user_id: string): UseQueryResult<UserType> {
  return useQuery(['user', user_id], () => fetchUser(user_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
