/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiPaperclip } from 'react-icons/fi'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { RiEditLine } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { getStatus } from '../../../../../services/endpoints/freights/getFreights'
import { useGetLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/GetLoadingRequest'
import { formatAccountType } from '../../../../../services/utils/formatAccountType'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'
import { AttachOcAtLoadingRequestModal } from '../modals/AttachOcAtLoadingRequestModal'
import { UpdateLoadingRequestModal } from '../modals/UpdateLoadingRequestModal'
import { ApproveOrRejectLoadingRequestModal } from '../modals/ApproveOrRejectLoadingRequestModal'
import { getLoadingRequestColorByStatus } from '../../../../../services/utils/getLoadingOrderColorByStatus'
import { loadingRequestStatusLegend } from '../../../../../services/utils/loadingRequestStatusLegend'
import { LoadingRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { UploadReceiptLoadingRequestModal } from '../modals/UploadReceiptLoadingRequestModal'
import { FinishLoadingRequestModal } from '../modals/FinishLoadingRequestModal'
import { useAuth } from '../../../../../contexts/AuthContext'

type RequestParams = {
  id: string
}

export function ShowLoadingRequest(): JSX.Element {
  const { user } = useAuth()
  const [analysisStatus, setAnalysisStatus] = useState('')
  const bg = useColorModeValue('white', 'gray.800')
  const toast = useToast()

  const { id } = useParams<RequestParams>()

  const { data: loadingOrderData, isLoading, isError, error } = useGetLoadingRequest({ id })

  const {
    isOpen: isApproveOrRejectLoadingRequestModalOpen,
    onOpen: onApproveOrRejectLoadingRequestModalOpen,
    onClose: onApproveOrRejectLoadingRequestModalClose,
  } = useDisclosure()

  const {
    isOpen: isAttachOcAtLoadingRequestModalOpen,
    onOpen: onAttachOcAtLoadingRequestModalOpen,
    onClose: onAttachOcAtLoadingRequestModalClose,
  } = useDisclosure()

  const {
    isOpen: isUpdateLoadingRequestModalOpen,
    onOpen: onUpdateLoadingRequestModalOpen,
    onClose: onUpdateLoadingRequestModalClose,
  } = useDisclosure()

  const {
    isOpen: isUploadReceiptLoadingRequestModalOpen,
    onOpen: onUploadReceiptLoadingRequestModalOpen,
    onClose: onUploadReceiptLoadingRequestModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenFinishLoadingRequestModal,
    onOpen: onOpenFinishLoadingRequestModal,
    onClose: onCloseFinishLoadingRequestModal,
  } = useDisclosure()

  function handleStatus(status: string): void {
    setAnalysisStatus(status)
    onApproveOrRejectLoadingRequestModalOpen()
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  return (
    <Layout>
      {!isLoading ? (
        loadingOrderData ? (
          <>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
              <Heading fontSize="2xl" display="flex" alignItems="center">
                <Text ml={2}>Frete #{loadingOrderData?.freight?.freight_number} - </Text>

                <Tooltip
                  label={loadingRequestStatusLegend(loadingOrderData?.status)}
                  hasArrow
                  placement="top"
                  arrowSize={15}
                >
                  <Text
                    backgroundColor={getLoadingRequestColorByStatus(loadingOrderData?.status)}
                    borderRadius={50}
                    padding={2}
                    textAlign="center"
                    fontSize={14}
                    textColor="white"
                    ml={1}
                  >
                    {loadingOrderData?.formatted_status}
                  </Text>
                </Tooltip>
              </Heading>

              {loadingOrderData.status === LoadingRequestStatusEnum.AWAITING_ANALYSIS &&
                user.email === 'zenilton.filho@spotx.com.br' && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => {
                        handleStatus('approved')
                      }}
                    >
                      <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                    </Button>

                    <Button
                      size="sm"
                      colorScheme="red"
                      ml={2}
                      onClick={() => {
                        handleStatus('rejected')
                      }}
                    >
                      <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                    </Button>
                  </Box>
                )}

              {loadingOrderData.status === LoadingRequestStatusEnum.AWAITING_CF &&
                (user.email === 'fernanda.salino@spotx.com.br' ||
                  user.email === 'monitoramento@spotx.com.br') && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => {
                        onAttachOcAtLoadingRequestModalOpen()
                      }}
                    >
                      <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar CF
                    </Button>
                  </Box>
                )}

              {loadingOrderData.status === LoadingRequestStatusEnum.FINISHED &&
                loadingOrderData.cf_number && (
                  <Box>
                    <Button as="p" size="sm" colorScheme="blue" borderRadius="full">
                      Número CF - {loadingOrderData.cf_number}
                    </Button>
                  </Box>
                )}

              {loadingOrderData.status === LoadingRequestStatusEnum.AWAITING_PAYMENT &&
                user.email === 'jessica.silva@spotx.com.br' && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="yellow"
                      borderRadius="full"
                      onClick={onUploadReceiptLoadingRequestModalOpen}
                    >
                      <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar comprovante
                    </Button>

                    <Button
                      size="sm"
                      colorScheme="cyan"
                      borderRadius="full"
                      onClick={() => {
                        handleStatus('request_review')
                      }}
                      ml={2}
                    >
                      <Icon fontSize={16} as={RiEditLine} mr={1} /> Reprovar
                    </Button>
                  </Box>
                )}

              {loadingOrderData.status === LoadingRequestStatusEnum.PAID &&
                user.email === 'jessica.silva@spotx.com.br' && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => {
                        onOpenFinishLoadingRequestModal()
                      }}
                    >
                      <Icon fontSize={16} as={IoCheckmark} mr={1} /> Finalizar
                    </Button>
                  </Box>
                )}

              {loadingOrderData.status === LoadingRequestStatusEnum.REJECTED && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      onUpdateLoadingRequestModalOpen()
                    }}
                  >
                    <Icon fontSize={16} as={RiEditLine} mr={1} /> Editar
                  </Button>
                </Box>
              )}
            </Flex>

            {loadingOrderData.reject_reason && (
              <Alert status="warning" variant="left-accent">
                <Box>
                  <AlertTitle mb={2}>Motivo de reprovação!</AlertTitle>
                  <AlertDescription>{loadingOrderData.reject_reason}</AlertDescription>
                </Box>
              </Alert>
            )}

            {loadingOrderData.observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação!</AlertTitle>
                  <AlertDescription>{loadingOrderData.observation}</AlertDescription>
                </Box>
              </Alert>
            )}

            <Box bg={bg} p={4} borderRadius="8" shadow="md" mt={4}>
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                Informações solicitação de carregamento
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Motivo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.reason}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Tipo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.type}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Operador:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.creator?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Desconto Buonny: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData.discont_buonny_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">App E-Frete: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData.e_frete ? 'Sim' : 'Não'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Valor do custo total:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.total_value_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Valor bruto pagamento:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.total_gross_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Origem:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.origin ? loadingOrderData?.origin : 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Destino:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.destination ? loadingOrderData?.destination : 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Data de criação:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.created_at}
                  </Text>
                </WrapItem>

                {loadingOrderData.cf_number && (
                  <WrapItem>
                    <Text color="gray.400">Número CF:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingOrderData?.cf_number}
                    </Text>
                  </WrapItem>
                )}

                {loadingOrderData.cf_file && loadingOrderData.cf_file_url && (
                  <WrapItem>
                    <Text color="gray.400">Arquivo CF:</Text>
                    <Tooltip
                      label="Clique para abrir o arquivo em uma nova aba"
                      hasArrow
                      placement="top"
                      arrowSize={15}
                    >
                      <Text
                        ml="1"
                        textDecoration="underline"
                        color="blue.500"
                        _hover={{
                          transition: '0.3s',
                          cursor: 'pointer',
                          color: 'blue.700',
                        }}
                        onClick={() => {
                          window.open(loadingOrderData.cf_file_url, '_blank')?.focus()
                        }}
                      >
                        Visualizar arquivo
                      </Text>
                    </Tooltip>
                  </WrapItem>
                )}

                {loadingOrderData.receipt_file && loadingOrderData.receipt_file_url && (
                  <WrapItem>
                    <Text color="gray.400">Recibo de pagamento:</Text>
                    <Tooltip
                      label="Clique para abrir o arquivo em uma nova aba"
                      hasArrow
                      placement="top"
                      arrowSize={15}
                    >
                      <Text
                        ml="1"
                        textDecoration="underline"
                        color="blue.500"
                        _hover={{
                          transition: '0.3s',
                          cursor: 'pointer',
                          color: 'blue.700',
                        }}
                        onClick={() => {
                          window.open(loadingOrderData.receipt_file_url, '_blank')?.focus()
                        }}
                      >
                        Visualizar arquivo
                      </Text>
                    </Tooltip>
                  </WrapItem>
                )}
              </SimpleGrid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb={4}>
                Informações gerais do frete
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Número do frete:</Text>
                  <Tooltip label="Ir para página do frete" hasArrow>
                    <Text
                      as={Link}
                      to={`/freights/show/${loadingOrderData?.freight_id}`}
                      fontWeight="medium"
                      ml="1"
                    >
                      #{loadingOrderData?.freight?.freight_number}
                    </Text>
                  </Tooltip>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Status do frete:</Text>
                  <Text fontWeight="medium" ml="1">
                    <Tag size="md" borderRadius="full" backgroundColor="green.500">
                      <TagLabel color="white">{getStatus(loadingOrderData?.freight?.status)}</TagLabel>
                    </Tag>
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.freight?.client?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente CNPJ:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(loadingOrderData?.freight?.client?.cnpj)}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">CTe: </Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.cte ? loadingOrderData?.cte?.nct : 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Motorista:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.motorist?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Motorista CPF:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.motorist?.cpf
                      ? formatCPFAndCNPJ(loadingOrderData?.motorist?.cpf)
                      : 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Placa do veículo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.freight?.vehicle?.license_plate}
                  </Text>
                </WrapItem>
              </SimpleGrid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb={4}>
                Informações de pagamento
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Favorecido:</Text>
                  <Text fontWeight="medium" ml="1">
                    {loadingOrderData?.beneficiary_name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Favorecido CPF/CNPJ:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(loadingOrderData?.beneficiary_document)}
                  </Text>
                </WrapItem>

                {loadingOrderData?.pix && (
                  <WrapItem>
                    <Text color="gray.400">Pix:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingOrderData?.pix}
                    </Text>
                  </WrapItem>
                )}

                {loadingOrderData.account_type && (
                  <WrapItem>
                    <Text color="gray.400">Tipo de conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {formatAccountType(loadingOrderData?.account_type)}
                    </Text>
                  </WrapItem>
                )}

                {loadingOrderData.bank && (
                  <WrapItem>
                    <Text color="gray.400">Banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingOrderData?.bank}
                    </Text>
                  </WrapItem>
                )}

                {loadingOrderData.agency && (
                  <WrapItem>
                    <Text color="gray.400">Agencia:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingOrderData?.agency}
                    </Text>
                  </WrapItem>
                )}

                {loadingOrderData.account && (
                  <WrapItem>
                    <Text color="gray.400">Conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingOrderData?.account}
                    </Text>
                  </WrapItem>
                )}

                {loadingOrderData.account_digit && (
                  <WrapItem>
                    <Text color="gray.400">Código banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {loadingOrderData?.account_digit}
                    </Text>
                  </WrapItem>
                )}
              </SimpleGrid>
            </Box>
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar a solicitação de carregamento</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de carregamento encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}

      <ApproveOrRejectLoadingRequestModal
        isOpen={isApproveOrRejectLoadingRequestModalOpen}
        onClose={onApproveOrRejectLoadingRequestModalClose}
        analysisStatus={analysisStatus}
      />

      <AttachOcAtLoadingRequestModal
        isOpen={isAttachOcAtLoadingRequestModalOpen}
        onClose={onAttachOcAtLoadingRequestModalClose}
      />

      <UpdateLoadingRequestModal
        isOpen={isUpdateLoadingRequestModalOpen}
        onClose={onUpdateLoadingRequestModalClose}
        loadingOrder={loadingOrderData}
      />

      <UploadReceiptLoadingRequestModal
        isOpen={isUploadReceiptLoadingRequestModalOpen}
        onClose={onUploadReceiptLoadingRequestModalClose}
      />

      <FinishLoadingRequestModal
        isOpen={isOpenFinishLoadingRequestModal}
        onClose={onCloseFinishLoadingRequestModal}
      />
    </Layout>
  )
}
