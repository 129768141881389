import { Grid, GridItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import {
  Autocomplete,
  AutocompleteAsync,
  Input,
  InputNumberMask,
  Select,
  TextArea,
} from '../../../../../components/form'
import { LoadingRequestTypeFormatted } from '../../../../../services/endpoints/freights/loading-request/GetLoadingRequest'
import { searchMotoristByName } from '../../../../../services/endpoints/motorists/searchMotoristByName'
import { bankList } from '../../../../../services/utils/bankList'
import { FreightCustomType } from '../modals/CreateLoadingRequestModal'

type FormLoadingRequestProps = {
  errors: DeepMap<FieldValues, FieldError>
  setValue: UseFormSetValue<FieldValues>
  customFreight?: FreightCustomType
  freightNumber?: string
  setFreightNumber?: (value: string) => void
  initialValue?: LoadingRequestTypeFormatted
}

export function FormLoadingRequest({
  errors,
  setValue,
  customFreight,
  freightNumber,
  setFreightNumber,
  initialValue,
}: FormLoadingRequestProps): JSX.Element {
  const [paymentMethod, setPaymentMethod] = useState('account')

  useEffect(() => {
    if (initialValue) {
      setPaymentMethod(initialValue.pix ? 'pix' : 'account')
    }
  }, [initialValue])

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Número do frete"
            name="freight_number"
            error={errors.freight_number}
            placeholder="Número do frete"
            onChange={e => {
              if (setFreightNumber) setFreightNumber(e.target.value)
            }}
            initialValue={initialValue?.freight?.freight_number || freightNumber}
            setValue={setValue}
            isDisabled={!!initialValue?.freight?.freight_number}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]} mr={2}>
          <Input
            title={customFreight?.client || 'Cliente'}
            label="Cliente"
            name="client"
            error={errors.client}
            placeholder="Cliente"
            setValue={setValue}
            initialValue={customFreight?.client || ''}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="CTe"
            name="cte"
            error={errors.cte}
            setValue={setValue}
            options={customFreight?.ctes?.length ? [{ label: '', value: '' }, ...customFreight?.ctes] : []}
            isDisabled={!customFreight}
            initialValue={initialValue?.cte_id}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]} mr={2}>
          <AutocompleteAsync
            label="Motorista"
            name="motorist_id"
            error={errors.motorist_id}
            placeholder="Motorista"
            setValue={setValue}
            loadOptions={searchMotoristByName}
            initialValue={customFreight?.motorist}
            isDisabled={!customFreight}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Placa do veículo"
            name="vehicle_license_plate"
            error={errors.vehicle_license_plate}
            placeholder="Placa do veículo"
            initialValue={customFreight?.vehicle_liscense_plate || ''}
            setValue={setValue}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Desconto Buonny"
            name="discount_buonny"
            error={errors.discount_buonny}
            setValue={setValue}
            options={[
              { label: 'Não', value: '0' },
              { label: 'R$ 35', value: '35' },
              { label: 'R$ 45', value: '45' },
            ]}
            isDisabled={!customFreight}
            initialValue={String(initialValue?.discount_buonny)}
            isRequired
          />
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap="3" mt={4}>
        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            label="Motivo"
            name="reason"
            error={errors.reason}
            placeholder="Motivo"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.reason}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            label="Tipo"
            name="type"
            error={errors.type}
            placeholder="Tipo"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.type}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <InputNumberMask
            label="Valor Total"
            name="total_value"
            error={errors.total_value}
            placeholder="Valor Total"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={Number(initialValue?.total_value)}
            isCashBRL
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <InputNumberMask
            label="Valor Bruto"
            name="total_gross"
            error={errors.total_gross}
            placeholder="Valor Bruto"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={Number(initialValue?.total_gross)}
            isCashBRL
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 5]} mr={2}>
          <Input
            label="Nome Favorecido"
            name="beneficiary_name"
            error={errors.beneficiary_name}
            placeholder="Nome Favorecido"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.beneficiary_name}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Documento Favorecido"
            name="beneficiary_document"
            error={errors.beneficiary_document}
            placeholder="Documento Favorecido"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.beneficiary_document}
            maxLength={18}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]}>
          <TextArea
            name="origin"
            h="130"
            error={errors.origin}
            label="Origem"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.origin}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]}>
          <TextArea
            name="destination"
            h="130"
            error={errors.destination}
            label="Destino"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.destination}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="E-Frete"
            name="e_frete"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setValue={setValue}
            options={[
              {
                label: 'Não',
                value: 'no',
              },
              {
                label: 'Sim',
                value: 'yes',
              },
            ]}
            isDisabled={!customFreight}
            initialValue={initialValue?.e_frete ? 'yes' : 'no'}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Tipo de pagamento"
            name="payment_type"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setValue={setValue}
            options={[
              {
                label: 'Conta bancaria',
                value: 'account',
              },
              {
                label: 'Pix',
                value: 'pix',
              },
            ]}
            onSelectOption={option => {
              setPaymentMethod(option.value)
            }}
            isDisabled={!customFreight}
            initialValue={initialValue?.pix ? 'pix' : 'account'}
          />
        </GridItem>

        {paymentMethod === 'pix' && (
          <GridItem colSpan={[12, 6, 6]} mr={2}>
            <Input
              name="pix"
              error={errors.pix}
              label="Pix"
              setValue={setValue}
              isDisabled={!customFreight}
              initialValue={initialValue?.pix}
            />
          </GridItem>
        )}

        {paymentMethod === 'account' && (
          <>
            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Select
                name="account_type"
                error={errors.account_type}
                label="Tipo de conta"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Corrente', value: 'current' },
                  { label: 'Poupança', value: 'savings' },
                  { label: 'Outros', value: 'others' },
                ]}
                initialValue={initialValue?.account_type}
                isDisabled={!customFreight}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Autocomplete
                name="bank"
                error={errors.bank}
                label="Banco"
                setValue={setValue}
                options={[...bankList]}
                initialValue={
                  initialValue?.bank
                    ? {
                        label: String(initialValue?.bank),
                        value: String(initialValue?.bank),
                      }
                    : undefined
                }
                isDisabled={!customFreight}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 4]}>
              <Input
                name="agency"
                error={errors.agency}
                label="Agencia"
                setValue={setValue}
                initialValue={initialValue?.agency}
                isDisabled={!customFreight}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Input
                name="account"
                error={errors.account}
                label="Conta"
                setValue={setValue}
                initialValue={initialValue?.account}
                isDisabled={!customFreight}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Input
                name="account_digit"
                error={errors.account_digit}
                label="Cód. Banco"
                setValue={setValue}
                initialValue={initialValue?.account_digit}
                isDisabled={!customFreight}
              />
            </GridItem>
          </>
        )}

        <GridItem colSpan={[12, 6, 12]}>
          <TextArea
            name="observation"
            h="130"
            error={errors.observation}
            label="Observação"
            setValue={setValue}
            isDisabled={!customFreight}
            initialValue={initialValue?.observation}
          />
        </GridItem>
      </Grid>
    </>
  )
}
