import { useColorModeValue } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { Layout } from '../../../layout'
import { useAppSelector } from '../../../store'
import { BuonnyHistoryFreight } from './cards/buonny-history'
import { RiskAnalysisInfos } from './cards/risk-analysis-infos/RiskAnalysisInfos'
import { ListRiskAnalysisHistory } from './cards/risk-analysis-status-history/ListRiskAnalysisHistory'

interface RouteParams {
  risk_analysis_id: string
}

export function ShowRiskAnalysis(): JSX.Element {
  const { riskAnalysis } = useAppSelector(s => s.riskAnalysisSlice)
  const { risk_analysis_id } = useParams<RouteParams>()
  const bg = useColorModeValue('white', 'gray.800')

  return (
    <Layout>
      <RiskAnalysisInfos bg={bg} risk_analysis_id={risk_analysis_id} />
      <ListRiskAnalysisHistory bg={bg} risk_analysis_id={risk_analysis_id} />
      {riskAnalysis && <BuonnyHistoryFreight bg={bg} freight_id={riskAnalysis.freight_id} />}
    </Layout>
  )
}
