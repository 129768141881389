import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { toastError } from '../../../../../config/error/toastError'
import { usePutLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PutLoadingRequest'
import { LoadingRequestStatusEnum } from '../../../../../services/types/EnumTypes'

type UploadReceiptLoadingRequestModalProps = {
  isOpen: boolean
  onClose: () => void
}

type UploadReceiptLoadingRequestType = {
  attachment: File
}

type RequestParams = {
  id: string
}

export function UploadReceiptLoadingRequestModal({
  isOpen,
  onClose,
}: UploadReceiptLoadingRequestModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue } = useForm({})

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateLoadingRequestStatus, isLoading: isLoadingUploadReceiptLoadingRequest } =
    usePutLoadingRequest({
      onError: error => {
        toastError({ toast, error })
      },
      onSuccess: () => {
        toast({
          title: 'Comprovante de pagamento anexado com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })
      },
    })

  const handleUploadReceiptLoadingRequest: SubmitHandler<UploadReceiptLoadingRequestType> = async data => {
    const formData = new FormData()

    formData.append('status', LoadingRequestStatusEnum.PAID)

    formData.append('attachment', data.attachment)
    formData.append('file_type', 'receipt')

    await updateLoadingRequestStatus({ id, data: formData })

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Anexar comprovante</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUploadReceiptLoadingRequest)} noValidate>
          <ModalBody>
            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="attachment"
              label="Comprovante de pagamento"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              mr={3}
              onClick={() => {
                onClose()
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingUploadReceiptLoadingRequest}
              isDisabled={isLoadingUploadReceiptLoadingRequest}
            >
              Avançar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
