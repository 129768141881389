import { useState } from 'react'
import {
  SimpleGrid,
  Flex,
  Box,
  Spinner,
  Alert,
  AlertIcon,
  Tooltip,
  Text,
  Table,
  Tbody,
  Td,
  Tr,
  Badge,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import Chart from 'react-apexcharts'
import { RiLineChartLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { MdAttachMoney } from 'react-icons/md'
import { Layout } from '../../../layout'
import { FreightsActiveCard } from './cards/FreightsActiveCard'
import { FreightsCurrentCard } from './cards/FreightsCurrentCard'
import { FreightsFinishedCard } from './cards/FreightsFinishedCard'
import { FreightsNoShowCard } from './cards/FreightsNoShowCard'
import { FreightsDisabledCard } from './cards/FreightsDisabled.Card'
import { ReportMotoristsCard } from './cards/ReportMotoristsCard'
import { FreightsMonitoredCard } from './cards/FreightsMonitoredCard'
import { FeedbacksByMotoristAndClientCard } from './cards/FeedbacksByMotoristAndClientCard'

import { useGetCountFreights } from '../../../services/endpoints/freights/getCountFreight'
import { getStatusZApiInstance } from '../../../services/endpoints/freights/getStatusZApiInstance'
import { useReportsFeedbacksMotorist } from '../../../services/endpoints/motorists/reportsFeedbacksMotorist'
import { useReportsFeedbacksClient } from '../../../services/endpoints/clients/reportsFeedbacksClient'

import { apexOptions } from '../apexcharts/FreightsPeriodMonths'
import { useGetCheckBalance } from '../../../services/endpoints/truckpad/checkBalance'
import parseCurrency from '../../../services/utils/parseCurrency'

interface ListCardsDashboardProps {
  bg: string
}

export function ListCardsDashboard({ bg }: ListCardsDashboardProps): JSX.Element {
  const [initialDate, setInitialDate] = useState<string>(() => {
    return format(new Date(), 'yyyy-MM-01')
  })
  const [finalDate, setFinalDate] = useState<string>(() => {
    return format(new Date(), 'yyyy-MM-dd')
  })

  const {
    data: countFreights,
    isLoading,
    isFetching,
    error,
  } = useGetCountFreights({
    initial_date: `${initialDate}T00:00:00`,
    final_date: `${finalDate}T23:59:59`,
  })

  const { data: dataStatusZApi, isLoading: isLoadingStatusZApi } = getStatusZApiInstance()

  const freightsActive = countFreights?.pending_freights
  const freightsCurrent = countFreights?.current_freights
  const freightsFinished = countFreights?.finished_freights
  const freightsNoShow = countFreights?.no_show
  const freightsDisable = countFreights?.disabled
  const freightsMonitored = {
    freightsMonitoredFinished: countFreights?.monitored_freights || 0,
    freightsFinished: countFreights?.finished_freights.total || 0,
    // freightsAerials: countFreights?.finished_freights.aerial || 0,
  }

  const feedbackResponseClient = useReportsFeedbacksClient({
    initialDateFeedbacksClient: initialDate,
    finalDateFeedbacksClient: finalDate,
  })

  const feedbackResponseMotorist = useReportsFeedbacksMotorist({
    initialDateFeedbacksMotorist: initialDate,
    finalDateFeedbacksMotorist: finalDate,
  })

  const dataChartPeriodMonths = [freightsFinished?.total, freightsNoShow, freightsDisable]

  const {
    data,
    isLoading: isLoadingTruckpad,
    isFetching: isFetchingTruckpad,
    isError: isErroTruckpad,
  } = useGetCheckBalance()

  return (
    <Layout
      initialDate={initialDate}
      finalDate={finalDate}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      SidebarMobile
      isFilters
    >
      <SimpleGrid gap="4" minChildWidth="300px" align="flex-start" mt="-4">
        <FreightsActiveCard
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          freightsActive={freightsActive}
          bg={bg}
        />

        <FreightsCurrentCard
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          freightsCurrent={freightsCurrent}
          bg={bg}
        />

        <Flex
          p={['2', '3']}
          bg={!isLoading ? bg : undefined}
          borderRadius={8}
          shadow="md"
          direction="column"
          gridGap="5"
        >
          <FreightsFinishedCard
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            freightsFinished={freightsFinished}
          />

          <FreightsNoShowCard
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            freightsNoShow={freightsNoShow}
          />

          <FreightsDisabledCard
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            freightsDisabled={freightsDisable}
          />
        </Flex>

        <ReportMotoristsCard bg={bg} />

        <FreightsMonitoredCard
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          freightsMonitored={freightsMonitored}
          freightsAerials={+String(countFreights?.aerial_freights)}
          bg={bg}
        />

        <FeedbacksByMotoristAndClientCard
          countFeedbackClient={feedbackResponseClient}
          countFeedbackMotorist={feedbackResponseMotorist}
          bg={bg}
        />

        {isLoading ? (
          <Flex justify="center">
            <Spinner ml={2} />
          </Flex>
        ) : error ? (
          <Alert status="warning">
            <AlertIcon />
            Falha ao carregar dados dos fretes. Verifique a conexão com a internet ou entre em contato com a
            equipe de suporte do sistema.
          </Alert>
        ) : (
          <Box bg={bg} borderRadius={8} shadow="md">
            <Flex gridGap="2" fontSize="lg" pt="4" alignItems="center" pl="4" mb="2" fontWeight="bold">
              <Tooltip placement="top" label="Relatório de fretes" hasArrow>
                <Flex>
                  <Link to="/freights-reports">
                    <RiLineChartLine />
                  </Link>
                </Flex>
              </Tooltip>
              GRÁFICO DE FRETES DO MÊS
            </Flex>
            <Chart type="pie" height={163} options={apexOptions} series={dataChartPeriodMonths} />
          </Box>
        )}

        {isLoadingTruckpad ? (
          <Flex justify="center">
            <Spinner ml={2} />
          </Flex>
        ) : isErroTruckpad ? (
          <Alert status="warning">
            <AlertIcon />
            Falha ao carregar dados do Truckpad. Verifique a conexão com a internet ou entre em contato com a
            equipe de suporte do sistema.
          </Alert>
        ) : (
          data && (
            <Box bg={bg} borderRadius={8} shadow="md">
              <Flex gridGap="2" fontSize="lg" pt="4" pl="4" alignItems="center" mb="3" fontWeight="bold">
                <Flex fontSize="2xl">
                  <MdAttachMoney />
                </Flex>
                SALDO DO TRUCKPAD {isFetchingTruckpad && <Spinner ml={2} />}
              </Flex>
              <Flex justifyContent="center" alignItems="center" fontSize="3xl" fontWeight="bold" h="50%">
                {parseCurrency(data.Value)}
              </Flex>
            </Box>
          )
        )}

        {isLoadingStatusZApi ? (
          <Flex justify="center">
            <Spinner ml={2} />
          </Flex>
        ) : error ? (
          <Alert status="warning">
            <AlertIcon />
            Falha ao carregar dados dos fretes. Verifique a conexão com a internet ou entre em contato com a
            equipe de suporte do sistema.
          </Alert>
        ) : (
          <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md">
            <Flex
              justifyContent="space-between"
              pl="5"
              pr="5"
              mt={1}
              alignItems="baseline"
              mb="4"
              fontWeight="bold"
            >
              <Text fontSize="lg">STATUS CONEXÃO Z-API</Text>
            </Flex>
            <Flex mt="8" justify="flex-end">
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>
                      <Text fontSize="md" color="gray.500">
                        STATUS DO BOT:
                      </Text>
                    </Td>
                    <Td fontSize="md" color="green.500" isNumeric>
                      {dataStatusZApi?.data ? (
                        <Tooltip label="Está tudo certo com o Z-API." aria-label="A tooltip">
                          <Badge colorScheme="green">CONECTADO</Badge>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label="Z-API DESCONECTADO! Chame alguém do time de Tech para conectar."
                          aria-label="A tooltip"
                        >
                          <Badge colorScheme="red">DESCONECTADO</Badge>
                        </Tooltip>
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Flex>
          </Box>
        )}
      </SimpleGrid>
    </Layout>
  )
}
