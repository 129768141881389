/* eslint-disable no-shadow */
export enum MessageCodeEnum {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum FreightRequestOriginEnum {
  TMS = 'tms',
  APP = 'app',
}

export enum TypeNotificationEnum {
  SIMPLE = 'simple',
  IMPORTANT = 'important',
  URGENT = 'urgent',
}

export enum RoutePricesTrackingDeviceTypeEnum {
  TRACKER = 'tracker',
  LOCATOR = 'locator',
  HYBRID = 'hybrid',
}

export enum UsersRoleEnum {
  SUPER = 'super',
  ADMIN = 'admin',
  SUPERVISOR = 'supervisor',
  LOGISTICS_ANALYST = 'logistics_analyst', // Antigo "operator"
  COMMERCIAL_ANALYST = 'commercial_analyst',
  SELLER = 'seller',
  FINANCIAL_ACCOUNTS_RECEIVABLE = 'financial_accounts_receivable', // O cargo "financial" deixou de existir - Jaqueline
  FINANCIAL_ACCOUNTS_PAYABLE = 'financial_accounts_payable', // O cargo "financial" deixou de existir - Jessica
  RISK_ANALYST = 'risk_analyst', // Somente Fernanda
  LOGISTICAL_ASSISTANT = 'logistical_assistant', // Larissa E Villyane
  MOTORIST = 'motorist',
}

export enum MotoristWaypointTypeEnum {
  APP = 'app',
  WHATSAPP = 'whatsapp',
  BUONNY = 'buonny',
}

export enum FreightExpenseStatusEnum {
  PENDING = 'pending',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  AWAITING_PROOF = 'awaiting_proof',
}

export enum FreightExpensesAttachmentsTypeEnum {
  OPERATIONAL = 'operational',
  FINANCIAL = 'financial',
}

export enum FreightExpenseEventEnum {
  DIESEL_ADVANCE = 'diesel_advance',
  FREIGHT_ADVANCE = 'freight_advance',
  AIR_EXPENSE = 'air_expense',
  DISCHARGE = 'discharge',
  DAILY = 'daily',
  FREIGHT_COMPLEMENT = 'freight_complement',
  TRANSPORT_KNOWLEDGE = 'transport_knowledge',
  ELECTRONIC_TRANSPORT_KNOWLEDGE = 'electronic_transport_knowledge',
  INVOICE = 'invoice',
  SERVICE_INVOICE = 'service_invoice',
  BILL = 'bill',
  BILL_INVOICE = 'bill_invoice',
  DIESEL = 'diesel',
  ELECTRONIC_INVOICE = 'electronic_invoice',
  TAX_COUPON = 'tax_coupon',
  SERVICE_ORDERS = 'service_orders',
  TOLL = 'toll',
  RECEIPT = 'receipt',
  NO_RECEIPT = 'no_receipt',
  FREIGHT_BALANCES = 'freight_balances',
  TOTAL_CTRC_INSTALLMENT = 'total_ctrc_installment',
  OPERATIONAL_EXPENSES = 'operational_expenses',
  PURCHASE_OF_EQUIPMENT = 'purchase_of_equipment',
  AGENCY = 'agency',
}

export enum FreightExpenseAttachmentsTypeEnum {
  OPERATIONAL = 'operational',
  FINANCIAL = 'financial',
}

export enum BlackListEntityTypeEnum {
  MOTORIST = 'motorist',
  OWNER = 'owner',
  VEHICLE = 'vehicle',
}

export enum DeviceInfosBatteryStateTypeEnum {
  CHARGING = 'charging',
  UNPLUGGED = 'unplugged',
  FULL = 'full',
}

export enum DeviceInfosTypeConnectionTypeEnum {
  NONE = 'none',
  UNKNOWN = 'unknown',
  CELLULAR = 'cellular',
  WIFI = 'wifi',
  BLUETOOTH = 'bluetooth',
  ETHERNET = 'ethernet',
  WIMAX = 'wimax',
  VPN = 'vpn',
  OTHER = 'other',
}

export enum FilterByOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export enum GenderEnum {
  M = 'M',
  F = 'F',
}

export enum MaritalStatusEnum {
  SINGLE = 'single',
  MARRIED = 'married',
  DIVORCED = 'divorced',
  WIDOW = 'widow',
}

export enum SchoolDegreeEnum {
  INCOMPLETE_BASIC_SCHOOL = 'incomplete_basic_school',
  BASIC_SCHOOL = 'basic_school',
  INCOMPLETE_HIGH_SCHOOL = 'incomplete_high_school',
  HIGH_SCHOOL = 'high_school',
  INCOMPLETE_GRADUATE = 'incomplete_graduate',
  GRADUATE = 'graduate',
  POSTGRADUATE = 'postgraduate',
  DOCTORATE = 'doctorate',
  OTHER = 'other',
}

export enum MotoristsProspectedByEnum {
  FRETEBRAS = 'fretebras',
  AGENCIATOR = 'agenciator',
  BASE = 'base',
  LINK = 'link',
  OTHER = 'other',
}

export enum RoutesFrequencyTypeEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum VehicleTypeEnum {
  VEHICLE = 'vehicle',
  TRAILER = 'trailer',
}

export enum VehicleCategoriesTypeEnum {
  LIGHT = 'light',
  MEDIUM = 'medium',
  HEAVY = 'heavy',
}

export enum VehicleBodiesTypeEnum {
  CLOSED = 'closed',
  OPENED = 'opened',
  SPECIAL = 'special',
}

export enum DependentsTypeEnum {
  CHILD = 'child',
  STEPCHILD = 'stepchild',
}

export enum OperationsStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  FINISHED = 'finished',
}

export enum CiotHiredDocTypeEnum {
  CPF = 'cpf',
  CNPJ = 'cnpj',
  RNTRC = 'rntrc',
}

export enum CiotHiredDocCarrierCategoryEnum {
  TAC = 'tac',
  ETC = 'etc',
}

export enum CiotStatusEnum {
  CANCELADO = 'cancelado',
  ENCERRADO = 'encerrado',
}

export enum LoadingRequestStatusEnum {
  AWAITING_ANALYSIS = 'awaiting_analysis',
  REJECTED = 'rejected',
  AWAITING_CF = 'awaiting_cf',
  AWAITING_PAYMENT = 'awaiting_payment',
  PAID = 'paid',
  FINISHED = 'finished',
}

export enum FreightsTypeEnum {
  TERRESTRIAL = 'terrestrial',
  AERIAL = 'aerial',
  HOTLINE = 'hotline',
}

export enum FreightsStatusEnum {
  PENDING = 'pending',
  IN_ORIGIN = 'in_origin',
  COLLECTING = 'collecting',
  READY = 'ready',
  ON_ROAD = 'on_road',
  IN_DESTINATION = 'in_destination',
  DELIVERED = 'delivered',
  FINISHED = 'finished',
  DISABLED = 'disabled',
}

export enum FreightsSuggestedPriceTypeEnum {
  PER_TON = 'per_ton',
  FULL = 'full',
  TO_MATCH = 'to_match',
  PER_KILOMETER = 'per_kilometer',
}

export enum FreightsTollTicketTypeEnum {
  NOT = 'not',
  CONECT_CAR = 'conect_car',
  NONSTOP = 'nonstop',
}

export enum FreightsPaymentMethodTypeEnum {
  TRUCK_PAD = 'truck_pad',
  E_FREIGHT = 'e_freight',
  RODOBANK = 'rodobank',
}

export enum ProspectedByEnum {
  FRETEBRAS = 'fretebras',
  AGENCIATOR = 'agenciator',
  BASE = 'base',
  LINK = 'link',
  OTHER = 'other',
}

export enum MotoristAttachmentsTypeEnum {
  CPF = 'cpf',
  RG = 'rg',
  CNH = 'cnh',
  ADDRESS_PROOF = 'address_proof',
  DEPENDENT_DOC = 'dependent_doc',
  OTHER = 'other',
}

export enum VehicleAttachmentsTypeEnum {
  LICENSE = 'license',
  ANTT = 'antt',
  PHOTO = 'photo',
  OTHER = 'other',
}

export enum FreightAttachmentsTypeEnum {
  DEFAULT = 'default',
  OCCURRENCE = 'occurrence',
  OTHER = 'other',
}

export enum FreightAttachmentsDocumentEnum {
  NFE = 'nf-e',
  CTE = 'ct-e',
  CIOT = 'ciot',
  MDFE = 'mdf-e',
  COLLECTING_ORDER = 'collecting_order',
  OTHER = 'other',
  STUB = 'stub',
  PROOF_PAID_ADVANCE = 'proof_paid_advance',
  PROOF_PAID_DAILY = 'proof_paid_daily',
  PROOF_PAID_DELIVERY = 'proof_paid_delivery',
  PROOF_PAID_GNRE = 'proof_paid_GNRE',
  PROOF_PAID_BALANCE = 'proof_paid_balance',
  PROOF_REQUEST_PAYMENT = 'proof_request_payment',
  FREIGHT_CONTRACT = 'freight_contract',
  PICTURES_SEALED_TRUCK = 'pictures_sealed_truck',
  GNRE_GUIDE = 'GNRE_guide',
  UNLOADING_RECEIPT = 'unloading_receipt',
  CONCIERGE_TICKET = 'concierge_ticket',
}

export enum FreightStatusHistoryDisabledReasonEnum {
  NO_SHOW = 'no_show',
  SINISTER = 'sinister',
  CANCELED_BY_CLIENT = 'canceled_by_client',
  OTHER = 'other',
}

export enum OperationAttachmentsValueTypeEnum {
  INCOME = 'income',
  OUTCOME = 'outcome',
}

export enum ClientsPaymentMethodEnum {
  BILL = 'bill',
  DEPOSIT = 'deposit',
  OTHER = 'other',
}

export enum FreightClientRefTypeEnum {
  CC = 'cc',
  DT = 'dt',
  NC = 'nc',
  NP = 'np',
  PC = 'pc',
  PO = 'po',
  RC = 'rc',
  SC = 'sc',
  SOTE = 'sote',
  STE = 'ste',
  OTHER = 'other',
}

export enum QuotationsStatusEnum {
  PENDING = 'pending',
  IN_QUOTATION = 'in_quotation',
  TO_APPROVE = 'to_approve',
  FINISHED = 'finished',
  DISABLED = 'disabled',
}

export enum QuotationsTypeEnum {
  TEST = 'test',
}

export enum FreightOccurrecesTypeEnum {
  SINISTER = 'sinister',
  DELAY = 'delay',
  DAMAGE = 'damage',
  OTHER = 'other',
}
