/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export interface IPostRoutePlannerParams {
  body: {
    vehicle_category: string
    round_trip: boolean
    provider: string
    note?: string
    vehicle_code: string
    end_date?: string
    document?: string
    places: Array<{
      date?: string
      point: [number, number]
      state: string
      country: string
      city: string
    }>
    integrated_by?: string
    start_date?: string
  }
}

type PointType = [number, number]

type PlaceType = {
  point: PointType
  provider: string
}

type DataResponseType = {
  points: PlaceType[]
  tolls_place: PointType[]
  distance: number
  distance_unit: string
  duration: number
  duration_unit: string
  tolls_amount: number
  tolls_gross_amount: number
  tolls_count: number
  route_provider: string
  travel_type: string
  cached: boolean
}

type IPostRoutePlannerResponse = {
  message: string
  data: DataResponseType
}

async function postRoutePlanner(data: IPostRoutePlannerParams) {
  return apiServer.post<ResponseApiType<IPostRoutePlannerResponse>>('/truckpad/toll/travel/route', data)
}

export function usePostRoutePlanner(
  options?: UseMutationOptions<AxiosResponse, unknown, IPostRoutePlannerParams, unknown>,
) {
  return useMutation(async (data: IPostRoutePlannerParams) => postRoutePlanner(data), options)
}
