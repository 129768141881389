import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { RiEditLine, RiSmartphoneLine } from 'react-icons/ri'
import { MotoristInfos } from '../../../../../components/card-infos/MotoristInfos'
import { useGlobalVariables } from '../../../../../contexts/GlobalVariables'
import { useAbility } from '../../../../../hooks/useAbility'
import { useIsBlockEntity } from '../../../../../services/endpoints/blocked-entities/isBlockEntity'
import { useGetMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { EditMotoristModal } from '../../../modals/EditMotoristModal'

interface MotoristInfoProps {
  motorist_id: string
  bg: string
}

export function MotoristInfo({ motorist_id, bg }: MotoristInfoProps): JSX.Element {
  const abilityUpdateMotorist = useAbility('people', 'update-motorist')
  const { setPageTitle } = useGlobalVariables()
  const { data: isBlockedEntity } = useIsBlockEntity({ motorist_id })
  const {
    isOpen: isMotoristFormModalOpen,
    onOpen: onMotoristFormModalOpen,
    onClose: onMotoristFormModalClose,
  } = useDisclosure()

  const { data: motorist, isLoading, isFetching, isError, refetch } = useGetMotorist(motorist_id)
  setPageTitle(`${motorist?.name} | Motoristas | SpotX Admin`)
  return isLoading ? (
    <Flex justify="center">
      <Spinner />
    </Flex>
  ) : !motorist || isError ? (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Erro ao carregar os dados do motorista!</AlertTitle>
      <AlertDescription>
        Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o administrador do
        sistema.
      </AlertDescription>
    </Alert>
  ) : (
    <Box bg={bg} p="6" borderRadius="8" shadow="md">
      <Flex mb="6" justify="space-between" align="center" gridGap="2" direction={['column', 'row']}>
        <Heading fontSize="2xl" fontWeight="medium">
          Informações do motorista
          {!isLoading && isFetching && <Spinner size="sm" ml="4" />}
        </Heading>

        <HStack>
          {motorist?.has_app && (
            <Tooltip hasArrow label="Este motorista possui o app instalado">
              <Flex>
                <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                  <Flex direction="row">
                    <Icon as={RiSmartphoneLine} fontSize="xx-large" mr="1" />
                  </Flex>
                </Text>
              </Flex>
            </Tooltip>
          )}

          <Button
            size="sm"
            colorScheme="yellow"
            leftIcon={<Icon as={RiEditLine} />}
            isDisabled={isBlockedEntity}
            onClick={() => {
              if (abilityUpdateMotorist) onMotoristFormModalOpen()
            }}
          >
            <Tooltip
              hasArrow
              placement="top"
              label={abilityUpdateMotorist || (isBlockedEntity && 'Motorista em analise! Edição bloqueada!')}
            >
              Editar
            </Tooltip>
          </Button>
        </HStack>
      </Flex>

      <MotoristInfos motorist={motorist} />

      <EditMotoristModal
        motorist_id={motorist_id}
        isOpen={isMotoristFormModalOpen}
        onClose={() => {
          onMotoristFormModalClose()
          refetch()
        }}
      />
    </Box>
  )
}
