import { SimpleGrid, VStack } from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import * as yup from 'yup'
import { Input } from '../../../components/form/Input'
import { InputMask } from '../../../components/form/InputMask'
import { InputPassword } from '../../../components/form/InputPassword'
import { Select } from '../../../components/form/Select'
import { useAbility } from '../../../hooks/useAbility'
import { useGetRoleOptions } from '../../../services/endpoints/authorization/getRolesOpitions'
import { UserType } from '../../../services/types'

export const userFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('Email inválido').required('Email obrigatório'),
  role: yup.string().required('Cargo obrigatório'),
  password: yup.string().min(6, 'No mínimo 6 caracteres'),
  passwordConfirmation: yup
    .string()
    .oneOf([null, yup.ref('password')], 'Senhas não correspondem')
    .when('password', {
      is: (password: string) => {
        return password && String(password).length > 0
      },
      then: yup.string().required('Cargo obrigatório'),
    }),
  phone: yup.string().required('Telefone obrigatório'),
})

interface UpdateUserModalProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  userData?: UserType
}

export function UserForm({ userData, setValue, formState }: UpdateUserModalProps): JSX.Element {
  const { data: userRolesOptions } = useGetRoleOptions()
  const { errors } = formState
  const abilityUpdateUser = useAbility('account', 'update-user')

  return (
    <>
      <VStack spacing={['4', '6']}>
        <SimpleGrid minChildWidth="240px" w="100%" gap={['4', '6']}>
          <Input
            isRequired
            isDisabled={!abilityUpdateUser}
            name="name"
            label="Nome completo"
            error={errors.name}
            setValue={setValue}
            initialValue={userData?.name}
            uppercaseFirst
          />
          <Input
            isRequired
            isDisabled={!abilityUpdateUser}
            name="email"
            type="email"
            label="E-mail"
            error={errors.email}
            setValue={setValue}
            initialValue={userData?.email}
          />
          {userRolesOptions && userRolesOptions.length > 0 && (
            <Select
              isRequired
              isDisabled={!abilityUpdateUser}
              name="role"
              label="Cargo"
              options={userRolesOptions}
              setValue={setValue}
              initialValue={userData?.role}
              error={errors.role}
            />
          )}
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" w="100%" gap={['4', '6']}>
          <InputPassword
            isDisabled={!abilityUpdateUser}
            name="password"
            type="password"
            label="Senha"
            error={errors.password}
            setValue={setValue}
          />
          <InputPassword
            isDisabled={!abilityUpdateUser}
            name="passwordConfirmation"
            type="password"
            label="Confirmação de senha"
            error={errors.passwordConfirmation}
            setValue={setValue}
          />
          <InputMask
            isRequired
            name="phone"
            mask="(99) 9 9999-9999"
            registerOnlyNumbers
            maskPlaceholder=""
            label="Telefone"
            error={errors.phone}
            setValue={setValue}
            initialValue={userData?.phone}
          />
        </SimpleGrid>
      </VStack>
    </>
  )
}
