import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { apiServer } from '../../api'
import { FreightType, ResponseListApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'
import parseCurrency from '../../utils/parseCurrency'

export interface Coords {
  lat: string
  lng: string
}

export interface FormattedAllFreights extends FreightType {
  formatted_type: string
  formatted_status: string
  formatted_received_at?: string
  formatted_link_motorist_at?: string
  input_collect_cargo_at?: string
  collect_cargo_at_pt_br?: string
  formatted_delivered_cargo_at?: string
  formatted_origin_coords?: Coords
  formatted_destination_coords?: Coords
  next_status: SelectOption | null
  next_status_quotation?: SelectOption
  previous_status_quotation?: SelectOption
  risk_analysis?: RiskAnalysisType[]
  origin_city: string
  destination_city: string
  formatted_suggested_price: string
  formatted_agreed_price: string
  formatted_suggested_price_type: string
  formatted_vehicle: string
  formatted_round_trip: string
  formatted_has_toll: string
  created_at_hour: string
  cargo_value: string
  cargo_dimensions: string
  cargo_weight: string
  is_cargo_complement: string
  cargo_require_tracker: string
  formatted_denied_at?: string
  formatted_finished_at?: string
  link_motorist_at_hour?: string
  collect_cargo_at_hour?: string
  deliver_cargo_at_hour?: string
  has_monitoring_whatsapp?: boolean
  has_monitoring_tracker_devices?: boolean
}

export type GetFreightsResponse = {
  total: number
  data: FormattedAllFreights[]
}

export type ManyStatus = Array<
  | 'pending'
  | 'in_origin'
  | 'collecting'
  | 'ready'
  | 'on_road'
  | 'in_destination'
  | 'delivered'
  | 'finished'
  | 'disabled'
>

export interface FiltersFreightsParams {
  origin_id?: number
  destination_id?: number
  is_no_show?: boolean
  status?: 'active' | 'current' | 'disabled' | 'finished' | string[] | string
  many_status?: ManyStatus
  sector?: string
  page?: number
  per_page?: number
  freight_number?: number | string
  initial_date?: Date | string
  final_date?: Date | string
  collect_initial_date?: string
  collect_final_date?: string
  client_id?: string
  client_contact_id?: string[]
  user_id?: string
  seller_id?: string
  motorist_id?: string
  cte_number?: string
  place_vehicle?: string
  has_monitoring_app?: boolean
  has_monitoring_buonny?: boolean
  has_monitoring_whatsapp?: boolean
  has_monitoring_tracker_devices?: boolean
  onlyWithoutCtes?: boolean
  unpaged?: boolean
  no_relations?: boolean
}

export function getStatus(status: string | undefined): string {
  if (!status) return '--'
  if (status === 'pending') return 'Aberto'
  if (status === 'in_origin') return 'Contratado'
  if (status === 'collecting') return 'Saiu para coleta'
  if (status === 'ready') return 'Chegou para carregar'
  if (status === 'on_road') return 'Começou a viagem'
  if (status === 'in_destination') return 'Chegou para descarregar'
  if (status === 'delivered') return 'Entrega Finalizada'
  if (status === 'finished') return 'Finalizado'
  return '--'
}

export function getNextStatus(status: string): SelectOption | null {
  const result = {
    label: '',
    value: '',
  } as SelectOption

  if (status === 'in_origin') {
    result.label = 'Contratado'
    result.value = 'collecting'
  }
  if (status === 'collecting') {
    result.label = 'Chegou para carregar'
    result.value = 'ready'
  }
  if (status === 'ready') {
    result.label = 'Começou a viagem'
    result.value = 'on_road'
  }
  if (status === 'on_road') {
    result.label = 'Chegou para descarregar'
    result.value = 'in_destination'
  }
  if (status === 'in_destination') {
    result.label = 'Entrega Finalizada'
    result.value = 'delivered'
  }
  if (status === 'delivered') {
    result.label = 'Entrega finalizada'
    result.value = 'finished'
  }
  if (status === 'finished') {
    return null
  }

  return result
}

export const freightStatusInProgressOptions: SelectOption[] = [
  { label: 'Contratado', value: 'in_origin' },
  { label: 'Saiu para coleta', value: 'collecting' },
  { label: 'Chegou para carregar', value: 'ready' },
  { label: 'Começou a viagem', value: 'on_road' },
  { label: 'Chegou para descarregar', value: 'in_destination' },
  { label: 'Entrega Finalizada', value: 'delivered' },
  { label: 'Finalizado', value: 'finished' },
]

export function getPriceType(type?: string): string {
  if (type === 'full') return 'Cheio'
  if (type === 'per_ton') return 'Por tonelada'
  if (type === 'per_kilometer') return 'Por kilômetro'
  if (type === 'to_match') return 'A combinar'
  return '--'
}

export function getType(type: string): string {
  if (type === 'terrestrial') return 'Terrestre'
  if (type === 'aerial') return 'Aéreo'
  if (type === 'hotline') return 'Hotline'
  return '--'
}

export function getStatusTabs(status: string): string | string[] {
  let parsedStatus: string | string[] = ''

  switch (status) {
    case 'active':
      parsedStatus = 'pending'
      break
    case 'started':
      parsedStatus = ['in_origin', 'collecting', 'ready']
      break
    case 'current':
      parsedStatus = ['in_origin', 'collecting', 'ready', 'on_road', 'in_destination', 'delivered']
      break
    case 'disabled':
      parsedStatus = 'disabled'
      break
    case 'finished':
      parsedStatus = 'finished'
      break
    case 'on_road':
      parsedStatus = 'on_road'
      break
    case 'in_destination':
      parsedStatus = ['in_destination', 'delivered']
      break
    case 'delivered':
      parsedStatus = 'delivered'
      break
    default:
      break
  }

  return parsedStatus
}

export async function fetchAllFreights({
  origin_id,
  destination_id,
  is_no_show,
  freight_number,
  status,
  many_status,
  sector,
  page = 1,
  per_page = 5,
  initial_date,
  final_date,
  client_id,
  client_contact_id,
  user_id,
  seller_id,
  motorist_id,
  cte_number,
  place_vehicle,
  has_monitoring_app,
  has_monitoring_buonny,
  has_monitoring_whatsapp,
  has_monitoring_tracker_devices,
  onlyWithoutCtes,
  unpaged,
  no_relations,
  collect_initial_date,
  collect_final_date,
}: FiltersFreightsParams): Promise<GetFreightsResponse> {
  let parsedStatus: string | string[] | undefined

  if (status && typeof status === 'string') {
    parsedStatus = getStatusTabs(status)
  }

  const { data } = await apiServer.get<ResponseListApiType<FormattedAllFreights[]>>('list-freight', {
    params: {
      origin_id,
      destination_id,
      is_no_show,
      freight_number,
      status: parsedStatus,
      many_status,
      sector,
      page,
      per_page,
      initial_date,
      final_date,
      client_id,
      client_contact_id,
      user_id,
      seller_id,
      motorist_id,
      cte_number,
      place_vehicle,
      has_monitoring_app,
      has_monitoring_buonny,
      has_monitoring_whatsapp,
      has_monitoring_tracker_devices,
      onlyWithoutCtes,
      unpaged,
      no_relations,
      collect_initial_date,
      collect_final_date,
    },
  })

  const { total } = data.data

  const freights = data.data.data.map(freight => {
    const createdAt = new Date(freight.created_at)

    let link_motorist_at_hour
    let collect_cargo_at_hour
    let deliver_cargo_at_hour
    let formatted_link_motorist_at
    let formatted_received_at
    let formatted_finished_at
    let collect_cargo_at_pt_br
    let input_collect_cargo_at
    let formatted_delivered_cargo_at
    let formatted_denied_at

    if (freight.finished_at) {
      const finishedAt = new Date(String(freight.finished_at))
      formatted_finished_at = format(finishedAt, 'dd/MM/yyyy HH:mm')
    }

    if (freight.denied_at) {
      const disabledAt = new Date(String(freight.denied_at))
      formatted_denied_at = format(disabledAt, 'dd/MM/yyyy HH:mm')
    }

    if (freight.link_motorist_at) {
      const linkMotoristAt = new Date(freight.link_motorist_at)
      formatted_link_motorist_at = format(linkMotoristAt, "yyyy-MM-dd'T'HH:mm:ss")
      link_motorist_at_hour = format(linkMotoristAt, 'HH:mm:ss')
    }

    if (freight.collect_cargo_at) {
      const collectItemAt = new Date(freight.collect_cargo_at)
      input_collect_cargo_at = format(collectItemAt, "yyyy-MM-dd'T'HH:mm:ss")
      collect_cargo_at_pt_br = format(collectItemAt, 'dd/MM/yy HH:mm')
      collect_cargo_at_hour = format(collectItemAt, 'HH:mm:ss')
    }

    if (freight.delivered_cargo_at) {
      const deliverItemAt = new Date(freight.delivered_cargo_at)
      formatted_delivered_cargo_at = format(deliverItemAt, "yyyy-MM-dd'T'HH:mm:ss")
      deliver_cargo_at_hour = format(deliverItemAt, 'HH:mm:ss')
    }

    if (freight.received_at) {
      const receivedItemAt = new Date(freight.received_at)
      formatted_received_at = format(receivedItemAt, "yyyy-MM-dd'T'HH:mm:ss")
    }

    return {
      ...freight,
      formatted_status: getStatus(freight.status),
      origin_city: `${freight.origin.name} - ${freight.origin.state.uf}`,
      destination_city: `${freight.destination.name} - ${freight.destination.state.uf}`,
      formatted_suggested_price: parseCurrency(freight.suggested_price),
      formatted_agreed_price: parseCurrency(freight.agreed_price),
      formatted_vehicle: `${freight.vehicle?.vehicle_category?.name} / ${freight.vehicle?.vehicle_body?.name}`,
      created_at: format(createdAt, 'dd/MM'),
      formatted_denied_at,
      created_at_hour: format(createdAt, 'HH:mm'),
      link_motorist_at_hour,
      collect_cargo_at_hour,
      deliver_cargo_at_hour,
      formatted_link_motorist_at,
      input_collect_cargo_at,
      collect_cargo_at_pt_br,
      formatted_delivered_cargo_at,
      formatted_received_at,
      formatted_finished_at,
    }
  })
  return { total, data: freights }
}

export function useGetFreights(params: FiltersFreightsParams): UseQueryResult<GetFreightsResponse> {
  return useQuery(['freights', params], () => fetchAllFreights(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
