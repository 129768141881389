import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InputMask } from '../../../components/form/InputMask'
import { Select } from '../../../components/form/Select'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../../services/api'
import { usePostBlackList } from '../../../services/endpoints/black-list/postBlackList'
import { ResponseApiType } from '../../../services/types'
import BlackListType, {
  CheckDocumentAndIsBlackListedExists,
  CheckDocumentEntityType,
} from '../../../services/types/BlackListType'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'
import { validateCpf } from '../../../services/utils/validateCpf'

interface IncludeInBlackListByCpfModalProps {
  isOpen: boolean
  onClose: () => void
}

interface IncludeInBlackListForm {
  license_plate: string
  reason: string
  owner_cpf: string
  motorist_cpf: string
  cnpj: string
}

const formSchema = yup.object().shape({
  license_plate: yup.string().min(7, 'Placa incompleta'),
  owner_cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (value) return validateCpf(value)
    return true
  }),
  motorist_cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (value) return validateCpf(value)
    return true
  }),
  cnpj: yup.string().min(14, 'CNPJ incompleto'),
})

export function IncludeInBlackListByDocumentModal({
  isOpen,
  onClose,
}: IncludeInBlackListByCpfModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  })
  const { errors } = formState
  const CpfRef = useRef<HTMLInputElement>(null)
  const licensePlateRef = useRef<HTMLInputElement>(null)
  const CnpjRef = useRef<HTMLInputElement>(null)
  const [reason, setReason] = useState<string>('')
  const [entityType, setEntityType] = useState<string>()
  const [docType, setDocType] = useState<string>()
  const [motoristExists, setMotoristExists] = useState<CheckDocumentEntityType>()
  const [ownerExists, setOwnerExists] = useState<CheckDocumentEntityType>()
  const [vehicleExists, setVehicleExists] = useState<CheckDocumentEntityType>()
  const [isCPFInBlacklist, setIsCPFInBlacklist] = useState<BlackListType | boolean>()
  const [isCNPJInBlacklist, setIsCNPJInBlacklist] = useState<BlackListType | boolean>()
  const [isLicensePlateInBlacklist, setIsLicensePlateInBlacklist] = useState<BlackListType | boolean>()

  async function checkIfCpfIsBlackListed() {
    if (CpfRef.current) {
      const { value } = CpfRef.current

      const {
        data: { data: blackList },
      } = await apiServer.get<{ data: CheckDocumentAndIsBlackListedExists }>(`/find-black-list`, {
        params: {
          cpf: value,
        },
      })
      setMotoristExists(blackList.motorist)
      setOwnerExists(blackList.owner)
      if (blackList.isCPFInBlacklist) {
        setIsCPFInBlacklist(blackList.isCPFInBlacklist)
      } else {
        setIsCPFInBlacklist(false)
      }
    }
  }

  async function checkIfCnpjIsBlackListed() {
    if (CnpjRef.current) {
      const { value } = CnpjRef.current

      const { data: cnpjData } = await apiServer.get<ResponseApiType<CheckDocumentAndIsBlackListedExists>>(
        `/find-black-list`,
        {
          params: { cnpj: getOnlyNumbers(value) },
        },
      )

      setOwnerExists(cnpjData.data?.owner || undefined)
      setIsCNPJInBlacklist(cnpjData.data?.isCnpjInBlacklist || false)
    }
  }

  async function checkIfLicensePlateIsBlackListed() {
    if (licensePlateRef.current) {
      const { value } = licensePlateRef.current

      const {
        data: { data: licensePlate },
      } = await apiServer.get<{ data: CheckDocumentAndIsBlackListedExists }>(`/find-black-list`, {
        params: {
          license_plate: value,
        },
      })

      if (licensePlate?.vehicle) {
        setVehicleExists(licensePlate.vehicle)
      }

      if (licensePlate?.isLicensePlateInBlacklist) {
        setIsLicensePlateInBlacklist(licensePlate.isLicensePlateInBlacklist)
      } else {
        setIsLicensePlateInBlacklist(false)
      }
    }
  }

  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingPost } = usePostBlackList({
    onSuccess: () => {
      queryClient.invalidateQueries('black-list')
      queryClient.invalidateQueries('motorists')
      queryClient.invalidateQueries('compatible_motorists')
    },
  })

  const handleIncludeBlackList: SubmitHandler<IncludeInBlackListForm> = async (): Promise<void> => {
    try {
      if (motoristExists) {
        await mutateAsyncPost({
          reason,
          entity_id: motoristExists?.id,
          entity_type: motoristExists?.entity_type,
          cpf: motoristExists?.cpf,
        })
        setOwnerExists(undefined)
      }

      if (ownerExists) {
        await mutateAsyncPost({
          reason,
          entity_id: ownerExists?.id,
          entity_type: ownerExists?.entity_type,
          cpf: ownerExists?.cpf,
          cnpj: ownerExists?.cnpj,
        })
        setOwnerExists(undefined)
      }

      if (vehicleExists) {
        await mutateAsyncPost({
          reason,
          entity_id: vehicleExists?.id,
          entity_type: vehicleExists?.entity_type,
          license_plate: vehicleExists?.license_plate,
        })
        setVehicleExists(undefined)
      }

      if (!motoristExists && !ownerExists && !vehicleExists) {
        if (isCPFInBlacklist === false && CpfRef.current?.value) {
          await mutateAsyncPost({
            reason,
            entity_type: entityType as 'motorist' | 'owner' | 'vehicle',
            cpf: CpfRef.current.value,
          })
        }

        if (isCNPJInBlacklist === false && CnpjRef.current?.value) {
          await mutateAsyncPost({
            reason,
            entity_type: entityType as 'motorist' | 'owner' | 'vehicle',
            cnpj: CnpjRef.current.value,
          })
        }

        if (isLicensePlateInBlacklist === false && licensePlateRef.current?.value) {
          await mutateAsyncPost({
            reason,
            entity_type: entityType as 'motorist' | 'owner' | 'vehicle',
            license_plate: licensePlateRef.current.value,
          })
        }
      }

      toast({
        status: 'success',
        title: 'Incluído na lista de restrição com sucesso!',
        position: 'top-right',
        isClosable: true,
      })
      onClose()
    } catch (err) {
      const error = err as AxiosError
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setOwnerExists(undefined)
    setVehicleExists(undefined)
    setMotoristExists(undefined)
    setIsCPFInBlacklist(undefined)
    setIsCNPJInBlacklist(undefined)
    setIsLicensePlateInBlacklist(undefined)
    setReason('')
    setEntityType('')
    setDocType('')
  }, [isOpen])

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleIncludeBlackList)} noValidate>
            <ModalHeader fontSize="x-large">Incluir na Lista de restrição</ModalHeader>

            <ModalCloseButton />

            <ModalBody pb={6} gridGap={2}>
              {isCPFInBlacklist && (
                <Alert status="error" mb="4" borderRadius="4">
                  <AlertIcon />
                  <Box>
                    O CPF digitado já está na <strong>lista de restrição</strong>.
                  </Box>
                </Alert>
              )}
              {isCNPJInBlacklist && (
                <Alert status="error" mb="4" borderRadius="4">
                  <AlertIcon />
                  <Box>
                    O CNPJ digitado já está na <strong>lista de restrição</strong>.
                  </Box>
                </Alert>
              )}
              {isLicensePlateInBlacklist && (
                <Alert status="error" mb="4" borderRadius="4">
                  <AlertIcon />
                  <Box>
                    A placa digitada já está na <strong>lista de restrição</strong>.
                  </Box>
                </Alert>
              )}
              <SimpleGrid minChildWidth="300px" spacing={3}>
                <Select
                  isRequired
                  label="Tipo"
                  name="entity_type"
                  error={errors.entity_type}
                  setValue={setValue}
                  onSelectOption={option => {
                    setEntityType(option.value)
                    setValue('cnpj', undefined)
                    setValue('owner_cpf', undefined)
                    setValue('motorist_cpf', undefined)
                    setValue('license_plate', undefined)
                    setIsLicensePlateInBlacklist(undefined)
                    setIsCNPJInBlacklist(undefined)
                    setIsCPFInBlacklist(undefined)
                    setOwnerExists(undefined)
                    setVehicleExists(undefined)
                    setMotoristExists(undefined)
                  }}
                  options={[
                    { label: '', value: '' },
                    { label: 'Motorista', value: 'motorist' },
                    { label: 'Proprietário', value: 'owner' },
                    { label: 'Veículo', value: 'vehicle' },
                  ]}
                />
                {entityType === 'vehicle' && (
                  <InputMask
                    label="Informe a placa"
                    name="license_plate"
                    mask="aaa9*99"
                    placeholder="Placa"
                    maskPlaceholder=""
                    error={errors.license_plate}
                    setValue={setValue}
                    uppercaseAll
                    isRequired
                    ref={licensePlateRef}
                    onChange={e => {
                      if (e.target.value.length === 7) {
                        checkIfLicensePlateIsBlackListed()
                      } else {
                        setIsLicensePlateInBlacklist(undefined)
                      }
                    }}
                    onBlur={checkIfLicensePlateIsBlackListed}
                  />
                )}

                {entityType === 'owner' && (
                  <>
                    <Select
                      isRequired
                      label="Tipo de documento"
                      name="doc_type"
                      error={errors.doc_type}
                      setValue={setValue}
                      onSelectOption={option => {
                        setDocType(option.value)
                        setValue('license_plate', undefined)
                        setValue('owner_cpf', undefined)
                        setValue('motorist_cpf', undefined)
                        setValue('cnpj', undefined)
                        setIsLicensePlateInBlacklist(undefined)
                        setIsCNPJInBlacklist(undefined)
                        setIsCPFInBlacklist(undefined)
                        setOwnerExists(undefined)
                        setVehicleExists(undefined)
                        setMotoristExists(undefined)
                      }}
                      options={[
                        { label: '', value: '' },
                        { label: 'CNPJ', value: 'cnpj' },
                        { label: 'CPF', value: 'cpf' },
                      ]}
                    />
                    {docType === 'cnpj' ? (
                      <InputMask
                        isRequired
                        label="Informe o CNPJ"
                        name="cnpj"
                        placeholder="CNPJ"
                        mask="99.999.999/9999-99"
                        maskPlaceholder=""
                        ref={CnpjRef}
                        error={errors.cnpj}
                        setValue={setValue}
                        onChange={e => {
                          if (e.target.value.length === 18) {
                            checkIfCnpjIsBlackListed()
                          } else {
                            setIsCNPJInBlacklist(undefined)
                          }
                        }}
                        onBlur={checkIfCnpjIsBlackListed}
                        registerOnlyNumbers
                      />
                    ) : (
                      docType === 'cpf' && (
                        <InputMask
                          isRequired
                          ref={CpfRef}
                          autoFocus
                          name="owner_cpf"
                          label="Informe o CPF"
                          placeholder="CPF"
                          mask="999.999.999-99"
                          maskPlaceholder=""
                          error={errors.owner_cpf}
                          setValue={setValue}
                          onChange={e => {
                            if (e.target.value.length === 14) {
                              checkIfCpfIsBlackListed()
                            } else {
                              setIsCPFInBlacklist(undefined)
                            }
                          }}
                          onBlur={checkIfCpfIsBlackListed}
                          registerOnlyNumbers
                        />
                      )
                    )}
                  </>
                )}
                {entityType === 'motorist' && (
                  <InputMask
                    isRequired
                    ref={CpfRef}
                    autoFocus
                    name="motorist_cpf"
                    label="Informe o CPF"
                    placeholder="CPF"
                    mask="999.999.999-99"
                    maskPlaceholder=""
                    error={errors.motorist_cpf}
                    setValue={setValue}
                    onChange={e => {
                      if (e.target.value.length === 14) {
                        checkIfCpfIsBlackListed()
                      } else {
                        setIsCPFInBlacklist(undefined)
                      }
                    }}
                    onBlur={checkIfCpfIsBlackListed}
                    registerOnlyNumbers
                  />
                )}

                <FormControl isRequired>
                  <FormLabel>Informe o motivo</FormLabel>
                  <Textarea
                    isDisabled={!entityType}
                    placeholder="Descreva o motivo..."
                    onChange={e => setReason(e.target.value)}
                  />
                </FormControl>
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="red"
                size="sm"
                h="7"
                mr="3"
                onClick={() => {
                  onClose()
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                size="sm"
                h="7"
                mr="3"
                type="submit"
                isDisabled={
                  !!isCPFInBlacklist ||
                  !!isCNPJInBlacklist ||
                  !!isLicensePlateInBlacklist ||
                  reason.length <= 0
                }
                isLoading={isLoadingPost}
              >
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
