import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../components/form'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { spotHubModule } from './spotHubModule'

export interface Actions {
  module: string
  actions: string[]
  roles_options?: AutocompleteOption[]
}

export interface RolesOptions {
  roles_options?: AutocompleteOption[]
}

type Input = { module?: string; only_roles_options: boolean }
type Output = Actions[] | RolesOptions[]
export async function getActions(params?: Input): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output>>('/authorization/actions', { params })
  data.data.push(spotHubModule as any)
  return data.data
}

export function useGetActions(params?: Input): UseQueryResult<Output, unknown> {
  return useQuery(['getActions', params], () => getActions(params), {
    refetchOnWindowFocus: false,
  })
}
