import { Box, Button, Center } from '@chakra-ui/react'
import React, { useCallback, useRef, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'
import MapComponent from '../../../components/GoogleMapComponents/MapComponent'
import { FormattedOneFreight } from '../../../services/endpoints/freights'

type MapFreightProps = {
  setOneFreightId: React.Dispatch<React.SetStateAction<string>>
  oneFreight?: FormattedOneFreight
  loadingOneFreight: boolean
}

const MapFreight = ({ setOneFreightId, oneFreight, loadingOneFreight }: MapFreightProps): JSX.Element => {
  const [height, setHeight] = useState(400)
  const handleRef = useRef(null)

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      const startY = e.clientY
      const startHeight = height

      const onMouseMove = (mouseMoveEvent: MouseEvent) => {
        const newHeight = startHeight + (mouseMoveEvent.clientY - startY)
        if (newHeight < 10) {
          setHeight(400)
          setOneFreightId('')
          return
        }

        setHeight(newHeight)
      }

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
    [height, setHeight, setOneFreightId],
  )
  return (
    <>
      <Box
        height={height}
        bg="gray.200"
        borderRadius="lg"
        overflow="hidden"
        position="relative"
        display={oneFreight ? 'flex' : 'none'}
      >
        {loadingOneFreight === false && oneFreight && (
          <Box
            bg="gray.200"
            flex={1}
            blackAlpha
            borderRadius="lg"
            overflow="hidden"
            minHeight="100%"
            maxHeight="100%"
            position="relative"
            resize="vertical"
          >
            <Button
              position="absolute"
              top="10px"
              right="58px"
              zIndex="10"
              size="md"
              bg="white"
              color="gray.500"
              px="0"
              onClick={() => {
                setOneFreightId('')
              }}
            >
              <IoMdClose />
            </Button>
            <MapComponent
              freight={oneFreight}
              type={
                oneFreight?.has_monitoring_buonny
                  ? 'buonny'
                  : !oneFreight?.has_monitoring_buonny && oneFreight?.has_monitoring_whatsapp
                  ? 'whatsapp'
                  : !oneFreight?.has_monitoring_buonny && !oneFreight?.has_monitoring_whatsapp
                  ? 'app'
                  : 'app'
              }
            />
          </Box>
        )}
      </Box>
      <Center
        display={oneFreight ? 'flex' : 'none'}
        ref={handleRef}
        className="handle"
        onMouseDown={handleMouseDown}
        cursor="ns-resize"
        color="gray.500"
        my="4px"
      >
        <BsThreeDots />
      </Center>
    </>
  )
}

export default MapFreight
