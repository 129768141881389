import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiArrowLeftLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Layout } from '../../../layout'
import { apiServer } from '../../../services/api'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { useAppSelector } from '../../../store'
import { TrailerCompleteForm } from '../form-vehicles/TrailerCompleteForm'
import { VehicleCompleteForm } from '../form-vehicles/VehicleCompleteForm'
import { InfoVehicleExists } from '../info-vehicle-exists/InfoVehicleExists'

export function CreateVehicle(): JSX.Element {
  const {
    trailerCreated,
    vehicleFound,
    hasTrailer,
    vehicleId: idVehicle,
  } = useAppSelector(state => state.vehicleSlice)
  const freight_id = useQueryParams('freight_id')
  const motorist_id = useQueryParams('motorist_id')
  const vehicle_id = useQueryParams('vehicle_id')
  const owner_id = useQueryParams('owner_id')
  const [tabIndex, setTabIndex] = useState(0)
  const bg = useColorModeValue('white', 'gray.800')
  const toast = useToast()
  const history = useHistory()
  let vehicleId = vehicle_id
  if (idVehicle) vehicleId = idVehicle
  const { data: vehicleCreated } = useGetVehicle(vehicleId)
  async function handleAttachVehicleToMotorist(id_motorist: string, id_vehicle: string) {
    try {
      await apiServer.post('/motorist-attach-vehicle', {
        motorist_id: id_motorist,
        vehicle_id: id_vehicle,
      })
      toast({
        title: 'Veículo vinculado ao motorista com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  async function handleLinkVehicleToOwner(id_owner: string, id_vehicle: string) {
    try {
      await apiServer.put(`/update-vehicle/${id_vehicle}`, {
        owner_id: id_owner,
      })
      toast({
        title: 'Veículo vinculado ao proprietário com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    if (freight_id && motorist_id && vehicleCreated) {
      window.location.href = `/freights/show/${freight_id}/pendencies?motorist_id=${motorist_id}&vehicle_id=${vehicleCreated.id}&integration=buonny`
    }
  }, [freight_id, history, motorist_id, vehicleCreated])

  return (
    <Layout>
      <Box p="8" bg={bg}>
        <Flex
          justifyContent="space-between"
          alignContent="center"
          gridGap={4}
          direction={['column', 'column', 'row']}
        >
          <Heading fontSize="x-large">Cadastro de veículos</Heading>

          {motorist_id && (
            <Button
              colorScheme="blue"
              leftIcon={<Icon as={RiArrowLeftLine} fontSize="x-large" />}
              onClick={() => {
                history.push(`/motorists/show/${motorist_id}`)
              }}
            >
              Voltar para página do motorista
            </Button>
          )}
          {owner_id && (
            <Button
              colorScheme="blue"
              leftIcon={<Icon as={RiArrowLeftLine} fontSize="x-large" />}
              onClick={() => {
                history.push(`/owners/show/${owner_id}`)
              }}
            >
              Voltar para página do propriétário
            </Button>
          )}
        </Flex>

        <Divider my="6" />

        {vehicleFound && <InfoVehicleExists motorist_id={motorist_id} vehicle_id={vehicleFound.id} />}
        {motorist_id && vehicleFound && (
          <Box p="6">
            <Divider my="4" />
            <Stack direction={['column', 'row']} gridGap={4}>
              <Button
                onClick={() => {
                  handleAttachVehicleToMotorist(motorist_id, vehicleFound.id)
                  if (vehicleFound.owner_id) {
                    history.push(`/motorists/show/${motorist_id}`)
                  }
                }}
                colorScheme="green"
              >
                + Vincular ao motorista
              </Button>
            </Stack>
          </Box>
        )}

        {owner_id && vehicleFound && (
          <>
            <Divider my="4" />
            <Button
              onClick={() => {
                if (owner_id && vehicleFound) {
                  handleLinkVehicleToOwner(owner_id, String(vehicleFound.id))
                  history.push(`/owners/show/${owner_id}`)
                }
              }}
              colorScheme="green"
            >
              + Vincular ao proprietário
            </Button>
          </>
        )}

        {!vehicleFound && (
          <Tabs
            index={tabIndex}
            variant="enclosed-colored"
            isFitted
            colorScheme="orange"
            onChange={setTabIndex}
          >
            <TabList d={['table', 'table', 'flex']} overflowX="auto" overflowY="hidden">
              {hasTrailer && (
                <>
                  <Tab
                    color={vehicleCreated ? 'green' : 'gray'}
                    cursor="pointer"
                    _disabled={{ opacity: 0.7 }}
                  >
                    {vehicleCreated && <Icon mr="1" as={RiCheckboxCircleFill} />}
                    Veículo
                  </Tab>
                  <Tab
                    color={trailerCreated ? 'green' : 'gray'}
                    cursor={vehicleCreated ? 'pointer' : 'not-allowed'}
                    isDisabled={!vehicleCreated}
                    _disabled={{ opacity: 0.7 }}
                  >
                    {trailerCreated && <Icon mr="1" as={RiCheckboxCircleFill} />}
                    Reboque
                  </Tab>
                </>
              )}
            </TabList>
            <TabPanels>
              <TabPanel p="0" pt="6">
                <VehicleCompleteForm
                  freight_id={freight_id}
                  vehicle={vehicleFound || vehicleCreated}
                  trailer={vehicleCreated?.trailers[0]}
                  owner_id={owner_id}
                  refetch={() => {
                    queryClient.invalidateQueries('motorist')
                    queryClient.invalidateQueries('vehicle')
                  }}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              {hasTrailer && vehicleCreated && (
                <TabPanel p="0" pt="6">
                  <TrailerCompleteForm
                    vehicle={vehicleCreated}
                    trailer={vehicleCreated.trailers[0]}
                    refetch={() => {
                      queryClient.invalidateQueries('motorist')
                      queryClient.invalidateQueries('vehicle')
                      queryClient.invalidateQueries('trailer')
                    }}
                    setTabIndex={setTabIndex}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </Layout>
  )
}
