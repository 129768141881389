import { Grid, GridItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import * as yup from 'yup'
import { Autocomplete } from '../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { Select } from '../../components/form/Select'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { MotoristType } from '../../services/types'
import OwnerType from '../../services/types/OwnerType'
import { bankList } from '../../services/utils/bankList'
import { formatDateForInitialValue } from '../../services/utils/dates/formatDateForInitialValue'
import { validateDate } from '../../services/utils/dates/isValidDate'
import { CEP, searchAddressByCep } from '../../services/utils/searchAddressByCep'
import { CnpjData, searchPersonByCnpj } from '../../services/utils/searchPersonByCnpj'
import { ufOptions } from '../../services/utils/ufOptions'
import { validateCpf } from '../../services/utils/validateCpf'

interface OwnerFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialData?: OwnerType
  handleCheckMotoristExists?: () => void
  motoristExists?: MotoristType
  motoristPhoneRef?: React.RefObject<HTMLInputElement>
}

export function OwnerForm({
  setValue,
  formState,
  initialData,
  handleCheckMotoristExists,
  motoristExists,
  motoristPhoneRef,
}: OwnerFormProps): JSX.Element {
  const { errors } = formState
  const [typePerson, setTypePerson] = useState(initialData?.type)
  const [addressByCep, setAddressByCep] = useState<CEP>()
  const [addressByCNPJ, setAddressByCNPJ] = useState<CnpjData>()

  useEffect(() => {
    setTypePerson(initialData?.type)
  }, [initialData?.type])

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="4" mb="6">
      <GridItem colSpan={[12, 3]}>
        <Select
          isRequired
          name="type"
          label="Tipo"
          error={errors.type}
          setValue={setValue}
          initialValue={initialData?.type}
          onSelectOption={option => {
            if (option.value === 'pj') {
              setTypePerson('pj')
            } else if (option.value === 'pf') {
              setTypePerson('pf')
            }
          }}
          options={[
            { label: '', value: '' },
            { label: 'Pessoa física', value: 'pf' },
            { label: 'Pessoa Jurídica', value: 'pj' },
          ]}
        />
      </GridItem>

      {typePerson === 'pj' && (
        <GridItem colSpan={[12, 3]}>
          <InputMask
            isRequired
            name="cnpj"
            mask="99.999.999/9999-99"
            maskPlaceholder=""
            label="CNPJ"
            onBlur={async e => {
              const cnpj = await searchPersonByCnpj(String(e.target.value))
              if (cnpj) {
                setAddressByCNPJ(cnpj)
              }
            }}
            onChange={async e => {
              if (e.target.value.length === 14) {
                const { value } = e.target
                const cnpj = await searchPersonByCnpj(value)
                if (cnpj) {
                  setAddressByCNPJ(cnpj)
                }
              }
            }}
            error={errors.cnpj}
            setValue={setValue}
            initialValue={(initialData?.cnpj && initialData.cnpj) || addressByCNPJ?.cnpj}
            registerOnlyNumbers
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 3]}>
        <InputMask
          isRequired
          ref={motoristPhoneRef}
          onBlur={() => {
            if (handleCheckMotoristExists) {
              handleCheckMotoristExists()
            }
          }}
          name="phone"
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          label="Telefone"
          error={errors.phone}
          setValue={setValue}
          initialValue={initialData?.phone || addressByCNPJ?.phone || motoristExists?.phone}
        />
      </GridItem>

      {typePerson === 'pf' && (
        <GridItem colSpan={[12, 3]}>
          <InputMask
            isRequired
            name="cpf"
            mask="999.999.999-99"
            maskPlaceholder=""
            label="CPF"
            error={errors.cpf}
            setValue={setValue}
            initialValue={initialData?.cpf ? initialData?.cpf || motoristExists?.cpf : undefined}
            registerOnlyNumbers
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 2]}>
        <InputMask
          mask="99999999"
          maskPlaceholder=""
          registerOnlyNumbers
          isRequired
          name="rntrc"
          label="RNTRC"
          error={errors.rntrc}
          setValue={setValue}
          initialValue={initialData?.rntrc}
        />
      </GridItem>

      <GridItem colSpan={[12, 2]}>
        <Select
          name="rntrc_type"
          label="Tipo RNTRC"
          options={[
            { label: '', value: '' },
            { label: 'Equiparado', value: 'EQ' },
            { label: 'ETC', value: 'E' },
            { label: 'CTC', value: 'C' },
            { label: 'TAC', value: 'T' },
          ]}
          setValue={setValue}
          error={errors.rntrc_type}
          initialValue={initialData?.rntrc_type}
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Input
          isRequired
          name="name"
          label={typePerson === 'pj' ? 'Razão social' : 'Nome completo'}
          error={errors.name}
          setValue={setValue}
          initialValue={initialData?.name || addressByCNPJ?.name || motoristExists?.name}
          uppercaseFirst
        />
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <InputMask
          name="other_phone"
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          label="Telefone secundário"
          error={errors.other_phone}
          setValue={setValue}
          initialValue={initialData?.other_phone || motoristExists?.other_phone}
        />
      </GridItem>
      {typePerson === 'pj' && (
        <GridItem colSpan={[12, 3]}>
          <Input
            isRequired
            name="ie"
            label="IE (Inscrição estadual)"
            error={errors.ie}
            setValue={setValue}
            initialValue={initialData?.ie}
          />
        </GridItem>
      )}
      <GridItem colSpan={[12, 3]}>
        <InputMask
          name="zipcode"
          mask="99.999-999"
          registerOnlyNumbers
          maskPlaceholder=""
          label="CEP"
          error={errors.zipcode}
          setValue={setValue}
          onBlur={async e => {
            const cep = await searchAddressByCep(String(e.target.value))
            if (cep) {
              setAddressByCep(cep)
            }
          }}
          initialValue={addressByCNPJ?.zipcode || initialData?.zipcode || motoristExists?.zipcode}
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Input
          name="address"
          label="Logradouro"
          error={errors.address}
          setValue={setValue}
          initialValue={
            addressByCep?.address || addressByCNPJ?.address || initialData?.address || motoristExists?.address
          }
          uppercaseFirst
        />
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Input
          name="district"
          label="Bairro"
          error={errors.district}
          setValue={setValue}
          initialValue={
            addressByCep?.district ||
            addressByCNPJ?.district ||
            initialData?.district ||
            motoristExists?.district
          }
          uppercaseFirst
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <AutocompleteAsync
          name="city_id"
          label="Cidade"
          error={errors.city_id}
          setValue={setValue}
          initialValue={
            addressByCep?.city ||
            addressByCNPJ?.city ||
            (initialData?.city_id && {
              label: String(initialData?.city?.name),
              value: String(initialData?.city?.ibge_id),
            }) ||
            undefined
          }
          loadOptions={searchCitiesByName}
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Input
          name="address_number"
          type="number"
          label="Número"
          error={errors.address_number}
          setValue={setValue}
          initialValue={
            initialData?.address_number || addressByCNPJ?.address_number || motoristExists?.address_number
          }
        />
      </GridItem>
      {typePerson === 'pf' && (
        <>
          <GridItem colSpan={[12, 6]}>
            <Input
              isRequired
              name="father_name"
              label="Nome do pai"
              error={errors.father_name}
              setValue={setValue}
              initialValue={initialData?.father_name}
              uppercaseFirst
            />
          </GridItem>

          <GridItem colSpan={[12, 6]}>
            <Input
              isRequired
              name="mother_name"
              label="Nome da mãe"
              error={errors.mother_name}
              setValue={setValue}
              initialValue={initialData?.mother_name}
              uppercaseFirst
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <Input
              isRequired
              name="birth"
              type="date"
              label="Data de nascimento"
              error={errors.birth}
              setValue={setValue}
              initialValue={
                initialData?.birth
                  ? formatDateForInitialValue(initialData?.birth) ||
                    formatDateForInitialValue(motoristExists?.birth)
                  : undefined
              }
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <Input
              isRequired
              name="rg"
              label="RG"
              error={errors.rg}
              setValue={setValue}
              initialValue={initialData?.rg || motoristExists?.rg}
            />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <Select
              options={ufOptions}
              isRequired
              name="rg_uf"
              label="RG UF"
              error={errors.rg_uf}
              setValue={setValue}
              initialValue={initialData?.rg_uf ? initialData?.rg_uf || motoristExists?.rg_uf : undefined}
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <Input
              isRequired
              name="rg_dispatcher"
              label="Órgão expeditor"
              error={errors.rg_dispatcher}
              setValue={setValue}
              initialValue={
                initialData?.rg_dispatcher
                  ? initialData?.rg_dispatcher || motoristExists?.rg_dispatcher
                  : undefined
              }
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <Input
              isRequired
              name="rg_dispatch_date"
              type="date"
              label="Data de expedição"
              error={errors.rg_dispatch_date}
              setValue={setValue}
              initialValue={
                initialData?.rg_dispatch_date
                  ? initialData?.rg_dispatch_date || motoristExists?.rg_dispatch_date
                  : undefined
              }
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <InputMask
              isRequired
              mask="999.99999.99.9"
              name="pis"
              label="PIS"
              error={errors.pis}
              setValue={setValue}
              initialValue={initialData?.pis ? initialData?.pis || motoristExists?.pis : undefined}
              registerOnlyNumbers
            />
          </GridItem>
        </>
      )}
      <GridItem colSpan={[12, 3]}>
        <Autocomplete
          name="bank"
          label="Banco"
          error={errors.bank}
          setValue={setValue}
          initialValue={{
            label: initialData?.bank ? String(initialData?.bank) : '',
            value: '',
          }}
          options={[{ label: '', value: '' }, ...bankList]}
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Select
          name="account_type"
          label="Tipo de conta"
          error={errors.account_type}
          setValue={setValue}
          initialValue={initialData?.account_type}
          options={[
            { label: '', value: '' },
            { label: 'Corrente', value: 'current' },
            { label: 'Poupança', value: 'savings' },
            { label: 'Outros', value: 'others' },
          ]}
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Input
          name="agency"
          label="Agencia"
          error={errors.agency}
          setValue={setValue}
          initialValue={initialData?.agency}
        />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Input
          name="account"
          label="Conta"
          error={errors.account}
          setValue={setValue}
          initialValue={initialData?.account}
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Input
          name="email"
          label="E-mail"
          error={errors.email}
          setValue={setValue}
          initialValue={initialData?.email || addressByCNPJ?.email || motoristExists?.email}
        />
      </GridItem>
    </Grid>
  )
}

export const OwnerFormSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  email: yup.string(),
  cnpj: yup.string().when('type', (type: string) => {
    if (type === 'pj') {
      return yup.string().min(14, 'CNPJ inválido').required('Campo obrigatório')
    }
    return yup.string()
  }),
  cpf: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup
        .string()
        .test('validate-cpf', 'CPF inválido', value => validateCpf(value))
        .required('Campo obrigatório')
    }
    return yup.string()
  }),
  birth: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .when('type', (type: string) => {
      if (type === 'pf') {
        return yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório')
      }
      return yup.date()
    }),
  name: yup.string().required('Campo obrigatório'),
  rntrc: yup.string().required('Campo obrigatório'),
  rntrc_type: yup.string().equals(['E', 'C', 'EQ', 'T']).required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  other_phone: yup.string(),
  zipcode: yup.string(),
  address: yup.string(),
  address_number: yup.string(),
  district: yup.string(),
  city_id: yup.object().shape({}),
  state: yup.string(),
  ie: yup.string().when('type', (type: string) => {
    if (type === 'pj') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  mother_name: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  father_name: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg_uf: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg_dispatcher: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg_dispatch_date: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .when('type', (type: string) => {
      if (type === 'pf') {
        return yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório')
      }
      return yup.date()
    }),
  pis: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  bank: yup.object().shape({}).nullable(),
  account_type: yup.string(),
  agency: yup.string().min(5, 'Deve conter no mínimo 5 caracteres'),
  account: yup.string(),
})
