import { Grid, GridItem } from '@chakra-ui/react'
import { DeepMap, FieldError, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Ability } from '../../components/ability'
import { Autocomplete } from '../../components/form'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { Select } from '../../components/form/Select'
import { useAuth } from '../../contexts/AuthContext'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { getUserSales } from '../../services/endpoints/users/getUserSales'

interface FiltersCtesProps {
  getValues?: UseFormGetValues<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
  initialDate: string
  finalDate: string
}

export function FiltersCtes({
  setValue,
  errors,
  finalDate,
  initialDate,
  getValues,
}: FiltersCtesProps): JSX.Element {
  const { storageSeller, getSellerIdToListFreights, setSellerIdToListFreights } = useAuth()

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="3">
      <GridItem colSpan={[12, 12, 6]}>
        <Input
          label="CTe Key"
          name="key"
          setValue={setValue}
          initialValue={getValues?.('key')}
          error={errors.key}
          placeholder="Ex: xxxxx0055035xxxxx1785500000000659919637xxxxx"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="número da CTE"
          type="number"
          name="nct"
          setValue={setValue}
          initialValue={getValues?.('nct')}
          error={errors.nct}
          placeholder="número da CTE"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Série"
          type="number"
          name="serie"
          initialValue={getValues?.('serie')}
          setValue={setValue}
          error={errors.serie}
          placeholder="Série"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Data inicial"
          name="initial_date"
          type="date"
          error={errors.initial_date}
          initialValue={initialDate}
          setValue={setValue}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Data final"
          name="final_date"
          type="date"
          setValue={setValue}
          initialValue={finalDate}
          error={errors.final_date}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <AutocompleteAsync
          name="origin_name"
          label="Origem"
          setValue={setValue}
          loadOptions={searchCitiesByName}
        />
      </GridItem>
      <GridItem colSpan={[12, 6, 3]}>
        <AutocompleteAsync
          name="destination_name"
          label="Destino"
          setValue={setValue}
          loadOptions={searchCitiesByName}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Input
          label="Emitente"
          name="emitter"
          setValue={setValue}
          initialValue={getValues?.('emitter')}
          error={errors.emitter}
          placeholder="Emitente"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Input
          label="Tomador"
          name="taker"
          initialValue={getValues?.('taker')}
          setValue={setValue}
          error={errors.taker}
          placeholder="Tomador"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Input
          label="Emissor"
          name="operator"
          setValue={setValue}
          initialValue={getValues?.('operator')}
          error={errors.operator}
          placeholder="Emissor (operador)"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Select
          label="Tipo"
          name="type"
          initialValue={getValues?.('type') || ''}
          setValue={setValue}
          error={errors.type}
          options={[
            {
              label: '',
              value: '',
            },
            {
              label: 'Normal',
              value: 'Normal',
            },
            {
              label: 'Complemento',
              value: 'Complemento',
            },
            {
              label: 'Anulação',
              value: 'Anulação',
            },
            {
              label: 'Substituição',
              value: 'Substituição',
            },
          ]}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Select
          label="Status"
          name="status"
          setValue={setValue}
          initialValue={getValues?.('status') || ''}
          error={errors.status}
          options={[
            {
              label: '',
              value: '',
            },
            {
              label: 'Autorização',
              value: 'Autorizado',
            },
            {
              label: 'Cancelamento',
              value: 'Cancelamento',
            },
            {
              label: 'Carta de Correção',
              value: 'Carta Correcao',
            },
          ]}
        />
      </GridItem>

      <Ability module="spotHub" action="show-filter-seller">
        <GridItem colSpan={[12, 6, 3]}>
          <Autocomplete
            name="seller"
            label="Vendedor"
            setValue={setValue}
            defaultValue={storageSeller?.label}
            onSelectOption={userSelected => {
              if (userSelected.value) {
                setSellerIdToListFreights(userSelected)
                getSellerIdToListFreights()
              } else {
                setSellerIdToListFreights()
                getSellerIdToListFreights()
              }
            }}
            clearState={() => {
              setSellerIdToListFreights(undefined)
              getSellerIdToListFreights()
            }}
            loadOptions={getUserSales}
          />
        </GridItem>
      </Ability>

      <GridItem colSpan={[12, 6, 3]}>
        <Select
          label="Status Pagamento"
          name="payment_status"
          initialValue={getValues?.('payment_status') || ''}
          setValue={setValue}
          error={errors.payment_status}
          options={[
            {
              label: '',
              value: '',
            },
            {
              label: 'Atrasado',
              value: 'ATRASADO',
            },
            {
              label: 'Vence Hoje',
              value: 'VENCE HOJE',
            },
            {
              label: 'A vencer',
              value: 'A VENCER',
            },
            {
              label: 'Recebido',
              value: 'RECEBIDO',
            },
          ]}
        />
      </GridItem>
    </Grid>
  )
}
