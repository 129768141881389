import { Button, Divider, Flex, Heading, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { apiServer } from '../../../services/api'
import { MotoristType, ResponseApiType } from '../../../services/types'
import OwnerType from '../../../services/types/OwnerType'
import { OwnerForm, OwnerFormSchema } from '../OwnerForm'

interface FormOption {
  label: string
  value: string | number
}

export interface OwnerFormData {
  type?: 'pf' | 'pj'
  cnpj?: string
  email?: string
  cpf?: string
  name?: string
  rntrc?: string
  rntrc_type?: 'E' | 'C' | 'EQ' | 'T'
  phone?: string
  other_phone?: string
  zipcode?: string
  address?: string
  address_number?: string
  district?: string
  city_id?: AutocompleteOption
  ie?: string
  mother_name?: string
  rg?: string
  rg_uf?: string
  rg_dispatcher?: string
  birth?: Date
  rg_dispatch_date?: Date
  pis?: string
  bank?: FormOption
  account_type?: string
  agency?: string
  account?: string
}

export function CreateOwner(): JSX.Element {
  const [motoristExists, setMotoristExists] = useState<MotoristType>()
  const motoristPhoneRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const history = useHistory()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(OwnerFormSchema),
  })

  const handleCreateOwner = useCallback<SubmitHandler<OwnerFormData>>(
    async data => {
      const { city_id, bank, ...ownerFormData } = data
      try {
        const { data: response } = await apiServer.post<ResponseApiType<OwnerType>>(`/create-owner`, {
          ...ownerFormData,
          city_id: city_id?.value,
          bank: bank?.label ? bank.label : undefined,
        })
        const { data: newOwner } = response
        toast({
          title: 'Proprietário cadastrado com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        history.push(`/owners/show/${newOwner.id}`)
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [toast, history],
  )

  async function handleCheckMotoristExists() {
    if (motoristPhoneRef.current) {
      const { value } = motoristPhoneRef.current

      try {
        const { data: response } = await apiServer.get<ResponseApiType<MotoristType>>(
          `/find-motorist-by-phone/${value}`,
        )
        const { data } = response
        setMotoristExists(data)
      } catch {
        setMotoristExists(undefined)
      }
    }
  }

  return (
    <Layout hasBackground>
      <Flex alignItems="center" justify="space-between" mb="4">
        <Heading size="md" fontWeight="normal">
          Cadastro de proprietário
        </Heading>
      </Flex>
      <Divider my="4" />
      <form onSubmit={handleSubmit(handleCreateOwner)} noValidate autoComplete="off">
        <OwnerForm
          setValue={setValue}
          formState={formState}
          handleCheckMotoristExists={handleCheckMotoristExists}
          motoristPhoneRef={motoristPhoneRef}
          motoristExists={motoristExists}
        />
        <Flex justifyContent="flex-end" gridGap="2">
          <Button as={Link} to="/owners" variant="ghost" colorScheme="red">
            Cancelar
          </Button>

          <Ability module="people" action="create-owner">
            <Button type="submit" colorScheme="orange" isLoading={formState.isSubmitting}>
              Cadastrar
            </Button>
          </Ability>
        </Flex>
      </form>
    </Layout>
  )
}
