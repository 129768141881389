import { Flex, Text } from '@chakra-ui/react'
import { InfoWindow, InfoWindowProps } from '@react-google-maps/api'
import { SelectedMarker } from '../../services/endpoints/motorists/getWaypoints'

interface InputInfoWindow extends InfoWindowProps {
  selectedMarker: SelectedMarker | undefined
}
export const infoWindowTextStyle = {
  color: '#333',
}

type InfoCitiesProps = {
  cityTitle: string
  selectedMarker: SelectedMarker
}

function formatPublicPlace(text: string): string {
  const numberIndex = text.indexOf('KM')
  if (numberIndex === -1) return text
  const kmNumberStr = text.substring(0, numberIndex).trim()
  const kmNumber = parseFloat(kmNumberStr.replace(',', '.'))
  const formattedKmNumber = kmNumber.toFixed(1)
  const newString = `HÁ ${formattedKmNumber} KM ${text.substr(numberIndex + 2)}`
  return newString
}

function InfoCities({ cityTitle, selectedMarker }: InfoCitiesProps): JSX.Element | null {
  return selectedMarker.city ? (
    <>
      <h1 style={infoWindowTextStyle}>
        <b>{cityTitle.toLocaleUpperCase()}</b>
      </h1>
      <Text color="blue.500" fontWeight="bold">
        {selectedMarker.city.name.toLocaleUpperCase()} - {selectedMarker.city.uf.toLocaleUpperCase()}
      </Text>
    </>
  ) : null
}

function InfoWindowContent({ title, value }: { title: string; value: string }): JSX.Element {
  return (
    <Flex gridGap={1} fontWeight="bold">
      <Text color="#333">{title.toLocaleUpperCase()}</Text>
      <Text color="blue.500">{value.toLocaleUpperCase()}</Text>
    </Flex>
  )
}

export function InfoWindowComponent({ selectedMarker, ...rest }: InputInfoWindow): JSX.Element {
  return (
    <>
      {selectedMarker && (
        <InfoWindow
          {...rest}
          position={{
            lat: Number(selectedMarker.lat),
            lng: Number(selectedMarker.lng),
          }}
          anchor={new window.google.maps.MVCObject()}
          options={{
            disableAutoPan: true,
            pixelOffset: new window.google.maps.Size(0, -12),
          }}
        >
          <div>
            {selectedMarker.city && (
              <>
                {selectedMarker.city && selectedMarker.city.is_origin && (
                  <InfoCities selectedMarker={selectedMarker} cityTitle="Cidade de origem" />
                )}
                {selectedMarker.city && selectedMarker.city.is_destination && (
                  <InfoCities selectedMarker={selectedMarker} cityTitle="Cidade de destino" />
                )}
              </>
            )}

            {selectedMarker.is_firstPoint ? (
              <>
                <Text style={infoWindowTextStyle}>
                  <b>DADOS DA PRIMEIRA POSIÇÃO DO VEÍCULO</b>
                </Text>
                <br />
              </>
            ) : (
              selectedMarker.is_lastPoint && (
                <>
                  <Text style={infoWindowTextStyle}>
                    <b>DADOS DA POSIÇÃO ATUAL DO VEÍCULO</b>
                  </Text>
                  <br />
                </>
              )
            )}

            {selectedMarker.public_place && selectedMarker.city && (
              <>
                <InfoWindowContent title="Cidade:" value={selectedMarker.city.name} />
                <InfoWindowContent
                  title="Logradouro:"
                  value={formatPublicPlace(selectedMarker.public_place)}
                />
              </>
            )}
            {selectedMarker.waypoint_date_formatted && (
              <>
                <InfoWindowContent title="Enviado em:" value={selectedMarker.waypoint_date_formatted} />
              </>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  )
}
