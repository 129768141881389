import { Icon, Spinner, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import Leaflet from 'leaflet'
import ReactDomServer from 'react-dom/server'
import { SiGooglemaps } from 'react-icons/si'
import { FreightType } from '../../../../services/types'
import { useFetchRoutesInSegments } from '../../../../services/endpoints/freights/getMapDirections'

export interface OriginDestination {
  ibge_id: number
  name: string
  lat: number
  lng: number
  is_capital: boolean
  state_ibge_id: number
  state: State
}
export interface State {
  ibge_id: number
  uf: string
  name: string
  lat: number
  lng: number
}

type LocationState = {
  label?: string
  lat: number
  lng: number
}

export interface IRoutePlannerMapComponentProps {
  origin: OriginDestination | LocationState | Record<string, never>
  destination: OriginDestination | LocationState | Record<string, never>
  freight_id?: string
  motorist_id?: string
  vehicle_id?: string
  freight_status?: string
  status?: string
  type?: 'app' | 'whatsapp' | 'buonny' | undefined
  initial_date?: Date
  final_date?: Date
  freight?: FreightType
  showDistance?: boolean
  places?: Array<{ position: google.maps.LatLngLiteral }>
  zoom?: number
  showIcons?: boolean
  stopPlaces?: Array<{ position: google.maps.LatLngLiteral }>
  freightTollValue?: number
}

const containerStyle = {
  width: '100%',
  minHeight: '400px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

interface City {
  lat: string | number
  lng: string | number
}

export default function RoutePlannerMapComponent({
  origin,
  destination,
  places,
  stopPlaces,
  zoom,
  showIcons = true,
}: IRoutePlannerMapComponentProps): JSX.Element {
  const [formattedPlaces, setFormattedPlaces] = useState<City[]>([])
  const toast = useToast()

  const mapIcon = Leaflet.divIcon({
    html: ReactDomServer.renderToString(
      <div
        style={{
          backgroundColor: 'transparent',
          display: 'inline-block',
          transform: 'translate(-50%, -100%)',
        }}
      >
        <Icon as={SiGooglemaps} color="blue" style={{ fontSize: '24px' }} />
      </div>,
    ),
    iconSize: [24, 24],
    iconAnchor: [1, 1],
    popupAnchor: [1, -34],
    className: 'custom-map-icon',
  })

  useEffect(() => {
    if (places && places?.length >= 1) {
      const stops = places.map(({ position }) => ({
        lat: position.lat.toString(),
        lng: position.lng.toString(),
      }))
      setFormattedPlaces(stops)
    }
  }, [places, toast])

  const { data: response, isLoading } = useFetchRoutesInSegments(formattedPlaces)

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner size="xl" color="blue.500" />
      </div>
    )
  }

  return (
    <div style={{ ...containerStyle, backgroundColor: 'white' }}>
      <MapContainer
        center={{
          lat: origin.lat || -15.793889,
          lng: origin.lng || -47.882778,
        }}
        zoom={zoom || 5}
        style={containerStyle}
      >
        {showIcons && origin.lat && origin.lng && (
          <Marker icon={mapIcon} position={[origin.lat, origin.lng]}>
            <Popup>{`Origem: Latitude: ${origin.lat}, Longitude: ${origin.lng}`}</Popup>
          </Marker>
        )}

        {showIcons && destination.lat && destination.lng && (
          <Marker icon={mapIcon} position={[destination.lat, destination.lng]}>
            <Popup>{`Destino: Latitude: ${destination.lat}, Longitude: ${destination.lng}`}</Popup>
          </Marker>
        )}

        {stopPlaces &&
          stopPlaces.map((place, index) => (
            <Marker icon={mapIcon} position={[place.position.lat, place.position.lng]}>
              <Popup>{`Parada ${index + 1}: Latitude: ${place.position.lat}, Longitude: ${
                place.position.lng
              }`}</Popup>
            </Marker>
          ))}

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {response && response?.coords?.length > 0 && (
          <Polyline
            pathOptions={{
              color: '#3366cc',
              opacity: 0.6,
            }}
            positions={response?.coords?.map(coord => [coord[0], coord[1]]) ?? []}
            weight={10}
            opacity={1}
          />
        )}
      </MapContainer>
    </div>
  )
}
