import { Box, Flex, HStack } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import KanbanFiltersQuotation from '../../../../components/kanban/KanbanFiltersQuotation'
import { useAuth } from '../../../../contexts/AuthContext'
import { Layout } from '../../../../layout'
import {
  fetchAllQuotations,
  FiltersQuotationsParams,
  FormattedQuotation,
  useGetAllQuotations,
} from '../../../../services/endpoints/freights'
import { KanbanListQuotation } from '../KanbanListQuotation'

export function FreightsQuotation(): JSX.Element {
  const [filters, setFilters] = useState<FiltersQuotationsParams>()
  const [pageFreightsSolicitation, setPageQuotationsSolicitation] = useState(1)
  const [pageFreightsPending, setPageQuotationsPending] = useState(1)
  const [pageFreightsFinished, setPageQuotationsFinished] = useState(1)
  const [pageFreightsInQuotation, setPageQuotationsInQuotation] = useState(1)
  const [pageFreightsDisabled, setPageQuotationsDisabled] = useState(1)

  const [quotationsSolicitation, setQuotationsSolicitation] = useState<FormattedQuotation[]>([])
  const [quotationsPending, setQuotationsToApprove] = useState<FormattedQuotation[]>([])
  const [quotationsInQuotation, setQuotationsInQuotation] = useState<FormattedQuotation[]>([])
  const [quotationsFinished, setQuotationsFinished] = useState<FormattedQuotation[]>([])
  const [quotationsDisabled, setQuotationsDisabled] = useState<FormattedQuotation[]>([])

  const [quotationsSolicitationTotal, setQuotationsSolicitationTotal] = useState(0)
  const [quotationsInQuotationTotal, setQuotationsInQuotationTotal] = useState(0)
  const [quotationsFinishedTotal, setQuotationsFinishedTotal] = useState(0)
  const [quotationsPendingTotal, setQuotationsToApproveTotal] = useState(0)
  const [quotationsDisabledTotal, setQuotationsDisabledTotal] = useState(0)

  const [initial_date] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })
  const [final_date] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })

  const { storageUser, storageSeller } = useAuth()

  useEffect(() => {
    setFilters({
      initial_date,
      final_date,
      user_id: storageUser ? String(storageUser.value) : undefined,
      seller_id: storageSeller ? String(storageSeller.value) : undefined,
    })
  }, [final_date, initial_date, storageSeller, storageUser])

  useEffect(() => {
    setPageQuotationsSolicitation(1)
    setPageQuotationsInQuotation(1)
    setPageQuotationsFinished(1)
    setPageQuotationsDisabled(1)
  }, [filters])

  const { data: Solicitations, isLoading: isLoadingSolicitation } = useGetAllQuotations({
    quotation_number: filters?.quotation_number,
    cte_number: filters?.cte_number,
    user_id: filters?.user_id,
    seller_id: filters?.seller_id,
    client_id: filters?.client_id,
    motorist_id: filters?.motorist_id,
    status: 'pending',
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
  })
  useEffect(() => {
    if (Solicitations) {
      setQuotationsSolicitation(Solicitations.data)
      setQuotationsSolicitationTotal(Solicitations.total)
    }
  }, [Solicitations])

  const { data: ToApprove, isLoading: isLoadingToApprove } = useGetAllQuotations({
    quotation_number: filters?.quotation_number,
    cte_number: filters?.cte_number,
    user_id: filters?.user_id,
    seller_id: filters?.seller_id,
    client_id: filters?.client_id,
    motorist_id: filters?.motorist_id,
    status: 'to_approve',
  })
  useEffect(() => {
    if (ToApprove) setQuotationsToApprove(ToApprove.data)
    if (ToApprove) setQuotationsToApproveTotal(ToApprove.total)
  }, [ToApprove])

  const { data: Finished, isLoading: isLoadingFinished } = useGetAllQuotations({
    quotation_number: filters?.quotation_number,
    cte_number: filters?.cte_number,
    user_id: filters?.user_id,
    seller_id: filters?.seller_id,
    client_id: filters?.client_id,
    motorist_id: filters?.motorist_id,
    status: 'finished',
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
  })
  useEffect(() => {
    if (Finished) setQuotationsFinished(Finished.data)
    if (Finished) setQuotationsFinishedTotal(Finished.total)
  }, [Finished])

  const { data: InQuotation, isLoading: isLoadingInQuotation } = useGetAllQuotations({
    quotation_number: filters?.quotation_number,
    cte_number: filters?.cte_number,
    user_id: filters?.user_id,
    seller_id: filters?.seller_id,
    client_id: filters?.client_id,
    motorist_id: filters?.motorist_id,
    status: 'in_quotation',
  })
  useEffect(() => {
    if (InQuotation) setQuotationsInQuotation(InQuotation.data)
    if (InQuotation) setQuotationsInQuotationTotal(InQuotation.total)
  }, [InQuotation])

  const { data: Disabled, isLoading: isLoadingDisabled } = useGetAllQuotations({
    quotation_number: filters?.quotation_number,
    cte_number: filters?.cte_number,
    user_id: filters?.user_id,
    seller_id: filters?.seller_id,
    client_id: filters?.client_id,
    motorist_id: filters?.motorist_id,
    status: 'disabled',
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
  })
  useEffect(() => {
    if (Disabled) setQuotationsDisabled(Disabled.data)
    if (Disabled) setQuotationsDisabledTotal(Disabled.total)
  }, [Disabled])

  useEffect(() => {
    if (isLoadingSolicitation) setQuotationsSolicitation([])
    if (pageFreightsSolicitation > 1) {
      fetchAllQuotations({
        quotation_number: filters?.quotation_number,
        cte_number: filters?.cte_number,
        user_id: filters?.user_id,
        seller_id: filters?.seller_id,
        client_id: filters?.client_id,
        motorist_id: filters?.motorist_id,
        per_page: 5,
        page: pageFreightsSolicitation,
        status: 'pending',
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
      }).then(response => {
        setQuotationsSolicitation(prevFreights => [...prevFreights, ...response.data])
        setQuotationsSolicitationTotal(response.total)
      })
    }
  }, [pageFreightsSolicitation, filters, isLoadingSolicitation])

  useEffect(() => {
    if (isLoadingToApprove) setQuotationsToApprove([])
    if (pageFreightsPending > 1) {
      fetchAllQuotations({
        quotation_number: filters?.quotation_number,
        cte_number: filters?.cte_number,
        user_id: filters?.user_id,
        seller_id: filters?.seller_id,
        client_id: filters?.client_id,
        motorist_id: filters?.motorist_id,
        per_page: 5,
        page: pageFreightsPending,
        status: 'to_approve',
      }).then(response => {
        setQuotationsToApprove(prevFreights => [...prevFreights, ...response.data])
        setQuotationsToApproveTotal(response.total)
      })
    }
  }, [filters, isLoadingToApprove, pageFreightsPending])

  useEffect(() => {
    if (isLoadingInQuotation) setQuotationsInQuotation([])
    if (pageFreightsInQuotation > 1) {
      fetchAllQuotations({
        quotation_number: filters?.quotation_number,
        cte_number: filters?.cte_number,
        user_id: filters?.user_id,
        seller_id: filters?.seller_id,
        client_id: filters?.client_id,
        motorist_id: filters?.motorist_id,
        per_page: 5,
        page: pageFreightsInQuotation,
        status: 'in_quotation',
      }).then(response => {
        setQuotationsInQuotation(prevFreights => [...prevFreights, ...response.data])
        setQuotationsInQuotationTotal(response.total)
      })
    }
  }, [filters, isLoadingInQuotation, pageFreightsInQuotation])

  useEffect(() => {
    if (isLoadingFinished) setQuotationsFinished([])
    if (pageFreightsFinished > 1) {
      fetchAllQuotations({
        quotation_number: filters?.quotation_number,
        cte_number: filters?.cte_number,
        user_id: filters?.user_id,
        seller_id: filters?.seller_id,
        client_id: filters?.client_id,
        motorist_id: filters?.motorist_id,
        per_page: 5,
        page: pageFreightsFinished,
        status: 'finished',
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
      }).then(response => {
        setQuotationsFinished(prevFreights => [...prevFreights, ...response.data])
        setQuotationsFinishedTotal(response.total)
      })
    }
  }, [pageFreightsFinished, filters, isLoadingFinished])

  useEffect(() => {
    if (isLoadingDisabled) setQuotationsDisabled([])
    if (pageFreightsDisabled > 1) {
      fetchAllQuotations({
        quotation_number: filters?.quotation_number,
        cte_number: filters?.cte_number,
        user_id: filters?.user_id,
        seller_id: filters?.seller_id,
        client_id: filters?.client_id,
        motorist_id: filters?.motorist_id,
        per_page: 5,
        page: pageFreightsDisabled,
        status: 'disabled',
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
      }).then(response => {
        setQuotationsDisabled(prevFreights => [...prevFreights, ...response.data])
        setQuotationsDisabledTotal(response.total)
      })
    }
  }, [pageFreightsDisabled, filters, isLoadingDisabled])

  return (
    <Layout isFullScreen SidebarMobile>
      {/* <KanbanHeaderQuotation setFilters={setFilters} /> */}
      <Box position="absolute" top="-10px" zIndex="10" w="100vw" px="4">
        <KanbanFiltersQuotation
          setFilters={setFilters}
          filters={filters}
          isLoading={isLoadingSolicitation || isLoadingInQuotation || isLoadingFinished || isLoadingDisabled}
        />
      </Box>
      <Flex h="100%">
        <HStack spacing="3" align="flex-start" overflowX="scroll" px="2" pt="10">
          <KanbanListQuotation
            isLoading={isLoadingSolicitation}
            total={quotationsSolicitation.some(i => i.status === 'pending') ? quotationsSolicitationTotal : 0}
            setPage={setPageQuotationsSolicitation}
            type="pending"
            title="SOLICITAÇÃO"
            colorProps={{ color: 'green.400' }}
            quotations={
              quotationsSolicitation.some(i => i.status === 'pending') ? quotationsSolicitation : []
            }
          />

          <KanbanListQuotation
            isLoading={isLoadingInQuotation}
            total={
              quotationsInQuotation.some(i => i.status === 'in_quotation') ? quotationsInQuotationTotal : 0
            }
            setPage={setPageQuotationsInQuotation}
            type="in_quotation"
            title="EM COTAÇÃO"
            colorProps={{ color: 'blue.400' }}
            quotations={
              quotationsInQuotation.some(i => i.status === 'in_quotation') ? quotationsInQuotation : []
            }
          />

          <KanbanListQuotation
            isLoading={isLoadingToApprove}
            total={quotationsPending.some(i => i.status === 'to_approve') ? quotationsPendingTotal : 0}
            setPage={setPageQuotationsPending}
            type="to_approve"
            title="A APROVAR"
            colorProps={{ color: 'yellow.400' }}
            quotations={quotationsPending.some(i => i.status === 'to_approve') ? quotationsPending : []}
          />

          <KanbanListQuotation
            isLoading={isLoadingFinished}
            total={quotationsFinished.some(i => i.status === 'finished') ? quotationsFinishedTotal : 0}
            setPage={setPageQuotationsFinished}
            type="finished"
            title="FINALIZADA"
            colorProps={{ color: 'orange.400' }}
            quotations={quotationsFinished.some(i => i.status === 'finished') ? quotationsFinished : []}
          />

          <KanbanListQuotation
            isLoading={isLoadingDisabled}
            total={quotationsDisabled.some(i => i.status === 'disabled') ? quotationsDisabledTotal : 0}
            setPage={setPageQuotationsDisabled}
            type="disabled"
            title="DESATIVADA"
            colorProps={{ color: 'red' }}
            quotations={quotationsDisabled.some(i => i.status === 'disabled') ? quotationsDisabled : []}
          />
        </HStack>
      </Flex>
    </Layout>
  )
}
