import { AxiosResponse } from 'axios'
import { apiServer } from '../../api'
import { VehicleType } from '../../types'

export async function detachTrailerFromVehicle(
  vehicle_id: string,
  trailer_id: string,
): Promise<AxiosResponse<VehicleType>> {
  return apiServer.patch('/detach-trailer', {
    vehicle_id,
    trailer_id,
  })
}
