/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Center, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { isBefore, startOfMonth } from 'date-fns'
import { format } from 'date-fns-tz'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import money from '../../../assets/money.mp3'
import notHaveGates from '../../../assets/nao_ha_ferrolho.mp3'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { SimpleHeader } from '../../../layout/Header/SimpleHeader'
import { useDailyClosureExpectation } from '../../../services/endpoints/datamex/getDailyClosureExpectation'
import { useGetTotalCteValues } from '../../../services/endpoints/datamex/getTotalCteValues'
import parseCurrency from '../../../services/utils/parseCurrency'

export interface FetchCteReportParamsFormatted {
  key?: string
  nct?: string
  serie?: number
  emit_date_ini?: string
  emit_date_final?: string
  emitter?: string
  taker?: string
  page?: number
  origin_name?: AutocompleteOption
  destination_name?: AutocompleteOption
  per_page?: number
  unpaged?: boolean
  status?: string
  type?: string
  operator?: string
}

export const filterCte = yup.object().shape({
  key: yup.string(),
  initial_date: yup.string(),
  final_date: yup.string(),
  status: yup.string(),
  type: yup.string(),
  origin_name: yup.object().shape({}),
  destination_name: yup.object().shape({}),
})

export function Invoicing(): JSX.Element {
  const dayOfGlory = '2024-09-11'
  const isToday = format(new Date(), 'yyyy-MM-dd') === dayOfGlory
  const [audioName, setAudioName] = useState<'money' | 'notHaveGates'>('money')
  const [audio, setAudio] = useState(new Audio(money))
  const [total, setTotal] = useState<number>(1)
  const initialDateMonth = format(startOfMonth(new Date()), 'yyyy-MM-dd')
  const finalDateMonth = format(new Date(), 'yyyy-MM-dd')
  const dateNow = format(new Date(), 'yyyy-MM-dd')

  const { data: cteValuesMonth, isLoading: isLoadingCteValuesMonth } = useGetTotalCteValues({
    emit_date_ini: initialDateMonth,
    emit_date_final: finalDateMonth,
  })

  const { data: cteValuesDay } = useGetTotalCteValues({
    emit_date_ini: dateNow,
    emit_date_final: dateNow,
  })

  const { data: dataDailyClosureExpectation, isLoading: isLoadingDailyClosureExpectation } =
    useDailyClosureExpectation()

  useEffect(() => {
    if (cteValuesMonth) {
      if (total < cteValuesMonth.totalValue) {
        if (isToday) {
          if (audioName !== 'notHaveGates') {
            setAudioName('notHaveGates')
            setAudio(new Audio(notHaveGates))
          }
        }
        audio.play()
      }
      if (total !== cteValuesMonth.totalValue) {
        setTotal(cteValuesMonth.totalValue)
      }
    }
  }, [audio, cteValuesMonth, total, isToday, audioName])

  return (
    <Flex p="4" direction="column" w="100%" h="100vh">
      <SimpleHeader />
      <Flex w="100%" h="100vh" direction="column" justifyContent="center">
        {isLoadingCteValuesMonth ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          cteValuesMonth &&
          cteValuesDay && (
            <>
              <Box>
                <Flex alignContent="center" justifyContent="center" direction="column" alignItems="center">
                  <Heading fontSize="9xl" color="#1A365D">
                    {/* Remover essa gambiarra depois de 30/09/2023 kkk */}
                    {isBefore(new Date(), new Date('2023-09-30'))
                      ? parseCurrency(cteValuesMonth.totalValue - 106916.27)
                      : parseCurrency(cteValuesMonth.totalValue)}
                  </Heading>
                  <Heading fontSize="6xl" color="#DD6B20">
                    {cteValuesMonth.totalFreights} Fretes{' '}
                  </Heading>
                </Flex>

                <Flex
                  alignContent="center"
                  justifyContent="center"
                  mt={10}
                  direction="column"
                  alignItems="center"
                >
                  <Heading fontSize="8xl" color="#1A365D">
                    {parseCurrency(cteValuesDay.totalValue)}
                  </Heading>
                  <Heading fontSize="5xl" color="#DD6B20">
                    {cteValuesDay.totalFreights} Fretes
                  </Heading>
                </Flex>
              </Box>

              <Flex direction="column" ml={20} mt={20}>
                <Text fontSize="4xl">Fechamento esperado dia:</Text>
                {isLoadingDailyClosureExpectation ? (
                  <Spinner />
                ) : (
                  <Text fontSize="4xl">{parseCurrency(dataDailyClosureExpectation)}</Text>
                )}
              </Flex>
            </>
          )
        )}
      </Flex>
    </Flex>
  )
}
