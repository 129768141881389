import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { AutocompleteOption, SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { getFreightByNumber } from '../../../../../services/endpoints/freights/getFreightByNumber'
import { usePostLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PostLoadingRequest'
import { FormLoadingRequest } from '../Form'

export type FreightCustomType = {
  freight_id: string
  client: string
  motorist: AutocompleteOption
  vehicle_liscense_plate: string
  ctes?: SelectOption[]
}

type CreateLoadingRequestModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateLoadingRequestSchema = yup.object().shape({
  freight_number: yup.string().required('Campo Obrigatório'),
  cte: yup.string(),
  motorist_id: yup.object().required('Campo obrigatório'),
  discount_buonny: yup.string().required('Campo Obrigatório'),
  reason: yup.string().required('Campo Obrigatório'),
  type: yup.string().required('Campo Obrigatório'),
  total_value: yup.string().required('Campo Obrigatório'),
  total_gross: yup.string().required('Campo Obrigatório'),
  beneficiary_name: yup.string().required('Campo Obrigatório'),
  beneficiary_document: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
  origin: yup.string(),
  destination: yup.string(),
  e_frete: yup.string().required('Campo Obrigatório'),
  payment_type: yup.string(),
  pix: yup.string(),
  bank: yup
    .object()
    .shape({})
    .when('pix', {
      is: undefined,
      then: yup.object().shape({}).required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
      otherwise: yup.object().shape({}),
    }),
  account_type: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  agency: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  account: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  account_digit: yup.string(),
})

type CreateLoadingRequestType = {
  freight_number: string
  cte?: string
  discount_buonny: string
  reason: string
  type: string
  total_value: string
  total_gross: string
  beneficiary_name: string
  beneficiary_document: string
  observation?: string
  origin?: string
  destination?: string
  e_frete: string
  payment_type: string
  pix?: string
  bank?: AutocompleteOption
  account_type?: string
  agency?: string
  account?: string
  account_digit?: string
  motorist_id?: AutocompleteOption
}

export function CreateLoadingRequestModal({ isOpen, onClose }: CreateLoadingRequestModalProps): JSX.Element {
  const toast = useToast()
  const [freightNumber, setFreightNumber] = useState<string>('')
  const [customFreight, setCustomFreight] = useState<FreightCustomType>()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(CreateLoadingRequestSchema),
  })

  const { errors } = formState

  const { mutateAsync: createLoadingRequest, isLoading: isLoadingCreateLoadingRequest } =
    usePostLoadingRequest({
      onSuccess: () => {
        toast({
          title: 'Nova solicitação de carregamento criada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })

        onClose()

        setFreightNumber('')
        setCustomFreight(undefined)
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  useEffect(() => {
    if (freightNumber && freightNumber.length >= 4) {
      getFreightByNumber(freightNumber)
        .then(response => {
          toast({
            title: 'Buscando dados do frete!',
            status: 'info',
            position: 'top',
            isClosable: true,
            duration: 3000,
          })

          if (response) {
            const { client, motorist, vehicle, ctes } = response
            const ctesFormated = ctes?.map(cte => ({ label: String(cte.nct), value: cte.id }))

            setCustomFreight({
              freight_id: response.id,
              client: `${client.name} - ${client.cnpj}`,
              motorist: {
                label: motorist?.name || '',
                value: motorist?.id || '',
              },
              vehicle_liscense_plate: `${vehicle?.license_plate}`,
              ctes: ctesFormated,
            })
          }

          toast({
            title: 'Frete encontrado com sucesso!',
            status: 'success',
            position: 'top',
            isClosable: true,
            duration: 3000,
          })
        })
        .catch(error => {
          toastError({ toast, error })
        })
    } else if (freightNumber && freightNumber.length < 4) {
      setCustomFreight(undefined)
    }
  }, [freightNumber, toast])

  const handleCreateLoadingRequest: SubmitHandler<CreateLoadingRequestType> = async data => {
    if (customFreight?.freight_id) {
      await createLoadingRequest({
        ...data,
        freight_id: customFreight?.freight_id,
      })
    }
  }

  return (
    <Modal
      size="4xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setFreightNumber('')
        setCustomFreight(undefined)
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Nova solicitação de carregamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleCreateLoadingRequest)} noValidate>
          <ModalBody>
            <FormLoadingRequest
              customFreight={customFreight}
              freightNumber={freightNumber}
              setFreightNumber={setFreightNumber}
              setValue={setValue}
              errors={errors}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingCreateLoadingRequest}
              isDisabled={isLoadingCreateLoadingRequest}
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
