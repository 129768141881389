import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { GrDocumentPdf } from 'react-icons/gr'
import { HiDocumentCheck } from 'react-icons/hi2'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../../../components/UI/PageHeader'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { CiotFormatted, useGetCiots } from '../../../../services/endpoints/documents/ciot/list-ciots'
import formatCPFAndCNPJ from '../../../../services/utils/formatCPFAndCNPJ'
import { getCiotPdfByCiotNumber } from '../../../../services/utils/getCiotPdfByCiotNumber'
import { boxWidth, displayAdaptable } from '../../../../services/utils/tableAdaptable'

export function ListCiots(): JSX.Element {
  const [page, setPage] = useState(1)

  const { data: ciots, isLoading } = useGetCiots({
    page,
  })

  const bg = useColorModeValue('white', 'gray.800')
  const textColor = useColorModeValue('gray.800', 'white')

  const toast = useToast()

  async function redirectToNewPageWithPdf(ciot: CiotFormatted): Promise<void> {
    const url = await getCiotPdfByCiotNumber({
      ciot_number: ciot.ciot_number,
      contractor_id: ciot.contractor_id,
    })

    if (url) {
      window.open(url, '_blank')
    } else {
      toast({
        title: 'Este arquivo não está mais disponível!',
        status: 'warning',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Layout>
      <PageHeader title="Lista de CIOT" icon={HiDocumentCheck} />

      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8" w={boxWidth}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            {ciots?.data && ciots.data.length > 0 ? (
              <Table mt={4} size="sm" d={displayAdaptable} overflowX="auto">
                <Thead>
                  <Tr>
                    <Th>Número</Th>
                    <Th textAlign="center">Status</Th>
                    <Th>Frete</Th>
                    <Th>CTE</Th>
                    <Th fontSize="xs" whiteSpace="nowrap">
                      Ord. de coleta
                    </Th>
                    <Th fontSize="xs" whiteSpace="nowrap">
                      Data Emissão
                    </Th>
                    <Th>Spotx</Th>
                    <Th>Proprietário</Th>
                    <Th>CPF/CNPJ</Th>
                    <Th>Valor</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {ciots.data.map(ciot => (
                    <Tr key={ciot.id}>
                      <Td pt={4} pb={4} whiteSpace="nowrap">
                        {ciot.ciot_number}
                      </Td>
                      <Td whiteSpace="nowrap">
                        <Text
                          textAlign="center"
                          bg={
                            ciot.ciot_status?.status === 'cancelado'
                              ? 'red.400'
                              : ciot.ciot_status?.status === 'encerrado'
                              ? 'gray.400'
                              : bg
                          }
                          textColor={textColor}
                          p={2}
                          borderRadius="full"
                        >
                          {ciot.ciot_status?.status ? ciot.ciot_status?.status.toLocaleUpperCase() : 'ABERTO'}
                        </Text>
                      </Td>
                      <Td whiteSpace="nowrap">
                        {ciot.cte?.freight && (
                          <Flex gridGap={4}>
                            <Tooltip
                              label={`Clique para ser direcionado ao frete #${ciot.cte?.freight.freight_number}`}
                              hasArrow
                              placement="top"
                            >
                              <Link to={`freights/show/${ciot.cte?.freight_id}`}>
                                <Text fontSize="xs">#{ciot.cte?.freight.freight_number}</Text>
                              </Link>
                            </Tooltip>
                          </Flex>
                        )}
                      </Td>

                      <Td whiteSpace="nowrap">{ciot.cte_number}</Td>
                      <Td whiteSpace="nowrap" textAlign="center">
                        {ciot.collect_order_number}
                      </Td>
                      <Td whiteSpace="nowrap">{ciot.date_emission}</Td>
                      <Td whiteSpace="nowrap">{ciot.contractor_number}</Td>
                      <Td whiteSpace="nowrap">
                        <Tooltip label={ciot.hired.name} hasArrow placement="top">
                          <Text>
                            {String(ciot.hired.name).substring(0, 20)}
                            {ciot.hired.name.length > 20 ? '...' : ''}
                          </Text>
                        </Tooltip>
                      </Td>
                      <Td whiteSpace="nowrap">{formatCPFAndCNPJ(ciot.hired_doc.number)}</Td>
                      <Td whiteSpace="nowrap">{ciot.gross_ciot_value}</Td>
                      <Td>
                        <Tooltip label="Baixar CIOT em pdf" hasArrow placement="top">
                          <Button
                            size="xs"
                            colorScheme="orange"
                            rel="noreferrer"
                            onClick={async () =>
                              // eslint-disable-next-line no-return-await
                              await redirectToNewPageWithPdf(ciot)
                            }
                          >
                            <Icon as={GrDocumentPdf} fontSize="18" />
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex justifyContent="center">
                <Text>Nenhuma ocorrência registrada</Text>
              </Flex>
            )}
            {ciots && ciots.total > 0 && (
              <Box mt={5}>
                <Pagination currentPage={page} totalCountOfRegisters={ciots.total} onPageChange={setPage} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Layout>
  )
}
