import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IoPush } from 'react-icons/io5'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import { Input, Select } from '../../../../../components/form'
import Filter from '../../../../../components/UI/Filter'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { Pagination } from '../../../../../layout/Pagination'
import {
  FilterLoadingRequestsType,
  useGetAllLoadingRequest,
} from '../../../../../services/endpoints/freights/loading-request/GetAllLoadingRequest'
import { getLoadingRequestColorByStatus } from '../../../../../services/utils/getLoadingOrderColorByStatus'
import { CreateLoadingRequestModal } from '../modals/CreateLoadingRequestModal'
import { loadingRequestStatusLegend } from '../../../../../services/utils/loadingRequestStatusLegend'

export function ListLoadingRequests(): JSX.Element {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<FilterLoadingRequestsType>({} as FilterLoadingRequestsType)

  const toast = useToast()

  const bg = useColorModeValue('white', 'gray.800')
  const hoverBgLineTable = useColorModeValue('gray.200', 'gray.700')

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })
  const {
    isOpen: isCreateLoadingRequestModalOpen,
    onOpen: onCreateLoadingRequestModalOpen,
    onClose: onCreateLoadingRequestModalClose,
  } = useDisclosure()

  const {
    data: loadingRequests,
    isLoading,
    isError,
    error,
  } = useGetAllLoadingRequest({
    ...filters,
    page,
  })

  const { handleSubmit, setValue } = useForm({})

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  const handleFilterLoadingRequests: SubmitHandler<FilterLoadingRequestsType> = data => {
    setFilters(data)
  }

  return (
    <Layout>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading fontSize="2xl">Solicitações de carregamento</Heading>

        <Box>
          <Button
            colorScheme="orange"
            leftIcon={<Icon as={IoPush} />}
            onClick={onCreateLoadingRequestModalOpen}
          >
            Nova solicitação de carregamento
          </Button>
          <Button
            leftIcon={<RiFilter2Line />}
            variant="outline"
            colorScheme="blue"
            ml={2}
            onClick={onToggleFilter}
          >
            Filtros
          </Button>
        </Box>
      </Flex>

      <Filter showFilter={onShowFilter} key={1}>
        <form onSubmit={handleSubmit(handleFilterLoadingRequests)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Input
                label="Número do frete"
                name="freight_number"
                placeholder="Filtrar pelo número"
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Select
                label="Status"
                name="status"
                setValue={setValue}
                options={[
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Aguardando Análise',
                    value: 'awaiting_analysis',
                  },
                  {
                    label: 'CF Reprovado',
                    value: 'rejected',
                  },
                  {
                    label: 'Aguardando CF',
                    value: 'awaiting_cf',
                  },
                  {
                    label: 'Aguardando Pagamento',
                    value: 'awaiting_payment',
                  },
                  {
                    label: 'Pago',
                    value: 'paid',
                  },
                  {
                    label: 'Finalizado',
                    value: 'finished',
                  },
                ]}
              />
            </GridItem>

            {/* <GridItem colSpan={[8, 3]}>
              <Input
                label="Data inicial"
                name="initial_date"
                type="date"
                initialValue={initialDate}
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data final"
                name="final_date"
                type="date"
                initialValue={finalDate}
                setValue={setValue}
              />
            </GridItem> */}
          </Grid>

          <Flex justifyContent="flex-end" mt={2}>
            <Button type="submit" size="md" colorScheme="blue" leftIcon={<Icon as={RiSearchLine} />}>
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} p={4} borderRadius="8" shadow="md">
        {!isLoading ? (
          loadingRequests && loadingRequests.data && loadingRequests.data.length > 0 ? (
            <>
              <Table size="sm" d={['block', 'block', 'table']} overflowX="auto">
                <Thead>
                  <Tr>
                    <Th>Frete</Th>
                    <Th>Status</Th>
                    <Th>Operador</Th>
                    <Th>Valor total</Th>
                    <Th>Valor bruto</Th>
                    <Th>Data</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loadingRequests?.data.map(loadingOrder => (
                    <Tr
                      key={loadingOrder.id}
                      cursor="pointer"
                      _hover={{ bg: hoverBgLineTable, transition: '0.3s' }}
                      onClick={() => {
                        window.open(`/loading-requests/${loadingOrder.id}`, '_blank')?.focus()
                      }}
                    >
                      <Td>#{loadingOrder?.freight?.freight_number}</Td>
                      <Td>
                        <Tooltip
                          label={
                            loadingOrder.reject_reason || loadingRequestStatusLegend(loadingOrder.status)
                          }
                          hasArrow
                          placement="top"
                          arrowSize={15}
                        >
                          <Text
                            backgroundColor={getLoadingRequestColorByStatus(loadingOrder.status)}
                            borderRadius={50}
                            padding={1}
                            textAlign="center"
                            fontSize={14}
                            textColor="white"
                          >
                            {loadingOrder.formatted_status}
                          </Text>
                        </Tooltip>
                      </Td>
                      <Td>{loadingOrder.creator?.name}</Td>
                      <Td>{loadingOrder.total_value_formatted}</Td>
                      <Td>{loadingOrder.total_gross_formatted}</Td>
                      <Td>{loadingOrder.created_at}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                currentPage={page}
                totalCountOfRegisters={loadingRequests?.total ?? 0}
                registersPerPage={15}
                onPageChange={setPage}
                pt="4"
                pb="1"
              />
            </>
          ) : isError ? (
            <Text textAlign="center">Erro ao carregar as ordens de carregamento</Text>
          ) : (
            <Text textAlign="center">Nenhuma solicitação de carregamento encontrada</Text>
          )
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <Spinner mr={2} />
            <Text>Carregando dados</Text>
          </Flex>
        )}
      </Box>

      <CreateLoadingRequestModal
        isOpen={isCreateLoadingRequestModalOpen}
        onClose={onCreateLoadingRequestModalClose}
      />
    </Layout>
  )
}
