import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  WrapItem,
} from '@chakra-ui/react'
import { RiArrowDownLine, RiArrowUpLine, RiEditLine } from 'react-icons/ri'
import { format } from 'date-fns'
import { useGetOwner } from '../../../../services/endpoints/owners/getOwner'
import { EditOwnerModal } from '../../modals/EditOwnerModal'
import { useGlobalVariables } from '../../../../contexts/GlobalVariables'
import { formatAccountType } from '../../../../services/utils/formatAccountType'
import { getLabelBank } from '../../../../services/utils/getLabelBank'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'

interface OwnerInfoProps {
  owner_id: string
  bg: string
}

export function OwnerInfo({ owner_id, bg }: OwnerInfoProps): JSX.Element {
  const { setPageTitle } = useGlobalVariables()
  const { data: isBlockedEntity } = useIsBlockEntity({
    trailer_owner_id: owner_id,
    vehicle_owner_id: owner_id,
  })
  const { isOpen: isCollapseInfoOpen, onToggle: onCollapseInfoToggle } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: owner, isLoading, isFetching, refetch } = useGetOwner(owner_id)
  setPageTitle(`${owner?.name} | Proprietários | SpotX Admin`)
  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md">
      <Flex mb="6" justify="space-between" align="center" gridGap="2" direction={['column', 'row']}>
        <Heading size="lg" fontWeight="normal">
          Informações do Proprietário
          {!isLoading && isFetching && <Spinner size="sm" ml="4" />}
        </Heading>

        <Button
          size="sm"
          colorScheme="yellow"
          isDisabled={isBlockedEntity}
          leftIcon={<Icon as={RiEditLine} />}
          onClick={() => {
            onOpen()
          }}
        >
          <Tooltip
            hasArrow
            placement="top"
            label={isBlockedEntity && 'Proprietário em analise! Edição bloqueada!'}
          >
            Editar
          </Tooltip>
        </Button>
      </Flex>
      <Divider my="6" />

      <Box>
        <Heading size="md" fontWeight="normal" mb="6">
          Dados pessoais
        </Heading>
        <SimpleGrid minChildWidth="300px" spacing="4">
          <WrapItem>
            <Text color="gray.400">Nome:</Text>
            <Text fontWeight="medium" ml="1">
              {owner?.name}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Telefone:</Text>
            <Text fontWeight="medium" ml="1">
              {owner?.phone}
            </Text>
          </WrapItem>
          <WrapItem>
            <Text color="gray.400">RNTRC:</Text>
            <Text fontWeight="medium" ml="1">
              {owner?.rntrc}
            </Text>
          </WrapItem>
        </SimpleGrid>
      </Box>

      <Collapse in={isCollapseInfoOpen} animateOpacity>
        <Box>
          <SimpleGrid minChildWidth="300px" spacing="6" mt="6">
            <WrapItem>
              <Text color="gray.400">E-mail:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.email}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Telefone secundario:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.other_phone}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Nome da mãe: </Text>
              <Text fontWeight="medium" ml="1">
                {owner?.mother_name}
              </Text>
            </WrapItem>
          </SimpleGrid>
          <Divider my="6" />
          <Heading size="md" fontWeight="normal" mb="6">
            Endereço
          </Heading>
          <SimpleGrid minChildWidth="300px" spacing="6" mb="6">
            <WrapItem>
              <Text color="gray.400">CEP:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.zipcode}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Logradouro:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.address}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Número:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.address_number}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Bairro:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.district}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Cidade: </Text>
              <Text fontWeight="medium" ml="1">
                {owner?.city?.name}
              </Text>
            </WrapItem>
          </SimpleGrid>
          <Divider mb="6" />
        </Box>

        <Box>
          <Heading size="md" fontWeight="normal" mb="6">
            Documentos
          </Heading>
          <SimpleGrid minChildWidth="300px" spacing="6" mb="6">
            {owner?.cpf && (
              <WrapItem>
                <Text color="gray.400">CPF:</Text>
                <Text fontWeight="medium" ml="1">
                  {owner?.cpf}
                </Text>
              </WrapItem>
            )}
            {owner?.cnpj && (
              <WrapItem>
                <Text color="gray.400">CNPJ:</Text>
                <Text fontWeight="medium" ml="1">
                  {owner?.cnpj}
                </Text>
              </WrapItem>
            )}

            <WrapItem>
              <Text color="gray.400">RG:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.rg}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Órgão expeditor:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.rg_dispatcher}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">RG UF:</Text>
              <Text fontWeight="medium" ml="1">
                {owner?.rg_uf}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Data de expedição do RG: </Text>
              <Text fontWeight="medium" ml="1">
                {owner?.rg_dispatch_date && format(new Date(owner?.rg_dispatch_date), 'dd/MM/yyyy')}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">PIS: </Text>
              <Text fontWeight="medium" ml="1">
                {owner?.pis}
              </Text>
            </WrapItem>
          </SimpleGrid>
          <Divider my="6" />
          <Heading size="md" fontWeight="normal" mb="6">
            Dados bancários
          </Heading>
          <SimpleGrid minChildWidth="200px" spacing="6" mb="6">
            {owner?.bank && (
              <WrapItem>
                <Text color="gray.400">Banco: </Text>
                <Text fontWeight="medium" ml="1">
                  {getLabelBank(owner.bank)}
                </Text>
              </WrapItem>
            )}

            <WrapItem>
              <Text color="gray.400">Agencia: </Text>
              <Text fontWeight="medium" ml="1">
                {owner?.agency}
              </Text>
            </WrapItem>

            {owner?.account_type && (
              <WrapItem>
                <Text color="gray.400">Tipo de conta: </Text>
                <Text fontWeight="medium" ml="1">
                  {formatAccountType(owner.account_type)}
                </Text>
              </WrapItem>
            )}

            <WrapItem>
              <Text color="gray.400">Conta: </Text>
              <Text fontWeight="medium" ml="1">
                {owner?.account}
              </Text>
            </WrapItem>
          </SimpleGrid>
        </Box>
      </Collapse>

      <Flex justify="center" mt="2">
        <Button
          variant="ghost"
          size="sm"
          rightIcon={isCollapseInfoOpen ? <Icon as={RiArrowUpLine} /> : <Icon as={RiArrowDownLine} />}
          onClick={onCollapseInfoToggle}
        >
          Mostrar {isCollapseInfoOpen ? 'menos' : 'mais'}
        </Button>
      </Flex>

      <EditOwnerModal
        owner_id={owner_id}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          refetch()
        }}
      />
    </Box>
  )
}
