import { Marker } from '@react-google-maps/api'
import { FormattedOneFreight } from '../../services/endpoints/freights'
import {
  MotoristWaypointTypeFormatted,
  SelectedMarker,
} from '../../services/endpoints/motorists/getWaypoints'
import { IconCarMotoristMap2, IconCarMotoristStartMap, IconStartOriginMap } from './Icons'

type MarkersProps = {
  freight: FormattedOneFreight
  setSelectedMarker: (marker: SelectedMarker | undefined) => void
  firstWayPoint?: SelectedMarker
  lastWayPoint?: SelectedMarker
  waypoints?: MotoristWaypointTypeFormatted[]
}

export function Markers({
  freight,
  setSelectedMarker,
  firstWayPoint,
  lastWayPoint,
  waypoints,
}: MarkersProps): JSX.Element {
  return (
    <>
      <Marker
        position={{
          lat: Number(freight.origin.lat),
          lng: Number(freight.origin.lng),
        }}
        icon={{
          path: IconStartOriginMap,
          fillColor: 'blue',
          strokeColor: 'blue',
          fillOpacity: 1,
          scale: 0.9,
          anchor: { x: 12, y: 12, equals: () => false },
        }}
        onClick={() => {
          setSelectedMarker(undefined)
          setSelectedMarker({
            lat: Number(freight.origin.lat),
            lng: Number(freight.origin.lng),
            city: {
              is_origin: true,
              name: freight.origin.name,
              uf: freight.origin.state.uf,
            },
          })
        }}
      />
      <Marker
        position={{
          lat: Number(freight.destination.lat),
          lng: Number(freight.destination.lng),
        }}
        icon={{
          path: IconStartOriginMap,
          fillColor: 'red',
          strokeColor: 'red',
          fillOpacity: 1,
          scale: 0.9,
          anchor: { x: 12, y: 12, equals: () => false },
        }}
        onClick={() => {
          setSelectedMarker(undefined)
          setSelectedMarker({
            lat: Number(freight.destination.lat),
            lng: Number(freight.destination.lng),
            city: {
              is_destination: true,
              name: freight.destination.name,
              uf: freight.destination.state.uf,
            },
          })
        }}
      />

      {waypoints && waypoints.length > 0 && (
        <>
          {firstWayPoint && (
            <Marker
              zIndex={9999}
              position={firstWayPoint}
              icon={{
                path: IconCarMotoristStartMap,
                fillColor: 'purple',
                strokeColor: 'purple',
                fillOpacity: 1,
                scale: 1.5,
                anchor: { x: 12, y: 12, equals: () => false },
              }}
              onClick={() => {
                setSelectedMarker(undefined)
                setSelectedMarker({ ...firstWayPoint, is_firstPoint: true })
              }}
            />
          )}

          {lastWayPoint && (
            <Marker
              zIndex={9999}
              position={lastWayPoint}
              icon={{
                path: IconCarMotoristMap2,
                fillColor: '#006400',
                strokeColor: '#006400',
                fillOpacity: 1,
                scale: 0.03,
                anchor: { x: 12, y: 12, equals: () => false },
              }}
              onClick={() => {
                setSelectedMarker(undefined)
                setSelectedMarker({ ...lastWayPoint, is_lastPoint: true })
              }}
            />
          )}

          {/* {type === 'whatsapp' &&
            waypoints?.length > 0 &&
            waypoints.map(wp => (
              <Marker
                key={`${wp.lat}${wp.lng}-${wp.waypoint_date}`}
                position={{
                  lat: Number(wp.lat),
                  lng: Number(wp.lng),
                }}
                icon={{
                  path: IconOriginMap,
                  fillColor: '#128C7E',
                  strokeColor: '#fff',
                  fillOpacity: 1,
                  scale: 0.9,
                  anchor: { x: 12, y: 12, equals: () => false },
                }}
                onClick={() => {
                  setSelectedMarker(undefined)
                  setSelectedMarker({
                    lat: Number(wp.lat),
                    lng: Number(wp.lng),
                    waypoint_date_formatted: wp.waypoint_date_formatted,
                  })
                }}
              />
            ))}

          <Polyline
            path={waypoints.map(wp => ({ lat: Number(wp.lat), lng: Number(wp.lng) }))}
            options={{
              strokeColor: 'red',
            }}
          /> */}
        </>
      )}
    </>
  )
}
