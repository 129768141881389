import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
  Text,
  useDisclosure,
  WrapItem,
} from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { IoReload } from 'react-icons/io5'
import { MdPostAdd } from 'react-icons/md'
import { queryClient } from '../../../../../config/react-query'
import { useAuth } from '../../../../../contexts/AuthContext'
import { useDeleteStub } from '../../../../../services/endpoints/freights/deleteStub'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { useGetStubs } from '../../../../../services/endpoints/freights/getStubs'
import { FreightStubsType } from '../../../../../services/types'
import { CreateNfesModal } from './create-nfes'
import { ListNfes } from './list-nfes'
import { AttachStubNfesAttachment } from './modals/AttachStubNfesAttachment'
import { CreateStubsModal } from './modals/CreateStubsModal'
import { DeleteStubModal } from './modals/DeleteStubModal'
import { ShowStubModal } from './modals/ShowStubModal'

interface ListStubProps {
  freight: FormattedOneFreight
  bg: string
}

interface IDeleteStubAttachmentProps {
  handleDeleteStub: () => Promise<void>
  attachment_file_url: string
}

export type DeleteStubParams = {
  id?: string
}

export function ListStub({ freight, bg }: ListStubProps): JSX.Element | null {
  const { user } = useAuth()
  const [stubAttachment, setStubAttachment] = useState<FreightStubsType | null>(null)

  const [showStubAttachment, setShowStubAttachment] = useState<FreightStubsType | null>(null)

  const [deleteStubAttachment, setDeleteStubAttachment] = useState<IDeleteStubAttachmentProps | null>(null)

  const {
    isOpen: isOpenCreateStubsModal,
    onClose: onCloseCreateStubsModal,
    onOpen: onOpenCreateStubsModal,
  } = useDisclosure()

  const {
    isOpen: isOpenCreateNfesModal,
    onClose: onCloseCreateNfesModal,
    onOpen: onOpenCreateNfesModal,
  } = useDisclosure()

  const {
    isOpen: isOpenViewStubModal,
    onClose: onCloseViewStubModal,
    onOpen: onOpenViewStubModal,
  } = useDisclosure()

  const {
    isOpen: isOpenShowStubModal,
    onClose: onCloseShowStubModal,
    onOpen: onOpenShowStubModal,
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteStubModal,
    onClose: onCloseDeleteStubModal,
    onOpen: onOpenDeleteStubModal,
  } = useDisclosure()

  const { data: stubsPending, isLoading: isLoadingStubs } = useGetStubs({
    freight_id: freight.id,
    status: 'pending',
  })

  const { data: stubs, isLoading: isLoadingApprovedAndDisapprovedStubs } = useGetStubs({
    freight_id: freight.id,
    status: ['approved', 'disapproved'],
  })

  const { mutateAsync: mutateAsyncDeleteStub } = useDeleteStub({
    onSuccess: () => {
      queryClient.invalidateQueries('stubs')
    },
  })

  async function handleDeleteStub(params: DeleteStubParams): Promise<void> {
    await mutateAsyncDeleteStub({ id: params.id })
  }

  return freight ? (
    <Box bg={bg} p="4" borderRadius="8">
      <Flex justify="space-between" align="center">
        <Flex justify="space-between" align="center">
          <Heading fontSize="md" fontWeight="medium">
            Canhotos
          </Heading>

          <IconButton
            icon={<IoReload />}
            onClick={() => {
              queryClient.invalidateQueries('stubs')
            }}
            isLoading={isLoadingApprovedAndDisapprovedStubs || isLoadingStubs}
            aria-label="Recarregar"
            size="sm"
            ml="2"
            title="Recarregar"
          />
        </Flex>
        <Stack direction={['column', 'row']}>
          {freight.origin_id === freight.destination_id && (
            <Button size="sm" colorScheme="orange" variant="outline" onClick={onOpenCreateNfesModal}>
              Adicionar nota fiscal
            </Button>
          )}
          <Button
            size="sm"
            colorScheme="orange"
            variant="outline"
            leftIcon={<MdPostAdd />}
            onClick={onOpenCreateStubsModal}
          >
            Adicionar canhotos
          </Button>
        </Stack>
      </Flex>

      <Box flexDirection="row">
        <SimpleGrid minChildWidth="300px" spacing="6" mt="4" columns={2} bg={bg} p="4" borderRadius="8">
          <Box>
            <Heading size="sm" fontWeight="normal" mb="2">
              Anexos
            </Heading>
            <WrapItem flexDirection="row" flex="1" justifyContent="space-between">
              {isLoadingStubs && <Spinner />}

              {!isLoadingStubs && !stubsPending?.length && !stubs?.length && (
                <Alert status="warning" mt={2}>
                  <AlertIcon />
                  <AlertTitle mr={2}>
                    Não há nenhum canhoto anexado! Adicione um canhoto antes de finalizar o frete!
                  </AlertTitle>
                </Alert>
              )}

              {stubsPending && stubsPending.length > 0 && !isLoadingStubs && (
                <Flex
                  border={1}
                  borderStyle="solid"
                  borderColor="#E2E8F0"
                  borderRadius="lg"
                  p={3}
                  mt={2}
                  flexDirection="column"
                  overflowX={stubsPending.length > 4 ? 'auto' : 'hidden'}
                  w="49%"
                >
                  <Flex flexDirection="row" fontSize={14}>
                    <Text textColor="#A0AEC0">Quantidade de anexos pendentes:</Text>
                    <Tag textColor="red" ml={1}>
                      {stubsPending.length}
                    </Tag>
                  </Flex>

                  <Flex mt={3} w="100%">
                    {stubsPending.map(stub => (
                      <Flex justifyContent="right" mr={1}>
                        <Icon
                          as={AiOutlineClose}
                          position="absolute"
                          bg={bg}
                          cursor="pointer"
                          onClick={() => {
                            onOpenDeleteStubModal()
                            setDeleteStubAttachment({
                              attachment_file_url: stub.attachment_file_url,
                              handleDeleteStub: () => handleDeleteStub({ id: stub.id }),
                            })
                          }}
                        />
                        <Image
                          w="100px"
                          h="100px"
                          src={stub.attachment_file_url}
                          cursor="pointer"
                          onClick={async () => {
                            if (
                              [
                                'antonio.salomao@spotx.com.br',
                                'zenilton.filho@spotx.com.br',
                                'marcelo.junior@spotx.com.br',
                                'monitoramento@spotx.com.br',
                                'fernando.vidal@spotx.com.br',
                                'villyane.santana@spotx.com.br',
                                'vinicius.lira@spotx.com.br',
                                'cleginaldo3000@gmail.com',
                                'pv.lima02@gmail.com',
                                'borges@gmail.com',
                              ].includes(user.email)
                            ) {
                              onOpenViewStubModal()
                              setStubAttachment({
                                ...stub,
                              })
                            }
                          }}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              )}

              {stubs && stubs.length > 0 && !isLoadingStubs && (
                <Flex
                  border={1}
                  borderStyle="solid"
                  borderColor="#E2E8F0"
                  borderRadius="lg"
                  p={3}
                  mt={2}
                  flexDirection="column"
                  overflowX={stubs.length > 4 ? 'auto' : 'hidden'}
                >
                  <Flex flexDirection="row" fontSize={14}>
                    <Text textColor="#A0AEC0">Quantidade de anexos analisados:</Text>
                    <Tag textColor="green" ml={1}>
                      {stubs.length}
                    </Tag>
                  </Flex>

                  <Flex mt={3} w="100%">
                    {stubs.map(stub => (
                      <Flex justifyContent="right" mr={1}>
                        <Icon
                          as={stub.status === 'approved' ? AiOutlineCheck : AiOutlineClose}
                          position="absolute"
                          bg={stub.status === 'approved' ? 'green' : 'red'}
                          cursor="pointer"
                        />
                        <Image
                          w="100px"
                          h="100px"
                          src={stub.attachment_file_url}
                          cursor="pointer"
                          onClick={async () => {
                            onOpenShowStubModal()
                            setShowStubAttachment({
                              ...stub,
                            })
                          }}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              )}
            </WrapItem>
          </Box>
        </SimpleGrid>
        <Divider my={6} />
        <ListNfes freight_id={freight.id} />
      </Box>

      <CreateStubsModal
        isOpen={isOpenCreateStubsModal}
        onClose={() => {
          onCloseCreateStubsModal()
          queryClient.invalidateQueries('stubs')
        }}
        freight_id={freight.id}
      />

      <CreateNfesModal isOpen={isOpenCreateNfesModal} onClose={onCloseCreateNfesModal} />

      <AttachStubNfesAttachment
        isOpen={isOpenViewStubModal}
        onClose={() => {
          onCloseViewStubModal()
          queryClient.invalidateQueries('stubs')
        }}
        stub={stubAttachment}
      />

      <ShowStubModal
        isOpen={isOpenShowStubModal}
        onClose={() => {
          onCloseShowStubModal()
          queryClient.invalidateQueries('stubs')
        }}
        stub={showStubAttachment}
      />

      {deleteStubAttachment && isOpenDeleteStubModal && (
        <DeleteStubModal
          stub={deleteStubAttachment}
          isOpen={isOpenDeleteStubModal}
          onClose={onCloseDeleteStubModal}
        />
      )}
    </Box>
  ) : null
}
