import RoutePlannerMap from './RoutePlannerMap'

export default function RoutePlanner(): JSX.Element {
  const urlParams = new URLSearchParams(window.location.search)

  return (
    <RoutePlannerMap
      freight_id={urlParams.get('freight_id') || undefined}
      freight_status={urlParams.get('freight_status') || undefined}
    />
  )
}
