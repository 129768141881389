import { Divider, Grid, GridItem, Heading, Icon, Text, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiFileCopyLine } from 'react-icons/ri'
import * as yup from 'yup'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { Select } from '../../components/form/Select'
import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { usePendenciesOCRContext } from '../../contexts/PendenciesOCRContext'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { findMotoristByCpf } from '../../services/endpoints/motorists/findMotoristByCpf'
import { FormattedMotorist } from '../../services/endpoints/motorists/getMotorist'
import { findOwnerByCpf } from '../../services/endpoints/owners/findOwnerByCpf'
import { MotoristType } from '../../services/types'
import BlackListType from '../../services/types/BlackListType'
import OwnerType from '../../services/types/OwnerType'
import { copyToClipboard } from '../../services/utils/copyToClipboard'
import { formatDateForInitialValue } from '../../services/utils/dates/formatDateForInitialValue'
import { formatDateToEUA } from '../../services/utils/dates/formatDateToCountry'
import { addMoreOneParamToUrl } from '../../services/utils/format-url/addMoreOneParamToUrl'
import { CEP, searchAddressByCep } from '../../services/utils/searchAddressByCep'
import { ufOptions } from '../../services/utils/ufOptions'
import { validateCpf } from '../../services/utils/validateCpf'
import { useAppDispatch } from '../../store'
import { setMotoristId } from '../../store/slices/motoristSlice'
import { handleCpfIsBlackListed } from '../black-list/check-document-exists/handleCpfIsBlackListed'
import { MotoristEditFormData } from './modals/EditMotoristModal'

export interface MotoristCompleteFormData {
  id?: string
  phone: string
  address_city_id: AutocompleteOption
  complement: string
  district: string
  address: string
  zipcode: string
  birth: string
  birth_city: AutocompleteOption
  rg_dispatch_date: string
  rg_uf: string
  rg: string
  mother_name: string
  father_name: string
  name: string
  cpf: string
  address_number: number
  is_completed?: number
  gender?: string
  rntrc: string
  birth_state?: string
  marital_status?: string
  school_degree?: string
  rg_dispatcher?: string
  pis?: string
  state?: string
  collects_annual_iss?: boolean
  bank?: AutocompleteOption
  account_type?: string
  agency?: string
  account?: string
  motorist_contact_id_ref_person_1: string
  motorist_contact_id_ref_person_2: string
  motorist_contact_id_ref_professional_1: string
  motorist_contact_id_ref_professional_2: string
  name_contacts_ref_person_1: string
  name_contacts_ref_person_2: string
  name_contacts_ref_professional_1: string
  name_contacts_ref_professional_2: string
  phone_ref_person_1: string
  phone_ref_person_2: string
  phone_ref_professional_1: string
  phone_ref_professional_2: string
  type_contacts_ref_person_1: string
  type_contacts_ref_person_2: string
  type_contacts_ref_professional_1: string
  type_contacts_ref_professional_2: string
}

interface MotoristCompleteFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialData?: FormattedMotorist | MotoristEditFormData
  isEdit?: boolean
  ownerExistsData?: OwnerType
  setCpfInBlackList?: (data: BlackListType | undefined) => void
  onOpen?: () => void
  CpfRef?: React.RefObject<HTMLInputElement>
}

export function MotoristCompleteForm({
  setValue,
  formState,
  initialData,
  ownerExistsData,
  isEdit,
  setCpfInBlackList,
  onOpen,
  CpfRef,
}: MotoristCompleteFormProps): JSX.Element {
  const { resultOCR } = usePendenciesOCRContext()
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { errors } = formState

  const motoristPhoneRef = useRef<HTMLInputElement>(null)
  const [owner, setOwner] = useState<OwnerType | undefined>(ownerExistsData)
  const [motoristExists, setMotoristExists] = useState<
    FormattedMotorist | MotoristType | OwnerType | undefined
  >()
  const [address, setAddress] = useState<CEP>()
  const [ufAddress, setUfAddress] = useState(initialData?.address_city?.state?.uf || '')

  const motoristContact1 = useMemo(() => {
    if (initialData?.motorist_contacts) {
      if (initialData.motorist_contacts.length > 0) {
        const personal = initialData.motorist_contacts.filter(contact => contact.type !== 'COMERCIAL')
        if (personal.length > 0) return personal[0]
      }
    }
    return undefined
  }, [initialData])

  const motoristContact2 = useMemo(() => {
    if (initialData?.motorist_contacts) {
      if (initialData.motorist_contacts.length > 0) {
        const personal = initialData.motorist_contacts.filter(contact => contact.type !== 'COMERCIAL')
        if (personal.length > 1) return personal[1]
      }
    }
    return undefined
  }, [initialData])

  const motoristContact3 = useMemo(() => {
    if (initialData?.motorist_contacts) {
      if (initialData.motorist_contacts.length > 0) {
        const personal = initialData.motorist_contacts.filter(contact => contact.type === 'COMERCIAL')
        if (personal.length > 0) return personal[0]
      }
    }
    return undefined
  }, [initialData])

  const motoristContact4 = useMemo(() => {
    if (initialData?.motorist_contacts) {
      if (initialData.motorist_contacts.length > 0) {
        const personal = initialData.motorist_contacts.filter(contact => contact.type === 'COMERCIAL')
        if (personal.length > 1) return personal[1]
      }
    }
    return undefined
  }, [initialData])

  useEffect(() => {
    if (owner && owner.zipcode) {
      const loadAddress = async () => {
        const cep = await searchAddressByCep(String(owner.zipcode))
        if (cep) setAddress(cep)
      }
      loadAddress()
    }
  }, [owner])

  useEffect(() => {
    const loadAddress = async () => {
      if (resultOCR?.cpf) {
        const motorist = await findMotoristByCpf(resultOCR.cpf)
        if (motorist?.id) {
          setMotoristExists(motorist)
          addMoreOneParamToUrl('motorist_id', motorist.id)
          dispatch(setMotoristId(motorist.id))
        }
      }
    }
    loadAddress()
  }, [dispatch, resultOCR?.cpf])

  if (initialData?.motorist_contacts && initialData.motorist_contacts.length) {
    setValue('motorist_contact_id_ref_person_1', motoristContact1?.id)
    setValue('motorist_contact_id_ref_person_2', motoristContact2?.id)
    setValue('motorist_contact_id_ref_professional_1', motoristContact3?.id)
    setValue('motorist_contact_id_ref_professional_2', motoristContact4?.id)
  }

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap="4">
        <GridItem colSpan={12}>
          <Divider />
        </GridItem>

        <GridItem colSpan={owner || initialData || resultOCR?.cpf ? [12, 6, 6] : 12}>
          <Heading size="md">Dados Pessoais</Heading>
        </GridItem>

        {(owner || initialData || resultOCR?.cpf) && (
          <GridItem colSpan={[12, 6, 6]}>
            <Text
              cursor="pointer"
              color="blue.200"
              onClick={() => {
                if (initialData?.cpf) copyToClipboard(initialData.cpf)
                if (owner?.cpf) copyToClipboard(owner.cpf)
                if (resultOCR?.cpf) copyToClipboard(resultOCR.cpf)
                toast({
                  title: 'Documento copiado com sucesso!',
                  status: 'success',
                  position: 'top-right',
                  duration: 1000,
                })
              }}
              fontWeight="medium"
            >
              Copiar documento <Icon as={RiFileCopyLine} fontSize={16} />
            </Text>
          </GridItem>
        )}

        <GridItem colSpan={[12, 3]}>
          <InputMask
            name="cpf"
            label="CPF"
            mask="999.999.999-99"
            autoFocus
            maskPlaceholder=""
            ref={CpfRef}
            setValue={setValue}
            initialValue={initialData?.cpf || resultOCR?.cpf}
            error={errors.cpf}
            onChange={async e => {
              if (e.target.value.length === 14) {
                const { value } = e.target
                const motorist = await findMotoristByCpf(value)
                if (motorist?.id) {
                  setMotoristExists(motorist)
                  addMoreOneParamToUrl('motorist_id', motorist.id)
                  dispatch(setMotoristId(motorist.id))
                }
                const data = await handleCpfIsBlackListed(value)
                const ownerCpf = await findOwnerByCpf(value)
                setOwner(ownerCpf)
                if (data) {
                  if (setCpfInBlackList) setCpfInBlackList(data)
                  if (onOpen) onOpen()
                } else if (setCpfInBlackList) setCpfInBlackList(undefined)
              }
            }}
            isRequired
            registerOnlyNumbers
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Input
            name="name"
            label="Nome Completo"
            setValue={setValue}
            initialValue={motoristExists?.name || owner?.name || resultOCR?.name || initialData?.name}
            error={errors.name}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <InputMask
            isRequired
            ref={motoristPhoneRef}
            name="phone"
            mask="(99) 9 9999-9999"
            registerOnlyNumbers
            maskPlaceholder=""
            label="Telefone Principal"
            error={errors.phone}
            autoFocus={!isEdit}
            setValue={setValue}
            initialValue={motoristExists?.phone || owner?.phone || initialData?.phone}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Input
            name="mother_name"
            label="Nome da Mãe"
            setValue={setValue}
            initialValue={
              motoristExists?.mother_name ||
              owner?.mother_name ||
              resultOCR?.motherName ||
              initialData?.mother_name
            }
            error={errors.mother_name}
            isRequired
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <Input
            isRequired
            name="father_name"
            label="Nome do pai"
            error={errors.father_name}
            setValue={setValue}
            initialValue={
              motoristExists?.father_name ||
              owner?.father_name ||
              resultOCR?.fatherName ||
              initialData?.father_name
            }
            uppercaseFirst
          />
        </GridItem>

        {/* <GridItem colSpan={12}>
          <Divider />
        </GridItem>

        <GridItem colSpan={12}>
          <Heading size="md">Dados Pessoais</Heading>
        </GridItem> */}

        <GridItem colSpan={[12, 4]}>
          <Input
            name="rg"
            label="RG"
            setValue={setValue}
            initialValue={motoristExists?.rg || owner?.rg || resultOCR?.identity || initialData?.rg}
            error={errors.rg}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="rg_dispatcher"
            label="Órgão expeditor RG"
            error={errors.rg_dispatcher}
            setValue={setValue}
            isRequired
            initialValue={
              motoristExists?.rg_dispatcher ||
              owner?.rg_dispatcher ||
              resultOCR?.dispatcher ||
              initialData?.rg_dispatcher
            }
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="rg_uf"
            label="RG UF"
            setValue={setValue}
            options={ufOptions}
            initialValue={
              motoristExists?.rg_uf ||
              owner?.rg_uf ||
              resultOCR?.dispatcherUf ||
              initialData?.driver_license?.dispatcher_uf
            }
            error={errors.rg_uf}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="rg_dispatch_date"
            type="date"
            label="Data de emissão RG"
            setValue={setValue}
            initialValue={
              motoristExists?.rg_dispatch_date ||
              owner?.rg_dispatch_date ||
              formatDateToEUA(resultOCR?.emissionDate) ||
              formatDateForInitialValue(initialData?.rg_dispatch_date)
            }
            error={errors.rg_dispatch_date}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="birth"
            type="date"
            label="Data Nascimento"
            setValue={setValue}
            initialValue={
              motoristExists?.birth ||
              owner?.birth ||
              formatDateToEUA(resultOCR?.birthDate) ||
              formatDateForInitialValue(initialData?.birth)
            }
            error={errors.birth}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="email"
            label="E-mail"
            error={errors.email}
            setValue={setValue}
            initialValue={motoristExists?.email || owner?.email || initialData?.email}
          />
        </GridItem>

        <GridItem colSpan={12}>
          <Divider my={4} />
        </GridItem>

        <GridItem colSpan={12}>
          <Heading size="md">Endereço</Heading>
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <InputMask
            name="zipcode"
            label="CEP"
            mask="99.999-999"
            registerOnlyNumbers
            maskPlaceholder=""
            setValue={setValue}
            initialValue={
              motoristExists?.zipcode || owner?.zipcode || address?.zipcode || initialData?.zipcode
            }
            error={errors.zipcode}
            isRequired
            onChange={async e => {
              if (e.target.value.length === 10) {
                const cep = await searchAddressByCep(String(e.target.value))

                if (cep) {
                  setAddress(cep)
                }
              }
            }}
            onBlur={async e => {
              const cep = await searchAddressByCep(String(e.target.value))
              if (cep) {
                setAddress(cep)
              }
            }}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="address"
            label="Endereço"
            setValue={setValue}
            initialValue={
              motoristExists?.address || owner?.address || address?.address || initialData?.address
            }
            error={errors.address}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <InputMask
            mask="99999999999999999999"
            maskPlaceholder=""
            name="address_number"
            label="Número"
            setValue={setValue}
            initialValue={
              (motoristExists?.address_number ? String(motoristExists?.address_number) : undefined) ||
              (owner?.address_number ? String(owner?.address_number) : undefined) ||
              (initialData?.address_number ? String(initialData?.address_number) : undefined)
            }
            error={errors.address_number}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="district"
            label="Bairro"
            setValue={setValue}
            initialValue={
              motoristExists?.district || owner?.district || address?.district || initialData?.district
            }
            error={errors.district}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <AutocompleteAsync
            name="address_city_id"
            label="Cidade"
            setValue={setValue}
            loadOptions={searchCitiesByName}
            onSelectOption={option => {
              setUfAddress(option.uf)
            }}
            initialValue={
              address?.city ||
              (initialData?.address_city && {
                label: `${initialData?.address_city?.name} - ${initialData?.address_city?.state?.uf}`,
                value: String(initialData?.address_city?.ibge_id),
              }) ||
              undefined
            }
            error={errors.address_city_id}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 1]}>
          <Input
            name="birth_state"
            label="UF"
            error={errors.birth_state}
            setValue={setValue}
            initialValue={ufAddress || address?.uf}
            isRequired
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={12}>
          <Divider my={4} />
        </GridItem>

        <GridItem colSpan={12}>
          <Heading size="md">Contatos</Heading>
        </GridItem>

        <GridItem colSpan={12}>
          <Heading size="sm" my={4}>
            Pessoal:
          </Heading>
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="name_contacts_ref_person_1"
            label="Nome do contato 1"
            setValue={setValue}
            error={errors.name_contacts_ref_person_1}
            isRequired
            initialValue={motoristContact1?.name}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Select
            isRequired
            name="type_contacts_ref_person_1"
            label="Tipo de relação 1"
            options={[
              { label: '', value: '' },
              { label: 'Amigo', value: 'AMIGO' },
              { label: 'Cônjuge', value: 'CÔNJUGE' },
              { label: 'Parente', value: 'PARENTE' },
              { label: 'Vizinho', value: 'VIZINHO' },
            ]}
            setValue={setValue}
            error={errors.type_contacts_ref_person_1}
            initialValue={motoristContact1?.type}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <InputMask
            isRequired
            name="phone_ref_person_1"
            mask="(99) 9 9999-9999"
            maskPlaceholder=""
            label="Telefone pessoal 1"
            error={errors.phone_ref_person_1}
            autoFocus={!isEdit}
            setValue={setValue}
            initialValue={motoristContact1?.phone}
            registerOnlyNumbers
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="name_contacts_ref_person_2"
            label="Nome do contato 2"
            setValue={setValue}
            error={errors.name_contacts_ref_person_2}
            isRequired
            initialValue={motoristContact2?.name}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Select
            name="type_contacts_ref_person_2"
            label="Tipo de relação 2"
            options={[
              { label: '', value: '' },
              { label: 'Amigo', value: 'AMIGO' },
              { label: 'Cônjuge', value: 'CÔNJUGE' },
              { label: 'Parente', value: 'PARENTE' },
              { label: 'Vizinho', value: 'VIZINHO' },
            ]}
            setValue={setValue}
            error={errors.type_contacts_ref_person_2}
            isRequired
            initialValue={motoristContact2?.type}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <InputMask
            name="phone_ref_person_2"
            mask="(99) 9 9999-9999"
            maskPlaceholder=""
            label="Telefone pessoal 2"
            error={errors.phone_ref_person_2}
            autoFocus={!isEdit}
            setValue={setValue}
            isRequired
            initialValue={motoristContact2?.phone}
            registerOnlyNumbers
          />
        </GridItem>

        <GridItem colSpan={12}>
          <Heading size="sm" my={4}>
            Profissional:
          </Heading>
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="name_contacts_ref_professional_1"
            label="Nome do contato 1"
            setValue={setValue}
            error={errors.name_contacts_ref_professional_1}
            isRequired
            initialValue={motoristContact3?.name}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Select
            isRequired
            name="type_contacts_ref_professional_1"
            label="Tipo de relação 1"
            options={[{ label: 'Comercial', value: 'COMERCIAL' }]}
            setValue={setValue}
            error={errors.type_contacts_ref_professional_1}
            initialValue="COMERCIAL"
            isDisabled
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <InputMask
            isRequired
            name="phone_ref_professional_1"
            mask="(99) 9 9999-9999"
            maskPlaceholder=""
            label="Telefone profissional 1"
            error={errors.phone_ref_professional_1}
            autoFocus={!isEdit}
            setValue={setValue}
            initialValue={motoristContact3?.phone}
            registerOnlyNumbers
          />
        </GridItem>
        {/* GridItem 2 */}
        <GridItem colSpan={[12, 4]}>
          <Input
            name="name_contacts_ref_professional_2"
            label="Nome do contato 2"
            setValue={setValue}
            error={errors.name_contacts_ref_professional_2}
            isRequired
            initialValue={motoristContact4?.name}
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <Select
            name="type_contacts_ref_professional_2"
            label="Tipo de relação 2"
            options={[{ label: 'Comercial', value: 'COMERCIAL' }]}
            setValue={setValue}
            error={errors.type_contacts_ref_professional_2}
            isRequired
            initialValue="COMERCIAL"
            isDisabled
          />
        </GridItem>
        <GridItem colSpan={[12, 4]}>
          <InputMask
            name="phone_ref_professional_2"
            mask="(99) 9 9999-9999"
            maskPlaceholder=""
            label="Telefone profissional 2"
            error={errors.phone_ref_professional_2}
            autoFocus={!isEdit}
            setValue={setValue}
            isRequired
            initialValue={motoristContact4?.phone}
            registerOnlyNumbers
          />
        </GridItem>
      </Grid>
    </>
  )
}

export const motoristCompleteFormSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  other_phone: yup.string(),
  email: yup.string().email('E-mail inválido'),
  gender: yup.string(),
  birth: yup.date(),
  birth_city: yup.string(),
  birth_state: yup.string(),
  cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (value) return validateCpf(value)
    return true
  }),
  marital_status: yup.string(),
  school_degree: yup.string(),
  rg: yup.string(),
  rg_uf: yup.string(),
  rg_dispatcher: yup.string(),
  rg_dispatch_date: yup.date(),
  pis: yup.string(),
  address: yup.string(),
  address_number: yup.string(),
  district: yup.string(),
  address_city_id: yup.object().shape({}),
  zipcode: yup.string(),
  state: yup.string(),
  collects_annual_iss: yup.boolean(),
  mother_name: yup.string(),
  father_name: yup.string(),
  bank: yup.object().shape({}),
  account_type: yup.string(),
  agency: yup.string().min(5, 'Deve conter no mínimo 5 caracteres'),
  account: yup.string(),
})
