import { Box, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiUserAddLine } from 'react-icons/ri'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { PageHeader } from '../../../components/UI/PageHeader'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { apiServer } from '../../../services/api'
import { MotoristType, ResponseApiType } from '../../../services/types'
import { validateCpf } from '../../../services/utils/validateCpf'
import { MotoristQuickForm } from '../MotoristQuickForm'

export interface MotoristQuickFormData {
  name: string
  phone: string
  cpf: string
  other_phone: string
  checkin: AutocompleteOption
  checkin_at: Date
  origin: AutocompleteOption
  destination: AutocompleteOption
  prospected_by: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const motoristQuickFormSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório').min(11, 'Telefone incompleto'),
  cpf: yup
    .string()
    .test('validate-cpf', 'CPF inválido', value => {
      if (value) return validateCpf(value)
      return true
    })
    .required('Campo obrigatório'),
  other_phone: yup.string(),
  checkin: yup.object().notRequired(),
  checkin_at: yup.string(),
  origin: yup.object().notRequired(),
  destination: yup.object().notRequired(),
  prospected_by: yup.string(),
})

export function CreateMotorist(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)

  const license_plate = query.get('license_plate') ? String(query.get('license_plate')) : undefined
  const toast = useToast()

  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(motoristQuickFormSchema),
  })
  const { errors } = formState

  const handleCreateMotorist: SubmitHandler<MotoristQuickFormData> = async data => {
    try {
      const { data: response } = await apiServer.post<ResponseApiType<MotoristType>>('/create-motorist', {
        name: data.name,
        phone: data.phone,
        cpf: data.cpf,
        secondary_phone: data.secondary_phone,
      })
      const { data: newMotorist } = response

      toast({
        title: 'Motorista cadastrado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
      history.push(`/vehicles/create?motorist_id=${newMotorist.id}`)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout>
      <PageHeader title="Cadastrar motorista" icon={RiUserAddLine} />
      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <MotoristQuickForm
          initialData={{ license_plate }}
          setValue={setValue}
          handleSubmit={handleSubmit}
          formState={formState}
          errors={errors}
          handleCreateMotorist={handleCreateMotorist}
        />
      </Box>
    </Layout>
  )
}
