/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Select,
  Skeleton,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiDeleteBinLine, RiDirectionLine, RiMapPin2Fill, RiMapPin2Line, RiSearchLine } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { AutocompleteAsync, Input, Switch } from '../../../../components/form'
import { ToggleColorMode } from '../../../../components/ToggleColorMode'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { Logo } from '../../../../layout/Header/Logo'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { useGetFreightByNumber } from '../../../../services/endpoints/freights/getFreightByNumber'
import {
  GetRoutePointsReturn,
  useGetRoutePoints,
} from '../../../../services/endpoints/truckpad/find-route-points'
import { getAnttValue } from '../../../../services/endpoints/truckpad/getAnttValue'
import {
  getTollTagsTransporters,
  useTollTagsTransporters,
} from '../../../../services/endpoints/truckpad/getTollTagsTransporters'
import { usePostRoutePlanner } from '../../../../services/endpoints/truckpad/postRoutePlanner'
import parseCurrency from '../../../../services/utils/parseCurrency'
import { RoutePlannerModal } from './modals/RoutePlannerModal'
import RoutePlannerMapComponent from './RoutePlannerMapComponent'

type LocationState = {
  label: string
  lat: number
  lng: number
  uf: string
  country: string
  cityName: string
}

export type PlaceType = {
  id?: string
  position: google.maps.LatLngLiteral & { label?: string }
  state?: string
  country?: string
  city?: string
  uf?: string
  cityName?: string
  type?: string | undefined
}
interface RoutePlannerMapProps {
  freight_id?: string
  freight_status?: string
}

type SolutionTollType = {
  provider: string
}

type VehicleCategoryType = {
  id: number | null
  codigo: number
  nome: string
  numeroEixo: number
  rodagem: string
}

const postRoutePlannerFormSchema = yup.object().shape({
  tollSolution: yup.string().required('Campo obrigatório'),
  vehicleCategory: yup.string().required('Campo obrigatório'),
  origin: yup.string().required('Campo obrigatório'),
  destination: yup.string().required('Campo obrigatório'),
})

const anttFormSchema = yup.object().shape({
  cargoType: yup.string().required('Campo obrigatório'),
})

const initialVehicleCategory = [
  {
    id: 1,
    nome: 'Automóvel, Caminhonete, Camioneta, Furgão',
    numeroEixo: 2,
    rodagem: 'Simples',
    codigo: 1,
  },
  {
    id: 2,
    nome: 'Caminhão Leve, Caminhão-Trator, Furgão | Ônibus',
    numeroEixo: 2,
    rodagem: 'Dupla',
    codigo: 2,
  },
  {
    id: 3,
    nome: 'Automóvel com Semi-Reboque',
    numeroEixo: 3,
    rodagem: 'Simples',
    codigo: 3,
  },
  {
    id: 4,
    nome: 'Caminhão, Caminhão-Trator com Semi-Reboque | Ônibus',
    numeroEixo: 3,
    rodagem: 'Dupla',
    codigo: 4,
  },
  {
    id: 5,
    nome: 'Automóvel com Reboque',
    numeroEixo: 4,
    rodagem: 'Simples',
    codigo: 5,
  },
  {
    id: 6,
    nome: 'Caminhão, Caminhão-Trator com Semi-Reboque',
    numeroEixo: 4,
    rodagem: 'Dupla',
    codigo: 6,
  },
  {
    id: 7,
    nome: 'Caminhão com Reboque, Caminhão-Trator',
    numeroEixo: 5,
    rodagem: 'Dupla',
    codigo: 7,
  },
  {
    id: 8,
    nome: 'Caminhão com Reboque, Caminhão-Trator com Semi-Reboque',
    numeroEixo: 6,
    rodagem: 'Dupla',
    codigo: 8,
  },
  {
    id: 9,
    nome: 'Motocicleta, Motoneta, Bicicletas a Motor',
    numeroEixo: 2,
    rodagem: 'Simples',
    codigo: 9,
  },
  {
    id: 10,
    nome: 'Veículo Especial - 7 Eixos',
    numeroEixo: 7,
    rodagem: 'Dupla',
    codigo: 10,
  },
  {
    id: 11,
    nome: 'Veículo Especial - 8 Eixos',
    numeroEixo: 8,
    rodagem: 'Dupla',
    codigo: 11,
  },
  {
    id: 12,
    nome: 'Veículo Especial - 9 Eixos',
    numeroEixo: 9,
    rodagem: 'Dupla',
    codigo: 12,
  },
  {
    id: 13,
    nome: 'Veículo Especial - 10 Eixos',
    numeroEixo: 10,
    rodagem: 'Dupla',
    codigo: 13,
  },
]

const parseLocationFromUrl = (paramName: string): LocationState | undefined => {
  const params = new URLSearchParams(window.location.search)
  const paramValue = params.get(paramName)
  if (paramValue) {
    try {
      return JSON.parse(decodeURIComponent(paramValue))
    } catch (error) {
      // pass
    }
  }
}

export default function RoutePlannerMap({ freight_id, freight_status }: RoutePlannerMapProps): JSX.Element {
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const history = useHistory()

  const [distance, setDistance] = useState<number | undefined>()

  const [origin, setOrigin] = useState<LocationState | undefined>(parseLocationFromUrl('origin'))
  const [destination, setDestination] = useState<LocationState | undefined>(
    parseLocationFromUrl('destination'),
  )
  const [places, setPlaces] = useState<PlaceType[]>([])
  const vehiclePlateParam = useQueryParams('vehicle_plate')
  const { data: tollTagData, isLoading: isLoadingTollTagData } = useTollTagsTransporters({
    plate: vehiclePlateParam,
  })
  const [vehiclePlate, setVehiclePlate] = useState(vehiclePlateParam)
  const [tollSolutions, setTollSolutions] = useState<SolutionTollType[]>([
    { provider: 'semparar' },
    { provider: 'conectcar' },
  ])
  const [tollSolution, setTollSolution] = useState(tollTagData ? tollTagData[0].provider : 'semparar')
  const [categoriaVeiculo, setCategoriaVeiculo] = useState(
    tollTagData ? tollTagData[0].categoria.codigo.toString() : '',
  )
  const [vehicleCategoryCode, setVehicleCategoryCode] = useState('')
  const [vehicleCategory, setVehicleCategory] = useState<VehicleCategoryType[]>(initialVehicleCategory)
  const [isLoadingGetTollsQuery, setIsLoadingGetTollsQuery] = useState(false)
  const [routes, setRoutes] = useState<any>()
  const [stops, setStops] = useState<JSX.Element[]>([])
  const [isRoundTrip, setIsRoundTrip] = useState(false)
  const [cargoType, setCargoType] = useState('5')
  const [isVehicleComposition, setIsVehicleComposition] = useState(false)
  const [isHighPerformance, setIsHighPerformance] = useState(false)
  const [isReturnEmpty, setIsReturnEmpty] = useState(false)
  const [axesNumber, setAxesNumber] = useState(4)
  const [isLoadingAnttValue, setIsLoadingAnttValue] = useState(false)
  const [anttValue, setAnttValue] = useState<number>()
  const [freightId, setFreightId] = useState<string | undefined>(
    new URLSearchParams(window.location.search).get('freight_id') || undefined,
  )
  const [shouldUpdateUrl, setShouldUpdateUrl] = useState(false)
  const [routeResponsePoints, setRouteResponsePoints] = useState<PlaceType[]>()

  const { data: isBlockedEntity } = useIsBlockEntity({
    freight_id,
  })

  const freightNumberQueryParam = useQueryParams('freight_number')

  const { data: freightData } = useGetFreightByNumber(freightNumberQueryParam)
  const isFreightPage = useQueryParams('isFreightPage')
  const isPendenciesPage = useQueryParams('isPendenciesPage')
  const { data: routePointsData } = useGetRoutePoints({ freight_id: useQueryParams('freight_id') })
  const [tollValue, setTollValue] = useState<number | undefined>(
    routePointsData && routePointsData.length > 0 ? routePointsData[0].toll_value : undefined,
  )

  const { colorMode } = useColorMode()

  function normalizeRoutePoints(data: GetRoutePointsReturn[]): PlaceType[] {
    return data.map(point => ({
      position: {
        lat: point.latitude,
        lng: point.longitude,
      },
    }))
  }

  function determinePlacesData(
    routePoints?: GetRoutePointsReturn[],
    routesToPlaces?: PlaceType[],
    placesParam?: PlaceType[],
  ): PlaceType[] {
    if (routesToPlaces) {
      return routesToPlaces
    }
    if (routePointsData) {
      return normalizeRoutePoints(routePoints as GetRoutePointsReturn[]) // isso vai acontecer quando a rota já tiver sido escolhida anteriormente e vindo da tela de pendencias
    }
    if (placesParam) {
      return placesParam
    }
    return []
  }

  const generateStateParams = () => {
    const searchParams = new URLSearchParams()

    if (origin) searchParams.set('origin', JSON.stringify(origin))
    if (destination) searchParams.set('destination', JSON.stringify(destination))
    if (places.length > 0) searchParams.set('places', JSON.stringify(places))
    if (vehiclePlate) searchParams.set('vehiclePlate', vehiclePlate)
    if (tollSolution) searchParams.set('tollSolution', tollSolution)
    if (categoriaVeiculo) searchParams.set('categoriaVeiculo', categoriaVeiculo)
    if (vehicleCategoryCode) searchParams.set('vehicleCategoryCode', vehicleCategoryCode)
    if (isRoundTrip) searchParams.set('isRoundTrip', 'true')

    if (cargoType) searchParams.set('cargoType', cargoType)
    if (isVehicleComposition) searchParams.set('isVehicleComposition', 'true')
    if (isHighPerformance) searchParams.set('isHighPerformance', 'true')
    if (isReturnEmpty) searchParams.set('isReturnEmpty', 'true')
    if (axesNumber !== 4) searchParams.set('axesNumber', axesNumber.toString())
    if (anttValue) searchParams.set('anttValue', String(anttValue))
    if (freightId) searchParams.set('freight_id', freightId)
    if (freight_status) searchParams.set('freight_status', freight_status)

    return searchParams
  }

  const {
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(postRoutePlannerFormSchema),
  })

  const {
    setValue: setValueAntt,
    formState: { errors: errorsAntt },
  } = useForm({
    resolver: yupResolver(anttFormSchema),
  })

  const getPlaceFromParams = (params: any, prefix: any) => {
    const lat = params.get(`${prefix}Lat`)
    const lng = params.get(`${prefix}Lng`)
    if (lat && lng) {
      return {
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          label: params.get(`${prefix}CityName`) ?? '',
        },
        uf: params.get(`${prefix}Uf`) ?? '',
        country: 'BRA',
        cityName: params.get(`${prefix}CityName`) ?? '',
      }
    }
    return null
  }

  const addPlace = (
    lat: string,
    lng: string,
    uf: string,
    cityName: string,
    type: 'origin' | 'destination' | 'stop',
  ) => {
    if (!lat || !lng) return

    if (places.find(place => place.position.label === cityName)) return

    const newPlace = {
      position: { lat: parseFloat(lat), lng: parseFloat(lng), label: cityName },
      uf,
      country: 'BRA',
      cityName,
      type,
    }

    const updatedPlaces = [...places]

    if (type === 'origin') {
      const originIndex = updatedPlaces.findIndex(place => place.type === 'origin')
      if (originIndex !== -1) {
        updatedPlaces[originIndex] = newPlace
      } else {
        updatedPlaces.unshift(newPlace)
      }
    } else if (type === 'destination') {
      const destinationIndex = updatedPlaces.findIndex(place => place.type === 'destination')
      if (destinationIndex !== -1) {
        updatedPlaces[destinationIndex] = newPlace
      } else {
        updatedPlaces.push(newPlace)
      }
    } else if (type === 'stop') {
      const destinationIndex = updatedPlaces.findIndex(place => place.type === 'destination')
      if (destinationIndex !== -1) {
        updatedPlaces.splice(destinationIndex, 0, newPlace)
      } else {
        updatedPlaces.push(newPlace)
      }
    }

    setPlaces(updatedPlaces)
    setShouldUpdateUrl(true)
  }

  const handleGetTollButtonClick = async () => {
    setIsLoadingGetTollsQuery(true)

    try {
      const responseToll = await getTollTagsTransporters({ plate: vehiclePlate || vehiclePlateParam })

      setIsLoadingGetTollsQuery(false)

      const vehicleCategoryAux: VehicleCategoryType[] = []
      const tollSolutionsAux: SolutionTollType[] = []
      const tagsAux: string[] = []
      if (responseToll) {
        responseToll.forEach(toll => {
          tollSolutionsAux.push({ provider: toll.provider })
          vehicleCategoryAux.push(toll.categoria)
          tagsAux.push(`${toll.tag} - ${toll.provider}`)
        })
        setTollSolutions(tollSolutionsAux)
        setVehicleCategory(vehicleCategoryAux)
        setCategoriaVeiculo(vehicleCategoryAux[0].codigo.toString())
        setTollSolution(tollSolutionsAux[0].provider)
      }
      // se estiver sem tags, não exibir mensagem na tela informando Tags não localizadas para a placa informada
      if (tagsAux.length === 0) {
        toast({
          title: 'Tags não localizadas',
          description: `Não foram encontradas tags para a placa informada.
            Digite outra placa, ou use a tag 'semparar' para simular o cálculo.
          `,
          status: 'warning',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      } else {
        toast({
          title: 'Consulta feita com sucesso!',
          description: 'Tags consultadas com sucesso.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      }
    } catch (error) {
      toast({
        title: 'Erro ao consultar dados do pedágio',
        description: 'Não foi possível consultar o valor do pedágio.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
    } finally {
      setIsLoadingGetTollsQuery(false)
    }
  }

  const getTollsTags = async () => {
    const responseToll = await getTollTagsTransporters({ plate: vehiclePlate || vehiclePlateParam })
    const vehicleCategoryAux: VehicleCategoryType[] = []
    const tollSolutionsAux: SolutionTollType[] = []

    if (responseToll) {
      responseToll.forEach(toll => {
        tollSolutionsAux.push({ provider: toll.provider })
        vehicleCategoryAux.push(toll.categoria)
      })
      if (tollSolutionsAux.length === 1) setTollSolution(String(tollSolutionsAux[0]))
      setTollSolutions(tollSolutionsAux)
      setVehicleCategory(vehicleCategoryAux)

      setCategoriaVeiculo(vehicleCategoryAux[0].codigo.toString()) // preenche o select
      setTollSolution(tollSolutionsAux[0].provider) // preenche o select
    }
  }

  useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search)
    const stateParams = generateStateParams()
    const hasChange = Array.from(stateParams.entries()).some(
      ([key, value]) => currentParams.get(key) !== value,
    )

    if (hasChange && !freight_id) {
      window.history.pushState({}, '', `${window.location.pathname}?${stateParams.toString()}`)
      return
    }
    const newPlaces = currentParams.get('places')
    if (newPlaces) setPlaces(JSON.parse(newPlaces))
    setVehiclePlate(currentParams.get('vehiclePlate') || '')
    setCategoriaVeiculo(currentParams.get('categoriaVeiculo') || '')
    setVehicleCategoryCode(currentParams.get('vehicleCategoryCode') || '')
    setIsRoundTrip(currentParams.get('isRoundTrip') === 'true')

    setCargoType(currentParams.get('cargoType') || '')
    setIsVehicleComposition(currentParams.get('isVehicleComposition') === 'true')
    setIsHighPerformance(currentParams.get('isHighPerformance') === 'true')
    setIsReturnEmpty(currentParams.get('isReturnEmpty') === 'true')
    setAxesNumber(Number(currentParams.get('axesNumber')) || 4)
    setAnttValue(Number(currentParams.get('anttValue') || 0))
    setFreightId(currentParams.get('freight_id') || '')

    const intermediatePlaces = JSON.parse(currentParams.get('places') || '[]')

    const originPlace = getPlaceFromParams(currentParams, 'origin')
    const destinationPlace = getPlaceFromParams(currentParams, 'destination')

    if (originPlace && destinationPlace) {
      const updatedPlaces = [...[originPlace], ...intermediatePlaces, ...[destinationPlace]]

      setPlaces(updatedPlaces)
      setShouldUpdateUrl(false)

      setOrigin({
        label: originPlace.position.label,
        lat: originPlace.position.lat,
        lng: originPlace.position.lng,
        uf: originPlace.uf,
        country: originPlace.country,
        cityName: originPlace.cityName,
      })

      setDestination({
        label: destinationPlace.position.label,
        lat: destinationPlace.position.lat,
        lng: destinationPlace.position.lng,
        uf: destinationPlace.uf,
        country: destinationPlace.country,
        cityName: destinationPlace.cityName,
      })

      setPlaces(updatedPlaces)
    }
    getTollsTags()
    setShouldUpdateUrl(false)
  }, [window.location.search])

  useEffect(() => {
    if (shouldUpdateUrl) {
      const params = new URLSearchParams()

      params.set('places', JSON.stringify(places))
      if (vehiclePlate) params.set('vehiclePlate', vehiclePlate)
      if (tollSolution) params.set('tollSolution', tollSolution)
      if (categoriaVeiculo) params.set('categoriaVeiculo', categoriaVeiculo)
      if (vehicleCategoryCode) params.set('vehicleCategoryCode', vehicleCategoryCode)

      params.set('isRoundTrip', String(isRoundTrip))

      params.set('cargoType', cargoType)
      params.set('isVehicleComposition', String(isVehicleComposition))
      params.set('isHighPerformance', String(isHighPerformance))
      params.set('isReturnEmpty', String(isReturnEmpty))
      params.set('axesNumber', String(axesNumber))
      params.set('anttValue', String(anttValue))
      params.set('freight_id', freightId || '')
      params.set('freight_status', freight_status || '')
      params.set('originFreightLat', String(origin?.lat))
      params.set('originFreightLng', String(origin?.lng))
      params.set('originFreightLabel', encodeURIComponent(String(origin?.label)))
      params.set('originFreightUf', encodeURIComponent(String(origin?.uf)))
      params.set('destinationFreightLat', String(destination?.lat))
      params.set('destinationFreightLng', String(destination?.lng))
      params.set('destinationFreightLabel', encodeURIComponent(String(destination?.label)))
      params.set('destinationFreightUf', encodeURIComponent(String(destination?.uf)))

      window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`)

      setShouldUpdateUrl(false)
    }
  }, [shouldUpdateUrl, places, origin, destination])

  useEffect(() => {
    const originAux = parseLocationFromUrl('origin')
    const destinationAux = parseLocationFromUrl('destination')
    const newPlaces = []

    if (originAux) {
      const newOrigin = {
        country: 'BRA',
        cityName: originAux.cityName,
        uf: originAux.uf,
        position: {
          lat: originAux.lat,
          lng: originAux.lng,
          label: originAux.cityName,
        },
        type: 'origin',
      }
      newPlaces.push(newOrigin)
    }

    if (destinationAux) {
      const newDestination = {
        country: 'BRA',
        cityName: destinationAux.cityName,
        uf: destinationAux.uf,
        position: {
          lat: destinationAux.lat,
          lng: destinationAux.lng,
          label: destinationAux.cityName,
        },
        type: 'destination',
      }
      newPlaces.push(newDestination)
      setPlaces(newPlaces)
    }
  }, [])

  const removeStop = (keyToRemove: string) => {
    setStops(stops.filter(stop => stop.key !== keyToRemove))
    setPlaces(places.filter(place => place.position.label !== keyToRemove))
    toast({
      title: 'Parada removida com sucesso!',
      description: 'Parada removida com sucesso.',
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top',
    })
  }

  const addNewStop = () => {
    const newStopKey = uuidv4()
    setStops([
      ...stops,
      <Box key={newStopKey}>
        <Flex align="center" gridGap="2" justify="space-between">
          <RiDirectionLine size="24px" color="yellow" />
          <AutocompleteAsync
            name={`address_stop_${newStopKey}`}
            placeholder="Digite o endereço da parada"
            setValue={setValue}
            onSelectOption={async option => {
              addPlace(option.lat, option.lng, option.uf, option.cityName, 'stop')
              toast({
                title: 'Parada adicionada com sucesso!',
                description: 'Parada adicionada com sucesso.',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top',
              })
            }}
            loadOptions={searchCitiesByName}
          />
          <Button size="lg" colorScheme="red" variant="ghost" p="0" onClick={() => removeStop(newStopKey)}>
            <RiDeleteBinLine />
          </Button>
        </Flex>
      </Box>,
    ])
  }

  const { mutateAsync: mutateAsyncCreate, isLoading } = usePostRoutePlanner({
    onSuccess: async response => {
      toast({
        title: 'Requição feita com sucesso!',
        description: 'Dados do pedágio consultados com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
      const allRoutes = response.data
      setRoutes(allRoutes)
      onOpen()
    },
    onError: () => {
      toast({
        title: 'Erro ao consultar dados do pedágio',
        description: 'Não foi possível consultar o valor do pedágio.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
    },
  })

  const handleAnttValueConsultation = () => {
    setIsLoadingAnttValue(true)
    getAnttValue({
      cargoType,
      axesNumber: String(axesNumber),
      distance: String(distance),
      isVehicleComposition: String(isVehicleComposition),
      isHighPerformance: String(isHighPerformance),
      isReturnEmpty: String(isReturnEmpty),
    })
      .then(response => {
        setAnttValue(response.value)
        toast({
          title: 'Requição feita com sucesso!',
          description: 'Valor ANTT consultado com sucesso.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(error => {
        toast({
          title: 'Erro ao consultar valor ANTT',
          description: `Não foi possível consultar o valor ANTT:${error.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .finally(() => {
        setIsLoadingAnttValue(false)
      })
  }

  useEffect(() => {
    if (!tollTagData && !isLoadingTollTagData) {
      toast({
        title: 'Tags não localizadas',
        description: `Não foram encontradas tags para a placa informada.
          Digite outra placa, ou use a tag 'semparar' para simular o cálculo.
        `,
        status: 'warning',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
    } else if (tollTagData && tollTagData.length > 0 && !isLoadingTollTagData) {
      toast({
        title: 'Consulta feita com sucesso!',
        description: 'Tags consultadas com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
    }
  }, [])

  return (
    <Flex>
      <Box w="750px" p={0} h="100%" background={colorMode === 'light' ? 'gray.100' : 'gray.800'}>
        <Flex alignItems="center" justifyContent="center">
          <Heading p={4} size="lg">
            <Logo />
          </Heading>
          <ToggleColorMode />
        </Flex>
        <Spacer marginY={4} />
        <Box
          p={2}
          overflow="auto"
          maxH="100vh"
          className="scrollable-content"
          h={['100%', '100%', 'calc(100vh - 15vh)']}
          overflowY={['hidden', 'hidden', 'scroll']}
        >
          {freight_id && (
            <Box px="6">
              <Button
                colorScheme="blue"
                onClick={() => {
                  if (isFreightPage) {
                    history.goBack()
                  } else if (isPendenciesPage) {
                    // caso onde o usuário está na página de pendências, mas vem pelo botão que fica abaixo do titulo
                    const oldUrl = localStorage.getItem('currentUrl')
                    if (!oldUrl) return
                    const url = new URL(oldUrl)
                    const currentTabIndex = url.searchParams.get('tabIndex')
                    const newTabIndex = Number(currentTabIndex) + 1

                    url.searchParams.set('tabIndex', newTabIndex.toString())

                    localStorage.setItem('currentUrl', url.toString())
                    window.location.href = url.toString()
                  } else {
                    // caso onde o usuário vem pela tela após antt
                    const oldUrl = localStorage.getItem('currentUrl')
                    if (!oldUrl) return
                    const url = new URL(oldUrl)
                    const currentTabIndex = url.searchParams.get('tabIndex')
                    const newTabIndex = Number(currentTabIndex) + 1
                    url.searchParams.set('tabIndex', newTabIndex.toString())

                    localStorage.setItem('currentUrl', url.toString())
                    window.location.href = url.toString()
                  }
                }}
              >
                {isFreightPage ? 'Voltar para a página do frete' : 'Voltar para a página de pendências'}
              </Button>
            </Box>
          )}

          <form
            onSubmit={async e => {
              e.preventDefault()
              await mutateAsyncCreate({
                body: {
                  vehicle_category: categoriaVeiculo,
                  vehicle_code: vehicleCategoryCode,
                  round_trip: isRoundTrip,
                  provider: tollSolution,
                  places: places.map(place => ({
                    point: [place.position.lng, place.position.lat],
                    state: String(place.uf),
                    country: 'BRA',
                    city: String(place.cityName),
                  })),
                },
              })
            }}
          >
            <Box p="6">
              <Heading size="sm" fontWeight="bold" mb="4">
                Roteirizador{' '}
                {freightData?.freight_number && String(freightData?.freight_number).length > 2 ? (
                  <>
                    -{' '}
                    <Tooltip label={`Ver o frete #${freightData?.freight_number}`} hasArrow placement="auto">
                      <Link to={`/freights/show/${freightData?.id}`}>
                        Frete #{freightData?.freight_number}
                      </Link>
                    </Tooltip>
                  </>
                ) : (
                  ''
                )}
              </Heading>
              <Divider />
              <Flex flexDir="column" gridGap="4" pt="6">
                {freight_id && (
                  <Box>
                    <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                      Placa do Veículo
                    </Heading>
                    <Flex justify="space-between" gridGap="2">
                      <Input
                        type="text"
                        name="ABC-1234"
                        initialValue={vehiclePlateParam !== undefined ? vehiclePlateParam : ''}
                        onChange={e => setVehiclePlate(e.target.value)}
                        setValue={setValue}
                        placeholder="ABC-1234"
                      />
                      <Button
                        colorScheme="orange"
                        size="lg"
                        onClick={handleGetTollButtonClick}
                        leftIcon={isLoadingGetTollsQuery ? <Spinner size="xs" /> : undefined}
                        isLoading={isLoadingGetTollsQuery}
                        p={0}
                      >
                        <RiSearchLine />
                      </Button>
                    </Flex>
                  </Box>
                )}
                <Box>
                  <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                    Solução de pedágio
                  </Heading>

                  {vehiclePlateParam && isLoadingTollTagData ? (
                    <Center>
                      <Skeleton
                        height="40px"
                        width="100%"
                        borderRadius="md"
                        bgColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                      />
                    </Center>
                  ) : (
                    <Select
                      placeholder="Selecione a solução"
                      value={tollSolution}
                      initialValue={tollSolution}
                      name="tollSolution"
                      isRequired
                      isLoading={isLoadingTollTagData}
                      setValue={setValue}
                      onChange={e => setTollSolution(e.target.value)}
                      error={errors.tollSolution}
                      bgColor={colorMode === 'light' ? 'white' : 'gray.900'}
                    >
                      {tollSolutions?.map(solucao => (
                        <option key={solucao.provider} value={solucao.provider}>
                          {solucao.provider}
                        </option>
                      ))}
                    </Select>
                  )}
                </Box>

                <Box>
                  <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                    Categoria do Veículo
                  </Heading>
                  {vehiclePlateParam && isLoadingTollTagData ? (
                    <Center>
                      <Skeleton
                        height="40px"
                        width="100%"
                        borderRadius="md"
                        bgColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                      />
                    </Center>
                  ) : (
                    <Select
                      placeholder="Selecione a categoria"
                      value={categoriaVeiculo}
                      isRequired
                      isLoading={isLoadingTollTagData}
                      errors={errors.vehicleCategory}
                      bgColor={colorMode === 'light' ? 'white' : 'gray.900'}
                      onChange={e => {
                        const selectedValue = e.target.value
                        setCategoriaVeiculo(selectedValue)

                        const selectedCategory = vehicleCategory.find(
                          category => category.codigo === Number(selectedValue),
                        )
                        if (selectedCategory) {
                          setVehicleCategoryCode(selectedCategory.codigo.toString())
                        }
                      }}
                    >
                      {vehicleCategory &&
                        vehicleCategory.map(categoria => (
                          <option key={categoria.codigo} value={categoria.codigo}>
                            {categoria.nome} (Eixos: {categoria.numeroEixo}, Rodagem: {categoria.rodagem})
                          </option>
                        ))}
                    </Select>
                  )}
                </Box>
                {/* <Box>
                  <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                    Tag do Veículo
                  </Heading>
                  <Select
                    bgColor={colorMode === 'light' ? 'white' : 'gray.900'}
                    placeholder="Selecione a tag"
                    value={selectedTag}
                    setValue={setValue}
                    name="tag"
                    onChange={e => {
                      setSelectedTag(e.target.value)
                    }}
                  >
                    {['Veicular', 'Caminhão', 'Caminhão Especial'].map((tg: string) => (
                      <option key={tg} value={tg}>
                        {tg}
                      </option>
                    ))}
                  </Select>
                </Box> */}
              </Flex>
            </Box>

            <Flex flexDir="column" gridGap="6" p="6">
              <Box>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Heading size="sm" fontWeight="bold">
                    Rota
                  </Heading>

                  <Button colorScheme="blue" size="sm" onClick={addNewStop}>
                    Adicionar parada
                  </Button>
                </Flex>
                <Heading
                  size="sm"
                  display="flex"
                  fontSize="sm"
                  gridGap="2"
                  mb="4"
                  color="blue.500"
                  textTransform="uppercase"
                >
                  <RiMapPin2Fill /> Origem
                </Heading>
                <Box>
                  <AutocompleteAsync
                    isRequired
                    initialValue={{
                      label: String(origin?.label || places[0]?.position.label || ''),
                      key: origin?.label,
                      value: String(places[0] && origin ? places[0]?.position.label : ''),
                    }}
                    error={errors.origin}
                    name="address_origin"
                    placeholder="Digite o endereço de origem"
                    setValue={setValue}
                    isDisabled={isBlockedEntity}
                    onSelectOption={async option => {
                      addPlace(option.lat, option.lng, option.uf, option.cityName, 'origin')
                      setOrigin({
                        label: option.cityName,
                        lat: option.lat,
                        lng: option.lng,
                        uf: option.uf,
                        country: 'BRA',
                        cityName: option.cityName,
                      })
                      toast({
                        title: 'Origem adicionada com sucesso!',
                        description: 'Origem adicionada com sucesso.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                        position: 'top',
                      })
                    }}
                    loadOptions={searchCitiesByName}
                  />
                </Box>
              </Box>

              {stops.length > 0
                ? stops.map(stopComponent => stopComponent)
                : places.length > 2 && (
                    <Box>
                      <Heading size="small" fontWeight="bold">
                        Paradas
                      </Heading>
                      <Flex direction="column" gap={2}>
                        {places.slice(1, -1).map(place => (
                          <>
                            <Flex>
                              <AutocompleteAsync
                                key={place.position.label}
                                isRequired
                                initialValue={{
                                  label: String(place.position.label || ''),
                                  key: place.position.label,
                                  value: String(place.position.label),
                                }}
                                name="address_stop"
                                placeholder="Digite o endereço da parada"
                                setValue={setValue}
                                onSelectOption={async option => {
                                  addPlace(option.lat, option.lng, option.uf, option.cityName, 'stop')
                                  toast({
                                    title: 'Parada adicionada com sucesso!',
                                    description: 'Parada adicionada com sucesso.',
                                    status: 'success',
                                    duration: 9000,
                                    isClosable: true,
                                    position: 'top',
                                  })
                                }}
                                loadOptions={searchCitiesByName}
                              />
                              <Button
                                colorScheme="red"
                                onClick={() => {
                                  const size = places.length
                                  setPlaces(places.filter(p => p.position.label !== place.position.label))
                                  if (places.length !== size) {
                                    toast({
                                      title: 'Parada removida com sucesso!',
                                      description: 'Parada removida com sucesso.',
                                      status: 'success',
                                      duration: 9000,
                                      isClosable: true,
                                      position: 'top',
                                    })
                                  }
                                  setShouldUpdateUrl(true)
                                }}
                              >
                                <RiDeleteBinLine />
                              </Button>
                            </Flex>
                          </>
                        ))}
                      </Flex>
                    </Box>
                  )}
              <Box>
                <Heading
                  size="sm"
                  display="flex"
                  fontSize="sm"
                  gridGap="2"
                  mb="4"
                  color="green.500"
                  textTransform="uppercase"
                >
                  <RiMapPin2Line /> Destino
                </Heading>
                <Box>
                  <AutocompleteAsync
                    isRequired
                    error={errors.destination}
                    initialValue={{
                      label: String(
                        destination?.label ||
                          (places.length > 1 ? places[places.length - 1].position.label : ''),
                      ),
                      key: destination?.label,
                      value:
                        destination?.label ||
                        (places.length > 1 ? String(places[places.length - 1].position.label) : ''),
                    }}
                    name="address_destination"
                    isDisabled={isBlockedEntity}
                    placeholder="Digite o endereço de destino"
                    setValue={setValue}
                    onSelectOption={async option => {
                      addPlace(option.lat, option.lng, option.uf, option.cityName, 'destination')
                      setDestination({
                        label: option.cityName,
                        lat: option.lat,
                        lng: option.lng,
                        uf: option.uf,
                        country: 'BRA',
                        cityName: option.cityName,
                      })
                      toast({
                        title: 'Destino adicionado com sucesso!',
                        description: 'Destino adicionado com sucesso.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                        position: 'top',
                      })
                    }}
                    loadOptions={searchCitiesByName}
                  />
                </Box>
              </Box>
            </Flex>

            <Flex px={4} direction="column" gridGap={4}>
              {/* <Flex alignItems="center" justifyContent="space-between">
                <Heading size="sm" fontWeight="bold" flex={1}>
                  Deseja fazer a viagem de volta?
                </Heading>
                <Box>
                  <Switch
                    isChecked={isRoundTrip}
                    onChange={() => {
                      setIsRoundTrip(!isRoundTrip)
                    }}
                    colorScheme="green"
                    size="lg"
                    setValue={setValue}
                    name="round_trip"
                  />
                </Box>
              </Flex> */}
              <Divider my={1} />
              <Box rounded="md">
                <Heading size="sm" colorScheme="gray.500" fontWeight="bold" flex={1}>
                  Valor do Pedágio
                </Heading>

                <Heading as="h3" pt={4} color="green.500" size="sm" colorScheme="green" fontWeight="bold">
                  {tollValue !== undefined
                    ? parseCurrency(tollValue)
                    : routePointsData &&
                      routePointsData.length > 0 &&
                      routePointsData[0].toll_value !== undefined
                    ? parseCurrency(routePointsData[0].toll_value)
                    : 'Sem pedágio'}
                </Heading>
              </Box>
            </Flex>
            <Box px={4} justify="space-between" align="center" gap="4" my={5}>
              <Button type="submit" w="100%" size="lg" colorScheme="green" isLoading={isLoading}>
                Consultar rotas disponíveis
              </Button>
            </Box>
          </form>
          <Divider my={6} />

          {!freight_id && (
            <form
              onSubmit={async e => {
                e.preventDefault()
                handleAnttValueConsultation()
              }}
            >
              <Box px="6">
                <Heading size="sm" fontWeight="bold" mb="4">
                  Calcular frete mínimo
                </Heading>
                <Divider />
                <Box py="4">
                  <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                    Tipo de Carga
                  </Heading>
                  <Select
                    bgColor={colorMode === 'light' ? 'white' : 'gray.900'}
                    isRequired
                    setValue={setValueAntt}
                    error={errorsAntt.cargoType}
                    placeholder="Selecione o tipo de carga"
                    value={cargoType}
                    name="cargoType"
                    onChange={e => setCargoType(e.target.value)}
                  >
                    <option value="5">Carga Geral</option>
                    <option value="12">Carga Granel Pressurizada</option>
                    <option value="4">Conteinerizada</option>
                    <option value="3">Frigorificada ou Aquecida</option>
                    <option value="2">Granel l&#237;quido</option>
                    <option value="1">Granel s&#243;lido</option>
                    <option value="6">Neogranel</option>
                    <option value="11">Perigosa (carga geral)</option>
                    <option value="10">Perigosa (conteinerizada)</option>
                    <option value="9">Perigosa (Frigorificada ou Aquecida)</option>
                    <option value="8">Perigosa (granel l&#237;quido)</option>
                    <option value="7">Perigosa (granel s&#243;lido)</option>
                  </Select>
                </Box>
              </Box>
              <Box px="6" pt="4">
                <Heading size="sm" color="gray.500" fontWeight="medium" mb="2">
                  Composição do Veículo
                </Heading>
                <Divider my={2} />
                <Flex flexDir="column" gridGap="1">
                  <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                    <Text Heading size="sm" fontWeight="bold" flex={1}>
                      Veículo de Composição
                    </Text>
                    <Box>
                      <Switch
                        size="sm"
                        isChecked={isVehicleComposition}
                        onChange={() => setIsVehicleComposition(!isVehicleComposition)}
                        setValue={setValueAntt}
                        name="vehicle_composition"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                    <Text Heading size="sm" fontWeight="bold" flex={1}>
                      Veículo de Alto Desempenho
                    </Text>
                    <Box>
                      <Switch
                        size="sm"
                        isChecked={isHighPerformance}
                        onChange={() => setIsHighPerformance(!isHighPerformance)}
                        setValue={setValueAntt}
                        name="high_performance"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                    <Text Heading size="sm" fontWeight="bold" flex={1}>
                      Retorno Vazio
                    </Text>
                    <Box>
                      <Switch
                        size="sm"
                        isChecked={isReturnEmpty}
                        onChange={() => setIsReturnEmpty(!isReturnEmpty)}
                        setValue={setValueAntt}
                        name="return_empty"
                      />
                    </Box>
                  </Box>
                </Flex>
                <Divider my={2} />
                <Flex flexDir="column" gridGap="4" py="4">
                  <Box>
                    <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                      Distância
                    </Heading>
                    <Input
                      name="distance"
                      type="number"
                      value={distance}
                      onChange={e => setDistance(Number(e.target.value))}
                      placeholder="Distância da Rota"
                      setValue={setValueAntt}
                      isRequired
                    />
                  </Box>
                  <Box>
                    <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
                      Número de Eixos
                    </Heading>

                    <Select
                      bgColor={colorMode === 'light' ? 'white' : 'gray.900'}
                      name="axesNumber"
                      setValue={setValue}
                      value={axesNumber}
                      onChange={e => setAxesNumber(Number(e.target.value))}
                      placeholder="2"
                      isRequired
                    >
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="9">9</option>
                    </Select>
                  </Box>
                </Flex>

                <Button
                  type="submit"
                  w="100%"
                  size="lg"
                  mt="6"
                  colorScheme="green"
                  isLoading={isLoadingAnttValue}
                >
                  Consultar valor ANTT
                </Button>

                <Box>
                  <Heading size="sm" fontWeight="bold" mt="6">
                    Valor ANTT
                  </Heading>
                  <Text>{anttValue ? `${anttValue}` : 'Valor ANTT não consultado'}</Text>
                </Box>
              </Box>
            </form>
          )}
        </Box>
      </Box>

      {!isOpen && (
        <Box w="100%" height="100vh">
          <RoutePlannerMapComponent
            origin={origin || {}}
            destination={destination || {}}
            stopPlaces={places ? places.slice(1, -1) : []}
            showDistance
            zoom={7}
            places={determinePlacesData(routePointsData, routeResponsePoints, places)}
          />
        </Box>
      )}

      <Box w={['100%', '100%', isOpen ? '30vw' : '0px']} p={0} h="100vh">
        <RoutePlannerModal
          isOpen={isOpen}
          onClose={onClose}
          routes={routes?.data?.data || []}
          origin={places.length > 0 ? String(places[0].position.label) : ''}
          destination={places.length > 0 ? String(places[places.length - 1].position.label) : ''}
          setTollValue={setTollValue}
          setDistance={setDistance}
          setRouteResponsePoints={setRouteResponsePoints}
          freight_id={freightId}
          vehicle_license_plate={vehiclePlate || vehiclePlateParam}
          tag_provider={tollSolution}
        />
      </Box>
    </Flex>
  )
}
