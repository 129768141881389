/* eslint-disable no-restricted-syntax */
/* eslint-disable no-inner-declarations */
import { UseToastOptions } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { MessageCodeEnum } from '../../services/types/EnumTypes'

interface ToastError {
  error: unknown | string
  toast: { (options?: UseToastOptions): string | number | undefined }
}

const formatDescription = (error: unknown | string): string => {
  const err = error as AxiosError
  const term =
    typeof err?.response?.data?.validation?.body?.message === 'string'
      ? String(err?.response?.data?.validation?.body?.message)
      : undefined || typeof err?.response?.data?.validation?.query?.message === 'string'
      ? String(err?.response?.data?.validation?.query?.message)
      : undefined || typeof err?.response?.data?.message === 'string'
      ? String(err?.response?.data?.message)
      : undefined || err?.response?.data?.data || String(err)
  let message = String(term)
  if (message.includes(MessageCodeEnum.WARNING)) {
    return message.replace(MessageCodeEnum.WARNING, '')
  }
  if (message.includes('/tmp/undefined')) {
    message =
      'Anexo não encontrado! Verifique se o anexo (CNH ou CRLV ou etc) foi enviado corretamente caso contrário anexe-o novamente.'
  }
  return message
}

export const toastError = ({ toast, error }: ToastError): void => {
  if (error) {
    function searchObject(obj: any, searchTerm: string): boolean {
      if (typeof obj === 'string' && obj.includes(searchTerm)) {
        return true
      }
      if (Array.isArray(obj)) {
        for (const item of obj) {
          if (searchObject(item, searchTerm)) {
            return true
          }
        }
      }
      if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
          if (searchObject(obj[key], searchTerm)) {
            return true
          }
        }
      }
      return false
    }

    toast({
      title: 'A requisição retornou o seguinte erro:',
      description: formatDescription(error),
      duration: 1000 * 15, // 15 seconds
      isClosable: true,
      position: 'top',
      status: searchObject(error, MessageCodeEnum.WARNING) ? 'warning' : 'error',
    })
  }
}
