import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { LoadingRequestStatusEnum } from '../../../types/EnumTypes'
import { LoadingRequestType } from '../../../types/LoadingRequestType'
import parseCurrency from '../../../utils/parseCurrency'

type LoadingRequestTypeFormatted = LoadingRequestType & {
  formatted_status:
    | 'Aguardando Análise'
    | 'CF Reprovado'
    | 'Aguardando CF'
    | 'Aguardando Pagamento'
    | 'Pagamento Realizado'
    | 'Finalizado'
  total_value_formatted: string
  total_gross_formatted: string
}

type ListLoadingRequestResponse = {
  total: number
  data: LoadingRequestTypeFormatted[]
}

export type FilterLoadingRequestsType = {
  freight_number?: string
  status?: LoadingRequestStatusEnum
  page?: number
}

function formatLoadingRequestStatus(status: LoadingRequestStatusEnum): string {
  const loadingOrderStatus = {
    awaiting_analysis: 'Aguardando análise',
    rejected: 'CF Reprovado',
    awaiting_cf: 'Aguardando CF',
    awaiting_payment: 'Aguardando Pagamento',
    paid: 'Pagamento Realizado',
    finished: 'Finalizado',
  }

  return loadingOrderStatus[status]
}

async function listLoadingRequest(params: FilterLoadingRequestsType) {
  const { data: response } = await apiServer.get(`/loading-requests`, {
    params,
  })

  const { data } = response
  const { total } = data

  const formattedLoadingRequests = data.data.map((loadingOrder: LoadingRequestType) => {
    return {
      ...loadingOrder,
      total_value_formatted: parseCurrency(loadingOrder.total_value),
      total_gross_formatted: parseCurrency(loadingOrder.total_gross),
      formatted_status: formatLoadingRequestStatus(loadingOrder.status),
      created_at: format(new Date(loadingOrder.created_at), 'dd/MM/yy HH:mm'),
    }
  })

  return { total, data: formattedLoadingRequests }
}

export function useGetAllLoadingRequest(
  params: FilterLoadingRequestsType,
): UseQueryResult<ListLoadingRequestResponse> {
  return useQuery(['loading-requests', params], () => listLoadingRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
