import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCaravanLine, RiEditLine } from 'react-icons/ri'
import { Ability } from '../../../components/ability'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../config/error/toastError'
import { useApiCarsRequestContext } from '../../../contexts/ApiCarsRequestContext'
import { apiServer } from '../../../services/api'
import { useIsBlockEntity } from '../../../services/endpoints/blocked-entities/isBlockEntity'
import { detachTrailerFromVehicle } from '../../../services/endpoints/vehicles/detachTrailerFromVehicle'
import { useGetTrailer } from '../../../services/endpoints/trailers/getTrailer'
import { VehicleResponseFormData } from '../../../services/endpoints/vehicles/getVehicle'
import { fetchVehicleByColumn } from '../../../services/endpoints/vehicles/getVehicleByColumn'
import { TrailerOwnerInfos } from '../cards/TrailerOwnerInfos'
import { TrailerForm, TrailerFormSchema } from '../TrailerForm'

export interface TrailerFormData {
  trailer: {
    type?: 'vehicle' | 'trailer'
    license_plate?: string
    license_uf?: string
    brand?: string
    model?: string
    release_year?: number
    model_year?: number
    renavam?: string
    chassi?: string
    antt?: string
    capacity_m3?: number
    capacity_tara?: number
    capacity_kg?: number
    vehicle_body: string
    vehicle_category?: string
    city_id?: AutocompleteOption
    color?: string
    axes?: number
    has_tracker?: boolean
    has_insurance?: boolean
  }
}

interface EditTrailerModalProps {
  trailer_id: string
  vehicle_id?: string
  motorist_id?: string
  isOpen: boolean
  onClose: () => void
}
export function EditTrailerModal({
  trailer_id,
  vehicle_id,
  motorist_id,
  isOpen,
  onClose,
}: EditTrailerModalProps): JSX.Element {
  const toast = useToast()
  const { data: trailer, refetch } = useGetTrailer(trailer_id)
  const { data: isBlockedEntity } = useIsBlockEntity({ trailer_id })
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()
  const { setTrailerApi } = useApiCarsRequestContext()
  const [vehicleExists, setVehicleExists] = useState<VehicleResponseFormData>()
  const renavamRef = useRef<HTMLInputElement>(null)
  async function handleCheckTrailerExists() {
    if (renavamRef.current) {
      const { value } = renavamRef.current
      try {
        const vehicle = await fetchVehicleByColumn({ columnName: 'renavam', columnValue: value })
        if (vehicle) {
          setVehicleExists(vehicle)
        } else {
          setVehicleExists(undefined)
        }
      } catch {
        setVehicleExists(undefined)
      }
    }
  }

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(TrailerFormSchema),
  })

  const handleEditTrailer = useCallback<SubmitHandler<TrailerFormData>>(
    async data => {
      const trailerFormData = {
        type: data.trailer.type,
        license_plate: data.trailer?.license_plate?.toLocaleUpperCase(),
        license_uf: data.trailer?.license_uf,
        brand: data.trailer?.brand,
        model: data.trailer?.model,
        release_year: data.trailer?.release_year,
        model_year: data.trailer?.model_year,
        renavam: data.trailer?.renavam,
        chassi: data.trailer?.chassi,
        antt: data.trailer?.antt,
        capacity_m3: data.trailer?.capacity_m3,
        capacity_tara: data.trailer?.capacity_tara,
        capacity_kg: data.trailer?.capacity_kg,
        vehicle_body_id: data.trailer.vehicle_body,
        vehicle_category_id: data.trailer.vehicle_category,
        city_id: data.trailer?.city_id?.value,
        color: data.trailer?.color,
        axes: data.trailer?.axes,
      }

      try {
        await apiServer.put(`/update-trailer/${trailer_id}`, trailerFormData)
        onClose()
        toast({
          title: 'Reboque editado com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        if (data.trailer.type === 'vehicle' && vehicle_id) {
          const result = await detachTrailerFromVehicle(vehicle_id, trailer_id)
          if (result.status === 200) {
            toast({
              status: 'success',
              title: 'Desvinculado com sucesso!',
              position: 'top-right',
              duration: 8000,
              isClosable: true,
            })
            refetch()
          }
        }
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [trailer_id, onClose, toast, vehicle_id, refetch],
  )

  useEffect(() => {
    refetch()
    setTrailerApi(undefined)
    setVehicleExists(undefined)
  }, [refetch, isOpen, setTrailerApi])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl">Editar reboque</ModalHeader>

        {vehicleExists && (
          <Flex mx={6}>
            <Alert status="error" my="4" borderRadius="4">
              <AlertIcon />
              <Flex>A placa digitada pertence a um veículo de tração</Flex>
            </Alert>
          </Flex>
        )}

        <form onSubmit={handleSubmit(handleEditTrailer)} noValidate>
          <ModalCloseButton />
          <ModalBody>
            {trailer && (
              <>
                <TrailerForm
                  setValue={setValue}
                  formState={formState}
                  initialData={trailer}
                  handleCheckTrailerExists={handleCheckTrailerExists}
                  renavamRef={renavamRef}
                  isEditTrailer
                />
                <Divider my="2" />

                <Flex>
                  <TrailerOwnerInfos motorist_id={motorist_id} trailer_id={trailer_id} />
                </Flex>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Stack direction={['column', 'row']}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>

              <Ability module="operation" action="detach-trailer">
                {vehicle_id && (
                  <Button
                    colorScheme="red"
                    leftIcon={<RiCaravanLine />}
                    onClick={() => {
                      onOpenConfirm()
                    }}
                  >
                    Desvincular da tração
                  </Button>
                )}
              </Ability>

              <Ability module="operation" action="update-trailer">
                <Button
                  type="submit"
                  colorScheme="orange"
                  isDisabled={isBlockedEntity}
                  leftIcon={<Icon as={RiEditLine} />}
                  isLoading={formState.isSubmitting}
                >
                  <Tooltip label={isBlockedEntity ?? 'Reboque em análise! Edição bloqueada!'}>Editar</Tooltip>
                </Button>
              </Ability>
            </Stack>
          </ModalFooter>
        </form>
        {vehicle_id && (
          <AlertDialogConfirm
            isOpen={isOpenConfirm}
            title="Desvincular reboque da tração"
            description="Tem certeza que deseja desvincular o reboque da tração?"
            onClose={onCloseConfirm}
            onConfirm={async () => {
              try {
                await detachTrailerFromVehicle(vehicle_id, trailer_id)

                toast({
                  status: 'success',
                  title: 'Reboque desvinculado da tração com sucesso!',
                  position: 'top-right',
                  duration: 8000,
                  isClosable: true,
                })

                refetch()
                onClose()
              } catch (error) {
                toastError({ toast, error })
              }
            }}
          />
        )}
      </ModalContent>
    </Modal>
  )
}
