import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'

type UpdateLoadingRequestType = {
  id: string
  data: FormData
}

async function updateLoadingRequest(data: UpdateLoadingRequestType) {
  const response = await apiServer.put(`/loading-requests/${data.id}`, data.data)
  queryClient.invalidateQueries('loading-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePutLoadingRequest(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateLoadingRequestType, unknown>,
) {
  return useMutation(async (data: UpdateLoadingRequestType) => updateLoadingRequest(data), options)
}
