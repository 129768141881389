import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Heading,
  Button,
  Icon,
  Link,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { RiEye2Line, RiStarFill } from 'react-icons/ri'
import { useGetFreights } from '../../../../../services/endpoints/freights/getFreights'
import { useGetMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { FreightType } from '../../../../../services/types'
import RateFreightType from '../../../../../services/types/RateFreightType'
import { ShowRateFreightModal } from './ShowRateFreightModal'

export interface MotoristFreightHistoryProps {
  motorist_id: string
  bg: string
}

export interface FreightHistoryType extends FreightType {
  formatted_created_at: string
}

export function MotoristFreightHistory({ motorist_id, bg }: MotoristFreightHistoryProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [rate, setRate] = useState<RateFreightType>()

  const { data: fetchedFreights } = useGetFreights({ motorist_id })
  const { data: motoristData } = useGetMotorist(motorist_id)

  function rateFreightAverage(data?: RateFreightType) {
    if (data) {
      const { behavior_score, cargo_state_score, ponctuality_score, reliability_score } = data
      const sum = behavior_score + cargo_state_score + ponctuality_score + reliability_score
      return sum / 4
    }

    return ''
  }

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Flex mb={['6', '8']} justify="space-between" align="center">
        <Heading size="lg" fontWeight="normal" display="flex" flexDirection="row">
          Histórico de fretes
          {motoristData?.rate_score && (
            <Text ml={1}>
              - {motoristData?.rate_score}
              <Icon as={RiStarFill} fontSize={22} color="orange" mb="1" ml="1" />
            </Text>
          )}
          {fetchedFreights?.total && <Text ml={1}>- {fetchedFreights?.total} Fretes</Text>}
        </Heading>
      </Flex>
      <Table d={['block', 'block', 'table']} overflowX="auto">
        <Thead>
          <Tr>
            <Th>Frete</Th>
            <Th>Nota</Th>
            <Th>Data</Th>
            <Th>Origem</Th>
            <Th>Destino</Th>
            <Th>Avaliação</Th>
            <Th textAlign="right">Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fetchedFreights?.data.map(history => (
            <Tr key={history.id}>
              <Td>#{history.freight_number}</Td>
              <Td fontSize="lg" color="orange" fontWeight="bold">
                {history.rate ? (
                  <>
                    <Icon as={RiStarFill} fontSize={20} mx="1" mb="1" />
                    {rateFreightAverage(history.rate)}
                  </>
                ) : (
                  'Frete não avaliado'
                )}
              </Td>
              <Td>{history.created_at}</Td>
              <Td>{history.origin.name}</Td>
              <Td>{history.destination.name}</Td>
              <Td>
                {history.rate && (
                  <Button
                    colorScheme="orange"
                    size="sm"
                    onClick={() => {
                      setRate(history.rate)
                      onOpen()
                    }}
                  >
                    Ver notas
                  </Button>
                )}
              </Td>
              <Td textAlign="right">
                <Button
                  as={Link}
                  isExternal
                  href={`/freights/show/${history.id}`}
                  size="sm"
                  variant="outline"
                  colorScheme="messenger"
                  leftIcon={<Icon as={RiEye2Line} />}
                >
                  Ver
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ShowRateFreightModal isOpen={isOpen} onClose={onClose} rate={rate} />
    </Box>
  )
}
