/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { publicApiServer } from '../../publicApi'
import { ResArrayApiType } from '../../types'
import MotoristWaypointType from '../../types/MotoristWaypointType'
import { FormattedOneFreight } from '../freights'

interface InputWaypoints {
  type: 'app' | 'whatsapp' | 'buonny'
  freight: FormattedOneFreight
  motorist_id?: string
  vehicle_id?: string
  amount?: number
  initial_date?: Date
  final_date?: Date
}

export type SelectedMarker = {
  lat: number
  lng: number
  waypoint_date_formatted?: string
  public_place?: string
  is_firstPoint?: boolean
  is_lastPoint?: boolean
  city?: {
    name: string
    uf: string
    is_origin?: boolean
    is_destination?: boolean
  }
}

export interface MotoristWaypointTypeFormatted extends MotoristWaypointType {
  waypoint_date_formatted: string
}

export interface OutputWaypoints {
  allWaypoints?: MotoristWaypointTypeFormatted[]
  firstWayPoint: SelectedMarker
  lastWayPoint: SelectedMarker
}

export interface InputGetDirectionService {
  firstWayPoint?: SelectedMarker
  lastWayPoint?: SelectedMarker
}
export interface OutputGetDirectionService {
  directions: google.maps.DirectionsResult | undefined
  distance: string
}

const formatDateWithHours = (date: Date | string): string => {
  const neqDate = new Date(date)
  return `${format(neqDate, 'dd/MM/yyyy')} às ${format(neqDate, 'HH:mm')}`
}

const getWaypointsFormatted = (
  freight: FormattedOneFreight,
  wayPoints?: MotoristWaypointType[],
): OutputWaypoints => {
  const newDate = formatDateWithHours(new Date())
  if (wayPoints && wayPoints.length > 0) {
    const wayPointsFormatted = wayPoints.map(wayPoint => ({
      ...wayPoint,
      lat: Number(wayPoint.lat),
      lng: Number(wayPoint.lng),
      waypoint_date: new Date(wayPoint.waypoint_date),
      waypoint_date_formatted: formatDateWithHours(wayPoint.waypoint_date),
    }))
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const waypointsAfterFreightCreated = wayPointsFormatted.filter(wayPoint => {
        return new Date(wayPoint.waypoint_date).getTime() >= new Date(freight.created_at_default).getTime()
      })
      return {
        allWaypoints: waypointsAfterFreightCreated,
        firstWayPoint: {
          lat: Number(waypointsAfterFreightCreated[0].lat),
          lng: Number(waypointsAfterFreightCreated[0].lng),
          public_place: waypointsAfterFreightCreated[0].public_place,
          city: {
            name: waypointsAfterFreightCreated[0].city_name || '',
            uf: waypointsAfterFreightCreated[0].uf || '',
          },
          waypoint_date_formatted:
            formatDateWithHours(waypointsAfterFreightCreated[0].waypoint_date) || newDate,
        },
        lastWayPoint: {
          lat: Number(waypointsAfterFreightCreated[waypointsAfterFreightCreated.length - 1].lat) || 0,
          lng: Number(waypointsAfterFreightCreated[waypointsAfterFreightCreated.length - 1].lng) || 0,
          public_place: waypointsAfterFreightCreated[waypointsAfterFreightCreated.length - 1].public_place,
          city: {
            name: waypointsAfterFreightCreated[waypointsAfterFreightCreated.length - 1].city_name || '',
            uf: waypointsAfterFreightCreated[waypointsAfterFreightCreated.length - 1].uf || '',
          },
          waypoint_date_formatted:
            formatDateWithHours(
              waypointsAfterFreightCreated[waypointsAfterFreightCreated.length - 1].waypoint_date,
            ) || newDate,
        },
      }
    }
    return {
      allWaypoints: wayPointsFormatted,
      firstWayPoint: {
        lat: Number(wayPointsFormatted[0].lat),
        lng: Number(wayPointsFormatted[0].lng),
        public_place: wayPointsFormatted[0].public_place,
        city: {
          name: wayPointsFormatted[0].city_name || '',
          uf: wayPointsFormatted[0].uf || '',
        },
        waypoint_date_formatted: formatDateWithHours(wayPointsFormatted[0].waypoint_date) || newDate,
      },
      lastWayPoint: {
        lat: Number(wayPointsFormatted[wayPointsFormatted.length - 1].lat) || 0,
        lng: Number(wayPointsFormatted[wayPointsFormatted.length - 1].lng) || 0,
        public_place: wayPointsFormatted[wayPointsFormatted.length - 1].public_place,
        city: {
          name: wayPointsFormatted[wayPointsFormatted.length - 1].city_name || '',
          uf: wayPointsFormatted[wayPointsFormatted.length - 1].uf || '',
        },
        waypoint_date_formatted:
          formatDateWithHours(wayPointsFormatted[wayPointsFormatted.length - 1].waypoint_date) || newDate,
      },
    }
  }
  return {
    firstWayPoint: {
      lat: Number(freight.origin.lat),
      lng: Number(freight.origin.lng),
      city: {
        name: freight.origin.name,
        uf: freight.origin.state.uf,
      },
    },
    lastWayPoint: {
      lat: Number(freight.destination.lat),
      lng: Number(freight.destination.lng),
      city: {
        name: freight.destination.name,
        uf: freight.destination.state.uf,
      },
    },
  }
}

const status = ['in_origin', 'collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished']
export async function getWaypoints(filters: InputWaypoints): Promise<OutputWaypoints> {
  const { freight } = filters
  let inProgress = false
  inProgress = status.includes(freight.status)
  if (inProgress) {
    const { data: response } = await publicApiServer.get<ResArrayApiType<MotoristWaypointType>>(
      'motorist/way-points',
      {
        params: {
          type: filters.type,
          freight_id: filters.freight.id,
          motorist_id: filters.motorist_id,
          vehicle_id: filters.vehicle_id,
          amount: filters.amount,
          initial_date: filters.initial_date,
          final_date: filters.final_date,
        },
      },
    )
    const outputWayPoints = getWaypointsFormatted(freight, response.data)
    return outputWayPoints
  }
  const outputWayPoints = getWaypointsFormatted(freight)
  return outputWayPoints
}

export function useGetWaypoints(params: InputWaypoints): UseQueryResult<OutputWaypoints> {
  return useQuery(['motorist_waypoints', params, params.freight?.status], () => getWaypoints(params), {
    staleTime: 1000 * 60 * 5, // 5 minutes,
    refetchInterval: params.freight?.status === 'finished' ? false : 1000 * 60 * 1, // 1 minute
  })
}

const getDirectionService = async (
  params: InputGetDirectionService,
): Promise<OutputGetDirectionService | undefined> => {
  const Google = window.google
  if (!Google || !Google.maps) return
  const directionsService = new Google.maps.DirectionsService()
  if (!params.firstWayPoint || !params.lastWayPoint) return
  const directions = await directionsService.route({
    origin: { lat: Number(params.firstWayPoint.lat), lng: Number(params.firstWayPoint.lng) },
    destination: { lat: Number(params.lastWayPoint.lat), lng: Number(params.lastWayPoint.lng) },
    travelMode: google.maps.TravelMode.DRIVING,
  })
  if (!directions) return
  const distance = String(directions.routes[0]?.legs[0]?.distance?.text) || ''
  const output = { directions, distance }
  return output
}

export function useGetDirectionService(
  params: InputGetDirectionService,
): UseQueryResult<OutputGetDirectionService> {
  return useQuery(['google_directions_service', params, window?.google], () => getDirectionService(params), {
    staleTime: 1000 * 60 * 15, // 15 minutes,
    retry: true,
  })
}
