/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatch } from '..'
import { FormattedOneFreight } from '../../services/endpoints/freights'
import { FreightPendenciesResult } from '../../services/endpoints/freights/checkPendencies'
import { FormattedMotorist } from '../../services/endpoints/motorists/getMotorist'

interface State {
  isLoading: boolean
  has_pendencies: boolean
  driverLicenseHasPendencies: boolean
  motoristHasPendencies: boolean
  vehicleHasPendencies: boolean
  anttOwnerHasPendencies: boolean
  trailerHasPendencies: boolean
  vehicleOwnerHasPendencies: boolean
  trailerOwnerHasPendencies: boolean
  routePointsHasPendencies: boolean
  all_pendencies: FreightPendenciesResult | null
}

const initialState: State = {
  isLoading: false,
  driverLicenseHasPendencies: true,
  motoristHasPendencies: true,
  vehicleHasPendencies: true,
  anttOwnerHasPendencies: true,
  trailerHasPendencies: true,
  vehicleOwnerHasPendencies: true,
  trailerOwnerHasPendencies: true,
  has_pendencies: true,
  all_pendencies: null,
  routePointsHasPendencies: true,
}

const freightPendenciesSlice = createSlice({
  name: 'motorists',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },

    setPendencies(state, action: PayloadAction<FreightPendenciesResult>) {
      state.all_pendencies = action.payload
    },

    setHasPendencies(state, action: PayloadAction<boolean>) {
      state.has_pendencies = action.payload
    },

    setDriverLicenseHasPendencies(state, action: PayloadAction<boolean>) {
      state.driverLicenseHasPendencies = action.payload
    },

    setMotoristHasPendencies(state, action: PayloadAction<boolean>) {
      state.motoristHasPendencies = action.payload
    },

    setVehicleHasPendencies(state, action: PayloadAction<boolean>) {
      state.vehicleHasPendencies = action.payload
    },

    setAnttOwnerHasPendencies(state, action: PayloadAction<boolean>) {
      state.anttOwnerHasPendencies = action.payload
    },

    setRoutePointsHasPendencies(state, action: PayloadAction<boolean>) {
      state.routePointsHasPendencies = action.payload
    },

    setTrailerHasPendencies(state, action: PayloadAction<boolean>) {
      state.trailerHasPendencies = action.payload
    },

    setVehicleOwnerHasPendencies(state, action: PayloadAction<boolean>) {
      state.vehicleOwnerHasPendencies = action.payload
    },

    setTrailerOwnerHasPendencies(state, action: PayloadAction<boolean>) {
      state.trailerOwnerHasPendencies = action.payload
    },
  },
})

export const {
  setIsLoading,
  setHasPendencies,
  setDriverLicenseHasPendencies,
  setMotoristHasPendencies,
  setTrailerHasPendencies,
  setTrailerOwnerHasPendencies,
  setVehicleHasPendencies,
  setAnttOwnerHasPendencies,
  setVehicleOwnerHasPendencies,
  setRoutePointsHasPendencies,
  setPendencies,
} = freightPendenciesSlice.actions
export default freightPendenciesSlice.reducer

export function loading(isLoading: boolean): void {
  dispatch(freightPendenciesSlice.actions.setIsLoading(isLoading))
}

export function getPendencies(
  result?: FreightPendenciesResult,
  freight?: FormattedOneFreight,
  motorist?: FormattedMotorist,
): () => Promise<unknown> {
  return async () => {
    if (
      motorist &&
      motorist.driver_license &&
      motorist.attachments?.some(
        i => i.type === 'cnh' && i.attachment_file_url && !i.attachment_file_url.includes('/undefined'),
      )
    ) {
      dispatch(setDriverLicenseHasPendencies(false))
    } else {
      dispatch(setDriverLicenseHasPendencies(true))
    }

    if (result) {
      if (
        result.cnh.length ||
        result.motorist.length ||
        result.vehicle.length ||
        result.trailer?.length ||
        result.anttOwner.length ||
        !result.vehicleOwner ||
        result.vehicleOwner.length ||
        !result.trailer ||
        !result.trailerOwner ||
        result.trailerOwner.length
      ) {
        dispatch(setHasPendencies(true))
      } else {
        dispatch(setHasPendencies(false))
      }

      if (result.motorist.length) {
        dispatch(setMotoristHasPendencies(true))
      } else {
        dispatch(setMotoristHasPendencies(false))
      }

      if (result.vehicle.length) {
        dispatch(setVehicleHasPendencies(true))
      } else {
        dispatch(setVehicleHasPendencies(false))
      }

      if (result.anttOwner.length) {
        dispatch(setAnttOwnerHasPendencies(true))
      } else {
        dispatch(setAnttOwnerHasPendencies(false))
      }

      if (result.routePoints.length && freight?.type !== 'areal') {
        dispatch(setRoutePointsHasPendencies(true))
        dispatch(setHasPendencies(true))
      } else {
        dispatch(setRoutePointsHasPendencies(false))
      }

      if (!result.vehicleOwner || result.vehicleOwner.length) {
        dispatch(setVehicleOwnerHasPendencies(true))
      } else {
        dispatch(setVehicleOwnerHasPendencies(false))
      }

      if (result.trailer?.length) {
        dispatch(setTrailerHasPendencies(true))
      } else {
        dispatch(setTrailerHasPendencies(false))
      }

      if (!result.trailerOwner || result.trailerOwner.length) {
        dispatch(setTrailerOwnerHasPendencies(true))
      } else {
        dispatch(setTrailerOwnerHasPendencies(false))
      }
    }
    return result
  }
}
