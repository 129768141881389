import { Button, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react'
import { RiArrowDownSLine, RiTruckLine } from 'react-icons/ri'
import { ChangeFreightLinkFreightContractModal } from '../../../pages/freight-contracts/list/modals/ChangeFreightContractRelatedFreightModal'
import { FreightContractType } from '../../../services/types/FreightContractType'

interface ButtonsActionsProps {
  freightContract: FreightContractType
}
export function ButtonsActions({ freightContract }: ButtonsActionsProps): JSX.Element {
  const {
    isOpen: isOpenChangeRelatedFreightModal,
    onClose: onCloseChangeRelatedFreightModal,
    onOpen: onOpenChangeRelatedFreightModal,
  } = useDisclosure()

  return (
    <>
      {freightContract && (
        <HStack spacing="2">
          <Menu>
            <MenuButton
              as={Button}
              size="xs"
              variant="outline"
              colorScheme="orange"
              rightIcon={<Icon as={RiArrowDownSLine} />}
            >
              Ações
            </MenuButton>
            <MenuList>
              {/* <Ability module="operation" action="generate-pdf-document-handler-cte">
                {cte.pdf_file_url ? (
                  <MenuItem
                    icon={<GrDocumentPdf size="18" />}
                    as={Link}
                    href={cte.pdf_file_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Baixar PDF
                  </MenuItem>
                ) : (
                  <MenuItem
                    icon={<GrDocumentPdf size="18" />}
                    isLoading={isLoadingPdfUrl}
                    onClick={async () => {
                      try {
                        const { data: pdfUrl } = await getPdfCte(cte.key)
                        if (pdfUrl) window.open(pdfUrl, '_blank')
                      } catch (error) {
                        toastError({ toast, error })
                      }
                    }}
                  >
                    Baixar PDF
                  </MenuItem>
                )}
              </Ability> */}

              {/* <Ability module="operation" action="change-freight-related-to-cte"> */}
              <MenuItem
                icon={<RiTruckLine size="18" color="blue" />}
                onClick={onOpenChangeRelatedFreightModal}
              >
                Mudar frete vinculado
              </MenuItem>
              {/* </Ability> */}
            </MenuList>
          </Menu>
        </HStack>
      )}

      <ChangeFreightLinkFreightContractModal
        isOpen={isOpenChangeRelatedFreightModal}
        onClose={() => {
          onCloseChangeRelatedFreightModal()
        }}
        id={freightContract.id}
      />
    </>
  )
}
