import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { RiMapPinLine } from 'react-icons/ri'
import { AccordionFreightPhases } from '../../../../../components/accordionFreightPhases/AccordionFreightPhases'
import MapComponent from '../../../../../components/GoogleMapComponents/MapComponent'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { Timeline } from '../monitoring-timeline/Timeline'
import { UpdateCheckinModal } from '../selected-motorist/UpdateCheckinModal'

interface FreighStatusTimelineProps {
  freight: FormattedOneFreight
}

const FreighStatusTimeline = ({ freight }: FreighStatusTimelineProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  const {
    isOpen: isUpdateCheckinOpen,
    onClose: onUpdateCheckinClose,
    onOpen: onUpdatecheckinOpen,
  } = useDisclosure()

  const { isOpen: isOpenTimeline, onClose: onCloseTimeline, onOpen: onOpenTimeline } = useDisclosure()

  if (freight.status === 'pending') {
    return (
      <Flex p="2" py="4" rounded="md" flex="1" maxH="500px" bg={bg}>
        <MapComponent freight={freight} type="app" />
      </Flex>
    )
  }

  return (
    <>
      <Accordion borderRadius="md" bg={bg} allowToggle w="100%" defaultIndex={[0]}>
        <AccordionItem border="none" w="100%">
          <AccordionButton
            border="0px"
            _active={{ outline: 'none' }}
            _focus={{ outline: 'none' }}
            _hover={{ outline: 'none', bg: 'none' }}
            py="3"
          >
            <Box as="span" flex="1" textAlign="left" fontSize="md" fontWeight="medium">
              Status do Frete
              <Badge ml="2" px="2" py="1" colorScheme={freight?.next_status ? 'green' : 'red'}>
                {freight.formatted_status}
              </Badge>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <Flex flexWrap="wrap" gridGap="4" flexDir={['column', 'row']}>
              <Box border="1px" borderColor="gray.100" p="2" py="4" rounded="md" flex="1" maxW="450px">
                <Flex mb="4" gridGap="2">
                  {freight.motorist_id && freight.vehicle_id && freight.has_monitoring_app && (
                    <Button colorScheme="orange" size="xs" onClick={onOpenTimeline}>
                      Ver timeline de monitoramento
                    </Button>
                  )}

                  {freight.status === 'on_road' && (
                    <Button
                      size="xs"
                      colorScheme="green"
                      leftIcon={<RiMapPinLine />}
                      onClick={onUpdatecheckinOpen}
                    >
                      Atualizar checkin
                    </Button>
                  )}
                </Flex>
                <AccordionFreightPhases />
              </Box>
              <Flex
                justifyContent="center"
                alignItems="center"
                border="1px"
                borderColor="gray.100"
                p="2"
                py="4"
                rounded="md"
                flex="1"
                maxH="500px"
              >
                <MapComponent freight={freight} type="app" />
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Timeline freight={freight} isOpen={isOpenTimeline} onClose={onCloseTimeline} />

      <UpdateCheckinModal
        isOpen={isUpdateCheckinOpen}
        onClose={() => {
          onUpdateCheckinClose()
        }}
        motorist_id={String(freight.motorist?.id)}
      />
    </>
  )
}

export default FreighStatusTimeline
