import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  StatHelpText,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine, RiStarFill } from 'react-icons/ri'
import * as yup from 'yup'
import { InputMask } from '../../../../components/form/InputMask'
import { RadioGroup } from '../../../../components/form/RadioGroup'
import { Select } from '../../../../components/form/Select'
import { SelectOption } from '../../../../components/form/types/SelectOption'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { useGetFreights } from '../../../../services/endpoints/freights/getFreights'
import { usePatchRiskAnalysis } from '../../../../services/endpoints/risk-analysis/patchRiskAnalysis'
import { useCheckRiskByBuonny } from '../../../../services/endpoints/risk-management/check-risk-by-buonny'
import BlackListType from '../../../../services/types/BlackListType'
import { buonnyItemTypesOptions } from '../../../../services/utils/getBuonnyItemTypes'
import { validateCpf } from '../../../../services/utils/validateCpf'
import { useAppSelector } from '../../../../store'
import { handleCpfIsBlackListed } from '../../../black-list/check-document-exists/handleCpfIsBlackListed'
import { ShowInfosBlackListModal } from '../../../black-list/modals/ShowInfosBlackListModal'
import { CheckRiskStatus } from '../../show/cards/check-risk-ststus/CheckRiskStatus'
import { ShowRegisterMotoristToBuonnyModal } from '../ShowRegisterMotoristToBuonnyModal'

interface CheckBuonnyFormData {
  document: string
  carter: string
  vehicle_license_plate: string
  trailer_license_plate: string
  buonny_cargo_type: SelectOption
  buonny_cargo_price: number
}

export interface BuonnyResponseStatus {
  motorist: string
  document: string
  vehicle: string
  trailer?: string
  vehicle_license_plate: string
  trailer_license_plate?: string
  status: string
  message: string
  consult_number: string
}

const checkBuonnyFormSchema = yup.object().shape({
  document: yup
    .string()
    .test('validate-cpf', 'CPF inválido', value => {
      return validateCpf(value)
    })
    .min(11, 'CPF incompleto')
    .required('Campo obrigatório'),
  carter: yup.string().required('Campo obrigatório'),
  vehicle_license_plate: yup.string().min(7, 'Placa inválida').required('Campo obrigatório'),
  trailer_license_plate: yup.string(),
  buonny_cargo_type: yup.string().required('Campo obrigatório'),
  buonny_cargo_price: yup.number().required('Campo obrigatório'),
})

export function CheckBuonny(): JSX.Element {
  const toast = useToast()
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()
  const checkRiskByBuonny = useCheckRiskByBuonny({
    onSuccess: () => {
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('one-risk-analysis')
      queryClient.invalidateQueries('cost-risk-analysis')
      queryClient.invalidateQueries('check-cost-risk-analysis')
      toast({
        title: 'Consulta na BuoNny realizada com sucesso',
        status: 'success',
        duration: 1000 * 5, // 5 seconds
        isClosable: true,
        position: 'top-right',
      })
    },
  })
  const { riskAnalysis } = useAppSelector(state => state.riskAnalysisSlice)
  const bg = useColorModeValue('white', 'gray.800')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [cpfInBlackList, setCpfInBlackList] = useState<BlackListType>()
  const [buonnyStatus, setBuonnyStatus] = useState<BuonnyResponseStatus>()
  const { data: motoristFreights } = useGetFreights({
    motorist_id: riskAnalysis?.motorist_id,
    status: 'finished',
    per_page: 5,
  })

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(checkBuonnyFormSchema),
  })

  const { errors } = formState

  const handleCheckBuonny: SubmitHandler<CheckBuonnyFormData> = async data => {
    if (riskAnalysis) {
      try {
        const plates = [data.vehicle_license_plate]
        if (data.trailer_license_plate) {
          plates.push(data.trailer_license_plate)
        }
        const { data: response } = await checkRiskByBuonny.mutateAsync({
          product: 2,
          document: data.document,
          carter: data.carter,
          plates,
          buonny_cargo_type: String(data.buonny_cargo_type),
          buonny_cargo_price: String(data.buonny_cargo_price),
          freight_id: riskAnalysis.freight.id,
          motorist_id: riskAnalysis.motorist.id,
          vehicle_id: riskAnalysis.vehicle.id,
        })

        if (response.data.buonny.status === 'PERFIL ADEQUADO AO RISCO') {
          await mutateAsyncChangeStatus({
            id: riskAnalysis?.id,
            consultation_code: response.data.buonny.status,
            status: 'awaiting_approval',
          })
        } else if (response.data.buonny.status !== 'PERFIL ADEQUADO AO RISCO') {
          mutateAsyncChangeStatus({
            id: riskAnalysis?.id,
            consultation_code: response.data.buonny.status,
            status: 'awaiting',
          })
        }

        setBuonnyStatus({
          motorist: response.data.motorist.name,
          document: response.data.motorist.cpf,
          vehicle: response.data.motorist.vehicle,
          trailer: response.data.motorist.trailer,
          vehicle_license_plate: response.data.motorist.vehicle_license_plate,
          trailer_license_plate: response.data.motorist.trailer_license_plate,
          status: response.data.buonny.status,
          message: response.data.buonny.mensagem,
          consult_number: response.data.buonny.consulta,
        })
      } catch (error) {
        toastError({ toast, error })
      }
    }
  }

  return buonnyStatus && riskAnalysis ? (
    <Box>
      <CheckRiskStatus buonnyStatus={buonnyStatus} />
      {buonnyStatus.status !== 'PERFIL ADEQUADO AO RISCO' && (
        <Flex justify="flex-end" mb="2">
          <ShowRegisterMotoristToBuonnyModal setValue={setValue} formState={formState} />
        </Flex>
      )}
    </Box>
  ) : riskAnalysis && riskAnalysis.motorist ? (
    <form onSubmit={handleSubmit(handleCheckBuonny)}>
      <Heading fontWeight="normal" size="md" mb="6" color="gray.500">
        Verificar junto à Buonny se o motorista <strong>{riskAnalysis.motorist.name}</strong> está apto a
        realizar o frete.
      </Heading>
      <Stack spacing="4">
        <SimpleGrid minChildWidth="150px" spacing="4">
          <InputMask
            isRequired
            mask="999.999.999-99"
            autoFocus
            maskPlaceholder=""
            name="document"
            label="CPF motoristas"
            setValue={setValue}
            isDisabled
            error={errors.document}
            initialValue={riskAnalysis.motorist.cpf}
            onBlur={async e => {
              const data = await handleCpfIsBlackListed(e.target.value)
              if (data) {
                setCpfInBlackList(data)
                onOpen()
              } else {
                setCpfInBlackList(undefined)
              }
            }}
            onChange={async e => {
              if (e.target.value.length === 14) {
                const data = await handleCpfIsBlackListed(e.target.value)
                if (data) {
                  setCpfInBlackList(data)
                  onOpen()
                } else {
                  setCpfInBlackList(undefined)
                }
              }
            }}
            registerOnlyNumbers
          />
          <RadioGroup
            name="carter"
            label="Carreteiro?"
            isDisabled
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="S"
            setValue={setValue}
            error={errors.carter}
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="150px" spacing="4">
          <InputMask
            isRequired
            mask="aaa9*99"
            isDisabled
            maskPlaceholder=""
            name="vehicle_license_plate"
            label="Placa (cavalo)"
            setValue={setValue}
            error={errors.vehicle_license_plate}
            initialValue={riskAnalysis.vehicle?.license_plate}
          />
          {riskAnalysis.vehicle?.vehicle_category.has_trailer && (
            <InputMask
              isRequired
              mask="aaa9*99"
              maskPlaceholder=""
              isDisabled
              name="trailer_license_plate"
              label="Placa (carroceria)"
              setValue={setValue}
              error={errors.trailer_license_plate}
              initialValue={
                riskAnalysis.vehicle.trailers.length > 0
                  ? riskAnalysis.vehicle.trailers[0]?.license_plate
                  : undefined
              }
            />
          )}
        </SimpleGrid>
        <SimpleGrid minChildWidth="150px" spacing="4">
          <Select
            isRequired
            isDisabled
            name="buonny_cargo_type"
            label="Tipo de carga"
            setValue={setValue}
            options={buonnyItemTypesOptions}
            error={errors.buonny_cargo_type}
            initialValue={riskAnalysis.freight.buonny_cargo_type}
          />
          <Select
            isRequired
            name="buonny_cargo_price"
            label="Valor da carga"
            isDisabled
            setValue={setValue}
            error={errors.buonny_cargo_price}
            options={[
              { label: '', value: '' },
              { label: 'De R$ 0,01 a R$ 100.000,00', value: '50000' },
              { label: 'De R$ 100.000,01 a R$ 200.000,00', value: '150000' },
              { label: 'De R$ 200.000,01 a R$ 300.000,00', value: '250000' },
              { label: 'De R$ 300.000,01 a R$ 400.000,00', value: '350000' },
              { label: 'De R$ 400.000,01 a R$ 500.000,00', value: '450000' },
              { label: 'De R$ 500.000,01 a R$ 800.000,00', value: '550000' },
              { label: 'De R$ 800.000,01 a R$ 1.000.000,00', value: '900000' },
              {
                label: 'De R$ 1.000.000,01 a R$ 3.000.000,00',
                value: '2000000',
              },
              {
                label: 'De R$ 3.000.000,01 a R$ 9.999.999,00',
                value: '5000000',
              },
            ]}
            initialValue={riskAnalysis.freight.buonny_cargo_price}
          />
        </SimpleGrid>
      </Stack>

      {motoristFreights && motoristFreights.total > 0 && (
        <Box my="8" bgColor={bg} p="4" borderRadius="8" maxH="240px" overflowY="auto">
          <Heading fontWeight="normal" size="md" color="gray.500">
            Últimas avaliações do profissional
          </Heading>

          <VStack spacing="2" mt="4">
            {motoristFreights.data.map(motoristFreight => (
              <Box bg={bg} p="3" w="100%" border="1px" borderRadius="4" borderColor="gray.300">
                <Flex d="flex" alignItems="flex-end" justifyContent="space-between">
                  <Box>
                    <Icon as={RiStarFill} fontSize={22} color="orange" mb="2" />
                    <Text as="span" fontWeight="bold" mx="1" color="orange" fontSize="lg">
                      {motoristFreight.rate?.behavior_score}
                    </Text>
                    <Text as="span" fontWeight="bold" fontSize="md">
                      - {motoristFreight.origin.name}/{motoristFreight.origin.state.uf}
                    </Text>
                    -
                    <Text as="span" fontWeight="bold" fontSize="md">
                      {motoristFreight.destination.name}/{motoristFreight.destination.state.uf}
                    </Text>
                  </Box>
                  <Button
                    colorScheme="orange"
                    as={Link}
                    size="sm"
                    ml="2"
                    href={`/freights/show/${motoristFreight.id}`}
                    isExternal
                  >
                    Ver
                  </Button>
                </Flex>
                <StatHelpText>{motoristFreight.rate?.description}</StatHelpText>
              </Box>
            ))}
          </VStack>
          {cpfInBlackList && (
            <ShowInfosBlackListModal isOpen={isOpen} onClose={onClose} black_list={cpfInBlackList} />
          )}
        </Box>
      )}

      <Stack spacing="2" direction={['column', 'row']} mt="4" py="2" justify="flex-end">
        <ShowRegisterMotoristToBuonnyModal setValue={setValue} formState={formState} />
        <Button
          type="submit"
          colorScheme="green"
          isDisabled={!!cpfInBlackList}
          leftIcon={<Icon as={RiCheckLine} />}
          isLoading={formState.isSubmitting || checkRiskByBuonny.isLoading || isLoadingChangeStatus}
        >
          Verificar
        </Button>
      </Stack>
    </form>
  ) : (
    <Alert status="error">
      <AlertIcon />
      Motorista não encontrado
    </Alert>
  )
}
