import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { AutocompleteOption } from '../../../../components/form'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'

type CreateLoadingRequestType = {
  freight_id: string
  cte?: string
  discount_buonny: string
  reason: string
  type: string
  total_value: string
  total_gross: string
  beneficiary_name: string
  beneficiary_document: string
  origin?: string
  destination?: string
  e_frete: string
  observation?: string
  pix?: string
  bank?: AutocompleteOption
  account_type?: string
  agency?: string
  account?: string
  account_digit?: string
  motorist_id?: AutocompleteOption
}

async function createLoadingRequest(data: CreateLoadingRequestType) {
  const response = await apiServer.post('/loading-requests', {
    ...data,
    bank: data.bank?.label,
    motorist_id: data.motorist_id?.value,
    discount_buonny: Number(data.discount_buonny),
    cte_id: data.cte,
  })
  queryClient.invalidateQueries('loading-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePostLoadingRequest(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateLoadingRequestType, unknown>,
) {
  return useMutation(async (data: CreateLoadingRequestType) => createLoadingRequest(data), options)
}
