import { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { TbTrash } from 'react-icons/tb'
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { Switch } from '../form/Switch'
import parseCurrency from '../../services/utils/parseCurrency'
import { CreateCostRiskAnalysis } from './CreateCostRiskAnalysis'
import { queryClient } from '../../config/react-query'
import { useDeleteCostRiskAnalysis } from '../../services/endpoints/risk-analysis/cost-risk-analysis/deleteCostRiskAnalysis'
import { useGetCostRiskAnalysis } from '../../services/endpoints/risk-analysis/cost-risk-analysis/getCostRiskAnalysis'
import { usePutCostRiskAnalysis } from '../../services/endpoints/risk-analysis/cost-risk-analysis/patchCostRiskAnalysis'

interface ICostRiskAnalysisProps {
  risk_analysis_id: string
  freight_id: string | undefined
  motorist_id: string | undefined
  bg: string
}

export function CostRiskAnalysis({
  bg,
  risk_analysis_id,
  freight_id,
  motorist_id,
}: ICostRiskAnalysisProps): JSX.Element {
  const [page] = useState(1)

  const {
    isOpen: isOpenCreateCostRiskAnalysis,
    onOpen: onOpenCreateCostRiskAnalysis,
    onClose: onCloseCreateCostRiskAnalysis,
  } = useDisclosure()

  const { setValue } = useForm({})

  const { data: costRiskAnalysis, isLoading } = useGetCostRiskAnalysis({
    risk_analysis_id,
    page,
    per_page: 10,
  })

  const { mutateAsync: mutateAsyncDelete } = useDeleteCostRiskAnalysis({
    onSuccess: () => {
      queryClient.invalidateQueries('cost-risk-analysis')
      queryClient.invalidateQueries('check-cost-risk-analysis')
    },
  })

  const { mutateAsync: patchCostRiskAnalysis } = usePutCostRiskAnalysis({
    onSuccess: () => {
      queryClient.invalidateQueries('cost-risk-analysis')
      queryClient.invalidateQueries('check-cost-risk-analysis')
    },
  })

  const handleDeleteCostRiskAnalysis = async (id: string) => {
    await mutateAsyncDelete(id)
  }

  return (
    <>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8" mb="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Custos de análise de risco
          </Heading>
          <Button
            size="sm"
            colorScheme="orange"
            onClick={() => {
              onOpenCreateCostRiskAnalysis()
            }}
          >
            + Adicionar custo
          </Button>
        </Flex>

        <Divider my="6" />

        <>
          {costRiskAnalysis && costRiskAnalysis.data.length > 0 ? (
            <TableContainer maxW="1100px">
              {!isLoading ? (
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>Data de criação</Th>
                      <Th>Gerenciador de risco</Th>
                      <Th>Valor</Th>
                      <Th>Motorista</Th>
                      <Th>Status do pagam.</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody flex={1}>
                    {costRiskAnalysis?.data.map(costRiskAnalysy => (
                      <Tr p={2} key={costRiskAnalysy.id}>
                        <Td maxW="100px">{format(new Date(costRiskAnalysy.created_at), 'dd/MM/yyyy')}</Td>
                        <Td textTransform="uppercase">{costRiskAnalysy.risk_manager}</Td>
                        <Td maxW="160px">{parseCurrency(costRiskAnalysy.value)}</Td>
                        <Td maxW="200px">{costRiskAnalysy?.motorist?.name}</Td>
                        <Td>
                          <Switch
                            setValue={setValue}
                            name="paid_out_status"
                            initialValue={costRiskAnalysy.paid_out}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
                            onChange={e => {
                              patchCostRiskAnalysis(costRiskAnalysy.id)
                            }}
                          />
                        </Td>

                        <Td p={2}>
                          <Flex justifyContent="center">
                            <Tooltip label="Deletar registro" hasArrow placement="bottom">
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => {
                                  handleDeleteCostRiskAnalysis(costRiskAnalysy.id)
                                }}
                              >
                                <Icon as={TbTrash} />
                              </Button>
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <>
                  <Flex justifyContent="center">
                    <Spinner />
                  </Flex>
                </>
              )}
            </TableContainer>
          ) : (
            <Flex justifyContent="center">
              <Text>Nenhuma custo registrado</Text>
            </Flex>
          )}
        </>

        {isOpenCreateCostRiskAnalysis && (
          <CreateCostRiskAnalysis
            isOpen={isOpenCreateCostRiskAnalysis}
            onClose={onCloseCreateCostRiskAnalysis}
            freight_id={freight_id}
            motorist_id={motorist_id}
            risk_analysis_id={risk_analysis_id}
          />
        )}
      </Box>
    </>
  )
}
